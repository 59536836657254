import jspreadsheet from "jspreadsheet-ce";
import { useEffect, useRef, useState, useCallback, useContext } from "react";
import { Grid } from "@mui/material";
// import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import "jspreadsheet-ce/dist/jspreadsheet.css";
import "jsuites/dist/jsuites.css";
import { createGlobalStyle } from "styled-components";
import { ApsContext } from "ApsContext";
import { useParts } from "services/query-service";
import CrudDialog from "customInputs/crud-dialog";
import { useAPSDB } from "hooks/useAPSDB";

// partNumber updates last for retaining reference for other fields
const partKeys = ["description", "supplier", "cost", "labour", "stock"];

const SpreadsheetStyle = createGlobalStyle`
  /* Body style adjusted here */
  body {
    font-size: 0.8rem;
    line-height: 1.5;
  }
  `;

/* part: {
      filter1: "",
      filter2: "",
      partNumber: "",
      description: "",
      supplier: "",
      cost: 0,
      labour: 0,
    } */

// if input props are undefined; use defaults set at useState
// e.target.value is the only prop used by functions below
// removeRow = (part) => {};
// handleUpdateDetail = (e, part, mode) => {}; mode one of many from Object.keys(part)
// handleUpdateQty = (e, part) => {};
export default function CustomSpreadSheet({
  data,
  columns,
  removeRow,
  handleUpdateDetail,
  handleUpdateQty,
  handleNewPart,
  handleMoveRow,
  viewingHeight,
}) {
  const {
    promptNotification,
    blankPart,
    partCostLimit,
    partQtyLimit,
    partLabourLimit,
  } = useContext(ApsContext);
  const { preventKeys, preventPasteNegative, partAssembler } = useAPSDB();
  const { data: allPartsData, isSuccess } = useParts();
  const tableRef = useRef(null);
  const [jspreadsheetInstance, setJspreadsheetInstance] = useState(null);
  const [columnsDef, setColumnDef] = useState([
    {
      type: "text",
      title: "Default",
      width: 100,
      align: "left",
    },
  ]);
  const [dataDef, setDataDef] = useState(
    Array(10)
      .fill(null)
      .map(() => Array(columnsDef.length).fill(""))
  );
  const [partsArray, setPartsArray] = useState([]);
  const [openAddRowsDialog, setOpenAddRowsDialog] = useState(false);
  const [numAddRows, setNumAddRows] = useState(1);
  const [maxLength, setMaxLength] = useState(420);
  const [scrollPosition, setScrollPosition] = useState({});
  const [specialCharsWidth] = useState({
    ".": 3.55,
    ",": 2.4,
    "-": 3.55,
    "|": 2.4,
    ":": 2.4,
    '"': 2.4,
    "'": 2.4,
    ";": 2.4,
    "!": 2.4,
    "?": 2.4,
    "(": 2.4,
    ")": 2.4,
    "[": 2.4,
    "]": 2.4,
    "{": 2.4,
    "}": 2.4,
    "<": 3.55,
    ">": 3.55,
    "/": 3.55,
    "\\": 3.55,
    "@": 3.55,
    "#": 3.55,
    $: 3.55,
    "%": 3.55,
    "^": 3.55,
    "&": 3.55,
    "*": 3.55,
    _: 6,
    "+": 3.55,
    "=": 3.55,
    "`": 3.55,
    "~": 3.55,
    f: 4.7,
    i: 3.55,
    I: 3.55,
    j: 3.55,
    l: 3.55,
    t: 3.55,
    // Add more special characters and their respective widths as needed
  });

  const getLastRowNumber = () => {
    // Get all the data from the spreadsheet
    let data = tableRef.current.jexcel.getData();
    const rowLength = data.length;

    // The last row number is the length of the data array minus one
    let lastRowNumber = rowLength - 1;

    return lastRowNumber;
  };

  // add number of rows at the end of table
  const addRows = (numOfRows) => {
    const rowIndex = getLastRowNumber();
    tableRef.current.jexcel.insertRow(numOfRows, rowIndex);
    promptNotification(
      `Added ${numAddRows} row(s) to the end of spreadsheet`,
      "success"
    );
  };

  // Calculates the column width based on the text length
  const calculateColumnWidth = useCallback(
    (name) => {
      const charWidths = {
        lowercase: 7.2, // Approximate width of a lowercase character in pixels
        uppercase: 8.6, // Approximate width of an uppercase character in pixels
        number: 7.5, // Approximate width of a number character in pixels
        special: 3.5, // Default width for special characters in pixels
        space: 0, // Width for space character
      };
      const padding = 40; // add padding pixels

      // Calculate the total width based on the character type
      let totalWidth = 0;
      for (let i = 0; i < name.toString().length; i++) {
        const char = name.toString()[i];
        if (char in specialCharsWidth) {
          totalWidth += specialCharsWidth[char];
        } else if (!isNaN(parseInt(char))) {
          totalWidth += charWidths.number;
        } else if (char === char.toUpperCase()) {
          totalWidth += charWidths.uppercase;
        } else if (char === char.toLowerCase()) {
          totalWidth += charWidths.lowercase;
        } else if (char === " ") {
          totalWidth += charWidths.space;
        } else {
          totalWidth += charWidths.special;
        }
      }
      return totalWidth + (totalWidth < 100 ? padding : 0); // (totalWidth > 200 ? padding : 0);
    },
    [specialCharsWidth]
  );

  /* const handleOpenAddRows = () => {
    setOpenAddRowsDialog(true);
  }; */

  const handleActionAddRows = () => {
    if (!isNaN(numAddRows)) {
      addRows(numAddRows);
    }
    setNumAddRows(1);
    setOpenAddRowsDialog(false);
  };

  const handleCancelAddRows = () => {
    setNumAddRows(1);
    setOpenAddRowsDialog(false);
  };

  // resize columns to fit content
  const reSizeCol = useCallback(() => {
    const colLength = columnsDef.length;
    for (let i = 0; i < colLength; i++) {
      const columnData = tableRef.current.jexcel.getColumnData(i);
      const currentColWidth = columnsDef[i].width;
      const longestStr = columnData.reduce(
        (a, b) => (a.toString().length > b.toString().length ? a : b),
        ""
      );
      const longestLength = calculateColumnWidth(longestStr);
      if (longestLength > maxLength || currentColWidth > maxLength) {
        tableRef.current.jexcel.setWidth(i, maxLength);
      } else {
        if (longestLength > currentColWidth) {
          tableRef.current.jexcel.setWidth(i, longestLength);
        } else if (currentColWidth > longestLength) {
          tableRef.current.jexcel.setWidth(i, currentColWidth);
        }
      }
    }
  }, [calculateColumnWidth, columnsDef, maxLength]);

  // find if pasted part exists in database
  const findPart = useCallback(
    (str) => {
      // removes special characters + leading and trailing whitespaces
      const cleanStr = str
        .replace(/[^\w\s]/gi, "")
        .trim()
        .toLowerCase();
      return partsArray.find(
        (part) =>
          part.partNumber
            .replace(/[^\w\s]/gi, "")
            .trim()
            .toLowerCase() === cleanStr
      );
    },
    [partsArray]
  );

  // call this function on events that updates data
  const preserveScrollPosition = useCallback(() => {
    const scrollElement = tableRef.current.querySelector(".jexcel_content");
    setScrollPosition({
      scrollLeft: scrollElement.scrollLeft,
      scrollTop: scrollElement.scrollTop,
    });
  }, []);

  // this function will be called at the end of all events of the spreadsheet to prevent sheet from resetting to the top
  const restoreScrollPosition = useCallback(() => {
    const scrollElement = tableRef.current.querySelector(".jexcel_content");
    scrollElement.scrollLeft = scrollPosition.scrollLeft;
    scrollElement.scrollTop = scrollPosition.scrollTop;
  }, [scrollPosition.scrollLeft, scrollPosition.scrollTop]);

  const updateMaxLength = () => {
    const internalViewingHeight = window.innerHeight;

    if (internalViewingHeight < 800) {
      setMaxLength(400);
    } else if (internalViewingHeight < 900) {
      setMaxLength(500);
    } else if (internalViewingHeight < 1000) {
      setMaxLength(600);
    } else if (internalViewingHeight < 1100) {
      setMaxLength(700);
    } else if (internalViewingHeight < 1200) {
      setMaxLength(800);
    } else if (internalViewingHeight < 1300) {
      setMaxLength(900);
    } else if (internalViewingHeight < 1400) {
      setMaxLength(1000);
    }
  };

  useEffect(() => {
    // Initial check
    updateMaxLength();

    // Add event listener for window resize
    const handleResize = () => {
      updateMaxLength();
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array to run only once on mount

  // loads parts data onto spreadsheet (typically after any change)
  useEffect(() => {
    if (jspreadsheetInstance) {
      jspreadsheetInstance.setData(dataDef);
    }
  }, [dataDef, jspreadsheetInstance]);

  // load parts table
  useEffect(() => {
    if (isSuccess) {
      const formattedParts = allPartsData.map((part) => {
        return partAssembler(part);
      });
      setPartsArray(formattedParts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  // re-set the data fields when data changes (change bay etc)
  useEffect(() => {
    if (
      Array.isArray(data) &&
      Array.isArray(columns) &&
      data[0].length === columns.length
    ) {
      setColumnDef(columns);
      const defaultStartRows = 20;
      const spareRows = 5;
      let newData = data.map((dataEntry) => dataEntry);
      if (data.length < defaultStartRows - spareRows) {
        for (let i = 0; i < defaultStartRows - data.length; i++) {
          newData.push(new Array(data[0].length).fill(null));
        }
        setDataDef(newData);
      } else {
        for (let i = 0; i < spareRows; i++) {
          newData.push(new Array(data[0].length).fill(null));
        }
        setDataDef(newData);
      }
    }
  }, [data, columns]);

  // jspreadsheet config
  useEffect(() => {
    if (tableRef.current && isSuccess) {
      const spreadsheet = jspreadsheet(tableRef.current, {
        data: dataDef,
        columns: columnsDef.map((column) => {
          return {
            type: column.type,
            title: column.title,
            width: column.width,
            readOnly: column.readOnly ? column.readOnly : false,
            align: column.align ? column.align : "center",
            source: column.source ? column.source : undefined,
            mask: column.mask ? column.mask : undefined,
            decimal: column.decimal ? column.decimal : undefined,
          };
        }),
        wordWrap: true,
        tableOverflow: true,
        tableHeight: viewingHeight > 0 ? `${viewingHeight * 2}px` : "250px",
        columnSorting: false,
        defaultColWidth: 100,
        defaultRowHeight: 10,
        allowRenameColumn: false,
        allowDeleteColumn: false,
        allowInsertColumn: false,
        allowExport: false,
        about: false,
        onmoverow: (el, oldPosition, newPosition) => {
          preserveScrollPosition();
          const newRowIndex = Number(newPosition);
          const row = spreadsheet.getConfig().data[newPosition];
          const partNumber = row[0];

          handleMoveRow({ partNumber: partNumber }, newRowIndex);
        },
        onbeforechange: (el, cell, colIndex, rowIndex, newValue) => {
          preserveScrollPosition();
          // Check if the column is numeric and if the value is not a number
          const column = spreadsheet.getConfig().columns[colIndex];
          const row = spreadsheet.getConfig().data[rowIndex];
          const partNumber = row[0];
          const originalValue = spreadsheet.getValueFromCoords(
            colIndex,
            rowIndex
          );
          let resultFromUpdateDetail;
          switch (column.title) {
            case "PART NUMBER":
              if (originalValue === "") {
                if (typeof newValue === "string" && newValue.trim() !== "") {
                  const foundPart = findPart(newValue);
                  if (foundPart !== undefined) {
                    handleNewPart({
                      part: partAssembler(foundPart),
                      quantity: 1,
                    });
                  } else {
                    handleNewPart({
                      part: { ...blankPart, partNumber: newValue.trim() },
                      quantity: 1,
                    });
                  }
                }
              } else if (
                typeof newValue === "string" &&
                newValue.trim() === ""
              ) {
                // if not empty and newValue = "", then delete part
                removeRow({ partNumber: partNumber });
                return newValue;
              } else {
                const partCol = columnsDef.findIndex(
                  (col) => col.title === "PART NUMBER"
                );
                if (partCol === -1) {
                  return originalValue;
                }
                const partExistInBay = dataDef.find(
                  (part) =>
                    part[partCol]
                      .replace(/[^\w\s]/gi, "")
                      .trim()
                      .toLowerCase() ===
                    newValue
                      .replace(/[^\w\s]/gi, "")
                      .trim()
                      .toLowerCase()
                );
                // if part exists in bay already add qty to that
                // then remove the edited part
                if (partExistInBay !== undefined) {
                  const ogPart = dataDef.find(
                    (part) => part[partCol] === originalValue
                  );
                  const qtyCol = columnsDef.findIndex(
                    (col) => col.title === "QTY"
                  );
                  if (qtyCol !== -1) {
                    handleUpdateQty(
                      {
                        target: {
                          value: Number(
                            ogPart[qtyCol] + partExistInBay[qtyCol]
                          ),
                        },
                      },
                      { partNumber: partExistInBay[partCol] }
                    );
                  } else {
                    handleUpdateQty(
                      {
                        target: {
                          value: Number(ogPart[qtyCol]),
                        },
                      },
                      { partNumber: partExistInBay[partCol] }
                    );
                  }
                  removeRow({ partNumber: partNumber });
                } else {
                  resultFromUpdateDetail = handleUpdateDetail(
                    { target: { value: newValue.trim() } },
                    { partNumber: partNumber },
                    "partNumber"
                  );
                  // part does not exist in bay yet
                  // find if its a valid part
                  const foundPart = findPart(newValue);
                  if (foundPart !== undefined) {
                    // its a valid part, update all details except qty
                    for (let key of partKeys) {
                      resultFromUpdateDetail = handleUpdateDetail(
                        { target: { value: foundPart[key] } },
                        { partNumber: newValue },
                        key
                      );
                    }
                  } else {
                    // its a custom part, update details via blankPart except qty
                    for (let key of partKeys) {
                      resultFromUpdateDetail = handleUpdateDetail(
                        { target: { value: blankPart[key] } },
                        { partNumber: newValue },
                        key
                      );
                    }
                  }
                }
                return resultFromUpdateDetail !== undefined
                  ? !resultFromUpdateDetail
                    ? originalValue
                    : newValue
                  : newValue;
              }
              break;
            case "DESCRIPTION":
              resultFromUpdateDetail = handleUpdateDetail(
                { target: { value: newValue.trim() } },
                { partNumber: partNumber },
                "description"
              );
              return resultFromUpdateDetail !== undefined
                ? !resultFromUpdateDetail
                  ? originalValue
                  : newValue
                : newValue;
            case "COST ($)":
              const costValue = Number(newValue.replace(/-/g, ""));
              if (!isNaN(newValue)) {
                const validCostValue =
                    costValue <= partCostLimit ? costValue : partCostLimit,
                  resultFromUpdateDetail = handleUpdateDetail(
                    {
                      target: {
                        value: validCostValue,
                      },
                    },
                    { partNumber: partNumber },
                    "cost"
                  );
                return resultFromUpdateDetail !== undefined
                  ? !resultFromUpdateDetail
                    ? originalValue
                    : validCostValue
                  : validCostValue;
              } else {
                break;
              }
            case "SUPPLIER":
              resultFromUpdateDetail = handleUpdateDetail(
                { target: { value: newValue.trim() } },
                { partNumber: partNumber },
                "supplier"
              );
              return resultFromUpdateDetail !== undefined
                ? !resultFromUpdateDetail
                  ? originalValue
                  : newValue
                : newValue;
            case "QTY":
              const qtyValue = Number(newValue.replace(/-/g, ""));
              if (!isNaN(newValue)) {
                const validQtyValue =
                  qtyValue <= partQtyLimit ? qtyValue : partQtyLimit;
                if (newValue === 0) {
                  handleUpdateQty(
                    { target: { value: 1 } },
                    { partNumber: partNumber }
                  );
                  return Number(1);
                } else {
                  handleUpdateQty(
                    {
                      target: {
                        value: validQtyValue,
                      },
                    },
                    { partNumber: partNumber }
                  );
                  return validQtyValue;
                }
              }
              break;
            case "LABOUR (MIN.)":
              const labourValue = Number(newValue.replace(/-/g, ""));
              if (!isNaN(newValue) && labourValue >= Number(originalValue)) {
                const validLabourValue =
                  labourValue <= partLabourLimit
                    ? labourValue
                    : partLabourLimit;
                resultFromUpdateDetail = handleUpdateDetail(
                  {
                    target: {
                      value: validLabourValue,
                    },
                  },
                  { partNumber: partNumber },
                  "labour"
                );
                return resultFromUpdateDetail !== undefined
                  ? !resultFromUpdateDetail
                    ? originalValue
                    : validLabourValue
                  : validLabourValue;
              } else {
                break;
              }
            default:
              break;
          }

          if (column.type === "numeric") {
            if (isNaN(newValue)) {
              return originalValue; // Return the original value
            } else if (Number(newValue) < 0) {
              return Number(newValue.replace(/-/g, ""));
            } else {
              return Number(newValue);
            }
          }

          return newValue; // Allow the change
        },
        onbeforedeleterow: (element, rowIndex, numOfRows) => {
          preserveScrollPosition();
          const confirmed = window.confirm(
            `Are you really sure you want to delete selected row(s)?`
          );
          if (!confirmed) {
            return false; // Prevent the row from being deleted
          } else {
            for (let i = rowIndex; i < rowIndex + numOfRows; i++) {
              const row = spreadsheet.getConfig().data[i];
              const partNumber = row[0];
              // row[0] being part number
              removeRow({ partNumber: partNumber });
            }
          }
        },
      });
      reSizeCol();
      restoreScrollPosition();
      setJspreadsheetInstance(spreadsheet);
      // Cleanup on component unmount
      return () => {
        spreadsheet.destroy();
      };
    }
  }, [
    allPartsData,
    blankPart,
    columnsDef,
    dataDef,
    findPart,
    handleMoveRow,
    handleNewPart,
    handleUpdateDetail,
    handleUpdateQty,
    isSuccess,
    partAssembler,
    partCostLimit,
    partLabourLimit,
    partQtyLimit,
    preserveScrollPosition,
    reSizeCol,
    removeRow,
    restoreScrollPosition,
    viewingHeight,
  ]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <SpreadsheetStyle />
        <MDBox
          minHeight={1}
          maxHeight={1}
          minWidth={1}
          maxWidth={1}
          ref={tableRef}
          sx={{ overflowX: "auto" }}
        />
      </Grid>
      <Grid item xs={1.5} mt={1} pl={1} pr={1}>
        {/* <MDBox sx={{ position: "sticky", top: "0", right: "0" }} zIndex={2}>
          <Grid container spacing={1}>
            <Grid item xs={12} display="flex" justifyContent="center">
              <MDButton
                size="small"
                color="info"
                variant="gradient"
                onClick={handleOpenAddRows}
                fullWidth
              >
                Add new rows
              </MDButton>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
              <MDButton
                size="small"
                color="dark"
                variant="gradient"
                fullWidth
                onClick={reSizeCol}
              >
                Resize columns to fit content
              </MDButton>
            </Grid>
          </Grid>
        </MDBox> */}
      </Grid>
      <CrudDialog
        title="Adding rows"
        size="xs"
        required={numAddRows === 0}
        control={{
          open: openAddRowsDialog,
          onClose: handleCancelAddRows,
        }}
        content={
          <Grid container>
            <Grid item xs={8}>
              <MDTypography variant="body2">
                Number of rows to be inserted:
              </MDTypography>
            </Grid>
            <Grid item xs={4} display="flex" justifyContent="flex-end">
              <MDInput
                variant="standard"
                size="small"
                type="number"
                autoFocus
                value={numAddRows}
                InputProps={{
                  inputProps: {
                    min: 1,
                    max: 50 /* prevent mass row insertion */,
                    style: { textAlign: "right" },
                  },
                  onKeyPress: preventKeys,
                  onKeyDown: preventKeys,
                  onPaste: preventPasteNegative,
                }}
                onChange={(e) => {
                  e.preventDefault();
                  if (Number(e.target.value) <= 50) {
                    setNumAddRows(Number(e.target.value));
                  } else {
                    setNumAddRows(50);
                  }
                }}
              />
            </Grid>
          </Grid>
        }
        actions={{
          confirmName: "Add",
          confirmHandler: handleActionAddRows,
        }}
      />
    </Grid>
  );
}
