/* eslint-disable react/prop-types */
// functional table component for displaying pricing data for the current distribution board
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {
  Divider,
  Table,
  TableBody,
  TableRow,
  Typography,
  InputAdornment,
} from "@mui/material";
import { useContext, useState } from "react";
import { ApsContext } from "ApsContext";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useAPSDB } from "hooks/useAPSDB";

/**
 * Renders a pricing table for a given database.
 * @param {Object} db - The database object to render the pricing table for.
 * @returns {JSX.Element} - The pricing table component.
 */
function DBPricingTable({ db, closeButton }) {
  const {
    quotingDB,
    updateAllAddCostings,
    allAddCostings,
    promptNotification,
  } = useContext(ApsContext);
  const { handleDbPartsMapped, preventPasteNegative, preventKeys } = useAPSDB();

  const [foundDBCosts, setFoundDBCosts] = useState(
    allAddCostings.find((cost) => cost.id === db.id)
  );
  const [mappedPartCosts] = useState(
    handleDbPartsMapped(allAddCostings, quotingDB)
  );

  const [maxQty] = useState(20);
  const [maxMargin] = useState(80);
  const [maxPrice] = useState(500);

  const totalCost = mappedPartCosts.find(
    (dbCost) => dbCost.identifier === db.id
  ).unitPrice;

  const totalLabour =
    Math.round(
      mappedPartCosts
        .find((dbCost) => dbCost.identifier === db.id)
        .UniqueParts.reduce((acc, part) => {
          return acc + part.part.labour * part.quantity;
        }, 0) * 10
    ) / 10;

  // function to map the unique parts into a table row
  const mapUniqueParts = () => {
    return mappedPartCosts
      .find((dbCost) => dbCost.identifier === db.id)
      .UniqueParts.map((part, index) => {
        return (
          <TableRow key={index}>
            <MDBox component="td" textAlign="left" p={1}>
              <MDTypography
                variant="body2"
                color="text"
                fontWeight="light"
                fontSize="0.75rem"
              >
                {part.part.description}
              </MDTypography>
            </MDBox>
            <MDBox component="td" textAlign="left" py={1} pr={1} pl={3}>
              <MDTypography
                variant="body2"
                color="text"
                fontWeight="regular"
                fontSize="0.75rem"
                textAlign="right"
              >
                {part.quantity}
              </MDTypography>
            </MDBox>
            <MDBox component="td" textAlign="left" p={1} pl={3}>
              <MDTypography
                variant="body2"
                color="text"
                fontWeight="regular"
                fontSize="0.75rem"
                textAlign="right"
              >
                {part.part.cost.toFixed(2)}
              </MDTypography>
            </MDBox>
            <MDBox component="td" textAlign="left" p={1} pl={3}>
              <MDTypography
                variant="body2"
                color="text"
                fontWeight="regular"
                fontSize="0.75rem"
                textAlign="right"
              >
                {(
                  Math.round(part.part.cost * part.quantity * 100) / 100
                ).toFixed(2)}
              </MDTypography>
            </MDBox>
          </TableRow>
        );
      });
  };

  // setAdditionalCosting called in handle functions when editing unitPrice and quantity
  const additionalCosting = [
    {
      id: "labour",
      description: "Total Hours Labour",
      unitPrice: foundDBCosts.unitPrice.labour,
      quantity: Math.round((totalLabour / 60) * 100) / 100,
      cost:
        Math.round(((totalLabour * foundDBCosts.unitPrice.labour) / 60) * 100) /
        100,
    },
    {
      id: "labels",
      description: "Labels and Sundries",
      unitPrice: foundDBCosts.unitPrice.labels,
      quantity: foundDBCosts.quantity.labels,
      cost: foundDBCosts.unitPrice.labels * foundDBCosts.quantity.labels,
    },
    {
      id: "drawings",
      description: "Drawings",
      unitPrice: foundDBCosts.unitPrice.drawings,
      quantity: foundDBCosts.quantity.drawings,
      cost: foundDBCosts.unitPrice.drawings * foundDBCosts.quantity.drawings,
    },
    {
      id: "freight",
      description: "Freight",
      unitPrice: foundDBCosts.unitPrice.freight,
      quantity: foundDBCosts.quantity.freight,
      cost: foundDBCosts.unitPrice.freight * foundDBCosts.quantity.freight,
    },
    {
      id: "subTotal",
      description: "Sub Total",
      unitPrice: "-",
      quantity: "-",
      cost:
        Math.round(
          (totalCost +
            (totalLabour * foundDBCosts.unitPrice.labour) / 60 +
            foundDBCosts.unitPrice.labels * foundDBCosts.quantity.labels +
            foundDBCosts.unitPrice.drawings * foundDBCosts.quantity.drawings +
            foundDBCosts.unitPrice.freight * foundDBCosts.quantity.freight) *
            100
        ) / 100,
    },
    {
      id: "markup",
      description: "Margin",
      unitPrice: foundDBCosts.unitPrice.markup,
      quantity: "-",
      cost:
        Math.round(
          ((totalCost +
            ((totalLabour * foundDBCosts.unitPrice.labour) / 60 +
              foundDBCosts.unitPrice.labels * foundDBCosts.quantity.labels +
              foundDBCosts.unitPrice.drawings * foundDBCosts.quantity.drawings +
              foundDBCosts.unitPrice.freight * foundDBCosts.quantity.freight)) /
            (1 - foundDBCosts.unitPrice.markup / 100) -
            (totalCost +
              ((totalLabour * foundDBCosts.unitPrice.labour) / 60 +
                foundDBCosts.unitPrice.labels * foundDBCosts.quantity.labels +
                foundDBCosts.unitPrice.drawings *
                  foundDBCosts.quantity.drawings +
                foundDBCosts.unitPrice.freight *
                  foundDBCosts.quantity.freight))) *
            100
        ) / 100,
    },
  ];

  const finalCost = () => {
    const markupCost = additionalCosting.find(
      (addCost) => addCost.id === "markup"
    ).cost;
    const subTotalCost = additionalCosting.find(
      (addCost) => addCost.id === "subTotal"
    ).cost;
    const totalPrice = markupCost + subTotalCost;
    return totalPrice;
  };

  // save price change to APSContext
  const handleSaveAll = () => {
    updateAllAddCostings(
      allAddCostings.map((findItem) => {
        if (findItem.id === foundDBCosts.id) {
          return foundDBCosts;
        }
        return findItem;
      })
    );
    promptNotification(`Saved all values`, "success");
  };

  // input field; keep in local variable
  const handleSetPrice = (value, id) => {
    switch (id) {
      case "labour":
        setFoundDBCosts({
          ...foundDBCosts,
          unitPrice: { ...foundDBCosts.unitPrice, labour: value },
        });
        break;
      case "markup":
        setFoundDBCosts({
          ...foundDBCosts,
          unitPrice: { ...foundDBCosts.unitPrice, markup: value },
        });
        break;
      case "labels":
        setFoundDBCosts({
          ...foundDBCosts,
          unitPrice: { ...foundDBCosts.unitPrice, labels: value },
        });
        break;
      case "drawings":
        setFoundDBCosts({
          ...foundDBCosts,
          unitPrice: { ...foundDBCosts.unitPrice, drawings: value },
        });
        break;
      case "freight":
        setFoundDBCosts({
          ...foundDBCosts,
          unitPrice: { ...foundDBCosts.unitPrice, freight: value },
        });
        break;
      default:
        break;
    }
  };

  const handleSetQuantity = (value, id) => {
    switch (id) {
      case "labels":
        setFoundDBCosts({
          ...foundDBCosts,
          quantity: { ...foundDBCosts.quantity, labels: value },
        });
        break;
      case "drawings":
        setFoundDBCosts({
          ...foundDBCosts,
          quantity: { ...foundDBCosts.quantity, drawings: value },
        });
        break;
      case "freight":
        setFoundDBCosts({
          ...foundDBCosts,
          quantity: { ...foundDBCosts.quantity, freight: value },
        });
        break;
      default:
        break;
    }
  };

  const borderBottom = {
    borderBottom: ({ borders: { borderWidth }, palette: { light } }) =>
      `${borderWidth[1]} solid ${light.main}`,
  };

  // function to map the additional costing into a table row
  const mapAdditionalCosting = (additionalCosting) => {
    return additionalCosting.map((cost, index) => {
      return (
        <TableRow key={index}>
          <MDBox component="td" textAlign="left" p={1}>
            <MDTypography
              variant="body2"
              color="text"
              fontWeight="light"
              fontSize="0.75rem"
            >
              {cost.description}
            </MDTypography>
          </MDBox>
          <MDBox component="td" textAlign="left" p={1} pl={3}>
            {cost.id === "labour" ||
            cost.id === "markup" ||
            cost.id === "subTotal" ? (
              <Typography fontSize="0.75rem">{cost.quantity}</Typography>
            ) : (
              <MDInput
                variant="standard"
                value={cost.quantity}
                type="number"
                sx={{
                  width: "30px",
                  ".css-15e8ec1-MuiInputBase-input-MuiInput-input": {
                    fontSize: "0.75rem",
                  },
                }}
                InputProps={{
                  inputProps: { min: 0 },
                  onKeyPress: preventKeys,
                  onKeyDown: preventKeys,
                  onPaste: preventPasteNegative,
                }}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue >= 0 && inputValue !== "") {
                    if (inputValue > maxQty) {
                      handleSetQuantity(maxQty, cost.id);
                    } else {
                      handleSetQuantity(inputValue, cost.id);
                    }
                  } else {
                    handleSetQuantity(0, cost.id);
                  }
                }}
              ></MDInput>
            )}
          </MDBox>
          <MDBox
            component="td"
            textAlign="left"
            p={1}
            pl={3}
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            {cost.id === "markup" ? (
              <MDInput
                variant="standard"
                fullWidth
                value={cost.unitPrice}
                type="number"
                sx={{
                  width: "70px",
                  ".css-15e8ec1-MuiInputBase-input-MuiInput-input": {
                    fontSize: "0.75rem",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography fontSize="0.75rem">%</Typography>
                    </InputAdornment>
                  ),
                  inputProps: { min: 0, style: { textAlign: "right" } },
                  onKeyPress: preventKeys,
                  onKeyDown: preventKeys,
                  onPaste: preventPasteNegative,
                }}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue >= 0 && inputValue !== "") {
                    if (inputValue > maxMargin) {
                      handleSetPrice(maxMargin, cost.id);
                    } else {
                      handleSetPrice(inputValue, cost.id);
                    }
                  } else {
                    handleSetPrice(0, cost.id);
                  }
                }}
              ></MDInput>
            ) : cost.id === "subTotal" ? (
              <Typography fontSize="0.75rem">{cost.unitPrice}</Typography>
            ) : (
              <MDInput
                variant="standard"
                value={cost.unitPrice}
                type="number"
                fontSize="0.75rem"
                sx={{
                  width: "70px",
                  ".css-15e8ec1-MuiInputBase-input-MuiInput-input": {
                    fontSize: "0.75rem",
                  },
                }}
                InputProps={{
                  fontSize: "0.75rem",
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography fontSize="0.75rem">$</Typography>
                    </InputAdornment>
                  ),
                  inputProps: {
                    min: 0,
                    step: 0.01,
                    style: { textAlign: "right" },
                  },
                  onKeyPress: preventKeys,
                  onKeyDown: preventKeys,
                  onPaste: preventPasteNegative,
                }}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue >= 0 && inputValue !== "") {
                    if (inputValue > maxPrice) {
                      handleSetPrice(maxPrice, cost.id);
                    } else {
                      handleSetPrice(inputValue, cost.id);
                    }
                  } else {
                    handleSetPrice(0, cost.id);
                  }
                }}
              ></MDInput>
            )}
          </MDBox>

          <MDBox component="td" textAlign="left" p={1} pl={3}>
            <MDTypography
              variant="body2"
              color="text"
              fontWeight="regular"
              fontSize="0.75rem"
              textAlign="right"
            >
              {cost.cost.toFixed(2)}
            </MDTypography>
          </MDBox>
        </TableRow>
      );
    });
  };

  return (
    <MDBox display="flex" flexDirection="column" width="100%" height="100%">
      <MDBox width="100%" overflow="auto" height="50%" display="flex">
        <Table sx={{ minWidth: "32rem" }}>
          <MDBox component="thead">
            <TableRow
              sx={{
                position: "sticky",
                top: "0px",
              }}
            >
              <MDBox
                component="th"
                width={{ xs: "45%", md: "50%" }}
                py={1.5}
                px={1}
                textAlign="left"
                bgColor="light"
                sx={borderBottom}
              >
                <MDTypography
                  variant="h6"
                  color="text"
                  fontWeight="medium"
                  fontSize={15}
                >
                  Part
                </MDTypography>
              </MDBox>
              <MDBox
                component="th"
                py={1.5}
                pl={3}
                pr={1}
                textAlign="left"
                bgColor="light"
                sx={borderBottom}
              >
                <MDTypography
                  variant="h6"
                  color="text"
                  fontWeight="medium"
                  fontSize={15}
                  textAlign="right"
                >
                  Qty
                </MDTypography>
              </MDBox>
              <MDBox
                component="th"
                py={1.5}
                pl={3}
                pr={1}
                textAlign="left"
                bgColor="light"
                sx={borderBottom}
              >
                <MDTypography
                  variant="h6"
                  color="text"
                  fontWeight="medium"
                  fontSize={15}
                  textAlign="right"
                >
                  Unit Price ($)
                </MDTypography>
              </MDBox>
              <MDBox
                component="th"
                py={1.5}
                pl={3}
                pr={1}
                textAlign="left"
                bgColor="light"
                sx={borderBottom}
              >
                <MDTypography
                  variant="h6"
                  color="text"
                  fontWeight="medium"
                  fontSize={15}
                  textAlign="right"
                >
                  Amount ($)
                </MDTypography>
              </MDBox>
            </TableRow>
          </MDBox>
          <TableBody>
            {mapUniqueParts()}

            <TableRow
              sx={{
                position: "sticky",
                bottom: "0px",
              }}
            >
              <MDBox component="td" textAlign="left" p={1} bgColor="light" />
              <MDBox
                component="td"
                textAlign="left"
                py={1}
                pr={1}
                pl={3}
                bgColor="light"
              />
              <MDBox
                component="td"
                textAlign="left"
                py={1}
                pr={1}
                pl={3}
                bgColor="light"
              >
                <MDTypography variant="h5" fontSize={15} textAlign="right">
                  Total
                </MDTypography>
              </MDBox>
              <MDBox
                component="td"
                textAlign="left"
                py={1}
                pr={1}
                pl={3}
                bgColor="light"
              >
                <MDTypography variant="h5" fontSize={15} textAlign="right">
                  ${totalCost.toFixed(2)}
                </MDTypography>
              </MDBox>
            </TableRow>
          </TableBody>
        </Table>
      </MDBox>
      <MDBox textAlign="center">
        <MDTypography variant="h6" fontWeight="medium" pt={5}>
          Additional Costing
        </MDTypography>
      </MDBox>
      <Divider sx={{ margin: 0 }} />
      <Table sx={{ minWidth: "32rem" }}>
        <MDBox component="thead">
          <TableRow>
            <MDBox
              component="th"
              width={{ xs: "45%", md: "50%" }}
              px={1}
              py={1.5}
              textAlign="left"
              sx={borderBottom}
            >
              <MDTypography
                variant="h6"
                color="text"
                fontWeight="medium"
                fontSize={15}
              >
                Item
              </MDTypography>
            </MDBox>
            <MDBox
              component="th"
              pl={3}
              pr={1}
              textAlign="left"
              sx={borderBottom}
            >
              <MDTypography
                variant="h6"
                color="text"
                fontWeight="medium"
                fontSize={15}
              >
                Qty
              </MDTypography>
            </MDBox>
            <MDBox
              component="th"
              py={1.5}
              pl={3}
              pr={1}
              textAlign="left"
              sx={borderBottom}
            >
              <MDTypography
                variant="h6"
                color="text"
                fontWeight="medium"
                fontSize={15}
                textAlign="right"
              >
                Unit Price
              </MDTypography>
            </MDBox>
            <MDBox
              component="th"
              py={1.5}
              pl={3}
              pr={1}
              textAlign="left"
              sx={borderBottom}
            >
              <MDTypography
                variant="h6"
                color="text"
                fontWeight="medium"
                fontSize={15}
                textAlign="right"
              >
                Amount ($)
              </MDTypography>
            </MDBox>
          </TableRow>
        </MDBox>
        <TableBody>
          {mapAdditionalCosting(additionalCosting)}

          <TableRow>
            <MDBox component="td" textAlign="left" p={1} sx={borderBottom} />
            <MDBox
              component="td"
              textAlign="left"
              py={1}
              pr={1}
              pl={3}
              display="flex"
              flex-direction="row"
              justifyContent="flex-start"
              sx={borderBottom}
            >
              <MDButton
                color="success"
                size="small"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  handleSaveAll();
                }}
              >
                Save Changes
              </MDButton>
            </MDBox>
            <MDBox
              component="td"
              textAlign="left"
              py={1}
              pr={1}
              pl={3}
              sx={borderBottom}
            >
              <MDTypography variant="h5" fontSize={15} textAlign="right">
                Total
              </MDTypography>
            </MDBox>
            <MDBox
              component="td"
              textAlign="left"
              py={1}
              pr={1}
              pl={3}
              sx={borderBottom}
            >
              <MDTypography variant="h5" fontSize={15} textAlign="right">
                {(Math.round(finalCost() * 100) / 100).toFixed(2)}
              </MDTypography>
            </MDBox>
          </TableRow>
        </TableBody>
      </Table>
      <MDButton onClick={closeButton}>Close</MDButton>
    </MDBox>
  );
}

export default DBPricingTable;
