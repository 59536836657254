// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import { FormControlLabel, Switch, Tooltip, Grid, Card } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

import { useContext, useState, useMemo } from "react";
import { ApsContext } from "ApsContext";
import SelectAutocomplete from "customInputs/select-autocomplete";

function AssemblyCard({ assemblyFetched, creator }) {
  const { handleUpdateAssemblyNaming, assemblyFilterCategories } =
    useContext(ApsContext);
  const [filter_2, setFilter_2] = useState(assemblyFetched.filter_2);

  const handleNameChange = (e) => {
    handleUpdateAssemblyNaming(
      e.target.value,
      assemblyFetched.description,
      assemblyFetched.height,
      assemblyFetched.state,
      assemblyFetched.filter_1,
      assemblyFetched.filter_2
    );
  };
  const handleDescriptionChange = (e) => {
    handleUpdateAssemblyNaming(
      assemblyFetched.assembly_name,
      e.target.value,
      assemblyFetched.height,
      assemblyFetched.state,
      assemblyFetched.filter_1,
      assemblyFetched.filter_2
    );
  };
  const handleHeightChange = (e) => {
    handleUpdateAssemblyNaming(
      assemblyFetched.assembly_name,
      assemblyFetched.description,
      e.target.value,
      assemblyFetched.state,
      assemblyFetched.filter_1,
      assemblyFetched.filter_2
    );
  };

  const handleStateChange = () => {
    handleUpdateAssemblyNaming(
      assemblyFetched.assembly_name,
      assemblyFetched.description,
      assemblyFetched.height,
      !assemblyFetched.state,
      assemblyFetched.filter_1,
      assemblyFetched.filter_2
    );
  };

  const handleFilter1Change = (value) => {
    handleUpdateAssemblyNaming(
      assemblyFetched.assembly_name,
      assemblyFetched.description,
      assemblyFetched.height,
      assemblyFetched.state,
      value,
      assemblyFetched.filter_2
    );
  };

  const handleFilter2Change = (value) => {
    handleUpdateAssemblyNaming(
      assemblyFetched.assembly_name,
      assemblyFetched.description,
      assemblyFetched.height,
      assemblyFetched.state,
      assemblyFetched.filter_1,
      value
    );
    setFilter_2(value);
  };

  const preventNaN = (e) => {
    const clipboardData = e.clipboardData;
    const pastedData = parseFloat(clipboardData.getData("text"));

    if (isNaN(pastedData)) {
      e.preventDefault();
    }
  };

  const filter1Categories = useMemo(() => {
    return assemblyFilterCategories.map((filter) => filter.filter1);
  }, [assemblyFilterCategories]);

  const filter2Categories = useMemo(() => {
    const formattedFilters = assemblyFilterCategories.filter(
      (filter) => filter.filter1 === assemblyFetched.filter_1
    )[0].filter2;
    setFilter_2("");
    return formattedFilters;
  }, [assemblyFetched.filter_1, assemblyFilterCategories]);

  return (
    <Card>
      <MDBox p={2}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} lg={12} xl={3}>
            <MDInput
              label="Assembly Name"
              variant="standard"
              size="small"
              fullWidth
              required
              value={assemblyFetched.assembly_name}
              onChange={handleNameChange}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={6} xl={1}>
            <MDInput
              label="Height"
              variant="standard"
              size="small"
              fullWidth
              type="number"
              required
              value={assemblyFetched.height}
              InputProps={{
                onPaste: preventNaN,
              }}
              onChange={handleHeightChange}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12} xl={4} mt={-0.4}>
            <SelectAutocomplete
              options={filter1Categories}
              selected={assemblyFetched.filter_1}
              selectedAction={handleFilter1Change}
              customLabel="Filter 1"
              requiredField={true}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12} xl={4} mt={-0.4}>
            <SelectAutocomplete
              options={filter2Categories}
              selected={filter_2}
              selectedAction={handleFilter2Change}
              customLabel="Filter 2"
              requiredField={true}
            />
          </Grid>
          <Grid item pt={2} xs={8}>
            <MDInput
              label="Assembly Description"
              variant="standard"
              size="small"
              fullWidth
              required
              value={assemblyFetched.description}
              onChange={handleDescriptionChange}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            xl={4}
            display="flex"
            justifyContent="center"
          >
            <Tooltip
              title={
                assemblyFetched.state
                  ? "Public assemblies are available to all users"
                  : "Restricted assemblies will not be available to users with role of Members"
              }
              position="bottom"
            >
              <FormControlLabel
                value="start"
                control={
                  <Switch
                    color="primary"
                    checked={assemblyFetched.state}
                    onChange={handleStateChange}
                  />
                }
                label={assemblyFetched.state ? "Public" : "Restricted"}
                labelPlacement="bottom"
              />
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="button" fontWeight="medium" color="text">
              {creator !== "" && `Created by: ${creator}`}
            </MDTypography>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

// Setting default values
AssemblyCard.defaultProps = {
  assemblyFetched: {
    name: "",
    description: "",
    height: 0,
    state: true,
    filter_1: "",
  },
  creator: "",
};

// Typechecking props
AssemblyCard.propTypes = {
  assemblyFetched: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    height: PropTypes.number,
    state: PropTypes.bool,
    filter_1: PropTypes.string,
  }),
  creator: PropTypes.string,
};

export default AssemblyCard;
