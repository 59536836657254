import { useContext, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { FormControlLabel, Checkbox, FormGroup } from "@mui/material";

import AuthService from "services/auth-service";
import { AuthContext } from "context";

import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

import bgImage from "assets/images/aps/DB-Ultimate.jpg";

function Login2FA() {
  const authContext = useContext(AuthContext);
  const [inputs, setInputs] = useState({
    verify: "",
  });
  const [errors, setErrors] = useState({
    verifyError: false,
    credentialsErros: false,
    textError: "",
  });
  const [checkedThirtyDays, setCheckedThirtyDays] = useState(false);

  const handleChecked = () => {
    setCheckedThirtyDays(!checkedThirtyDays);
  };

  const changeHandler = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (inputs.verify.trim().length < 6) {
      setErrors({
        ...errors,
        verifyError: true,
        textError: "Code entered is not valid",
      });
      return;
    }

    const myData = {
      data: {
        type: "token",
        attributes: {
          email: authContext.email,
          token: inputs.verify,
          thirtyDays: checkedThirtyDays,
        },
      },
    };

    try {
      const response = await AuthService.login2fa(myData);
      authContext.login2fa(response.access_token, response.refresh_token);
    } catch (res) {
      if (res.hasOwnProperty("message")) {
        setErrors({
          ...errors,
          verifyError: false,
          credentialsErros: true,
          textError: res.message,
        });
      } else {
        setErrors({
          ...errors,
          verifyError: false,
          credentialsErros: true,
          textError: res.errors[0].detail,
        });
      }
    }

    return () => {
      setInputs({
        verify: "",
      });

      setErrors({
        verifyError: false,
        credentialsErros: false,
        textError: "",
      });
    };
  };

  return (
    <IllustrationLayout
      title="Two-Factor Authentication"
      description="Enter the verification code generated from the auth app linked to this account"
      illustration={bgImage}
      disableNavBar={true}
    >
      <MDBox
        component="form"
        role="form"
        method="POST"
        onSubmit={submitHandler}
      >
        <MDBox mb={2}>
          <MDInput
            type="text"
            label="Verification Code"
            fullWidth
            name="verify"
            onChange={changeHandler}
            value={inputs.verify}
            error={errors.verifyError}
          />
          {errors.verifyError && (
            <MDTypography
              variant="caption"
              color="error"
              fontWeight="light"
              paddingTop="1rem"
            >
              {errors.textError}
            </MDTypography>
          )}
          {errors.credentialsErros && (
            <MDTypography variant="caption" color="error" fontWeight="light">
              {errors.textError}
            </MDTypography>
          )}
        </MDBox>
        <MDBox width="50%">
          <FormGroup>
            <FormControlLabel
              label="Remember for 30 days"
              control={
                <Checkbox
                  defaultChecked={checkedThirtyDays}
                  onClick={handleChecked}
                />
              }
            />
          </FormGroup>
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton
            variant="gradient"
            color="info"
            size="large"
            fullWidth
            type="submit"
          >
            Submit
          </MDButton>
        </MDBox>
      </MDBox>
    </IllustrationLayout>
  );
}

export default Login2FA;
