import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

function AddAssemblyCell({ icon, color, onIconClick, assembly }) {
  return (
    <MDBox display="flex" alignItems="center">
      <MDButton
        variant="text"
        color={color}
        size="small"
        iconOnly
        circular
        onClick={() => {
          onIconClick(assembly);
        }}
      >
        <Icon>{icon}</Icon>
      </MDButton>
    </MDBox>
  );
}

AddAssemblyCell.defaultProps = {
  icon: "more_vert",
  color: "dark",
};

// Typechecking props for the StatusCell
AddAssemblyCell.propTypes = {
  icon: PropTypes.string,
  color: PropTypes.string,
  onIconClick: PropTypes.func.isRequired,
  assembly: PropTypes.object.isRequired,
};

export default AddAssemblyCell;
