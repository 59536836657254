/* eslint-disable react/react-in-jsx-scope */
import { useState, useContext } from "react";
import crudsService from "services/cruds-service";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDButton from "components/MDButton";
import { DialogContentText, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ApsContext } from "ApsContext";
import { Can } from "Can";
import { subject } from "@casl/ability";
import { useAllQuotes, useCurrentUser } from "services/query-service";
import Grid from "@mui/material/Grid";
import AddAssemblyCell from "customInputs/customTableCells/addAssemblyCell";
import AssemblyProductCell from "customInputs/customTableCells/assemblyProductCell";
import QueryCustomTable from "customInputs/queryTables/QueryCustomTable";
import Footer from "examples/Footer";
import EstimateInfo from "../NewEstimate/EstimateInfo";
import CrudDialog from "customInputs/crud-dialog";
import { useAPSDB } from "hooks/useAPSDB";

function EstimateLanding() {
  const {
    overwriteContext,
    resetContext,
    currentProject,
    currentClient,
    currentQuoteNum,
    promptNotification,
  } = useContext(ApsContext);
  const { formatNumber, calcTotalPrice } = useAPSDB();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [quoteToDelete, setQuoteToDelete] = useState(null);
  const [copyDialogOpen, setCopyDialogOpen] = useState(false);
  const [quoteToCopy, setQuoteToCopy] = useState(null);
  const [copyLoading, setCopyLoading] = useState(false);
  const navigate = useNavigate();

  const {
    data: allQuoteData,
    isSuccess: allQuoteIsSuccess,
    isLoading,
    refetch: allQuoteReFetch,
  } = useAllQuotes();

  const handleNewQuoteClick = async () => {
    await resetContext();
    navigate("/configurator/new-quote");
  };

  const handleEditQuoteClick = async (qID) => {
    navigate(`/configurator/edit-quote/${qID.quoteID}`);
  };

  const { data: user, isSuccess: userSuccess } = useCurrentUser();

  const handleDeleteQuoteClick = (qID) => {
    setDeleteDialogOpen(true);
    setQuoteToDelete(qID.quoteID);
  };

  const handleActionDeleteQuote = async () => {
    try {
      await crudsService.deleteQuotingTool(quoteToDelete);
      setDeleteDialogOpen(false);
      promptNotification("Quote has been deleted", "success");
      setQuoteToDelete(null);
      allQuoteReFetch();
    } catch (error) {
      promptNotification("Quote delete failed", "error");
    }
  };

  const handleCopyQuoteClick = async (qID) => {
    setCopyDialogOpen(true);
    if (userSuccess) {
      try {
        const copyingQuote = await crudsService.getQuotingTool(qID.quoteID);
        setQuoteToCopy(copyingQuote.data);
        overwriteContext({ ...copyingQuote.data, crm: "" });
      } catch (error) {
        promptNotification("Failed to get quote.", "error");
      }
    }
  };

  const handleActionCopyQuote = async () => {
    if (userSuccess) {
      const payload = {
        quoteNumber: currentQuoteNum,
        revision: quoteToCopy.revision,
        additionalCodes: quoteToCopy.additional_costs,
        customer: currentClient,
        project: currentProject,
        quotingDB: quoteToCopy.quotingDB,
        titleList: quoteToCopy.tc_list,
        creator: user,
        approvalStatus: quoteToCopy.approvalStatus,
        approved_by: quoteToCopy.approved_by,
        line_items: quoteToCopy.line_items,
        total_price: calcTotalPrice(),
      };

      // save the quote to the database
      try {
        await crudsService.createQuotingTool(payload);
        promptNotification("Quote has been copied", "success");
        setCopyDialogOpen(false);
        allQuoteReFetch();
        setQuoteToCopy(null);
      } catch (error) {
        promptNotification("Copying quote failed", "error");
      }
    }
    setCopyLoading(false);
  };

  const handleCancelDeleteQuote = () => {
    setDeleteDialogOpen(false);
    setQuoteToDelete(null);
  };

  const handleCancelCopyQuote = async () => {
    await resetContext();
    setCopyDialogOpen(false);
    setQuoteToCopy(null);
  };

  const makeQuoteTableRows = () => {
    if (allQuoteData.data.length > 0) {
      return allQuoteData.data.map((quote, index) => {
        return {
          crm: quote.crm,
          project: quote.projectName,
          revision: quote.revision,
          customer: quote.customers[0].Name,
          date: quote.created_at.split("-").reverse().join("-"),
          updated_at: quote.updated_at.split("-").reverse().join("-"),
          status: quote.approvalStatus.toUpperCase(),
          preparedBy: quote.estimator.Name,
          creator: (
            <AssemblyProductCell
              name={quote.user.name}
              image={quote.user.profile_image}
              id={index}
            />
          ),
          price: quote.total_price ? formatNumber(quote.total_price) : "",
          actions: (
            <Grid container spacing={2}>
              <Grid item xs={3} />
              <Grid item xs={3}>
                <AddAssemblyCell
                  icon="content_copy"
                  color="dark"
                  onIconClick={handleCopyQuoteClick}
                  assembly={{ quoteID: quote.id }}
                />
              </Grid>
              <Grid item xs={3}>
                <AddAssemblyCell
                  icon="edit"
                  color="info"
                  onIconClick={handleEditQuoteClick}
                  assembly={{ quoteID: quote.id }}
                />
              </Grid>
              <Grid item xs={3}>
                <Can I="delete" this={subject("quotes", quote)}>
                  <AddAssemblyCell
                    icon="delete"
                    color="error"
                    onIconClick={handleDeleteQuoteClick}
                    assembly={{ quoteID: quote.id }}
                  />
                </Can>
              </Grid>
            </Grid>
          ),
        };
      });
    } else {
      return [
        {
          crm: "",
          project: "",
          revision: "",
          customer: "",
          date: "",
          updated_at: "",
          status: "",
          preparedBy: "",
          creator: "",
          price: "",
          actions: "",
        },
      ];
    }
  };

  const requiredFields =
    currentClient.customerName === "" ||
    currentClient.customerName === undefined ||
    currentClient.customerName === null ||
    currentClient.contactName === "" ||
    currentClient.contactName === undefined ||
    currentClient.contactName === null ||
    currentClient.businessName === "" ||
    currentClient.businessName === undefined ||
    currentClient.businessName === null ||
    currentProject.projectName === "" ||
    currentProject.projectName === undefined ||
    currentProject.projectName === null ||
    currentProject.crmOpp === "" ||
    currentProject.crmOpp === undefined ||
    currentProject.crmOpp === null;

  return (
    <DashboardLayout>
      <Grid container spacing={2} my={2}>
        <Grid item>
          <MDBox component="h1" fontWeight="bold" fontSize={24}>
            Quotes
          </MDBox>
        </Grid>
        <Grid item xs={1.5} sx={{ ml: "auto" }}>
          <MDButton
            variant="gradient"
            size="small"
            color="info"
            fullWidth
            onClick={handleNewQuoteClick}
          >
            +&nbsp; New Quote
          </MDButton>
        </Grid>
        <Grid item xs={12}>
          {!allQuoteIsSuccess || isLoading ? (
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              height={300}
            >
              <CircularProgress color="info" />
            </MDBox>
          ) : (
            <QueryCustomTable
              data={{
                columns: [
                  {
                    header: "CRM",
                    accessorKey: "crm",
                    size: 50,
                  },
                  {
                    header: "Rev",
                    accessorKey: "revision",
                    size: 10,
                  },
                  {
                    header: "Project",
                    accessorKey: "project",
                    size: 100,
                  },
                  {
                    header: "Client",
                    accessorKey: "customer",
                    size: 100,
                  },
                  {
                    header: "Created",
                    accessorKey: "date",
                    size: 60,
                  },
                  {
                    header: "Updated",
                    accessorKey: "updated_at",
                    size: 60,
                  },
                  {
                    header: "Status",
                    accessorKey: "status",
                    size: 70,
                  },
                  {
                    header: "BDM",
                    accessorKey: "preparedBy",
                    size: 80,
                  },
                  { header: "Created By", accessorKey: "creator", size: 120 },
                  {
                    header: "Price excl. GST ($)",
                    accessorKey: "price",
                    size: 80,
                    muiTableHeadCellProps: {
                      align: "right",
                    },
                    muiTableBodyCellProps: {
                      align: "right",
                    },
                  },
                  {
                    header: "Actions",
                    accessorKey: "actions",
                    size: 85,
                    muiTableHeadCellProps: {
                      align: "right",
                    },
                    muiTableBodyCellProps: {
                      align: "right",
                    },
                  },
                ],
                rows: makeQuoteTableRows(),
              }}
            />
          )}
        </Grid>
      </Grid>
      <CrudDialog
        title="Delete Quote"
        size="xs"
        control={{
          open: deleteDialogOpen,
          onClose: handleCancelDeleteQuote,
        }}
        content={
          <DialogContentText>
            Are you sure you want to delete this quote?
          </DialogContentText>
        }
        actions={{
          confirmName: "Delete",
          confirmHandler: handleActionDeleteQuote,
        }}
      />
      <CrudDialog
        title="Copy Quote"
        control={{
          open: copyDialogOpen,
          onClose: handleCancelCopyQuote,
        }}
        required={requiredFields}
        content={<EstimateInfo />}
        actions={{
          confirmName: "Copy",
          confirmHandler: () => {
            setCopyLoading(true);
            handleActionCopyQuote();
          },
          confirmLoading: copyLoading,
        }}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default EstimateLanding;
