// React Query Functions. Used to fetch data from the backend. Functions to fetch data from the backend are defined in the src/services/cruds-service.js file. Using React Query, we can define the functions in the src/services/query-service.js file and use them in the components.

import crudsService from "services/cruds-service";
import AuthService from "services/auth-service";
import { useQuery, QueryClient, keepPreviousData } from "@tanstack/react-query";

const queryClient = new QueryClient();

const staleTime = 60000; // 1 minute
const cacheTime = 300000; // 5 minutes

// function to get the current user id
export const useCurrentUser = () => {
  const { data, isLoading, isError, error, isSuccess } = useQuery({
    queryKey: ["currentUser"],
    queryFn: async () => {
      const res = await AuthService.getProfile();
      return res.data.id;
    },
    staleTime: staleTime,
    cacheTime: cacheTime,
  });

  return { data, isLoading, isError, error, isSuccess };
};

export const useParts = () => {
  const { data, isLoading, isError, error, isSuccess, isStale, isRefetching } =
    useQuery({
      queryKey: ["parts"],
      queryFn: async () => {
        const res = await crudsService.getParts();
        return res.data;
      },
      staleTime: staleTime,
      cacheTime: cacheTime,
    });

  return { data, isLoading, isError, error, isSuccess, isStale, isRefetching };
};

export const useQueryParts = () => {
  const { data, isLoading, isError, error, isSuccess } = useQuery({
    queryKey: ["queryParts"],
    queryFn: crudsService.getParts,
    placeholderData: keepPreviousData,
    staleTime: staleTime,
    cacheTime: cacheTime,
  });

  return { data, isLoading, isError, error, isSuccess };
};

// line items
export const useNewItems = () => {
  const { data, isLoading, isError, error, isSuccess } = useQuery({
    queryKey: ["newItems"],
    queryFn: async () => {
      const res = await crudsService.getNewItems();
      return res.data;
    },
    staleTime: staleTime,
    cacheTime: cacheTime,
  });

  return { data, isLoading, isError, error, isSuccess };
};

// line items discounts based on customer deb_acc (id)
export const useNewItemsDiscounts = (id) => {
  const { data, isLoading, isError, error, isSuccess } = useQuery({
    queryKey: ["newItemsDiscounts", id],
    queryFn: async () => {
      const res = await crudsService.getNewItemsDiscounts(id);
      return res.data;
    },
    staleTime: staleTime,
    cacheTime: cacheTime,
  });

  return { data, isLoading, isError, error, isSuccess };
};

export const useAssemblies = () => {
  const { data: id } = useCurrentUser();
  const { isLoading, isError, data, error, isSuccess, refetch } = useQuery({
    queryKey: ["assemblies", id],
    queryFn: () => crudsService.getPartAssemblies(),
    enabled: !!id,
    staleTime: staleTime,
    cacheTime: cacheTime,
  });

  return { data, isLoading, isError, error, isSuccess, refetch };
};

export const useAssembly = (id) => {
  const { data, isLoading, isError, error, isSuccess, refetch } = useQuery({
    queryKey: ["assembly", id],
    queryFn: () => crudsService.getPartAssembly(id),
    enabled: !!id,
    staleTime: staleTime,
    cacheTime: cacheTime,
    /* initialData: () => {
      return queryClient
        .getQueryData("assemblies")
        .find((assembly) => assembly.id === id);
    }, */
  });

  return { data, isLoading, isError, error, isSuccess, refetch };
};

// get logged in user's quotes
export const useQuotes = () => {
  const { data: id } = useCurrentUser();
  const { data, isLoading, isError, error, isSuccess } = useQuery({
    queryKey: ["quotes", id],
    queryFn: () => crudsService.getUserQuotingTools(id),
    enabled: !!id,
    staleTime: staleTime,
    cacheTime: cacheTime,
  });

  return { data, isLoading, isError, error, isSuccess };
};

export const useAllQuotes = () => {
  const { data, isLoading, isError, error, refetch, isSuccess } = useQuery({
    queryKey: ["allQuotes"],
    queryFn: crudsService.getQuotingTools,
    staleTime: staleTime,
    cacheTime: cacheTime,
  });

  return { data, isLoading, isError, error, refetch, isSuccess };
};

export const useQuote = (id) => {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["quote", id],
    queryFn: () => crudsService.getQuotingTool(id),
    enabled: !!id,
    staleTime: staleTime,
    cacheTime: cacheTime,
    initialData: () => {
      return queryClient
        .getQueryData("quotes")
        .find((quote) => quote.id === id);
    },
  });

  return { data, isLoading, isError, error };
};

export const useUsers = () => {
  const { data, isLoading, isError, error, isSuccess } = useQuery({
    queryKey: ["users"],
    queryFn: crudsService.getUsers,
    staleTime: staleTime,
    cacheTime: cacheTime,
  });

  return { data, isLoading, isError, error, isSuccess };
};

export const useCircuitBreakers = () => {
  const { data, isLoading, isError, error, isSuccess, isFetching } = useQuery({
    queryKey: ["circuitBreakers"],
    queryFn: async () => {
      const res = await crudsService.getCircuitBreakers();
      return res.circuitBreakers;
    },
    staleTime: staleTime,
    cacheTime: cacheTime,
  });

  return { data, isLoading, isError, error, isSuccess, isFetching };
};

export const useCustomers = (subString) => {
  const { data, isLoading, isError, error, isSuccess } = useQuery({
    queryKey: ["customers", subString],
    queryFn: async () => await crudsService.getCustomers(subString),
    staleTime: staleTime,
    cacheTime: cacheTime,
  });
  return { data, isLoading, isError, error, isSuccess };
};

export const useEstimators = () => {
  const { data, isLoading, isError, error, isSuccess } = useQuery({
    queryKey: ["estimators"],
    queryFn: async () => await crudsService.getEstimators(),
    staleTime: staleTime,
    cacheTime: cacheTime,
  });
  return { data, isLoading, isError, error, isSuccess };
};

export const useDefaultTCList = () => {
  const { data, isLoading, isError, error, isSuccess } = useQuery({
    queryKey: ["defaultTCList"],
    queryFn: async () => await crudsService.getDefaultTCList(),
    staleTime: staleTime,
    cacheTime: cacheTime,
  });

  return { data, isLoading, isError, error, isSuccess };
};

export const useDBInfo = () => {
  const { data, isLoading, isError, error, isSuccess } = useQuery({
    queryKey: ["dbInfo"],
    queryFn: async () => await crudsService.getdbInfo(),
    staleTime: staleTime,
    cacheTime: cacheTime,
  });

  return { data, isLoading, isError, error, isSuccess };
};
