import MDButton from "components/MDButton";
import { Grid } from "@mui/material";

import QuoteInformation from "layouts/November/NewEstimate/EstimateSave/components/QuoteInformation";
import FinalQuoteTable from "layouts/November/NewEstimate/EstimateSave/components/FinalQuoteTable";
import PDFListGenerator from "layouts/November/NewEstimate/EstimateSave/components/PDFListGenerator";
import { ApsContext } from "ApsContext";
import FinalFactoryItemsTable from "./components/FinalFactoryItemsTable";
import { useContext } from "react";
import { QuoteExports } from "hooks/QuoteExports";

function EstimateSave() {
  const { generatePDFQuote } = PDFListGenerator();
  const {
    bomExcel,
    manufactureWorkPackExcel,
    esgAutoGenExcelData,
    quoteExcel,
  } = QuoteExports();
  const { approvalStatus } = useContext(ApsContext);
  /*  const { data: quote, isLoading: quoteIsLoading } = useQuote(); */

  const handleGenerateQuote = () => {
    generatePDFQuote();
    quoteExcel();
  };

  const handleGenerateESG = () => {
    esgAutoGenExcelData();
  };

  const handleGenerateBOM = () => {
    bomExcel();
  };

  const statusMWO =
    approvalStatus === "manufacturing" || approvalStatus === "approved";

  const handleGenerateMWP = () => {
    if (statusMWO) {
      manufactureWorkPackExcel("const");
    } else {
      manufactureWorkPackExcel("prelim");
    }
  };

  return (
    <>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <QuoteInformation />
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={0} md={0} lg={2}></Grid>
            <Grid item xs={12} md={6} lg={2}>
              <MDButton
                color="info"
                size="small"
                variant="gradient"
                fullWidth
                onClick={() => handleGenerateQuote()}
              >
                Download Quote
              </MDButton>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <MDButton
                color="info"
                size="small"
                variant="gradient"
                fullWidth
                onClick={() => handleGenerateESG()}
              >
                Download ESG
              </MDButton>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <MDButton
                color="info"
                size="small"
                variant="gradient"
                fullWidth
                onClick={() => handleGenerateBOM()}
              >
                Download Sum. BOM
              </MDButton>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <MDButton
                color="info"
                size="small"
                variant="gradient"
                fullWidth
                onClick={() => handleGenerateMWP()}
              >
                {`Download MWO - ${statusMWO ? "CONST." : "PRELIM"}`}
              </MDButton>
            </Grid>
            <Grid item xs={0} md={0} lg={2}></Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FinalQuoteTable />
        </Grid>
        <Grid item xs={12}>
          <FinalFactoryItemsTable />
        </Grid>
      </Grid>
    </>
  );
}

export default EstimateSave;
