import { useMemo } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import MDTypography from "components/MDTypography";

const INITIAL_STATE = {
  density: "compact",
  showGlobalFilter: true,
  showToolbarDropZone: false,
};

function QueryCustomTable({ data }) {
  const columns = useMemo(() => data.columns, [data.columns]);
  const tableData = useMemo(() => data.rows, [data.rows]);
  let title = "";
  if (data.title) {
    title = data.title;
  }

  const table = useMaterialReactTable({
    columns: columns,
    data: tableData,
    initialState: INITIAL_STATE,
    enableHiding: false,
    enableFullScreenToggle: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableDensityToggle: false,
    layoutMode: "grid",
    autoResetPageIndex: false,
    paginationDisplayMode: "pages",

    renderTopToolbarCustomActions: () => {
      return (
        <>
          {data.customTopActions ? (
            data.customTopActions
          ) : (
            <MDTypography
              variant="h6"
              fontWeight="medium"
              textTransform="capitalize"
            >
              {title}
            </MDTypography>
          )}
        </>
      );
    },

    muiTableHeadProps: {
      sx: {
        display: "table-header-group",
        padding: 0,
      },
    },
    muiTableHeadRowProps: {
      sx: {
        boxShadow: "none",
      },
    },
    muiTablePaperProps: {
      sx: {
        width: "100%",
        px: 2,
        elevation: 0,
        backgroundColor: "transparent",
      },
    },
    muiTableContainerProps: {
      sx: {
        mt: 2,
        elevation: 0,
        borderRadius: 0,
        boxShadow: "none",
      },
    },
    muiPaginationProps: {
      rowsPerPageOptions: [6],
      showRowsPerPage: false,
      showFirstButton: false,
      showLastButton: false,
      variant: "text",
    },
    muiTableBodyCellProps: {
      sx: {
        paddingY: 0,
        borderTop: "0.1px solid #E0E0E0",
        fontSize: "0.75rem",
        fontWeight: "400",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        fontSize: "0.8rem",
        fontWeight: "600",
        textTransform: "uppercase",
      },
    },
    muiDetailPanelProps: {
      style: {
        padding: 0,
      },
    },
    mrtTheme: {
      baseBackgroundColor: "#ffffff00",
    },
  });

  return <MaterialReactTable table={table} />;
}

export default QueryCustomTable;
