import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";

/*    Dialog for add, edit and delete
    title (optional): string; title of the dialog
    size (optional): string; size of the dialog
    control (required): {}; contains dialog state controls
      open (required): boolean; state of dialog
      onClose (required): () => {}; function to call when dialog requests to close
    required (optional): boolean; special conditions to prevent user from clicking "Confirm" button
    content (required): <></>; elements that render inside <DialogContent>
    actions (required): {}; actions for cancel and confirm action
      cancelName (optional): string; name on "Cancel" button
      cancelHandler (optional): () => {}; cancel function to call when "Cancel" button is pressed
      Note: cancelHandler will be the same as onClose by default
      confirmName (optional): string; name on "Confirm" button
      confirmHandler (required): () => {}; confirm function to call when "Confirm" button is pressed
      confirmLoading (optional): boolean; if true, confirm button replaced by loading symbol
 */

export default function CrudDialog({
  title = "Dialog",
  size = "xl",
  control = { open: false, onClose: () => {} },
  required = false,
  content = <></>,
  actions = {
    cancelName: "Cancel",
    confirmName: "Confirm",
    confirmHandler: () => {},
    confirmLoading: false,
  },
}) {
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      actions.confirmHandler();
      e.preventDefault();
    }
  };

  return (
    <Dialog
      open={control.open}
      onClose={control.onClose}
      fullWidth
      maxWidth={size}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <form onKeyDown={handleKeyDown}>{content}</form>
      </DialogContent>
      <DialogActions>
        {actions.hasOwnProperty("cancelName") && actions.cancelName === "" ? (
          <></>
        ) : (
          <MDButton
            variant="gradient"
            size="small"
            color="error"
            onClick={
              actions.hasOwnProperty("cancelHandler") &&
              typeof actions.cancelHandler === "function"
                ? actions.cancelHandler
                : control.onClose
            }
          >
            {actions.hasOwnProperty("cancelName") &&
            typeof actions.cancelName === "string"
              ? actions.cancelName
              : "Cancel"}
          </MDButton>
        )}

        {actions.confirmLoading ? (
          <MDBox display="flex" justifyContent="center" alignItems="center">
            <CircularProgress color="info" />
          </MDBox>
        ) : required ? (
          <Tooltip title="Required fields can not be empty">
            <MDBox ml={1}>
              <MDButton
                variant="gradient"
                size="small"
                color="success"
                disabled
              >
                {actions.hasOwnProperty("confirmName") &&
                typeof actions.confirmName === "string"
                  ? actions.confirmName
                  : "Confirm"}
              </MDButton>
            </MDBox>
          </Tooltip>
        ) : (
          <MDButton
            variant="gradient"
            size="small"
            color="success"
            onClick={actions.confirmHandler}
          >
            {actions.hasOwnProperty("confirmName") &&
            typeof actions.confirmName === "string"
              ? actions.confirmName
              : "Confirm"}
          </MDButton>
        )}
      </DialogActions>
    </Dialog>
  );
}
