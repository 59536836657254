import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DragTestBaseChassis from "customInputs/DragNDropChassis/DragTestBaseChassis";
import { useEffect, useContext, useState } from "react";
import { ApsContext } from "ApsContext";
import { DragContext } from "customInputs/DragNDropChassis/DragContext";
import { DialogContentText, Grid } from "@mui/material";
import MDInput from "components/MDInput";
import CrudDialog from "customInputs/crud-dialog";

const placeHolderFuncText = {
  id: "",
  funcText: "",
  other: "",
};

const blankPart = {
  partNumber: "",
  description: "",
  supplier: "",
  cost: "",
};

function ChassisDnD() {
  const {
    handleCloseBreakerDialog,
    breakerDialogOpen,
    breakerToEdit,
    updateFuncText,
  } = useContext(DragContext);
  const { getCurrentChassis } = useContext(ApsContext);
  const { poleConfig, circuitBreakers } = getCurrentChassis();

  const [breakerFuncTextInput, setBreakerFuncTextInput] =
    useState(placeHolderFuncText);
  const [currentSelection, setCurrentSelection] = useState(blankPart);

  // function to update the breakerFuncTextInput when the breakerToEdit changes. This is to ensure that the text fields are updated when the user clicks on a different breaker. The breakerFuncTextInput is used to store the text fields for the breaker that is being edited and may exist in the breakerFuncText array. If the breakerToEdit is not found in the breakerFuncText array, then the breakerFuncTextInput is set to the placeHolderFuncText.
  useEffect(() => {
    if (breakerToEdit) {
      const breaker = poleConfig.find(
        (pole) => pole.breaker && pole.breaker.id === breakerToEdit
      );

      // set the details for the breaker
      const breakerDetails = circuitBreakers.find(
        (breaker) => breaker.part.partNumber === breakerToEdit.slice(0, -2)
      );

      if (breakerDetails) {
        const foundDetails = {
          partNumber: breakerDetails.part.partNumber,
          description: breakerDetails.part.description,
          supplier: breakerDetails.part.supplier,
          cost: breakerDetails.part.cost,
        };
        setCurrentSelection(foundDetails);
      }

      if (breaker) {
        const newBreakerFuncTextInput = {
          id: breakerToEdit,
          funcText: breaker.breaker.funcText,
          other: breaker.breaker.other,
        };
        setBreakerFuncTextInput(newBreakerFuncTextInput);
      }
    } else {
      setBreakerFuncTextInput(placeHolderFuncText);
      setCurrentSelection(blankPart);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breakerToEdit]);

  const onBreakerFuncTextChange = (e) => {
    setBreakerFuncTextInput({
      ...breakerFuncTextInput,
      funcText: e.target.value,
    });
  };

  // uncomment if add. text gets used again
  /* const onBreakerOtherTextChange = (e) => {
    setBreakerFuncTextInput({
      ...breakerFuncTextInput,
      other: e.target.value,
    });
  }; */

  const onConfirmFuncText = () => {
    if (breakerToEdit && breakerToEdit === breakerFuncTextInput.id) {
      const result = updateFuncText(breakerToEdit, breakerFuncTextInput);
      if (result.success) {
        handleCloseBreakerDialog();
      } else {
        window.alert(result.message);
      }
    }
  };

  return (
    <>
      <MDBox display="flex" width="100%" justifyContent="space-between" p={2}>
        <Grid
          container
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          spacing={2}
        >
          <Grid item xs={12}>
            <DragTestBaseChassis />
          </Grid>
        </Grid>
      </MDBox>
      <CrudDialog
        title="Device Properties"
        size="xs"
        control={{
          open: breakerDialogOpen,
          onClose: handleCloseBreakerDialog,
        }}
        content={
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {currentSelection ? (
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <MDTypography
                      variant="body2"
                      fontWeight="medium"
                      align="center"
                    >
                      Part Number
                    </MDTypography>
                    <MDTypography
                      variant="body2"
                      fontWeight="regular"
                      align="center"
                    >
                      {currentSelection.partNumber}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDTypography
                      variant="body2"
                      fontWeight="medium"
                      align="center"
                    >
                      Description
                    </MDTypography>
                    <MDTypography
                      variant="body2"
                      fontWeight="regular"
                      align="center"
                    >
                      {currentSelection.description}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDTypography
                      variant="body2"
                      fontWeight="medium"
                      align="center"
                    >
                      Supplier
                    </MDTypography>
                    <MDTypography
                      variant="body2"
                      fontWeight="regular"
                      align="center"
                    >
                      {currentSelection.supplier}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDTypography
                      variant="body2"
                      fontWeight="medium"
                      align="center"
                    >
                      Cost
                    </MDTypography>
                    <MDTypography
                      variant="body2"
                      fontWeight="regular"
                      align="center"
                    >
                      {currentSelection.cost}
                    </MDTypography>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <DialogContentText align="center">
                Use the field below to specify device properties shown in
                schematics
              </DialogContentText>
            </Grid>
            <Grid item xs={12}>
              <MDInput
                margin="dense"
                fullWidth
                label="Functional text"
                value={
                  breakerFuncTextInput.id === ""
                    ? ""
                    : breakerFuncTextInput.funcText
                }
                onChange={(e) => onBreakerFuncTextChange(e)}
              />
            </Grid>
            {/* uncomment if add. text gets used again */}
            {/* <Grid item xs={12}>
              <MDInput
                margin="dense"
                fullWidth
                label="Additional text"
                value={
                  breakerFuncTextInput.id === ""
                    ? ""
                    : breakerFuncTextInput.other
                }
                onChange={(e) => onBreakerOtherTextChange(e)}
              />
            </Grid> */}
          </Grid>
        }
        actions={{
          confirmName: "Save",
          confirmHandler: onConfirmFuncText,
        }}
      />
    </>
  );
}

export default ChassisDnD;
