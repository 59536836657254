import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useFormik } from "formik";
import MDInput from "components/MDInput";
import { useContext, useEffect } from "react";
import { ApsContext } from "ApsContext";

function ProjectInfo() {
  const { currentProject, updateProject, currentQuoteNum } =
    useContext(ApsContext);

  const formik = useFormik({
    initialValues: currentProject,
    onSubmit: (values) => {},
  });

  // refreshing the page, set values
  useEffect(() => {
    formik.setFieldValue("projectName", currentProject.projectName);
    formik.setFieldValue("subProjectName", currentProject.subProjectName);
    formik.setFieldValue("crmOpp", currentProject.crmOpp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuoteNum]);

  useEffect(() => {
    updateProject(formik.values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  const handleProjChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length < 65) {
      formik.handleChange(e);
    }
  };

  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold" textAlign="left">
        Project Information
      </MDTypography>
      <MDBox mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <MDInput
              type="text"
              size="small"
              label="Main Project Name"
              variant="standard"
              fullWidth
              required
              onChange={handleProjChange}
              value={formik.values.projectName}
              name="projectName"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <MDInput
              type="text"
              label="CRM Opportunity Number"
              variant="standard"
              size="small"
              fullWidth
              required
              onChange={handleProjChange}
              value={formik.values.crmOpp}
              name="crmOpp"
            />
          </Grid>
          <Grid item xs={12}>
            <MDInput
              type="text"
              label="Sub Project Name"
              variant="standard"
              size="small"
              fullWidth
              onChange={handleProjChange}
              value={formik.values.subProjectName}
              name="subProjectName"
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default ProjectInfo;
