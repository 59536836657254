import { useState, useContext, useEffect, useCallback } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { useFormik } from "formik";
import { ApsContext } from "ApsContext";
import { useCustomers, useEstimators } from "services/query-service";
import { CircularProgress } from "@mui/material";
import MDButton from "components/MDButton";
import SelectAutocomplete from "customInputs/select-autocomplete";

function ClientInfo() {
  const { currentClient, updateClient, currentQuoteNum, promptNotification } =
    useContext(ApsContext);
  const [searchValue, setSearchValue] = useState("qwerty");
  const [typedValue, setTypedValue] = useState("qwerty");
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  const [estimatorOptions, setEstimatorOptions] = useState([]);
  const [selected, setSelected] = useState(false);
  const [emptyEstimator] = useState({
    contactName: "",
    phone: "0",
    email: "example@email.com",
    directEmail: "example@email.com",
    mobilePhone: "0",
  });

  const {
    data: customers,
    isLoading: customersIsLoading,
    isSuccess: customersIsSuccess,
  } = useCustomers(searchValue);

  const {
    data: estimators,
    isLoading: estimatorsIsLoading,
    isSuccess: estimatorsIsSuccess,
  } = useEstimators();

  const formik = useFormik({
    initialValues: currentClient,
    onSubmit: (values) => {},
  });

  // refreshing the page, set values
  useEffect(() => {
    formik.setFieldValue("deb_acc", currentClient.deb_acc);
    formik.setFieldValue("businessName", currentClient.businessName);
    formik.setFieldValue("city", currentClient.city);
    formik.setFieldValue("state", currentClient.state);
    formik.setFieldValue("contactName", currentClient.contactName);
    formik.setFieldValue("contactNumber", currentClient.contactNumber);
    formik.setFieldValue("contactEmail", currentClient.contactEmail);
    formik.setFieldValue("customerName", currentClient.customerName);
    formik.setFieldValue("directEmail", currentClient.directEmail);
    formik.setFieldValue("mobilePhone", currentClient.mobilePhone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuoteNum]);

  useEffect(() => {
    if (customersIsSuccess && searchValue !== "qwerty") {
      if (customers.data.length > 0) {
        promptNotification("Results found", "success");
      } else {
        promptNotification("Results found", "error");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customersIsSuccess]);

  useEffect(() => {
    updateClient(formik.values);
  }, [formik.values, updateClient]);

  useEffect(() => {
    setSelected(false);
  }, [searchValue]);

  const formatCustomers = useCallback(() => {
    return customers.data.map((customer) => {
      if (customer.estimator !== null) {
        return {
          deb_acc: customer.deb_acc,
          businessName: customer.Name,
          city: customer.City,
          state: customer.State,
          customerName: "",
          contactName: customer.salesRep,
          phone: customer.estimator.OfficePhone,
          email: customer.estimator.APSEmail,
          directEmail: customer.estimator.DirectEmail,
          mobilePhone: customer.estimator.MobilePhone,
          discountGroup: "",
          specialPriceSchedule: "",
        };
      } else {
        return {
          deb_acc: customer.deb_acc,
          businessName: customer.Name,
          city: customer.City,
          state: customer.State,
          customerName: "",
          contactName: customer.salesRep,
          phone: "",
          email: "",
          directEmail: "",
          mobilePhone: "",
          discountGroup: "",
          specialPriceSchedule: "",
        };
      }
    });
  }, [customers]);

  const formatEstimators = useCallback(() => {
    return estimators.data.map((estimator) => {
      return {
        contactName: estimator.Name,
        phone: estimator.OfficePhone,
        email: estimator.APSEmail,
        directEmail: estimator.DirectEmail,
        mobilePhone: estimator.MobilePhone,
      };
    });
  }, [estimators]);

  const handleEstimatorChange = (option) => {
    formik.setFieldValue("contactName", option.contactName);
    formik.setFieldValue("contactNumber", option.phone);
    formik.setFieldValue("contactEmail", option.email);
    formik.setFieldValue("directEmail", option.directEmail);
    formik.setFieldValue("mobilePhone", option.mobilePhone);
  };

  const handleSearch = () => {
    setSearchValue(typedValue);
  };

  useEffect(() => {
    // Update the autocomplete options when customers data changes
    if (customersIsSuccess && customers) {
      setAutocompleteOptions(formatCustomers());
    }
  }, [customers, formatCustomers, customersIsSuccess]);

  useEffect(() => {
    // Update the autocomplete options when estimators data changes
    if (estimatorsIsSuccess && estimators) {
      setEstimatorOptions([emptyEstimator, ...formatEstimators()]);
    }
  }, [estimators, formatEstimators, estimatorsIsSuccess, emptyEstimator]);

  return (
    <MDBox>
      <Grid
        container
        alignItems="center"
        justifyContent="flex-start"
        spacing={2}
      >
        <Grid item xs={12}>
          <MDTypography variant="h5" textAlign="left" mb={2}>
            Client Information
          </MDTypography>
        </Grid>
        <Grid item xs={12} lg={6}>
          {customersIsLoading && !customersIsSuccess ? (
            <MDBox display="flex" justifyContent="center" alignItems="center">
              <CircularProgress color="info" />
            </MDBox>
          ) : (
            <Autocomplete
              open={customers.data.length !== 0 && !selected}
              onInputChange={(event, newValue) => {
                if (newValue === null) {
                  formik.resetForm();
                }
                if (
                  event.target.value !== 0 &&
                  event.target.value !== null &&
                  event.target.value !== undefined
                ) {
                  setTypedValue(event.target.value);
                }
              }}
              onChange={(event, newValue) => {
                if (newValue === null) {
                  setSelected(false);
                  formik.resetForm();
                }
                if (
                  (event.target.value !== 0 &&
                    event.target.value !== null &&
                    event.target.value !== undefined) ||
                  newValue !== null
                ) {
                  handleSearch();
                  setSelected(true);
                }
                if (newValue !== null) {
                  formik.setFieldValue("deb_acc", newValue.deb_acc);
                  formik.setFieldValue("businessName", newValue.businessName);
                  formik.setFieldValue("city", newValue.city);
                  formik.setFieldValue("state", newValue.state);
                  formik.setFieldValue("contactName", newValue.contactName);
                  formik.setFieldValue("contactNumber", newValue.phone);
                  formik.setFieldValue("contactEmail", newValue.email);
                  formik.setFieldValue("directEmail", newValue.directEmail);
                  formik.setFieldValue("mobilePhone", newValue.mobilePhone);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = options.filter((option) =>
                  `${option.deb_acc} | ${option.businessName} | ${option.contactName} | ${option.city} | ${option.state}`
                    .toLowerCase()
                    .includes(params.inputValue.toLowerCase())
                );
                if (filtered.length === 0) {
                  filtered.push();
                }

                return filtered;
              }}
              options={autocompleteOptions}
              autoComplete
              autoFocus
              freeSolo
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Regular option
                return option.businessName;
              }}
              renderOption={(props, option) => (
                <li
                  {...props}
                  key={`${option.deb_acc} | ${option.businessName} | ${option.contactName} | ${option.city} | ${option.state}`}
                >{`${option.deb_acc} | ${option.businessName} | ${option.contactName} | ${option.city} | ${option.state}`}</li>
              )}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  size="small"
                  variant="outlined"
                  placeholder={
                    customers.data.length === 0
                      ? "Type Account Number, Customer Name, BDM, City or State"
                      : "Results found. Type again to refine the search and select an option"
                  }
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12} lg={2}>
          <MDButton
            size="small"
            color="dark"
            variant="gradient"
            onClick={handleSearch}
          >
            Search
          </MDButton>
        </Grid>
        <Grid item xs={0} lg={6}></Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={3}>
          <MDTypography name="deb_acc" variant="caption">
            Account Number: {formik.values.deb_acc}
          </MDTypography>
        </Grid>
        <Grid item xs={12} md={3}>
          <MDTypography name="businessName" variant="caption">
            Business Name: {formik.values.businessName}
          </MDTypography>
        </Grid>
        <Grid item xs={12} md={3}>
          <MDTypography name="businessName" variant="caption">
            City: {formik.values.city}
          </MDTypography>
        </Grid>
        <Grid item xs={12} md={3}>
          <MDTypography name="businessName" variant="caption">
            State: {formik.values.state}
          </MDTypography>
        </Grid>
        <Grid item xs={12} md={6}>
          <MDInput
            type="text"
            label="Contact Name"
            variant="standard"
            fullWidth
            required
            onChange={formik.handleChange}
            value={formik.values.customerName}
            name="customerName"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {estimatorsIsLoading && !estimatorsIsSuccess ? (
            <MDBox display="flex" justifyContent="center" alignItems="center">
              <CircularProgress color="info" />
            </MDBox>
          ) : (
            <SelectAutocomplete
              options={estimatorOptions}
              selected={
                currentClient.contactName === null ||
                currentClient.contactName === "" ||
                currentClient.contactName === undefined
                  ? emptyEstimator
                  : estimatorOptions.find(
                      (estimator) =>
                        estimator.contactName === currentClient.contactName
                    )
              }
              selectedAction={handleEstimatorChange}
              customLabel="BDM"
              requiredField={true}
              renderOption={(props, option) => (
                <li
                  {...props}
                  key={option.contactName}
                >{`${option.contactName}`}</li>
              )}
              getOptionLabel={(option) => `${option.contactName}`}
              filterOptions={(options, params) => {
                const filtered = options.filter((option) =>
                  `${option.contactName}`
                    .toLowerCase()
                    .includes(params.inputValue.toLowerCase())
                );
                if (filtered.length === 0) {
                  filtered.push();
                }
                return filtered;
              }}
            />
          )}
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default ClientInfo;
