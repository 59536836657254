// function to return a table for parts of an assembly. This will be rendered in the DetailPanel of the assembly table.

import React, { useMemo } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";

const INITIAL_STATE = {
  density: "compact",
  showGlobalFilter: true,
  showToolbarDropZone: false,
  pagination: { pageIndex: 0, pageSize: 100 },
};

function QueryAssembliesDetailTable({ assemblyData }) {
  const data = useMemo(
    () => assemblyData.original.subRows,
    [assemblyData.original.subRows]
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: "partNumber",
        header: "Part Number",
        size: 150,
      },
      {
        accessorKey: "description",
        header: "Part Description",
        size: 600,
      },
      {
        accessorKey: "supplier",
        header: "Supplier",
        size: 150,
      },
      {
        accessorKey: "cost",
        header: "Cost ($)",
        size: 100,
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },
      {
        accessorKey: "qty",
        header: "Qty",
        size: 50,
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },
      {
        accessorKey: "labour",
        header: "Labour (min.)",
        size: 50,
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },
      {
        accessorKey: "stock",
        header: "Stock",
        size: 100,
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableDensityToggle: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableToolbarInternalActions: false,
    editDisplayMode: "cell",
    enableEditing: false,
    enableColumnOrdering: false,
    enableColumnFilters: false,
    enableColumnActions: false,
    autoResetPageIndex: false,
    initialState: INITIAL_STATE,
    paginationDisplayMode: "pages",

    muiTableHeadProps: {
      sx: {
        display: "table-header-group",
        padding: 0,
      },
    },

    muiTableHeadRowProps: {
      sx: {
        boxShadow: "none",
      },
    },
    muiTablePaperProps: {
      sx: {
        width: "100%",
        px: 2,
        elevation: 0,
        backgroundColor: "transparent",
        boxShadow: "none",
        mb: 2,
      },
    },
    muiTableContainerProps: {
      sx: {
        mt: 1,
        elevation: 0,
        borderRadius: 0,
        boxShadow: "none",
      },
    },
    muiPaginationProps: {
      showRowsPerPage: false,
      showFirstButton: false,
      showLastButton: false,
      variant: "text",
    },
    muiTableBodyCellProps: {
      sx: {
        paddingY: 0,
        borderTop: "0.1px solid #E0E0E0",
        fontSize: "0.75rem",
        fontWeight: "400",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        fontSize: "0.8rem",
        fontWeight: "600",
        textTransform: "uppercase",
      },
    },
    mrtTheme: {
      baseBackgroundColor: "#ffffff00",
    },
  });

  return <MaterialReactTable table={table} />;
}

export default QueryAssembliesDetailTable;
