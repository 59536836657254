import { useMemo, useState, useEffect, useContext } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import QueryAssembliesNoActionTable from "customInputs/queryTables/QueryAssembliesNoActionTable";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { Autocomplete, Grid } from "@mui/material";
import { ApsContext } from "ApsContext";

const INITIAL_STATE = {
  density: "compact",
  showGlobalFilter: true,
  columnVisibility: { filter_1: false, filter_2: false },
  showToolbarDropZone: false,
};

function QueryAssembliesTable({ assemblyData: data }) {
  const columns = useMemo(
    () => [
      {
        accessorKey: "filter_1",
        header: "Filter 1",
      },
      {
        accessorKey: "filter_2",
        header: "Filter 2",
      },
      ...data.columns,
    ],
    [data.columns]
  );
  const tableData = useMemo(() => data.rows, [data.rows]);
  let title = "";
  if (data.title) {
    title = data.title;
  }
  const { assemblyFilterCategories } = useContext(ApsContext);
  const [currentFilter1, setCurrentFilter1] = useState("");
  const [currentFilter2, setCurrentFilter2] = useState("");

  const getFilter1Options = () => {
    const filter1Options = new Set();
    filter1Options.add("");
    assemblyFilterCategories.forEach((category) => {
      filter1Options.add(category.filter1);
    });
    return Array.from(filter1Options);
  };

  const getFilter2Options = (filter1Val) => {
    const filter2Options = new Set();
    filter2Options.add("");
    assemblyFilterCategories.forEach((filter) => {
      if (filter.filter1 === filter1Val) {
        filter.filter2.forEach((options) => filter2Options.add(options));
      }
    });

    return Array.from(filter2Options);
  };

  const table = useMaterialReactTable({
    columns,
    data: tableData,
    initialState: INITIAL_STATE,
    enableHiding: false,
    enableFullScreenToggle: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableColumnOrdering: false,
    enableDensityToggle: false,
    layoutMode: "grid",
    autoResetPageIndex: false,
    renderTopToolbarCustomActions: () => {
      return (
        <Grid container spacing={1}>
          {title !== "" && (
            <Grid item xs={3} display="flex" alignItems="center">
              <MDTypography
                variant="h6"
                fontWeight="medium"
                textTransform="capitalize"
              >
                {title}
              </MDTypography>
            </Grid>
          )}
          <Grid item xs={4.5}>
            <Autocomplete
              value={currentFilter1}
              options={getFilter1Options(data.rows)}
              onChange={(e, val) => {
                if (val === null || val === "") {
                  table.resetColumnFilters();
                  setCurrentFilter1("");
                  setCurrentFilter2("");
                } else {
                  table.setColumnFilters([
                    { id: "filter_1", value: val },
                    {
                      id: "filter_2",
                      value: getFilter2Options(val)[0],
                    },
                  ]);
                  setCurrentFilter1(val);
                  setCurrentFilter2(getFilter2Options(val)[0]);
                }
              }}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  size="medium"
                  variant="standard"
                  label="Filter 1"
                />
              )}
            />
          </Grid>
          <Grid item xs={4.5}>
            <Autocomplete
              value={currentFilter2}
              options={getFilter2Options(currentFilter1)}
              onChange={(e, val) => {
                if (val === null || val === "") {
                  table.setColumnFilters((currentFilters) =>
                    currentFilters.filter((filter) => filter.id !== "filter_2")
                  );

                  setCurrentFilter2(val);
                  return;
                } else {
                  table.setColumnFilters((currentFilters) => [
                    ...currentFilters.filter(
                      (filter) => filter.id !== "filter_2"
                    ),
                    { id: "filter_2", value: val },
                  ]);
                  setCurrentFilter2(val);
                }
              }}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  size="medium"
                  variant="standard"
                  label="Filter 2"
                />
              )}
            />
          </Grid>
        </Grid>
      );
    },

    muiTableHeadProps: {
      sx: {
        display: "table-header-group",
        padding: 0,
      },
    },
    muiTableHeadRowProps: {
      sx: {
        boxShadow: "none",
      },
    },
    muiTablePaperProps: {
      sx: {
        width: "100%",
        px: 2,
        elevation: 0,
        backgroundColor: "transparent",
      },
    },
    muiTableContainerProps: {
      sx: {
        mt: 2,
        elevation: 0,
        borderRadius: 0,
        boxShadow: "none",
      },
    },
    muiPaginationProps: {
      rowsPerPageOptions: [6],
      showRowsPerPage: false,
      showFirstButton: false,
      showLastButton: false,
      variant: "text",
    },
    paginationDisplayMode: "pages",
    muiTableBodyCellProps: {
      sx: {
        paddingY: 0,
        borderTop: "0.1px solid #E0E0E0",
        fontSize: "0.75rem",
        fontWeight: "400",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        fontSize: "0.8rem",
        fontWeight: "600",
        textTransform: "uppercase",
      },
    },
    muiDetailPanelProps: {
      style: {
        padding: 0,
      },
    },
    mrtTheme: {
      baseBackgroundColor: "#ffffff00",
    },
    renderDetailPanel: ({ row }) => (
      <QueryAssembliesNoActionTable assemblyData={row} />
    ),
  });

  useEffect(() => {
    // Set the column filters here
    table.setColumnFilters((currentFilters) => [
      ...currentFilters.filter((filter) => filter.id !== "filter_1"),
      { id: "filter_1", value: currentFilter1 },
    ]);
    table.setColumnFilters((currentFilters) => [
      ...currentFilters.filter((filter) => filter.id !== "filter_2"),
      { id: "filter_2", value: currentFilter2 },
    ]);
  }, [currentFilter1, currentFilter2, table]);

  return <MaterialReactTable table={table} />;
}

export default QueryAssembliesTable;
