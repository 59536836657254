import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Card,
  Grid,
  Divider,
} from "@mui/material";
import { useState, useEffect, useContext } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import authService from "services/auth-service";
import QRCode from "qrcode";
import { ApsContext } from "ApsContext";

// Element used to enable and setup 2FA
function Setup2FA({ user, updateInfo }) {
  const { promptNotification } = useContext(ApsContext);
  const [setup2FADialog, setSetup2FADialog] = useState(false);
  const [disable2FADialog, setDisable2FADialog] = useState(false);
  const [token, setToken] = useState("");
  const [password2fa, setPassword2fa] = useState("");
  const [secret, setSecret] = useState("");

  useEffect(() => {
    if (setup2FADialog) {
      setToken("");
      setSecret("");
      (async () => {
        try {
          const res = await authService.init2fa({
            data: { attributes: { email: user.email, name: user.name } },
          });
          setSecret(res.secret);
          promptNotification(res.message, "success");
          QRCode.toCanvas(
            res.qrCode,
            { errorCorrectionLevel: "H" },
            function (err, canvas) {
              if (err) throw err;
              const container = document.getElementById("qrcode");
              container.appendChild(canvas);
            }
          );
        } catch (error) {
          promptNotification(
            `Error: ${
              error.hasOwnProperty("message")
                ? error.message
                : error.length > 0
                ? error[0].detail
                : "Unknown error has occurred when generating QR Code"
            }`,
            "error"
          );
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setup2FADialog]);

  const handleToken = (e) => {
    setToken(e.target.value);
  };

  const handleDisablePassword = (e) => {
    setPassword2fa(e.target.value);
  };

  const onSubmitSetup = async () => {
    if (!user.verified) {
      if (token.trim().length === 6) {
        try {
          const res = await authService.verify2fa({
            token: token,
            userId: user.id,
          });
          if (res.hasOwnProperty("message")) {
            updateInfo();
            promptNotification(`${res.message} 2FA Activated`, "success");
            setSetup2FADialog(false);
          } else {
            promptNotification(`Error: ${res.errors[0].detail}`, "error");
          }
        } catch (error) {
          promptNotification(
            `Error: ${
              error.hasOwnProperty("message")
                ? error.message
                : error.length > 0
                ? error[0].detail
                : "Unable to verify. Please try again"
            }`,
            "error"
          );
        }
      } else {
        promptNotification("Invalid token. Please try again.", "error");
      }
    } else {
      promptNotification("User is already verified.", "error");
    }
  };

  const onSubmitDisable = async () => {
    if (user.enable2fa) {
      if (!password2fa.trim().length < 8) {
        try {
          const res = await authService.updateProfile({
            data: {
              attributes: {
                verified: false,
                secret: null,
                enable2fa: false,
                require2fa: null,
                password: password2fa,
              },
            },
          });
          if (res.hasOwnProperty("data")) {
            updateInfo();
            promptNotification("2FA Disabled", "success");
            setDisable2FADialog(false);
          } else {
            promptNotification(`Error: ${res.errors[0].detail}`, "error");
          }
        } catch (error) {
          promptNotification(
            `Error: ${
              error.hasOwnProperty("message")
                ? error.message
                : error.length > 0
                ? error[0].detail
                : "Unable to disable. Please try again"
            }`,
            "error"
          );
        }
      } else {
        promptNotification("Enter a valid password", "error");
      }
    } else {
      promptNotification("User did not have 2FA active.", "error");
    }
  };

  const handleDialogSetupClose = () => {
    setSetup2FADialog(false);
  };

  const handleDialogDisableClose = () => {
    setDisable2FADialog(false);
  };

  return (
    <>
      <Card id="setup2fa">
        <Grid p={3} container spacing={2} alignItems="center">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={2.5}>
            <MDTypography variant="h5">2-Factor Authentication</MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={7} xl={6.5}>
            <MDTypography variant="body2" color="text">
              Setup 2FA for this account using TOTP
            </MDTypography>
          </Grid>
          <Grid item xs={6} sm={6} md={2} lg={3} xl={1.5}>
            <MDTypography
              variant="body2"
              color={user.enable2fa ? "success" : "error"}
            >
              {user.enable2fa ? "2FA is active" : "2FA is not active"}
            </MDTypography>
          </Grid>
          <Grid item xs={6} sm={6} md={2} lg={2} xl={1.5}>
            <MDBox display="flex" justifyContent="flex-end">
              <MDButton
                variant="gradient"
                color={user.enable2fa ? "error" : "dark"}
                size="small"
                onClick={() => {
                  user.enable2fa
                    ? setDisable2FADialog(true)
                    : setSetup2FADialog(true);
                }}
              >
                {user.enable2fa ? "Disable 2FA" : "Setup 2FA"}
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </Card>
      <Dialog open={setup2FADialog} onClose={handleDialogSetupClose}>
        <DialogTitle>{`Two-Factor Authentication (2FA)`}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ marginBottom: 1 }}>
            <MDTypography variant="h6" color="dark">
              Authenticator App Configuration
            </MDTypography>
            <MDTypography variant="body2" color="dark">
              1. Install Microsoft Authenticator on your mobile device. (May or
              may not be compatible with other authentication apps).
            </MDTypography>
            <MDTypography variant="body2" color="dark">
              2. In the application, add a new account or select the '+' icon.
            </MDTypography>
            <MDTypography variant="body2" color="dark">
              3. Find the "Scan a QR Code" or when prompted.
            </MDTypography>
            <Divider />
            <MDTypography variant="h6" color="dark">
              Scan QR Code
            </MDTypography>
          </DialogContentText>
          <MDBox display="flex" justifyContent="center">
            <MDBox id="qrcode" />
          </MDBox>

          <DialogContentText sx={{ marginBottom: 1 }}>
            <MDTypography variant="h6" color="dark">
              Or Enter Key Into The Auth App
            </MDTypography>
            <MDTypography variant="body2" color="dark">
              {`Key: ${secret}`}
            </MDTypography>
            <Divider />
            <MDTypography variant="h6" color="dark">
              Verify Code
            </MDTypography>
            <MDTypography variant="body2" color="dark">
              Once auth app is setup, enter the code generated by the app to
              verify:
            </MDTypography>
          </DialogContentText>
          <MDInput
            fullWidth
            placeholder="Enter code generated from 2FA app"
            onChange={handleToken}
          ></MDInput>
        </DialogContent>
        <DialogActions>
          <MDButton
            variant="gradient"
            color="error"
            size="small"
            onClick={handleDialogSetupClose}
          >
            Cancel
          </MDButton>
          <MDButton
            variant="gradient"
            size="small"
            color="success"
            onClick={() => onSubmitSetup()}
          >
            Verify & Activate
          </MDButton>
        </DialogActions>
      </Dialog>
      <Dialog open={disable2FADialog} onClose={handleDialogDisableClose}>
        <DialogTitle>Confirm to disable two-factor authentication</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Only disable 2FA if access to the linked authentication app is lost.
            Re-enabling 2FA will require going through the setup again.
          </DialogContentText>
        </DialogContent>
        <MDBox pl={2} pr={2}>
          <MDInput
            type="password"
            label="Password"
            fullWidth
            placeholder="Enter password to confirm"
            onChange={handleDisablePassword}
          ></MDInput>
        </MDBox>
        <DialogActions>
          <MDButton
            variant="gradient"
            size="small"
            color="error"
            onClick={handleDialogDisableClose}
          >
            Cancel
          </MDButton>
          <MDButton
            variant="gradient"
            size="small"
            color="success"
            onClick={() => onSubmitDisable()}
          >
            Confirm
          </MDButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Setup2FA;
