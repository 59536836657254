import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function NoChassisFound() {
  return (
    <MDBox
      height="100%"
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center">
      <MDBox
        p={6}
        borderRadius="lg"
        shadow="xl"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        bgColor="white">
        <MDTypography variant="h1" color="info" textGradient>
          No Chassis Found
        </MDTypography>
        <MDTypography variant="body2" fontWeight="regular" mt={2}>
          Add A Chassis To A Bay In Part Selection
        </MDTypography>
        <MDTypography variant="body2" fontWeight="regular">
          Or
        </MDTypography>
        <MDTypography variant="body2" fontWeight="regular">
          Skip This Step
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

export default NoChassisFound;
