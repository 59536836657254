// functional component to return a table for the final quote. Each row will be a db from the quote. The columns will be id, name, qty, unit price, margin, total price, and actions
import { useContext } from "react";
import { ApsContext } from "ApsContext";
import { useAPSDB } from "hooks/useAPSDB";
import QueryCustomTable from "customInputs/queryTables/QueryCustomTable";
import PartQtyCell from "customInputs/customTableCells/partQtyCell";

function FinalQuoteTable() {
  const { quotingDB, allAddCostings, updateQtyDB } = useContext(ApsContext);
  const { handleDbPartsMapped } = useAPSDB();

  const handleUpdateQty = (e, dbID) => {
    updateQtyDB(dbID.dbID, parseInt(e.target.value));
  };

  const makeDbItemRows = () => {
    const mappedDbs = handleDbPartsMapped(allAddCostings, quotingDB);
    if (mappedDbs) {
      return mappedDbs.map((db) => {
        return {
          identifier: db.identifier,
          name: db.Name,
          partsPrice: `${db.unitPrice.toFixed(2)}`,
          otherCosts: `${db.otherAddCosts.toFixed(2)}`,
          subTotal: `${(db.unitPrice + db.otherAddCosts).toFixed(2)}`,
          margin: `${db.margin}`,
          totalUnitPrice: `${db.totalUnitPrice.toFixed(2)}`,
          quantity: (
            <PartQtyCell
              currentQty={db.quantity}
              handleUpdateQty={handleUpdateQty}
              partGroupItem={{ dbID: db.identifier }}
            ></PartQtyCell>
          ),
          totalPrice: `${db.totalPrice.toFixed(2)}`,
        };
      });
    } else {
      return [
        {
          identifier: "",
          name: "",
          partsPrice: "",
          otherCosts: "",
          margin: "",
          quantity: "",
          totalPrice: "",
        },
      ];
    }
  };

  return (
    <QueryCustomTable
      data={{
        columns: [
          {
            header: "Id",
            accessorKey: "identifier",
            size: 5,
          },
          {
            header: "Name",
            accessorKey: "name",
            size: 250,
          },
          {
            header: "Cost Price ($)",
            accessorKey: "partsPrice",
            size: 100,
            muiTableHeadCellProps: {
              align: "right",
            },
            muiTableBodyCellProps: {
              align: "right",
            },
          },
          {
            header: "Add. Costs ($)",
            accessorKey: "otherCosts",
            size: 100,
            muiTableHeadCellProps: {
              align: "right",
            },
            muiTableBodyCellProps: {
              align: "right",
            },
          },
          {
            header: "Sub Total ($)",
            accessorKey: "subTotal",
            size: 100,
            muiTableHeadCellProps: {
              align: "right",
            },
            muiTableBodyCellProps: {
              align: "right",
            },
          },
          {
            header: "Margin (%)",
            accessorKey: "margin",
            size: 80,
            muiTableHeadCellProps: {
              align: "right",
            },
            muiTableBodyCellProps: {
              align: "right",
            },
          },
          {
            header: "Unit Price ($)",
            accessorKey: "totalUnitPrice",
            size: 100,
            muiTableHeadCellProps: {
              align: "right",
            },
            muiTableBodyCellProps: {
              align: "right",
            },
          },
          {
            header: "Qty",
            accessorKey: "quantity",
            size: 50,
            muiTableHeadCellProps: {
              align: "right",
            },
            muiTableBodyCellProps: {
              align: "right",
            },
          },
          {
            header: "Total Price ($)",
            accessorKey: "totalPrice",
            size: 100,
            muiTableHeadCellProps: {
              align: "right",
            },
            muiTableBodyCellProps: {
              align: "right",
            },
          },
        ],
        rows: makeDbItemRows(),
        title: `DB Items`,
      }}
    />
  );
}

export default FinalQuoteTable;
