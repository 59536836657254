import MDTypography from "components/MDTypography";

const PermissionRoleTip = () => {
  return (
    <MDTypography variant="caption">
      <strong>Role + Permissions -{">"} Member:</strong> Create & Edit Quotes{" "}
      <strong> | TeamLeader:</strong> Member + Create, Edit & Delete Assemblies{" "}
      <strong> | Manager:</strong> TeamLeader + Create, Edit & Delete Users{" "}
      {"("}Excludes Admin{")"} + Delete Quotes <strong> | Admin: </strong>{" "}
      Manager + Database Uploads + Management of Admin Users
    </MDTypography>
  );
};

export default PermissionRoleTip;
