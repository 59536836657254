import { ApsContext } from "ApsContext";
import { useCallback, useContext } from "react";

export const useAPSDB = () => {
  const { quotingDB, lineItems, allAddCostings } = useContext(ApsContext);

  // use function to assemble part properties
  // update details for all parts in apsMenu should match this structure to bring old quotes up to date.
  // should not be used for default or custom parts
  const partAssembler = useCallback((part) => {
    return {
      filter1: part.filter1 ? part.filter1 : "unset1",
      filter2: part.filter2 ? part.filter2 : "unset2",
      partNumber: part.partNumber ? part.partNumber : idGen(),
      description: part.description ? part.description : "",
      supplier: part.supplier ? part.supplier : "",
      cost: isNaN(part.cost) ? 0 : part.cost,
      labourMount: isNaN(part.labourMount) ? 0 : part.labourMount,
      labourWire: isNaN(part.labourWire) ? 0 : part.labourWire,
      labour: isNaN(part.labour) ? 0 : part.labour,
      inclDesc:
        typeof part.inclDesc === "string" && part.inclDesc.toUpperCase() === "Y"
          ? "Y"
          : "N",
      manuClass1: part.manuClass1 ? part.manuClass1 : "",
      manuClass2: part.manuClass2 ? part.manuClass2 : "",
      manuDesc: part.manuDesc ? part.manuDesc : "",
      stock: part.stock ? part.stock : "",
    };
  }, []);

  const preventKeys = (e) => {
    const pressedKey = e.key;
    if (
      pressedKey === "-" ||
      pressedKey === "ArrowUp" ||
      pressedKey === "ArrowDown"
    ) {
      e.preventDefault();
    }
  };

  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData;
    const pastedData = parseFloat(clipboardData.getData("text"));

    if (
      pastedData < 0 ||
      isNaN(pastedData) ||
      pastedData === undefined ||
      pastedData === null ||
      pastedData === ""
    ) {
      e.preventDefault();
    }
  };

  const getChassisSpecsFromPartNumber = useCallback((partNumber) => {
    // determine if the first 4 letters of the part number is ENCY or ISCY, if it is, then it is not a hybrid chassis
    const prefix = partNumber.substring(0, 5);
    const isHybridChassis = prefix === "ENCHY";

    // get the number of poles of the chassis
    // if it is not a hybrid, the number of poles is the 8th and 9th letters of the part number
    if (!isHybridChassis) {
      const type =
        partNumber.substring(0, 3) === "ENC" ? "Encapsulated" : "Isolated";

      const rating =
        partNumber.indexOf("Y") === 3
          ? partNumber.substring(4, 7) === "250"
            ? "250A"
            : "400A"
          : partNumber.substring(3, 6) === "250"
          ? "250A"
          : "400A";
      const numberOfPoles =
        partNumber.indexOf("Y") === 3
          ? parseInt(partNumber.substring(7, 9))
          : parseInt(partNumber.slice(-2));
      return { numberOfPoles, isHybridChassis, type, rating };
    } else {
      // if it is a hybrid, the number of poles is (the 9th and 10th letters of the part number) + (the 12th and 13th letters of the part number)
      const type = "Hybrid";
      const rating = partNumber.substring(5, 8) === "250" ? "250A" : "400A";
      const numberOfPoles =
        parseInt(partNumber.substring(8, 10)) +
        parseInt(partNumber.substring(11, 13));
      return { numberOfPoles, isHybridChassis, type, rating };
    }
  }, []);

  // function to return the circuit breakers of the chassis
  const getCircuitBreakersOfChassis = (bayID, chassisNumber) => {
    const bay = quotingDB
      .map((db) => db.tiers)
      .flat()
      .map((tier) => tier.bays)
      .flat()
      .find((bay) => bay.id === bayID);
    return bay.chassis[chassisNumber].circuitBreakers;
  };

  // function to return all the parts of all bays and circuit breakers of all chassis within a db
  const getAllPartsAndCircuitBreakersInDB = useCallback(
    (localQuotingDB, dbID) => {
      const db = localQuotingDB.find((db) => db.id === dbID);
      let allParts = [];
      db.tiers.forEach((tier) =>
        tier.bays.forEach((bay) =>
          bay.assemblies.forEach((assembly) =>
            assembly.parts.forEach((part) => {
              allParts.push({
                ...part,
                AssembliesRelation: {
                  quantity:
                    part.AssembliesRelation.quantity * assembly.quantity,
                },
              });
            })
          )
        )
      );

      let circuitBreakerArray = [];
      const allCircuitBreakers = (db) => {
        db.tiers.forEach((tier) =>
          tier.bays.forEach((bay) =>
            Object.values(bay.chassis).forEach((chassis) => {
              if (chassis !== null && chassis.circuitBreakers.length > 0) {
                chassis.circuitBreakers.forEach((circuitBreaker) => {
                  circuitBreakerArray.push({
                    ...partAssembler(circuitBreaker.part),
                    AssembliesRelation: {
                      quantity: circuitBreaker.quantity,
                    },
                  });
                });
              }
            })
          )
        );
      };

      allCircuitBreakers(db);

      return [...allParts, ...circuitBreakerArray];
    },
    [partAssembler]
  );

  // generate unique id
  const idGen = () => {
    return Math.random().toString(16).slice(2);
  };

  // id gen only capital letters
  const idGenCap = (len) => {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < len) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  };

  // id gen only numbers
  const idGenNum = (len) => {
    let result = "";
    const characters = "0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < len) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  };

  // check file type is an image (for profile images)
  const checkFileType = (fileInput) => {
    const file = fileInput.files[0]; // Access the first file selected by the user
    if (!file) {
      return false;
    }
    const fileName = file.name.toLowerCase();
    const fileType = file.type;
    if (
      fileName &&
      (![".jpg", ".jpeg", ".png"].some((ext) => fileName.endsWith(ext)) ||
        !fileType.includes("image"))
    ) {
      return false;
    }
    return true;
  };

  // return the current date in the format DD/MM/YYYY
  const getDateValidity = () => {
    const now = new Date();
    const date = new Date();
    date.setDate(now.getDate() + 30);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const getCurrentDate = () => {
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  // function to get the assemblies in the bay using the bayID
  const getAssembliesInBay = useCallback(
    (bayID) => {
      const bay = quotingDB
        .map((db) => db.tiers)
        .flat()
        .map((tier) => tier.bays)
        .flat()
        .find((bay) => bay.id === bayID);
      return bay.assemblies;
    },
    [quotingDB]
  );

  // function to get the unique items of filter1
  const getFilter1Options = (partlist) => {
    if (!partlist) return;
    const filter1Options = [];
    partlist.forEach((part) => {
      if (!filter1Options.includes(part.filter1)) {
        filter1Options.push(part.filter1);
      }
    });
    return filter1Options;
  };

  // function to get the unique items of filter2 for each filter 1 option
  const getFilter2Options = (partlist, filter1Option) => {
    const filter2Options = [];
    partlist.forEach((part) => {
      if (part.filter1 === filter1Option) {
        if (!filter2Options.includes(part.filter2)) {
          filter2Options.push(part.filter2);
        }
      }
    });
    return filter2Options;
  };

  const getBreakerParts = (partlist) => {
    const breakerParts = [];
    partlist.forEach((part) => {
      if (
        part.filter1 === "6a. MCBs - 1P" ||
        part.filter1 === "6b. MCBs - 3P" ||
        part.filter1 === "6c. MCBs - 1P - 27mm" ||
        part.filter1 === "6d. MCBs - 3P - 27mm"
      ) {
        breakerParts.push(part);
      }
    });
    return breakerParts;
  };

  // calculations for pricing for db etc
  // totalPrice = totalUnitPrice * quantity of db
  // totalUnitPrice = (unitPrice + otherAddCosts) * (1 - margin * 100)
  // unitPrice = sum of all part costs
  // otherAddCosts = labour, freight, sunderies, material
  const handleDbPartsMapped = useCallback(
    (localAllAddCostings, localQuotingDB) => {
      const mappedDbs = localQuotingDB.map((db) => {
        const parts = getAllPartsAndCircuitBreakersInDB(localQuotingDB, db.id);
        const filteredParts = parts.filter((part) => part !== undefined);

        const sumDuplicateParts = (parts) => {
          let uniqueParts = [];
          parts.forEach((part) => {
            const index = uniqueParts.findIndex(
              (uniquePart) => uniquePart.partNumber === part.partNumber
            );
            if (index >= 0) {
              uniqueParts[index].AssembliesRelation.quantity +=
                part.AssembliesRelation.quantity;
            } else {
              uniqueParts.push(part);
            }
          });
          return uniqueParts;
        };

        //const uniqueParts = checkForDuplicateParts(filteredParts);
        const uniqueParts = sumDuplicateParts(filteredParts).map((part) => {
          return {
            part: partAssembler(part),
            quantity: part.AssembliesRelation.quantity,
          };
        });

        const totalLabour = uniqueParts.reduce((acc, part) => {
          return acc + part.part.labour * part.quantity;
        }, 0);

        const unitPrice = uniqueParts.reduce((acc, part) => {
          return acc + part.part.cost * part.quantity;
        }, 0);

        const totalUnitPriceExclMargin = unitPrice * db.quantity;

        const labelsCosts =
          Number(
            localAllAddCostings.find((currentDB) => currentDB.id === db.id)
              .unitPrice.labels
          ) *
          Number(
            localAllAddCostings.find((currentDB) => currentDB.id === db.id)
              .quantity.labels
          );

        const freightCosts =
          Number(
            localAllAddCostings.find((currentDB) => currentDB.id === db.id)
              .unitPrice.freight
          ) *
          Number(
            localAllAddCostings.find((currentDB) => currentDB.id === db.id)
              .quantity.freight
          );

        const drawingHrs = Number(
          localAllAddCostings.find((currentDB) => currentDB.id === db.id)
            .quantity.drawings
        );

        const labourCosts =
          Number(
            localAllAddCostings.find((currentDB) => currentDB.id === db.id)
              .unitPrice.labour
          ) *
          (totalLabour / 60);

        const otherAddCosts =
          Math.round(
            (labourCosts +
              labelsCosts +
              Number(
                localAllAddCostings.find((currentDB) => currentDB.id === db.id)
                  .unitPrice.drawings
              ) *
                drawingHrs +
              freightCosts) *
              100
          ) / 100;

        const marginValue = Number(
          localAllAddCostings.find((currentDB) => currentDB.id === db.id)
            .unitPrice.markup
        );

        const marginCalc = 1 - marginValue / 100;

        return {
          Name: db.name,
          quantity: db.quantity,
          identifier: db.id,
          unitPrice: Math.round(unitPrice * 100) / 100,
          totalUnitPriceExclMargin:
            Math.round(totalUnitPriceExclMargin * 100) / 100,
          labelsCosts: Math.round(labelsCosts * 100) / 100,
          freightCosts: Math.round(freightCosts * 100) / 100,
          drawingHrs: drawingHrs,
          margin: marginValue,
          totalUnitPrice:
            Math.round(((unitPrice + otherAddCosts) / marginCalc) * 100) / 100,
          otherAddCosts: otherAddCosts,
          totalPrice:
            Math.round(
              ((unitPrice + otherAddCosts) / marginCalc) * db.quantity * 100
            ) / 100,
          UniqueParts: uniqueParts,
        };
      });
      return mappedDbs;
    },
    [getAllPartsAndCircuitBreakersInDB, partAssembler]
  );

  // cost calculations for line items (factory installed items)
  const handleLineItemCosts = (localLineItems) => {
    const totalLineItemsCost =
      Math.round(
        localLineItems.reduce(
          (accumulator, currentValue) =>
            accumulator + currentValue.finalPrice * currentValue.quantity,
          0
        ) * 100
      ) / 100;
    return { totalLineItemsCost: totalLineItemsCost };
  };

  // Add dp and commas and returns a formatted string
  // number (required): number
  const formatNumber = (number) => {
    return number.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  // calculate total price of current quote
  const calcTotalPrice = () => {
    const total_price = quotingDB[0].hasOwnProperty(
      "quantity"
    ) /* backward compatible for quotingDB without quantity property */
      ? Math.round(
          handleDbPartsMapped(allAddCostings, quotingDB).reduce(
            (accumulator, currentValue) =>
              accumulator + currentValue.totalPrice,
            0
          ) * 100
        ) /
          100 +
        (lineItems !== null /* neq null for old quotes without line_items */
          ? handleLineItemCosts(lineItems).totalLineItemsCost
          : 0)
      : Math.round(
          handleDbPartsMapped(allAddCostings, quotingDB).reduce(
            (accumulator, currentValue) =>
              accumulator +
              (Math.round(
                currentValue.UniqueParts.reduce(
                  (accumulator, currentValue) =>
                    accumulator +
                    currentValue.part.cost * currentValue.quantity,
                  0
                ) * 100
              ) /
                100 +
                currentValue.otherAddCosts) /
                (1 - currentValue.margin / 100),
            0
          ) * 100
        ) / 100;
    return total_price;
  };

  return {
    partAssembler,
    preventKeys,
    preventPasteNegative,
    getChassisSpecsFromPartNumber,
    getCircuitBreakersOfChassis,
    getAllPartsAndCircuitBreakersInDB,
    idGen,
    idGenCap,
    idGenNum,
    checkFileType,
    getDateValidity,
    getCurrentDate,
    getAssembliesInBay,
    getFilter1Options,
    getFilter2Options,
    getBreakerParts,
    handleDbPartsMapped,
    handleLineItemCosts,
    formatNumber,
    calcTotalPrice,
  };
};
