// functional component to return the client and project information for the quote

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import { useContext } from "react";
import { ApsContext } from "ApsContext";
import { Select, MenuItem, Grid } from "@mui/material";
import { useAPSDB } from "hooks/useAPSDB";

const alphabetArray = Array.from({ length: 26 }, (_, i) =>
  String.fromCharCode(65 + i)
);

function QuoteInformation() {
  const {
    currentClient,
    currentProject,
    currentQuoteNum,
    revisionVersion,
    updateRevision,
    approvalStatus,
    updateApprovalStatus,
    quoteID,
  } = useContext(ApsContext);
  const { getDateValidity, getCurrentDate } = useAPSDB();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor={darkMode ? "transparent" : "grey-100"}
      borderRadius="lg"
    >
      <Grid container p={1} pl={2}>
        <Grid item xs={12} sm={12} md={7} lg={7} xl={6}>
          <Grid container>
            <Grid item xs={12}>
              <MDTypography variant="caption" fontWeight="regular" color="text">
                Project:&nbsp;&nbsp;&nbsp;
                <MDTypography
                  variant="caption"
                  fontWeight="medium"
                  textTransform="capitalize"
                >
                  {currentProject.projectName}
                </MDTypography>
              </MDTypography>
            </Grid>
            <Grid item xs={12}>
              <MDTypography variant="caption" fontWeight="regular" color="text">
                Client:&nbsp;&nbsp;&nbsp;
                <MDTypography
                  variant="caption"
                  fontWeight="medium"
                  textTransform="capitalize"
                >
                  {currentClient.businessName}
                </MDTypography>
              </MDTypography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={5} md={4} lg={4} xl={3}>
          <Grid container>
            <Grid item xs={12}>
              <MDTypography variant="caption" fontWeight="regular" color="text">
                Ref:&nbsp;&nbsp;&nbsp;
                <MDTypography
                  variant="caption"
                  fontWeight="medium"
                  textTransform="capitalize"
                >
                  {currentQuoteNum}
                </MDTypography>
              </MDTypography>
            </Grid>
            <Grid item xs={12}>
              <MDTypography variant="caption" fontWeight="regular" color="text">
                CRM:&nbsp;&nbsp;&nbsp;
                <MDTypography variant="caption" fontWeight="medium">
                  {currentProject.crmOpp}
                </MDTypography>
              </MDTypography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={4} xl={3}>
          <Grid container>
            <Grid item xs={12}>
              <MDTypography variant="caption" fontWeight="regular" color="text">
                Revision:&nbsp;&nbsp;&nbsp;
                <Select
                  value={revisionVersion}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    updateRevision(inputValue);
                  }}
                >
                  {alphabetArray.map((letter) => (
                    <MenuItem key={letter} value={letter}>
                      {letter}
                    </MenuItem>
                  ))}
                </Select>
              </MDTypography>
            </Grid>
            <Grid item xs={12}>
              <MDTypography variant="caption" fontWeight="regular" color="text">
                Status:&nbsp;&nbsp;&nbsp;
                <MDTypography variant="caption" fontWeight="medium">
                  {quoteID === "" ? (
                    <Select
                      value={approvalStatus}
                      onChange={(e) => {
                        updateApprovalStatus(e.target.value);
                      }}
                    >
                      <MenuItem value="draft">Draft</MenuItem>
                    </Select>
                  ) : (
                    <Select
                      value={approvalStatus}
                      onChange={(e) => {
                        updateApprovalStatus(e.target.value);
                      }}
                    >
                      <MenuItem value="draft">Draft</MenuItem>
                      <MenuItem value="peer_reviewed">Peer Reviewed</MenuItem>
                      <MenuItem value="submitted">Submitted</MenuItem>
                      <MenuItem value="approved">Approved</MenuItem>
                      <MenuItem value="manufacturing">Manufacturing</MenuItem>
                    </Select>
                  )}
                </MDTypography>
              </MDTypography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={7} md={5} lg={5} xl={6}>
          <Grid container>
            <Grid item xs={12}>
              <MDTypography variant="caption" fontWeight="regular" color="text">
                Contact Name:&nbsp;&nbsp;&nbsp;
                <MDTypography variant="caption" fontWeight="medium">
                  {currentClient.customerName}
                </MDTypography>
              </MDTypography>
            </Grid>
            <Grid item xs={12}>
              <MDTypography variant="caption" fontWeight="regular" color="text">
                BDM:&nbsp;&nbsp;&nbsp;
                <MDTypography
                  variant="caption"
                  fontWeight="medium"
                  textTransform="capitalize"
                >
                  {currentClient.contactName}
                </MDTypography>
              </MDTypography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={4} xl={6}>
          <Grid container>
            <Grid item xs={12}>
              <MDTypography variant="caption" fontWeight="regular" color="text">
                Date:&nbsp;&nbsp;&nbsp;
                <MDTypography variant="caption" fontWeight="medium">
                  {getCurrentDate()}
                </MDTypography>
              </MDTypography>
            </Grid>
            <Grid item xs={12}>
              <MDTypography variant="caption" fontWeight="regular" color="text">
                Valid Until:&nbsp;&nbsp;&nbsp;
                <MDTypography variant="caption" fontWeight="medium">
                  {getDateValidity()}
                </MDTypography>
              </MDTypography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default QuoteInformation;
