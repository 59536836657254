/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setLayout } from "context";
import { Grid } from "@mui/material";

function UltimateLayout({ children }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();

  const ultimateHeight = window.innerHeight - 50;
  const ultimateWidth = window.innerWidth - 100;

  useEffect(() => {
    setLayout(dispatch, "dashboard");
  }, [dispatch, pathname]);

  if (ultimateWidth < 1200 - 100) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MDTypography textAlign="center">
            No support for browser resolution widths {"<"} 1200px. Please zoom
            out or increase the browser window size.
          </MDTypography>
        </Grid>
      </Grid>
    );
  } else if (ultimateWidth < 1300 - 100 || ultimateHeight < 700 - 50) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MDTypography textAlign="center">
            No support for browser resolutions {"<"} 1300px x 700px. Please zoom
            out or increase the browser window size.
          </MDTypography>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <MDBox
        sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
          p: 0,
          pt: 0,
          height: ultimateHeight,
          width: ultimateWidth,
          position: "relative",
          [breakpoints.up("xl")]: {
            marginLeft: miniSidenav ? pxToRem(86) : pxToRem(240),
            transition: transitions.create(["margin-left", "margin-right"], {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.standard,
            }),
          },
        })}
      >
        {children}
      </MDBox>
    );
  }
}

// Typechecking props for the DashboardLayout
UltimateLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UltimateLayout;
