import MDBox from "components/MDBox";
import { useState, useContext, useCallback } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import {
  AddCircleRounded,
  EditRounded,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import MDButton from "components/MDButton";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import MDInput from "components/MDInput";
import Tooltip from "@mui/material/Tooltip";
import { ApsContext } from "ApsContext";

function EstimateTerms() {
  // collapse control each category in order of titleList
  const [open, setOpen] = useState(true); // terms and conditions collapse
  const [filterPoints, setFilterPoints] = useState(false); // filter out unchecked points/title groups

  // add point
  const [addMode, setAddMode] = useState(false);
  const [newPoint, setNewPoint] = useState("");
  const [titleID, setTitleID] = useState("");

  // editing point text
  const [editPoint, setEditPoint] = useState({ checked: false, point: "" }); // point object containing the point (string) and checked (bool)
  const [editMode, setEditMode] = useState(false);

  // contains all the categories and their associated points (adjust the order of the categories here)
  const { titleList, updateTitleList, idGen } = useContext(ApsContext);

  //#region--------------Update Functions------------------
  // Update the title checked state return a title (string) object
  const updateTitle = useCallback(
    (t) => {
      const afterUpdate = titleList.map((titles) => {
        if (titles.title === t.title) {
          return {
            ...titles,
            checkedTitle: t.checkedTitle,
          };
        }
        return titles;
      });
      return afterUpdate;
    },
    [titleList]
  );

  // Update the point text and checked state (true/false) returns a point object
  const updatePoint = useCallback(
    (p) => {
      const afterTitle = titleList.map((titles) => {
        const afterUpdate = titles.points.map((points) => {
          // if statement checks the if the point matches and is under the right title
          // prevents changing multiple points if they are the same
          if (points.id === p.id && editMode) {
            // changing the text/point
            return {
              ...points,
              point: editPoint.point,
            };
          } else if (points.point === p.point) {
            // changing the checkbox state
            return {
              ...points,
              checked: p.checked,
            };
          }
          return points;
        });
        return { ...titles, points: afterUpdate };
      });
      return afterTitle;
    },
    [editMode, editPoint.point, titleList]
  );

  // Add new point under a title category
  const addPoint = () => {
    const point = { checked: true, id: idGen(), point: newPoint };
    const afterTitle = titleList.map((titles) => {
      if (titles.idTitle === titleID) {
        return { ...titles, points: [...titles.points, point] };
      }
      return titles;
    });
    return afterTitle;
  };

  //#endregion----------------------------

  //#region------------- Handlers -------------
  const handleEdit = useCallback((point) => {
    // opens edit modal; set edit point object for editing
    handleEditModeOpen();
    setEditPoint(point);
  }, []);

  const handleSaveEdit = () => {
    updateTitleList(updatePoint(editPoint));
    handleEditModeClose();
  };

  const handleEditModeOpen = () => {
    setEditMode(true);
  };

  const handleEditModeClose = () => {
    setEditMode(false);
  };

  const handleAddModeOpen = () => {
    setAddMode(true);
  };

  const handleAddModeClose = () => {
    setAddMode(false);
  };

  const handleChecked = useCallback(
    (point) => {
      updateTitleList(updatePoint({ ...point, checked: !point.checked }));
    },
    [updatePoint, updateTitleList]
  );

  const handleClick = useCallback(
    (titles) => {
      updateTitleList(
        updateTitle({ ...titles, checkedTitle: !titles.checkedTitle })
      );
    },
    [updateTitle, updateTitleList]
  );

  const handleMainClick = () => {
    setOpen(!open);
  };

  const handleAddPoint = useCallback((idTitle) => {
    handleAddModeOpen();
    setTitleID(idTitle);
  }, []);

  const handleSaveAdd = () => {
    updateTitleList(addPoint());
    handleAddModeClose();
  };

  const handleFilter = () => {
    filterPoints ? setFilterPoints(false) : setFilterPoints(true);
  };

  //#endregion------------------------------------

  //#region----------------Render title list----------------
  // constructs row with checkbox, text and edit button
  const tableRow = useCallback(
    (point, index) => {
      return !filterPoints ? (
        <MDBox
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          bgColor="secondary"
          borderRadius="10px"
          alignItems="center"
          pr={1}
          key={index}
        >
          <MDBox display="flex" flexDirection="row" alignItems="center">
            <FormControlLabel
              control={
                <Checkbox
                  checked={point.checked}
                  onClick={() => {
                    handleChecked(point);
                  }}
                />
              }
              labelPlacement="start"
            />
            <MDButton
              fontSize="8px"
              fontWeight="bold"
              disableRipple
              variant="text"
              onClick={() => handleChecked(point)}
              sx={{ textTransform: "none", textAlign: "left" }}
            >
              {point.point}
            </MDButton>
          </MDBox>
          <Tooltip title="Edit" placement="left">
            <MDButton
              color="info"
              size="small"
              mr={1}
              onClick={() => {
                handleEdit(point);
              }}
              circular
              iconOnly
            >
              <EditRounded></EditRounded>
            </MDButton>
          </Tooltip>
        </MDBox>
      ) : point.checked ? (
        <MDBox
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          bgColor="secondary"
          borderRadius="10px"
          alignItems="center"
          pr={1}
          key={index}
        >
          <MDBox display="flex" flexDirection="row" alignItems="center">
            <FormControlLabel
              control={
                <Checkbox
                  checked={point.checked}
                  onClick={() => {
                    handleChecked(point);
                  }}
                />
              }
              labelPlacement="start"
            />
            <MDButton
              fontSize="8px"
              fontWeight="bold"
              disableRipple
              variant="text"
              onClick={() => handleChecked(point)}
              sx={{ textTransform: "none", textAlign: "left" }}
            >
              {point.point}
            </MDButton>
          </MDBox>
          <Tooltip title="Edit" placement="left">
            <MDButton
              color="info"
              size="small"
              mr={1}
              onClick={() => {
                handleEdit(point);
              }}
              circular
              iconOnly
            >
              <EditRounded></EditRounded>
            </MDButton>
          </Tooltip>
        </MDBox>
      ) : null;
    },
    [filterPoints, handleChecked, handleEdit]
  );

  // list of all points under category; calls tableRow
  const list = useCallback(
    (titles) => {
      return (
        <Collapse in={titles.checkedTitle} timeout="auto" unmountOnExit>
          <MDBox display="flex" flexDirection="column" gap={0.5}>
            {titles.points.map((points, index) => {
              // tableRow() structure checkbox, point and edit button
              return tableRow(points, index);
            })}
          </MDBox>
        </Collapse>
      );
    },
    [tableRow]
  );

  // title of categories; calls list
  const title = useCallback(() => {
    return (
      <Collapse in={open} timeout="auto" unmountOnExit>
        {titleList.map((titles, index) => {
          return !filterPoints ? (
            <FormGroup key={index}>
              <MDBox
                display="flex"
                flexDirection="row"
                bgColor="dark"
                mt={0.5}
                mb={0.5}
                justifyContent="space-between"
                borderRadius="10px"
                zIndex={2}
                sx={{ position: "sticky", top: "2.6rem" }}
              >
                <MDBox
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-start"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={titles.checkedTitle}
                        onClick={() => {
                          handleClick(titles, index);
                        }}
                      />
                    }
                    labelPlacement="start"
                  />
                  <Tooltip
                    title="Collapse title group removes the title and all associated points from the quote"
                    placement="top"
                  >
                    <MDButton
                      fontWeight="bold"
                      disableRipple
                      variant="text"
                      onClick={() => handleClick(titles, index)}
                      sx={{
                        textTransform: "none",
                        textAlign: "left",
                        fontSize: "16px",
                      }}
                    >
                      {titles.title}

                      {titles.checkedTitle ? <ExpandMore /> : <ExpandLess />}
                    </MDButton>
                  </Tooltip>
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                  borderRadius="10px"
                  alignItems="center"
                  mr={1}
                >
                  <Tooltip title="Add New Point" placement="left">
                    <MDButton
                      size="small"
                      color="info"
                      iconOnly
                      circular
                      onClick={() => {
                        handleAddPoint(titles.idTitle);
                      }}
                    >
                      <AddCircleRounded></AddCircleRounded>
                    </MDButton>
                  </Tooltip>
                </MDBox>
              </MDBox>
              {/* list() structure for the points under each title stored in titleList */}
              {list(titles, index)}
            </FormGroup>
          ) : titles.checkedTitle ? (
            <FormGroup key={index}>
              <MDBox
                display="flex"
                flexDirection="row"
                bgColor="dark"
                mt={0.5}
                mb={0.5}
                justifyContent="space-between"
                borderRadius="10px"
                zIndex={2}
                sx={{ position: "sticky", top: "2.6rem" }}
              >
                <MDBox
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-start"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={titles.checkedTitle}
                        onClick={() => {
                          handleClick(titles, index);
                        }}
                      />
                    }
                    labelPlacement="start"
                  />
                  <MDButton
                    fontWeight="bold"
                    disableRipple
                    variant="text"
                    onClick={() => handleClick(titles, index)}
                    sx={{
                      textTransform: "none",
                      textAlign: "left",
                      fontSize: "16px",
                    }}
                  >
                    {titles.title}

                    {titles.checkedTitle ? <ExpandMore /> : <ExpandLess />}
                  </MDButton>
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                  borderRadius="10px"
                  alignItems="center"
                  mr={1}
                >
                  <Tooltip title="Add New Point" placement="left">
                    <MDButton
                      size="small"
                      color="info"
                      iconOnly
                      circular
                      onClick={() => {
                        handleAddPoint(titles.idTitle);
                      }}
                    >
                      <AddCircleRounded></AddCircleRounded>
                    </MDButton>
                  </Tooltip>
                </MDBox>
              </MDBox>
              {/* list() structure for the points under each title stored in titleList */}
              {list(titles, index)}
            </FormGroup>
          ) : null;
        })}
      </Collapse>
    );
  }, [filterPoints, handleAddPoint, handleClick, list, open, titleList]);

  //#endregion---------------------

  return (
    <>
      {/* this box contains the title bar that is in fixed position at the top of the page */}
      <MDBox
        zIndex={3}
        bgColor="light"
        sx={{ position: "sticky", top: "0rem" }}
      >
        <MDButton
          fontWeight="bold"
          color="dark"
          disableRipple
          variant="text"
          onClick={() => handleMainClick()}
          sx={{
            textTransform: "none",
            textAlign: "left",
            fontSize: "16px",
          }}
        >
          Terms and Conditions
          {open ? <ExpandMore /> : <ExpandLess />}
        </MDButton>
        <Tooltip
          title="Turn filter on to view points that will be included in the quote"
          placement="top"
        >
          <MDButton
            color={filterPoints ? "success" : "dark"}
            variant="gradient"
            onClick={() => {
              handleFilter();
            }}
            sx={{ textTransform: "none" }}
            size="small"
          >
            {filterPoints ? "Filter ON" : "Filter OFF"}
          </MDButton>
        </Tooltip>
      </MDBox>
      {/* titleList and all its points in this box */}
      <FormGroup>
        {/* title() is structure of each category and its points stored in titleList */}
        {title()}
      </FormGroup>
      {/* Modals for editing and adding a point on the list are below */}
      <Dialog
        keepMounted
        open={editMode}
        onClose={handleEditModeClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Edit Point</DialogTitle>
        <DialogContent dividers>
          <MDInput
            fullWidth
            type="text"
            value={editPoint.point}
            multiline
            rows={5}
            onChange={(e) => {
              setEditPoint({ ...editPoint, point: e.target.value });
            }}
          />
        </DialogContent>
        <DialogActions>
          <MDButton
            color="error"
            variant="contained"
            onClick={handleEditModeClose}
          >
            Cancel
          </MDButton>
          <MDButton
            disabled={editPoint.point === ""}
            color="success"
            variant="contained"
            onClick={handleSaveEdit}
          >
            Save
          </MDButton>
        </DialogActions>
      </Dialog>
      <Dialog
        keepMounted
        open={addMode}
        onClose={handleAddModeClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Add New Point</DialogTitle>
        <DialogContent dividers>
          <MDInput
            fullWidth
            type="text"
            value={newPoint}
            multiline
            rows={5}
            onChange={(e) => {
              setNewPoint(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <MDButton
            color="error"
            variant="contained"
            onClick={handleAddModeClose}
          >
            Cancel
          </MDButton>
          <MDButton
            disabled={newPoint === ""}
            color="success"
            variant="contained"
            onClick={handleSaveAdd}
          >
            Save
          </MDButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EstimateTerms;
