import { useState, useEffect, useContext, useMemo, useCallback } from "react";

// @mui material components
import { CircularProgress, DialogContentText, Grid } from "@mui/material";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import { ApsContext } from "ApsContext";
import { useNavigate } from "react-router-dom";
import AssemblyCard from "layouts/November/Assembly/components/AssemblyCard";
import QueryTable from "customInputs/queryTables/QueryTable";
import SelectedPartCardDialog from "../components/SelectedPartCardDialog";
import crudsService from "services/cruds-service";
import { useCurrentUser, useAssemblies } from "services/query-service";
import { useAbility } from "@casl/react";
import { AbilityContext } from "Can";
import CustomSpreadSheet from "customInputs/customSpreadSheet";
import CrudDialog from "customInputs/crud-dialog";
import { useAPSDB } from "hooks/useAPSDB";

function AssemblyNew() {
  const {
    assemblyNaming,
    handleUpdateAssemblyNaming,
    promptNotification,
    partGroupItemsColumns,
  } = useContext(ApsContext);

  const { partAssembler } = useAPSDB();

  const navigate = useNavigate();
  const ability = useAbility(AbilityContext);

  const [currentTableSelection, setCurrentTableSelection] = useState({
    part: {
      filter1: "",
      filter2: "",
      partNumber: "",
      description: "",
      supplier: "",
      cost: 0,
      labour: 0,
      stock: "",
    },
    quantity: 0,
  });
  const [partGroupItems, setPartGroupItems] = useState([]);
  const [openSelectedPartCardDialog, setOpenSelectedPartCardDialog] =
    useState(false);
  const [openAddPartsDialog, setOpenAddPartsDialog] = useState(false);
  const [handleQtyCheck, setHandleQtyCheck] = useState(false);
  const [pendingUpdates, setPendingUpdates] = useState([]);
  const [confirmCancelOpenDialog, setConfirmCancelOpenDialog] = useState(false);
  const [viewingHeight, setViewingHeight] = useState(window.innerHeight);
  const [saveLoading, setSaveLoading] = useState(false);

  const { data: userId, isSuccess: userSuccess } = useCurrentUser();

  const { refetch: refetchAssemblies } = useAssemblies();

  const handleQuantityChange = (val) => {
    setHandleQtyCheck(true);
    // if val is a string, convert it to a number
    if (typeof val === "string") {
      val = parseInt(val);
    }
    setCurrentTableSelection((prevState) => ({
      ...prevState,
      quantity: val,
    }));
  };
  // function to add the currentTableSelection to the partGroupItems array
  const addPartToPartGroupItems = () => {
    setOpenSelectedPartCardDialog(false);
    // check if the partGroupItems array already contains the currentTableSelection
    if (
      partGroupItems.find(
        (partGroupItem) =>
          partGroupItem.partNumber === currentTableSelection.part.partNumber
      )
    ) {
      // if it does, update the quantity of the partGroupItem
      setPartGroupItems(
        partGroupItems.map((partGroupItem) => {
          if (
            partGroupItem.partNumber === currentTableSelection.part.partNumber
          ) {
            return {
              ...partGroupItem,
              AssembliesRelation: {
                quantity:
                  partGroupItem.AssembliesRelation.quantity +
                  parseInt(handleQtyCheck ? currentTableSelection.quantity : 1),
              },
            };
          } else {
            return partGroupItem;
          }
        })
      );
      setHandleQtyCheck(false);
      promptNotification(
        `Added ${currentTableSelection.quantity} of ${currentTableSelection.part.partNumber} `,
        "success"
      );
      return;
    }
    // if it doesn't, add the currentTableSelection to the partGroupItems array
    setPartGroupItems([
      ...partGroupItems,
      {
        ...partAssembler(currentTableSelection.part),
        AssembliesRelation: { quantity: currentTableSelection.quantity },
      },
    ]);
    promptNotification(
      `Added ${currentTableSelection.quantity} of ${currentTableSelection.part.partNumber} `,
      "success"
    );
  };

  // clicked on plus (add) for a part from the table
  const handleLogTable = (row) => {
    const partGroupItem = partGroupItems.find(
      (part) => part.partNumber === row.partNumber
    );
    if (partGroupItem) {
      setCurrentTableSelection({
        part: row,
        quantity: partGroupItem.AssembliesRelation.quantity,
      });
    } else {
      setCurrentTableSelection({ part: row, quantity: 1 });
    }
    setOpenSelectedPartCardDialog(true);
  };

  // add part from pasting/typing part number in spreadsheet
  const addPartFromSpreadSheet = (spreadsheetPart) => {
    const part = {
      ...partAssembler(spreadsheetPart.part),
      AssembliesRelation: { quantity: spreadsheetPart.quantity },
    };
    setPendingUpdates((prev) => [...prev, { type: "newPart", part }]);
  };

  const removePartFromGroup = useCallback((part) => {
    setPendingUpdates((prev) => [...prev, { type: "removePart", part }]);
  }, []);

  const handleQtyCellChange = useCallback((e, part) => {
    let val = e.target.value;
    if (val !== undefined && val !== null && val !== "" && !isNaN(val)) {
      val = parseInt(val);
    } else {
      val = 1;
    }
    setPendingUpdates((prev) => [...prev, { type: "updateQty", part, val }]);
  }, []);

  // moving a single part on the spreadsheet to a new position
  const handleMoveMiscPart = useCallback((part, newRowIndex) => {
    setPendingUpdates((prev) => [
      ...prev,
      { type: "movePart", part, newRowIndex },
    ]);
  }, []);

  const handleUpdateDetail = useCallback(() => {
    return false;
  }, []);

  // create the partAssemblyTable rows from the partGroupItems array starting with the parentPart
  const makePartAssemblyTableRows = useMemo(() => {
    if (partGroupItems.length > 0) {
      return partGroupItems.map((partGroupItem) => {
        return [
          partGroupItem.partNumber,
          partGroupItem.description,
          partGroupItem.supplier,
          partGroupItem.AssembliesRelation.quantity,
          partGroupItem.cost,
          partGroupItem.labour,
          partGroupItem.stock,
        ];
      });
    } else {
      return Array(20)
        .fill(null)
        .map(() => Array(partGroupItemsColumns.length).fill(""));
    }
  }, [partGroupItemsColumns.length, partGroupItems]);

  const handleSaveController = (status) => {
    if (status === "success") {
      refetchAssemblies();
      promptNotification("Assembly saved", "success");
      navigate(`/configurator/assembly-landing`);
    } else if (status === "error") {
      promptNotification("Saving Assembly Failed", "error");
    }
  };

  const handlePartDialogClose = () => {
    setOpenSelectedPartCardDialog(false);
  };

  const handleAddPartDialogOpen = () => {
    setOpenAddPartsDialog(true);
  };

  const handleAddPartDialogClose = () => {
    setOpenAddPartsDialog(false);
  };

  const handleConfirmCancelDialogOpen = () => {
    setConfirmCancelOpenDialog(true);
  };

  const handleConfirmCancelDialogClose = () => {
    setConfirmCancelOpenDialog(false);
  };

  const handleConfirmCancelActionDialog = () => {
    setConfirmCancelOpenDialog(false);
    handleUpdateAssemblyNaming("", "", 0, true, "", "");
    navigate("/configurator/assembly-landing");
  };

  const handleSaveButtonClick = async () => {
    if (assemblyNaming.name.trim().length === 0) {
      setSaveLoading(false);
      promptNotification("The name cannot be empty", "error");
      return;
    }

    if (assemblyNaming.description.trim().length === 0) {
      setSaveLoading(false);
      promptNotification("The description cannot be empty", "error");
      return;
    }

    if (assemblyNaming.height.length === 0) {
      setSaveLoading(false);
      promptNotification("The height cannot be empty", "error");
      return;
    }

    if (assemblyNaming.filter_1 === "") {
      setSaveLoading(false);
      promptNotification("The filter 1 cannot be empty", "error");
      return;
    }

    if (assemblyNaming.filter_2 === "") {
      setSaveLoading(false);
      promptNotification("The filter 2 cannot be empty", "error");
      return;
    }

    if (partGroupItems.length === 0) {
      setSaveLoading(false);
      promptNotification("The assembly must have at least one part", "error");
      return;
    }

    if (partGroupItems.some((part) => part.description === "Not found")) {
      setSaveLoading(false);
      promptNotification("Remove all parts that were not found", "error");
      return;
    }

    if (userSuccess) {
      const payload = {
        data: {
          type: "part_assemblies",
          attributes: {
            name: assemblyNaming.name,
            description: assemblyNaming.description,
            parts: partGroupItems,
            creator: userId,
            height: assemblyNaming.height,
            global: assemblyNaming.global,
            filter_1: assemblyNaming.filter_1,
            filter_2: assemblyNaming.filter_2,
          },
        },
      };
      try {
        await crudsService.createPartAssembly(payload);
        handleSaveController("success");
      } catch (error) {
        if (error.hasOwnProperty("message")) {
          promptNotification(`${error.message}`, "error");
        } else {
          handleSaveController("error");
        }
      }
    } else {
      handleSaveController("error");
    }
    setSaveLoading(false);
  };

  useEffect(() => {
    if (pendingUpdates.length > 0) {
      let localPartGroupItems = partGroupItems;

      pendingUpdates.forEach((update) => {
        const partInPartGroupItems = partGroupItems.find(
          (part) => part.partNumber === update.part.partNumber
        );

        switch (update.type) {
          case "updateQty":
            if (partInPartGroupItems) {
              partInPartGroupItems.AssembliesRelation.quantity = update.val;
            }
            break;
          case "removePart":
            localPartGroupItems = localPartGroupItems.filter(
              (part) => part.partNumber !== update.part.partNumber
            );
            break;
          case "newPart":
            if (partInPartGroupItems) {
              partInPartGroupItems.AssembliesRelation.quantity +=
                update.part.AssembliesRelation.quantity;
            } else {
              localPartGroupItems.push(update.part);
            }
            break;
          case "movePart":
            if (partInPartGroupItems) {
              const partGroupItemsCopy = JSON.parse(
                JSON.stringify(localPartGroupItems)
              );
              const ogIndex = partGroupItemsCopy.findIndex(
                (part) => part.partNumber === partInPartGroupItems.partNumber
              );
              const partsBefore = partGroupItemsCopy
                .slice(
                  0,
                  update.newRowIndex + (ogIndex < update.newRowIndex ? 1 : 0)
                )
                .filter((part) => part.partNumber !== update.part.partNumber);
              const partsAfter = partGroupItemsCopy
                .slice(
                  update.newRowIndex + (ogIndex > update.newRowIndex ? 0 : 1)
                )
                .filter((part) => part.partNumber !== update.part.partNumber);
              localPartGroupItems = [
                ...partsBefore,
                partInPartGroupItems,
                ...partsAfter,
              ];
            }
            break;
          default:
            break;
        }
      });

      setPartGroupItems(() => {
        return [...localPartGroupItems];
      });
      setPendingUpdates([]);
    }
  }, [pendingUpdates, partGroupItems]);

  useEffect(() => {
    const handleResize = () => {
      setViewingHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array to run only once on mount

  const jspsreadsheetViewingHeight =
    viewingHeight /
    (viewingHeight < 700
      ? 3.15
      : viewingHeight < 800
      ? 2.45
      : viewingHeight < 900
      ? 2.3
      : viewingHeight < 1000
      ? 2.2
      : viewingHeight < 1100
      ? 2.1
      : viewingHeight < 1200
      ? 2
      : viewingHeight < 1300
      ? 1.95
      : viewingHeight < 1400
      ? 1.9
      : 1.5);

  return (
    <DashboardLayout sx={{ overflowAnchor: "none" }}>
      {ability.can("create", "assemblies") ? (
        <Grid container spacing={1} pt={2}>
          <Grid item xs={12}>
            <MDBox component="h1" fontWeight="bold" fontSize={24}>
              New Assembly
            </MDBox>
          </Grid>
          <Grid item xs={10}>
            <AssemblyCard
              assemblyFetched={{
                assembly_name: assemblyNaming.name,
                description: assemblyNaming.description,
                height: assemblyNaming.height,
                state: assemblyNaming.global,
                filter_1: assemblyNaming.filter_1,
                filter_2: assemblyNaming.filter_2,
              }}
            />
          </Grid>
          <Grid item xs={2} display="flex" alignItems="center">
            <Grid container spacing={1} display="flex" justifyItems="center">
              <Grid item xs={12} display="flex" justifyContent="center">
                <MDButton
                  color="info"
                  variant="gradient"
                  size="small"
                  fullWidth
                  onClick={handleAddPartDialogOpen}
                >
                  Add Parts
                </MDButton>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="center">
                {saveLoading ? (
                  <MDBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress color="info" />
                  </MDBox>
                ) : (
                  <MDButton
                    onClick={() => {
                      setSaveLoading(true);
                      handleSaveButtonClick();
                    }}
                    color="success"
                    variant="gradient"
                    size="small"
                    fullWidth
                  >
                    Save Assembly
                  </MDButton>
                )}
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="center">
                <MDButton
                  color="error"
                  variant="gradient"
                  size="small"
                  fullWidth
                  onClick={handleConfirmCancelDialogOpen}
                >
                  Cancel
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <CustomSpreadSheet
              data={makePartAssemblyTableRows}
              columns={partGroupItemsColumns}
              removeRow={removePartFromGroup}
              handleUpdateQty={handleQtyCellChange}
              handleUpdateDetail={handleUpdateDetail}
              handleNewPart={addPartFromSpreadSheet}
              handleMoveRow={handleMoveMiscPart}
              viewingHeight={jspsreadsheetViewingHeight}
            />
          </Grid>
          <SelectedPartCardDialog
            currentTableSelection={currentTableSelection}
            handleQuantityChange={handleQuantityChange}
            handleAddPart={addPartToPartGroupItems}
            open={openSelectedPartCardDialog}
            onClose={() => handlePartDialogClose()}
          />
          <CrudDialog
            title="Parts"
            size="xxl"
            control={{
              open: openAddPartsDialog,
              onClose: handleAddPartDialogClose,
            }}
            content={<QueryTable addSinglePart={handleLogTable} />}
            actions={{
              cancelName: "",
              confirmName: "Close",
              confirmHandler: handleAddPartDialogClose,
            }}
          />
          <CrudDialog
            title="Cancel Changes"
            size="xs"
            control={{
              open: confirmCancelOpenDialog,
              onClose: handleConfirmCancelDialogClose,
            }}
            content={
              <DialogContentText>
                Any unsaved changes will be lost.
              </DialogContentText>
            }
            actions={{
              confirmName: "Confirm",
              confirmHandler: handleConfirmCancelActionDialog,
            }}
          />
        </Grid>
      ) : (
        <MDBox component="h1" fontWeight="bold" fontSize={24} p={2}>
          Unauthorised
        </MDBox>
      )}
      <Footer />
    </DashboardLayout>
  );
}

export default AssemblyNew;
