import { useState, useEffect, useContext, useCallback } from "react";

// react-router components
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { setupAxiosInterceptors } from "services/interceptor";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MDAvatar from "components/MDAvatar";

// Material Dashboard 2 PRO React examples
import Sidenav from "examples/Sidenav";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 PRO React routes
import routes from "routes";
import crudRoutes from "crud.routes";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController, setMiniSidenav, AuthContext } from "context";

import { getPermissions } from "config/Permissions";

// Images
import brandWhite from "assets/images/aps/aps-logo-retina.png";
import brandDark from "assets/images/aps/aps-logo-retina.png";
import ProtectedRoute from "examples/ProtectedRoute";
import Login from "auth/login";
import AuthService from "services/auth-service";
import Login2FA from "auth/login-2fa";
import Landing from "layouts/November/Landing";
import NotifySnackbar from "customInputs/notify-snackbar";
import { ApsContext } from "ApsContext";

export default function App({ ability }) {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  const authContext = useContext(AuthContext);
  const { message, notifyEvent, messageType } = useContext(ApsContext);
  const [userDetails, setUserDetails] = useState({ name: "", image: "" });

  // if the token expired or other errors it logs out and goes to the login page
  const navigate = useNavigate();
  setupAxiosInterceptors(() => {
    authContext.logout();
    navigate("/auth/login");
  });

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = useCallback(() => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  }, [dispatch, miniSidenav, onMouseEnter]);

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = useCallback(() => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  }, [dispatch, onMouseEnter]);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    if (!authContext.isAuthenticated) return;
    (async () => {
      const response = await AuthService.getProfile();
      setUserDetails({
        name: response.data.attributes.name,
        image: response.data.attributes.profile_image,
      });
      const rules = await getPermissions(response.data.id);
      ability.update(rules);
    })();
  }, [ability, authContext.isAuthenticated]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.key === "user") {
        route.name = userDetails.name;
        route.icon = (
          <MDAvatar src={userDetails.image} alt="Aps User" size="sm" />
        );
      }
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={
              <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                {route.component}
              </ProtectedRoute>
            }
            key={route.key}
          />
        );
      }

      return null;
    });

  const getCrudRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getCrudRoutes(route.collapse);
      }
      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={
              <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                {route.component}
              </ProtectedRoute>
            }
            key={route.key}
          />
        );
      }

      return null;
    });

  return (
    <>
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={
                (transparentSidenav && !darkMode) || whiteSidenav
                  ? brandDark
                  : brandWhite
              }
              brandName="APS Industrial"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          </>
        )}
        {layout === "vr" /* && <Configurator /> */}
        <Routes>
          {!authContext.isAuthenticated && (
            <Route path="/auth/login" element={<Login />} />
          )}
          {!authContext.isAuthenticated && authContext.loggedIn2FA && (
            <Route path="/auth/login-2fa" element={<Login2FA />} />
          )}
          {/* <Route path="/auth/register" element={<Login />} />
            <Route path="/auth/forgot-password" element={<Login />} />
            <Route path="/auth/reset-password" element={<Login />} /> */}
          <Route
            path="/home"
            element={
              <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                <Landing />
              </ProtectedRoute>
            }
          />
          {getRoutes(routes)}
          {getCrudRoutes(crudRoutes)}
          <Route path="*" element={<Navigate to="/home" />} />
        </Routes>
        <NotifySnackbar
          notification={message}
          type={messageType}
          event={notifyEvent}
        />
      </ThemeProvider>
    </>
  );
}
