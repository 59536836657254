/* eslint-disable react/prop-types */
import * as React from "react";
import { useSpring, animated } from "@react-spring/web";
import SvgIcon from "@mui/material/SvgIcon";
import Divider from "@mui/material/Divider";
import Collapse from "@mui/material/Collapse";
import { alpha, styled } from "@mui/material/styles";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import { useContext, useState, useEffect } from "react";
import { ApsContext } from "ApsContext";
import {
  Icon,
  Stack,
  Popover,
  Drawer,
  Card,
  CircularProgress,
  Tooltip,
  DialogContentText,
} from "@mui/material";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import Grid from "@mui/material/Grid";
import QuoteDbBreadcrumbs from "customInputs/apsTreeMenu/components/QuoteDbBreadcrumbs";
import DBPricingTable from "customInputs/apsTreeMenu/components/DBPricingTable";
import { useNewItems } from "services/query-service";
import DeleteCell from "customInputs/customTableCells/deleteCell";
import { useNewItemsDiscounts, useParts } from "services/query-service";
import LineItemsDialog from "./components/LineItemsDialog";
import PartQtyCell from "customInputs/customTableCells/partQtyCell";
import AddAssemblyCell from "customInputs/customTableCells/addAssemblyCell";
import { useAPSDB } from "hooks/useAPSDB";
import CrudDialog from "customInputs/crud-dialog";

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 15, height: 15 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 15, height: 15 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function AddSquare(props) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function NavSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 15, height: 15 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-520h560v-120H200v120Zm560 80H200v360h560v-360Zm-560-80v80-80Zm0 0v-120 120Zm0 80v360-360Z" />
    </SvgIcon>
  );
}

function TransitionComponent(props) {
  const style = useSpring({
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

// eslint-disable-next-line react/display-name
const CustomTreeItem = React.forwardRef((props, ref) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} ref={ref} />
));

const StyledTreeItem = styled(CustomTreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.1,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
  [`.MuiTreeItem-content.Mui-selected`]: {
    backgroundColor: `${alpha(theme.palette.info.focus, 0.1)}`,
    "&:hover": {
      backgroundColor: `${alpha(theme.palette.info.focus, 0.1)}`,
    },
  },
  [`.MuiTreeItem-content.Mui-selected.Mui-focused`]: {
    backgroundColor: `${alpha(theme.palette.info.focus, 0.1)}`,
  },
}));

export default function ApsTreeMenu({ viewingHeight }) {
  const {
    quotingDB, //dbItems,
    updateQuotingDB,
    addNewDB,
    addNewTier,
    addNewBay,
    currentID,
    deleteDB,
    deleteTier,
    deleteBay,
    changeDBName,
    updateCurrentIDs,
    duplicateDBNew,
    toggleTreeMenu,
    flattenTierIDsInDB,
    flattenBayIDs,
    flattenDBIDsInQuote,
    lineItems,
    updateLineItems,
    currentClient,
    allAddCostings,
    promptNotification,
    blankPart,
  } = useContext(ApsContext);
  const { handleDbPartsMapped, handleLineItemCosts, partAssembler } =
    useAPSDB();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [newDBName, setNewDBName] = useState("");
  const [selectedEdit, setSelectedEdit] = useState(null);
  const [showPricing, setShowPricing] = useState(false);
  const [openLineItemDialog, setOpenLineItemDialog] = useState(false);
  const [openUpdatePriceDialog, setOpenUpdatePriceDialog] = useState(false);
  const [dbTotal, setDBTotal] = useState(0);
  const [lineTotal, setLineTotal] = useState(0);

  const { data: allParts, isSuccess: isSuccessAllParts } = useParts();
  const { data: newItems, isSuccess: isSuccessNewItems } = useNewItems();
  const { data: discounts, isSuccess: isSuccessDiscounts } =
    useNewItemsDiscounts(
      currentClient.deb_acc !== "" && currentClient.deb_acc !== undefined
        ? currentClient.deb_acc
        : "newquote"
    );

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setAnchorEl(null);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleEditClick = (e, db) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setNewDBName(db.name);
    setSelectedEdit(db);
  };

  const handleChangeBay = (dbID, tierID, bayID) => {
    updateCurrentIDs(dbID, tierID, bayID);
  };

  const handleSaveDBName = () => {
    changeDBName(selectedEdit.id, newDBName);
    setAnchorEl(null);
    setOpenDialog(false);
  };

  const handleOpenDBPricing = () => {
    setAnchorEl(null);
    setShowPricing(true);
  };

  const handleDeletingDB = () => {
    setAnchorEl(null);
    deleteDB(selectedEdit.id);
  };

  const handleOpenUpdatePriceDialog = () => {
    setOpenUpdatePriceDialog(true);
  };

  const handleCloseUpdatePriceDialog = () => {
    setOpenUpdatePriceDialog(false);
  };

  const handleActionUpdatePriceDialog = () => {
    updatePartsDetails();
    setOpenUpdatePriceDialog(false);
  };

  // find and update details before returning the part
  // will bring old part objs up to date
  const findAndUpdatePart = (part) => {
    // part.part.partNumber for chassis circuitBreakers structure
    // part.partNumber for assembly parts
    const chassisPart = part.hasOwnProperty("part");
    const foundPart = allParts.find(
      (fetchedPart) =>
        fetchedPart.partNumber ===
        (chassisPart ? part.part.partNumber : part.partNumber)
    );
    if (foundPart !== undefined) {
      return chassisPart
        ? {
            ...part,
            part: {
              ...part.part,
              ...partAssembler(foundPart),
              description: part.part.description,
            },
          }
        : {
            ...part,
            ...partAssembler(foundPart),
            description: part.description,
          };
    } else {
      const checkValidLabour = chassisPart
        ? (part.part.labourMount !== undefined ||
            part.part.labourMount !== null) &&
          (part.part.labourWire !== undefined ||
            part.part.labourWire !== null) &&
          !isNaN(part.part.labourMount) &&
          !isNaN(part.part.labourWire) &&
          part.part.labour !== part.part.labourMount + part.part.labourWire
        : (part.labourMount !== undefined || part.labourMount !== null) &&
          (part.labourWire !== undefined || part.labourWire !== null) &&
          !isNaN(part.labourMount) &&
          !isNaN(part.labourWire) &&
          part.labour !== part.labourMount + part.labourWire;
      // custom parts
      return chassisPart
        ? {
            ...part,
            part: {
              ...blankPart,
              ...part.part,
              inclDesc:
                typeof part.part.inclDesc === "string" &&
                part.part.inclDesc.toUpperCase() === "Y"
                  ? "Y"
                  : "N",
              labourMount: checkValidLabour
                ? part.part.labour
                : blankPart.labourMount,
              labourWire: checkValidLabour
                ? blankPart.labourWire
                : part.part.labourWire && !isNaN(part.part.labourWire)
                ? part.part.labourWire
                : blankPart.labourWire,
            },
          }
        : {
            ...blankPart,
            ...part,
            inclDesc:
              typeof part.inclDesc === "string" &&
              part.inclDesc.toUpperCase() === "Y"
                ? "Y"
                : "N",
            labourMount: checkValidLabour ? part.labour : blankPart.labourMount,
            labourWire: checkValidLabour
              ? blankPart.labourWire
              : part.labourWire && !isNaN(part.labourWire)
              ? part.labourWire
              : blankPart.labourWire,
          };
    }
  };

  // update details for all parts that exist from database
  const updatePartsDetails = () => {
    // first assemblies
    const assemblyUpdatedQuotingDB = quotingDB.map((db) => {
      return {
        ...db,
        tiers: db.tiers.map((tier) => {
          return {
            ...tier,
            bays: tier.bays.map((bay) => {
              return {
                ...bay,
                assemblies: bay.assemblies.map((assembly) => {
                  return {
                    ...assembly,
                    parts: assembly.parts.map((part) => {
                      return findAndUpdatePart(part);
                    }),
                  };
                }),
              };
            }),
          };
        }),
      };
    });
    // second chassis1 and chassis2 circuitBreakers
    const chassisUpdatedQuotingDB = assemblyUpdatedQuotingDB.map((db) => {
      return {
        ...db,
        tiers: db.tiers.map((tier) => {
          return {
            ...tier,
            bays: tier.bays.map((bay) => {
              const updatedChassis1 = bay.chassis.chassis1
                ? {
                    ...bay.chassis.chassis1,
                    circuitBreakers: bay.chassis.chassis1.circuitBreakers.map(
                      (breaker) => {
                        return findAndUpdatePart(breaker);
                      }
                    ),
                  }
                : bay.chassis.chassis1;
              const updatedChassis2 = bay.chassis.chassis2
                ? {
                    ...bay.chassis.chassis2,
                    circuitBreakers: bay.chassis.chassis2.circuitBreakers.map(
                      (breaker) => {
                        return findAndUpdatePart(breaker);
                      }
                    ),
                  }
                : bay.chassis.chassis2;
              return {
                ...bay,
                chassis: {
                  ...bay.chassis,
                  chassis1: updatedChassis1,
                  chassis2: updatedChassis2,
                },
              };
            }),
          };
        }),
      };
    });
    updateQuotingDB(chassisUpdatedQuotingDB);
    promptNotification("Updated details for all parts", "success");
  };

  // function to return a list of ids (as strings) from the dbItems array of objects where the isVisible property is true
  const getVisibleIDs = (dbItems) => {
    const visibleIDs = [];
    dbItems.forEach((db) => {
      if (db.isVisible) {
        visibleIDs.push(db.id.toString());
        db.tiers.forEach((tier) => {
          if (tier.isVisible) {
            visibleIDs.push(tier.id.toString());
            tier.bays.forEach((bay) => {
              if (bay.isVisible) {
                visibleIDs.push(bay.id.toString());
              }
            });
          }
        });
      }
    });
    return visibleIDs;
  };

  const dbTreeToggle = (e, nodeIds) => {
    toggleTreeMenu(nodeIds);
  };

  const dbTreeList = quotingDB.map((db) => {
    return (
      <StyledTreeItem
        key={db.id}
        nodeId={`${db.id}`}
        label={
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mr={2}
          >
            <MDTypography variant="h6" fontWeight="bold">
              {db.name.length > 20 ? `${db.name.substring(0, 20)}...` : db.name}
            </MDTypography>
            <Icon
              onClick={(e) => {
                handleEditClick(e, db);
              }}
            >
              more_vert
            </Icon>
          </Stack>
        }
      >
        {db.tiers.map((tier) => {
          return (
            <StyledTreeItem
              key={tier.id}
              nodeId={`${tier.id}`}
              label={
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <MDTypography variant="h6" fontWeight="medium">
                    {tier.name}
                  </MDTypography>
                  {flattenTierIDsInDB().length > 1 ? (
                    <Icon
                      color="error"
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteTier(db.id, tier.id);
                      }}
                    >
                      delete
                    </Icon>
                  ) : null}
                </Stack>
              }
            >
              {tier.bays.map((bay) => {
                return (
                  <StyledTreeItem
                    key={bay.id}
                    nodeId={`${bay.id}`}
                    icon={<NavSquare />}
                    endIcon={<CloseSquare />}
                    onClick={() => handleChangeBay(db.id, tier.id, bay.id)}
                    label={
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <MDTypography variant="h6" fontWeight="regular">
                          {bay.name}
                        </MDTypography>
                        {flattenBayIDs().length > 1 ? (
                          <Icon
                            color="error"
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteBay(db.id, tier.id, bay.id);
                            }}
                          >
                            delete
                          </Icon>
                        ) : null}
                      </Stack>
                    }
                  />
                );
              })}
              {tier.bays.length === 1 && (
                <StyledTreeItem
                  nodeId={`${tier.id}addBay`}
                  label={<MDTypography variant="body2">Add Bay</MDTypography>}
                  icon={<PlusSquare color="success" />}
                  onClick={() => addNewBay(db.id, tier.id)}
                  sx={{ mt: 0.5, mb: 0.5 }}
                />
              )}
            </StyledTreeItem>
          );
        })}
        {db.tiers.length < 3 ? (
          <StyledTreeItem
            nodeId={`${db.id}addTier`}
            label={<MDTypography variant="body2">Add Tier</MDTypography>}
            icon={<PlusSquare color="success" />}
            onClick={() => addNewTier(db.id)}
            sx={{ mt: 0.5, mb: 0.5 }}
          />
        ) : null}
      </StyledTreeItem>
    );
  });

  // function to remove a part from lineItems
  const removePart = (removeLineItem) => {
    if (lineItems.length !== 1) {
      const findItem = lineItems.find(
        (item) => item.partNumber === removeLineItem.partNumber
      );
      if (findItem !== undefined) {
        const foundLineItem = lineItems.filter(
          (item) => item.partNumber !== removeLineItem.partNumber
        );
        updateLineItems(foundLineItem);
      }
    } else {
      updateLineItems([]);
    }
  };

  // update lineItems with new discounts when a new customer (currentClient) is selected
  useEffect(() => {
    if (
      isSuccessDiscounts &&
      (currentClient.deb_acc !== "" || currentClient.deb_acc !== undefined)
    ) {
      const updatedLineItems = lineItems.map((item) => {
        const foundDiscount = discounts.find(
          (discount) => discount.prod_price_code === item.priceCode
        );
        if (foundDiscount !== undefined) {
          return {
            ...item,
            discount: foundDiscount.discountPercent,
            finalPrice:
              Math.round(
                item.basePrice *
                  (1 - foundDiscount.discountPercent / 100) *
                  item.quantity *
                  100
              ) / 100,
          };
        } else {
          return {
            ...item,
            discount: 0,
            finalPrice: Math.round(item.basePrice * item.quantity * 100) / 100,
          };
        }
      });
      updateLineItems(updatedLineItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessDiscounts, currentClient.deb_acc]);

  // function to add a part from lineItems
  const addPart = (newLineItem) => {
    const findItem = lineItems.find(
      (item) => item.partNumber === newLineItem.partNumber
    );
    if (findItem !== undefined) {
      const foundLineItem = lineItems.map((item) => {
        if (item.partNumber === newLineItem.partNumber) {
          return { ...item, quantity: item.quantity + 1 };
        }
        return item;
      });

      updateLineItems(foundLineItem);
    } else {
      const foundDiscount = discounts.find(
        (discount) => discount.prod_price_code === newLineItem.priceCode
      );
      if (foundDiscount !== undefined) {
        updateLineItems([
          ...lineItems,
          {
            ...newLineItem,
            discount: foundDiscount.discountPercent,
            finalPrice:
              Math.round(
                newLineItem.basePrice *
                  (1 - foundDiscount.discountPercent / 100) *
                  100
              ) / 100,
            quantity: 1,
          },
        ]);
      } else {
        updateLineItems([
          ...lineItems,
          {
            ...newLineItem,
            discount: 0,
            finalPrice: newLineItem.basePrice,
            quantity: 1,
          },
        ]);
      }
    }
  };

  const handleUpdateQty = (e, partGroupItem) => {
    const val = e.target.value;
    if (val !== undefined && val !== null && val !== "" && !isNaN(val)) {
      const updatedLineItems = lineItems.map((item) => {
        if (item.partNumber === partGroupItem.partNumber) {
          return { ...item, quantity: parseInt(val) };
        } else {
          return item;
        }
      });
      updateLineItems(updatedLineItems);
    }
  };

  // all database factory items
  const makeNewItemsRows = () => {
    if (isSuccessNewItems) {
      if (newItems.length > 0) {
        return newItems.map((item) => {
          return {
            partNumberLineItems: item.partNumber,
            descriptionLineItems: item.description,
            supplierLineItems: item.supplier,
            basePriceLineItems: item.basePrice.toFixed(2),
            actionsLineItems: (
              <AddAssemblyCell
                icon="add"
                color="success"
                onIconClick={addPart}
                assembly={item}
              />
            ),
          };
        });
      } else {
        return [
          {
            partNumberLineItems: "",
            descriptionLineItems: "",
            supplierLineItems: "",
            basePriceLineItems: "",
            actionsLineItems: "",
          },
        ];
      }
    } else {
      return [];
    }
  };

  // current factory items
  const makeAddedNewItemsRows = () => {
    if (isSuccessDiscounts) {
      if (discounts.length >= 0) {
        return lineItems.map((item) => {
          const foundDiscount = discounts.find(
            (discount) => discount.prod_price_code === item.priceCode
          );
          if (foundDiscount !== undefined) {
            return {
              partNumberAddedLineItems: item.partNumber,
              descriptionAddedLineItems: item.description,
              supplierAddedLineItems: item.supplier,
              basePriceAddedLineItems: item.basePrice.toFixed(2),
              discountAddedLineItems: foundDiscount.discountPercent,
              quantityAddedLineItems: (
                <PartQtyCell
                  currentQty={item.quantity}
                  handleUpdateQty={handleUpdateQty}
                  partGroupItem={item}
                />
              ),
              finalPriceAddedLineItems: (
                Math.round(
                  item.basePrice *
                    (1 - foundDiscount.discountPercent / 100) *
                    item.quantity *
                    100
                ) / 100
              ).toFixed(2),
              actionsAddedLineItems: (
                <DeleteCell handleDelete={removePart} part={item} />
              ),
            };
          } else {
            return {
              partNumberAddedLineItems: item.partNumber,
              descriptionAddedLineItems: item.description,
              supplierAddedLineItems: item.supplier,
              basePriceAddedLineItems: item.basePrice.toFixed(2),
              discountAddedLineItems: 0,
              quantityAddedLineItems: (
                <PartQtyCell
                  currentQty={item.quantity}
                  handleUpdateQty={handleUpdateQty}
                  partGroupItem={item}
                />
              ),
              finalPriceAddedLineItems: (
                Math.round(item.basePrice * item.quantity * 100) / 100
              ).toFixed(2),
              actionsAddedLineItems: (
                <DeleteCell handleDelete={removePart} part={item} />
              ),
            };
          }
        });
      } else {
        return [
          {
            partNumberAddedLineItems: "",
            descriptionAddedLineItems: "",
            supplierAddedLineItems: "",
            basePriceAddedLineItems: "",
            discountAddedLineItems: "",
            quantityAddedLineItems: "",
            finalPriceAddedLineItems: "",
            actionsAddedLineItems: "",
          },
        ];
      }
    } else {
      return [
        {
          partNumberAddedLineItems: "",
          descriptionAddedLineItems: "",
          supplierAddedLineItems: "",
          basePriceAddedLineItems: "",
          discountAddedLineItems: "",
          quantityAddedLineItems: "",
          finalPriceAddedLineItems: "",
          actionsAddedLineItems: "",
        },
      ];
    }
  };

  const handleDBRename = (e) => {
    const { value } = e.target;
    // Filter out parentheses from the input value
    const filteredValue = value.replace(/[()]/g, "");
    setNewDBName(filteredValue);
  };

  useEffect(() => {
    setDBTotal(
      Math.round(
        handleDbPartsMapped(allAddCostings, quotingDB).reduce(
          (accumulator, currentValue) => accumulator + currentValue.totalPrice,
          0
        ) * 100
      ) / 100
    );
  }, [allAddCostings, handleDbPartsMapped, quotingDB]);

  useEffect(() => {
    setLineTotal(handleLineItemCosts(lineItems).totalLineItemsCost);
  }, [handleLineItemCosts, lineItems]);

  return (
    <>
      <Card
        sx={
          (/* {
          palette: { transparent: transparentColor, white, background },
          functions: { rgba },
        } */) => ({
            //backgroundColor: rgba(darkMode ? background.sidenav : white.main, 1),
            height: "100%",
          })
        }
      >
        <Grid container spacing={1} p={1}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <MDTypography variant="h6" textAlign="center">
                  Distribution Boards
                </MDTypography>
                <Divider sx={{ m: 0, mt: 1 }} />
              </Grid>
              <Grid item xs={12}>
                <QuoteDbBreadcrumbs />
              </Grid>
              <Grid item xs={12}>
                <TreeView
                  sx={{
                    height: viewingHeight * 0.65,
                    overflowY: "auto",
                  }}
                  onNodeToggle={dbTreeToggle}
                  selected={currentID.toString()}
                  expanded={getVisibleIDs(quotingDB)}
                  aria-label="customized"
                  defaultCollapseIcon={<MinusSquare />}
                  defaultExpandIcon={<PlusSquare />}
                  defaultEndIcon={<AddSquare />}
                >
                  {dbTreeList}
                </TreeView>
                <Divider sx={{ m: 0, mt: 2 }} />
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      size="small"
                      onClick={() => addNewDB()}
                      fullWidth
                    >
                      Add DB
                    </MDButton>
                  </Grid>
                  {!isSuccessDiscounts && !isSuccessNewItems ? (
                    <MDBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <CircularProgress color="info" />
                    </MDBox>
                  ) : (
                    <Grid item xs={8}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        size="small"
                        onClick={() => setOpenLineItemDialog(true)}
                        fullWidth
                      >
                        Add FTY INSTL Items
                      </MDButton>
                    </Grid>
                  )}
                  {!isSuccessAllParts ? (
                    <MDBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <CircularProgress color="info" />
                    </MDBox>
                  ) : (
                    <Grid item xs={12}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        size="small"
                        onClick={handleOpenUpdatePriceDialog}
                        fullWidth
                      >
                        Update Details of All Parts
                      </MDButton>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <MDBox borderRadius="lg" bgColor="light" p={0.5}>
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <MDTypography
                                fontWeight="bold"
                                variant="caption"
                                textAlign="center"
                              >
                                DB's Total ($):
                              </MDTypography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <MDTypography
                                fontWeight="bold"
                                variant="caption"
                                textAlign="center"
                              >
                                {dbTotal.toFixed(2)}
                              </MDTypography>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Grid>
                      <Grid item xs={4}>
                        <MDBox borderRadius="lg" bgColor="light" p={0.5}>
                          <Grid container display="flex">
                            <Grid
                              item
                              xs={12}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <MDTypography
                                fontWeight="bold"
                                variant="caption"
                                textAlign="center"
                              >
                                FII Total ($):
                              </MDTypography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <MDTypography
                                fontWeight="bold"
                                variant="caption"
                                textAlign="center"
                              >
                                {lineTotal.toFixed(2)}
                              </MDTypography>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Grid>
                      <Grid item xs={4}>
                        <MDBox borderRadius="lg" bgColor="light" p={0.5}>
                          <Grid container display="flex">
                            <Grid
                              item
                              xs={12}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <MDTypography
                                fontWeight="bold"
                                variant="caption"
                                textAlign="center"
                              >
                                Total ($):
                              </MDTypography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <MDTypography
                                fontWeight="bold"
                                variant="caption"
                                textAlign="center"
                              >
                                {(dbTotal + lineTotal).toFixed(2)}
                              </MDTypography>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          sx: { p: 0, backgroundColor: "white" },
        }}
      >
        <MDBox
          height="100%"
          width="100%"
          display="flex"
          flexDirection="column"
          bgColor="white"
        >
          <MDButton variant="text" color="dark" onClick={handleOpenDBPricing}>
            View Pricing
          </MDButton>
          <MDButton variant="text" color="dark" onClick={handleOpenDialog}>
            Rename
          </MDButton>
          <MDButton
            variant="text"
            color="dark"
            onClick={() => {
              duplicateDBNew(selectedEdit.id);
              setAnchorEl(null);
            }}
          >
            Copy
          </MDButton>
          {flattenDBIDsInQuote().length > 1 ? (
            <MDButton
              variant="text"
              color="error"
              onClick={() => handleDeletingDB()}
            >
              Delete
            </MDButton>
          ) : null}
        </MDBox>
      </Popover>
      <CrudDialog
        title="Rename DB"
        size="xs"
        required={
          newDBName === "" || newDBName === undefined || newDBName === null
        }
        control={{
          open: openDialog,
          onClose: handleCloseDialog,
        }}
        content={
          <Tooltip title="Prohibited characters: ( )" placement="top">
            <MDInput
              autoFocus
              margin="dense"
              fullWidth
              multiline
              rows={3}
              value={newDBName}
              onChange={handleDBRename}
            />
          </Tooltip>
        }
        actions={{
          confirmName: "Save",
          confirmHandler: handleSaveDBName,
        }}
      />
      <CrudDialog
        title="Update Pricing For All Parts"
        size="xs"
        control={{
          open: openUpdatePriceDialog,
          onClose: handleCloseUpdatePriceDialog,
        }}
        content={
          <DialogContentText>
            This action will update{" "}
            <strong> all non-custom parts in this quote </strong>
            with latest information available (excl. description). Please only
            consider using this action if its an outdated/old quote and
            sparingly.
          </DialogContentText>
        }
        actions={{
          confirmName: "Update",
          confirmHandler: handleActionUpdatePriceDialog,
        }}
      />
      <Drawer
        variant="temporary"
        anchor="right"
        open={showPricing}
        onClose={() => setShowPricing(false)}
        PaperProps={{
          sx: {
            width: "40%",
            maxWidth: "40%",
            whiteSpace: "normal",
            overflowY: "hidden",
          },
        }}
        ModalProps={{
          keepMounted: false,
          disablePortal: true,
        }}
      >
        <MDBox
          px={2}
          pt={1}
          width="100%"
          display="flex"
          flexDirection="column"
          maxHeight="90vh"
        >
          <MDTypography variant="h6" my={1} mt={2} textAlign="center">
            Pricing for{" "}
            {selectedEdit?.name.length > 20
              ? `${selectedEdit?.name.substring(0, 20)}...`
              : selectedEdit?.name}
          </MDTypography>
          <Divider sx={{ margin: 0 }} />
          <DBPricingTable
            db={selectedEdit ? selectedEdit : null}
            closeButton={() => setShowPricing(false)}
          />
        </MDBox>
      </Drawer>
      {isSuccessDiscounts && isSuccessNewItems && (
        <LineItemsDialog
          open={openLineItemDialog}
          onClose={() => setOpenLineItemDialog(false)}
          lineItemsData={makeNewItemsRows()}
          data={makeAddedNewItemsRows()}
        />
      )}
    </>
  );
}
