import AuthService from "services/auth-service";
import { AuthContext } from "context";
import {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { useAPSDB } from "hooks/useAPSDB";
import crudsService from "services/cruds-service";

export const ApsContext = createContext({
  quotingDB: [],
  updateQuotingDB: () => {}, // only used for update pricing; use other functions if possible
  currentID: 1.11,
  updateQtyDB: () => {},
  addNewDB: () => {},
  addNewTier: () => {},
  addNewBay: () => {},
  deleteDB: () => {},
  deleteTier: () => {},
  deleteBay: () => {},
  changeDBName: () => {},
  handleUpdateAssemblyNaming: () => {},
  assemblyNaming: { name: "", description: "", height: 0, global: true },
  duplicateDBNew: () => {},
  getChassisList: () => {},
  updateChassis1Part: () => {}, // used in EstimatePartSelection
  updateChassis2Part: () => {},
  updateBothChassisParts: () => {}, // used in EstimatePartSelection
  removeChassis: () => {},
  updateCurrentIDs: () => {},
  getCurrentDBTierBayNames: () => {},
  addCircuitBreakersToChassis: () => {},
  updateClient: () => {},
  updateProject: () => {},
  currentClient: {},
  currentProject: {},
  titleList: {},
  updateTitleList: () => {},
  currentQuoteNum: "",
  updateCurrentQuoteNum: () => {},
  currentUser: "",
  overwriteContext: () => {},
  allAddCostings: {},
  updateAllAddCostings: () => {},
  marginLimit: {},
  addAssembliesToBay: () => {},
  getAssembliesInBay: () => {},
  revisionVersion: "A",
  ogRevisionVersion: "A",
  updateRevision: () => {},
  approvalStatus: "draft",
  updateApprovalStatus: () => {},
  resetContext: () => {},
  quoteID: "",
  updateQuoteID: () => {},
  toggleTreeMenu: () => {},
  updateClientProject: () => {},
  userProfile: {},
  currentRole: "",
  chassisList: {},
  flattenBayIDs: () => {},
  flattenTierIDsInDB: () => {},
  flattenDBIDsInQuote: () => {},
  triggerChassisSave: () => {},
  savedChassisConfig: {},
  addConfigBreakersToChassis: () => {},
  currentChassis: "chassis1",
  getCurrentChassis: () => {},
  updateCurrentChassisPoleConfig: () => {},
  swapChassis: () => {},
  updateDefaultTCList: () => {},
  handleUpdateChassisPoleConfigAndCircuitBreakers: () => {},
  changeCurrentChassis: () => {},
  lineItems: [],
  updateLineItems: () => {},
  promptNotification: () => {},
  message: "",
  messageType: "",
  notifyEvent: false,
  assemblyFilterCategories: [],
  partGroupItemsColumns: [],
  blankPart: {},
  quoteCreator: "",
  partCostLimit: 0,
  partQtyLimit: 0,
  partLabourLimit: 0,
  resetTCList: () => {},
  resetQuoteStep: false,
  updateResetQuoteStep: () => {},
});

const currentQuotingClient = {
  deb_acc: "",
  businessName: "",
  city: "",
  state: "",
  customerName: "",
  contactName: "",
  contactNumber: "",
  contactEmail: "",
  directEmail: "",
  mobilePhone: "",
  discountGroup: "None",
  specialPriceSchedule: "None",
};

const currentProjectDetails = {
  projectName: "",
  subProjectName: "",
  crmOpp: "",
};

// example of DB's in the quote
const exampleQuotingDBs = [
  {
    id: 1, // unique ID for the DB
    name: "DB", // name of the DB
    isVisible: true, // boolean to determine if the DB is visible in the menu
    quantity: 1,
    tiers: [
      {
        id: 1.1, // unique ID for the tier
        name: "Tier 1", // name of the tier
        isVisible: true, // boolean to determine if the tier is visible in the menu
        bays: [
          {
            id: 1.11, // unique ID for the bay
            name: "Bay 1", // name of the bay
            isVisible: true, // boolean to determine if the bay is visible in the menu
            assemblies: [],
            chassis: { chassis1: null, chassis2: null },
          },
        ],
      },
    ],
  },
];

// additional costs and quantities for all DB's
const additionalCostings = [
  {
    id: 1,
    unitPrice: {
      labour: "70",
      markup: "20",
      labels: "80",
      drawings: "90",
      freight: "50",
    },
    quantity: {
      labels: "1",
      drawings: "1",
      freight: "1",
    },
  },
];

/* {
    partNumber: "",
    description: "",
    supplier: "",
    basePrice: 0.0,
    discount: 0,
    quantity: 0,
    finalPrice: 0.0,
} element for each lineItems []*/
const defaultLineItems = [];

const defaultAssemblyFilterCategories = [
  { filter1: "", filter2: [""] },
  {
    filter1: "Panel Assembly",
    filter2: [
      "",
      "STK DB-TOP-MSW-ENC",
      "STK DB-TOP-MSW-ENC + 1M",
      "STK DB-TOP-MSW-ENC + 2M",
      "STK DB-TOP-MSW-ENC + Surge",
      "STK DB-TOP-MSW-ENC + Surge + 1M",
      "STK DB-TOP-MSW-ENC + Surge + 2M",
    ],
  },
  {
    filter1: "Sub-Assembly",
    filter2: [
      "",
      "MSW",
      "MCCB",
      "Chassis",
      "Power Monitoring",
      "Surge Kit",
      "Emergency Lighting",
      "RCD Testing",
      "1 x DIN Rail Kit (per row)",
    ],
  },
];

// for init or adding custom part (partAssembler in useAPSDB() for part props must match)
const defaultPart = {
  filter1: "custom1",
  filter2: "custom2",
  partNumber: "custom",
  description: "Not found",
  supplier: "Unknown",
  cost: 0,
  labourMount: 0,
  labourWire: 0,
  labour: 0,
  inclDesc: "Y",
  manuClass1: "custom",
  manuClass2: "custom",
  manuDesc: "custom",
  stock: "Not Stocked_C",
};

const defaultPartCostLimit = 1000000;

const defaultPartQtyLimit = 1000;

const defaultPartLabourLimit = 1000;

/**
 * Provides context for the APS application.
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components.
 * @returns {JSX.Element} - The context provider component.
 */
// eslint-disable-next-line react/prop-types
const ApsContextProvider = ({ children }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const [currentClient, setCurrentClient] = useState(currentQuotingClient);
  const [currentProject, setCurrentProject] = useState(currentProjectDetails);
  const [quotingDB, setQuotingDB] = useState(exampleQuotingDBs); // the DB's in the quote
  const [currentID, setCurrentID] = useState(1.11); // the ID of the bay that is currently being worked on
  const [assemblyNaming, setAssemblyNaming] = useState({
    name: "",
    description: "",
    height: 0,
    global: true,
    filter_1: "",
    filter_2: "",
  });
  const [titleList, setTitleList] = useState([]); // terms and conditions
  const [fetchedDefaultTCList, setFetchedDefaultTCList] = useState([]);
  const [currentDB, setCurrentDB] = useState(1); // the ID of the DB that is currently being worked on
  const [currentTier, setCurrentTier] = useState(1.1); // the ID of the tier that is currently being worked on]
  const [currentUser, setCurrentUser] = useState("");
  const [quoteCreator, setQuoteCreator] = useState("");
  const [allAddCostings, setAllAddCostings] = useState(additionalCostings); // additional costings array for pricing DB's
  const [lineItems, setLineItems] = useState(defaultLineItems);
  const [currentQuoteNum, setCurrentQuoteNum] = useState("");
  const [marginLimit] = useState(20); // hard coded margin limit
  const [revisionVersion, setRevisionVersion] = useState("A"); // revision version of the quote
  const [ogRevisionVersion, setOGRevisionVersion] = useState("A"); // og revision version of the quote
  const [approvalStatus, setApprovalStatus] = useState("draft"); // approval status of the quote
  const [quoteID, setQuoteID] = useState(""); // the ID of the quote
  const [userProfile, setUserProfile] = useState({});
  const [currentRole, setCurrentRole] = useState("");
  const [currentChassis, setCurrentChassis] = useState("chassis1");

  const [savedChassisConfig, setSavedChassisConfig] = useState({});

  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState(null);
  const [notifyEvent, setNotifyEvent] = useState(null);
  const [assemblyFilterCategories] = useState(defaultAssemblyFilterCategories);

  const [blankPart] = useState(defaultPart);
  const [partCostLimit] = useState(defaultPartCostLimit);
  const [partQtyLimit] = useState(defaultPartQtyLimit);
  const [partLabourLimit] = useState(defaultPartLabourLimit);

  const [resetQuoteStep, setResetQuoteStep] = useState(false);

  // for both assembly pages
  const [partGroupItemsColumns] = useState([
    {
      type: "text",
      title: "PART NUMBER",
      width: 150,
      align: "left",
    },
    {
      type: "text",
      title: "DESCRIPTION",
      width: 300,
      align: "left",
      readOnly: true,
    },
    {
      type: "text",
      title: "SUPPLIER",
      width: 100,
      align: "left",
      readOnly: true,
    },
    { type: "numeric", title: "QTY", width: 40, align: "right" },
    {
      type: "numeric",
      title: "COST ($)",
      width: 60,
      align: "right",
      readOnly: true,
      mask: "0.00",
      decimal: ".",
    },
    {
      type: "numeric",
      title: "LABOUR (MIN.)",
      width: 100,
      align: "right",
      readOnly: true,
    },
    {
      type: "text",
      title: "STOCK",
      width: 80,
      align: "left",
      readOnly: true,
    },
  ]);

  const {
    idGen,
    idGenCap,
    idGenNum,
    getChassisSpecsFromPartNumber,
    getAssembliesInBay,
  } = useAPSDB();

  const updateQuoteID = (id) => {
    setQuoteID(id);
  };

  const triggerChassisSave = (newData) => {
    setSavedChassisConfig(newData);
  };

  const getApsUser = useCallback(async () => {
    if (!isAuthenticated || currentUser !== "") return null;

    try {
      const res = await AuthService.getProfile();
      setCurrentUser(res.data.attributes.name);
      setUserProfile(res.data);
      setCurrentRole(res.data.attributes.role_name);
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  }, [isAuthenticated, currentUser]); // add other dependencies if needed

  useEffect(() => {
    getApsUser();
  }, [getApsUser]);

  const updateRevision = (rev) => {
    setRevisionVersion(rev);
  };

  const updateApprovalStatus = (status) => {
    setApprovalStatus(status);
  };

  /**
   * Updates the current DB, tier, and bay IDs.
   * @param {number} dbID - The ID of the DB.
   * @param {number} tierID - The ID of the tier.
   * @param {number} bayID - The ID of the bay.
   */
  const updateCurrentIDs = (dbID, tierID, bayID) => {
    setCurrentDB(dbID);
    setCurrentTier(tierID);
    setCurrentID(bayID);
  };

  /**
   * Updates the assembly naming state.
   * @param {string} name - The name of the assembly.
   * @param {string} description - The description of the assembly.
   */
  const handleUpdateAssemblyNaming = useCallback(
    (name, description, height, global, filter_1, filter_2) => {
      setAssemblyNaming({
        name,
        description,
        height,
        global,
        filter_1,
        filter_2,
      });
    },
    []
  );

  // update quantity of db
  const updateQtyDB = (id, qty) => {
    setQuotingDB(
      quotingDB.map((db) => {
        if (db.id === id) {
          return { ...db, quantity: qty };
        } else {
          return db;
        }
      })
    );
  };

  /**
   * Adds a new DB to the quote.
   */
  const addNewDB = () => {
    const latestID = quotingDB[quotingDB.length - 1].id;
    const newID = latestID + 1;
    const newDBName = "DB";
    const currentCount = countDBsWithSameName(newDBName);
    const newDB = {
      id: newID,
      name: `DB${currentCount === 0 ? "" : `(${currentCount})`}`,
      isVisible: true,
      quantity: 1,
      tiers: [
        {
          id: newID + 0.1,
          name: `Tier 1`,
          isVisible: true,
          bays: [
            {
              id: newID + 0.11,
              name: `Bay 1`,
              isVisible: true,
              assemblies: [],
              chassis: { chassis1: null, chassis2: null },
            },
          ],
        },
      ],
    };
    setQuotingDB([...quotingDB, newDB]);

    // additional costings for db
    setAllAddCostings([
      ...allAddCostings,
      {
        id: newID,
        unitPrice: allAddCostings[0].unitPrice,
        quantity: allAddCostings[0].quantity,
      },
    ]);
  };

  // function to flatten the bay ids in the quote
  const flattenBayIDs = () => {
    const bayIDs = quotingDB
      .map((db) => db.tiers)
      .flat()
      .map((tier) => tier.bays)
      .flat()
      .map((bay) => bay.id);
    return bayIDs;
  };

  // function to return the bay id at index -1 of a given bay id from the flattened bay ids
  const getPreviousBayID = (bayID) => {
    const bayIDs = flattenBayIDs();
    const index = bayIDs.indexOf(bayID);
    if (index - 1 < 0) return bayIDs[index + 1];
    return bayIDs[index - 1];
  };

  // function to return a list of bay ids for a given tier
  const flattenBayIDsInTier = (tierID) => {
    const bayIDs = quotingDB
      .map((db) => db.tiers)
      .flat()
      .filter((tier) => tier.id === tierID)
      .map((tier) => tier.bays)
      .flat()
      .map((bay) => bay.id);
    return bayIDs;
  };

  const flattenTierIDsInDB = () => {
    const tierIDs = quotingDB
      .map((db) => db.tiers)
      .flat()
      .map((tier) => tier.id);
    return tierIDs;
  };

  const flattenDBIDsInQuote = () => {
    const dbIDs = quotingDB.map((db) => db.id);
    return dbIDs;
  };

  const flattenBayIDsInDB = (dbID) => {
    const bayIDs = quotingDB
      .filter((db) => db.id === dbID)
      .map((db) => db.tiers)
      .flat()
      .map((tier) => tier.bays)
      .flat()
      .map((bay) => bay.id);
    return bayIDs;
  };

  const getPreviousBayIDExcludingDeletedTier = (tierID) => {
    const deletedTierBayIDs = flattenBayIDsInTier(tierID);
    const allBayIDs = flattenBayIDs();
    const startingDeleteIndex = allBayIDs.indexOf(deletedTierBayIDs[0]);
    const endingDeleteIndex = allBayIDs.indexOf(
      deletedTierBayIDs[deletedTierBayIDs.length - 1]
    );
    if (startingDeleteIndex === 0) return allBayIDs[endingDeleteIndex + 1];
    return allBayIDs[startingDeleteIndex - 1];
  };

  const getPreviousBayIDExcludingDeletedDB = (dbID) => {
    const deletedDBBayIDs = flattenBayIDsInDB(dbID);
    const allBayIDs = flattenBayIDs();
    const startingDeleteIndex = allBayIDs.indexOf(deletedDBBayIDs[0]);
    const endingDeleteIndex = allBayIDs.indexOf(
      deletedDBBayIDs[deletedDBBayIDs.length - 1]
    );
    if (startingDeleteIndex === 0) return allBayIDs[endingDeleteIndex + 1];
    return allBayIDs[startingDeleteIndex - 1];
  };

  // function to update the currentdb, currenttier, and currentid from a bayid
  const updateCurrentIDsFromBayID = (bayID) => {
    const dbID = Math.floor(bayID);
    const tierID = Math.floor((bayID - dbID) * 10) / 10;
    const newTierID = dbID + tierID;
    const newID = bayID;
    updateCurrentIDs(dbID, newTierID, newID);

    // set the visibility of the db, tier, and bay to true only for the current db, tier, and bay
    const newQuotingDB = quotingDB.map((db) => {
      if (db.id === dbID) {
        return {
          ...db,
          isVisible: true,
          tiers: db.tiers.map((tier) => {
            if (tier.id === newTierID) {
              return {
                ...tier,
                isVisible: true,
                bays: tier.bays.map((bay) => {
                  if (bay.id === newID) {
                    return {
                      ...bay,
                      isVisible: true,
                    };
                  }
                  return {
                    ...bay,
                    isVisible: false,
                  };
                }),
              };
            }
            return {
              ...tier,
              isVisible: false,
            };
          }),
        };
      } else {
        return {
          ...db,
          isVisible: false,
          tiers: db.tiers.map((tier) => {
            return {
              ...tier,
              isVisible: false,
              bays: tier.bays.map((bay) => {
                return {
                  ...bay,
                  isVisible: false,
                };
              }),
            };
          }),
        };
      }
    });
    setQuotingDB(newQuotingDB);
  };

  /**
   * Adds a new tier to the DB.
   * @param {number} dbID - The ID of the DB.
   */
  const addNewTier = (dbID) => {
    // get the number of tiers in the DB to determine the tier number
    const db = quotingDB.find((db) => db.id === dbID);
    let tierOptions = [1, 2, 3];
    db.tiers.forEach((tier) => {
      const tierNumber = Number(tier.name.slice(-1));
      tierOptions = tierOptions.filter((option) => option !== tierNumber);
    });
    const tierNumber = tierOptions[0];
    const newTierID = Math.round((dbID + tierNumber / 10) * 10) / 10;

    const newTier = {
      id: newTierID,
      name: `Tier ${tierNumber}`,
      isVisible: true,
      bays: [
        {
          id: Math.round((newTierID + 0.01) * 100) / 100,
          name: `Bay 1`,
          isVisible: true,
          assemblies: [],
          chassis: { chassis1: null, chassis2: null },
        },
      ],
    };
    const newQuotingDB = quotingDB.map((db) => {
      if (db.id === dbID) {
        return {
          ...db,
          tiers: [...db.tiers, newTier],
        };
      }
      return db;
    });
    setQuotingDB(newQuotingDB);
  };

  /**
   * Adds a new bay to the tier.
   * @param {number} dbID - The ID of the DB.
   * @param {number} tierID - The ID of the tier.
   */
  const addNewBay = (dbID, tierID) => {
    // get the number of bays in the tier to determine the bay number
    const tier = quotingDB
      .find((db) => db.id === dbID)
      .tiers.find((tier) => tier.id === tierID);

    // if the tier has 1 bay and the existing bay name is 'Bay 1', then the new bay name is 'Bay 2' else the new bay name is 'Bay 1'
    const existingBayName = tier.bays[0].name;
    const bayNumber = existingBayName === "Bay 1" ? 2 : 1;
    const newBayID = Math.round((tierID + bayNumber / 100) * 100) / 100;

    const newBay = {
      id: newBayID,
      name: `Bay ${bayNumber}`,
      isVisible: false,
      assemblies: [],
      chassis: { chassis1: null, chassis2: null },
    };
    const newQuotingDB = quotingDB.map((db) => {
      if (db.id === dbID) {
        return {
          ...db,
          tiers: db.tiers.map((tier) => {
            if (tier.id === tierID) {
              return {
                ...tier,
                bays: [...tier.bays, newBay],
              };
            }
            return tier;
          }),
        };
      }
      return db;
    });
    setQuotingDB(newQuotingDB);
  };

  /**
   * Deletes a DB from the quote.
   * @param {number} dbID - The ID of the DB to delete.
   */
  const deleteDB = (dbID) => {
    if (flattenBayIDs().length > 1) {
      updateCurrentIDsFromBayID(getPreviousBayIDExcludingDeletedDB(dbID));
      let orderingID = 0;
      const newQuotingDB = quotingDB
        .filter((db) => db.id !== dbID)
        .map((db) => {
          orderingID += 1;
          return {
            ...db,
            id: orderingID,
            tiers: db.tiers.map((tier) => ({
              ...tier,
              id: orderingID + Math.round((tier.id % 1) * 10) / 10,
              bays: tier.bays.map((bay) => ({
                ...bay,
                id: orderingID + Math.round((bay.id % 1) * 100) / 100,
              })),
            })),
          };
        });
      setQuotingDB(newQuotingDB);
      updateCurrentIDs(1, 1.1, 1.11);
      orderingID = 0;
      const newAllAddCostings = allAddCostings
        .filter((db) => db.id !== dbID)
        .map((db) => {
          orderingID += 1;
          return { ...db, id: orderingID };
        });
      setAllAddCostings(newAllAddCostings);
    }
  };

  /**
   * Deletes a tier from the DB.
   * @param {number} dbID - The ID of the DB.
   * @param {number} tierID - The ID of the tier to delete.
   */
  const deleteTier = (dbID, tierID) => {
    // if the db has 1 tier, then delete the db
    const db = quotingDB.find((db) => db.id === dbID);

    if (db.tiers.length === 1) {
      deleteDB(dbID);
    } else {
      const newQuotingDB = quotingDB.map((db) => {
        if (db.id === dbID) {
          return {
            ...db,
            tiers: db.tiers.filter((tier) => tier.id !== tierID),
          };
        }
        return db;
      });

      if (tierID === currentTier) {
        const newBayID = getPreviousBayIDExcludingDeletedTier(currentTier);
        const dbID = Math.floor(newBayID);
        const tierID = Math.floor((newBayID - dbID) * 10) / 10;
        const newTierID = dbID + tierID;
        const newID = newBayID;
        updateCurrentIDs(dbID, newTierID, newID);

        // set the visibility of the db, tier, and bay to true only for the current db, tier, and bay
        const updatedQuotingDB = newQuotingDB.map((db) => {
          if (db.id === dbID) {
            return {
              ...db,
              isVisible: true,
              tiers: db.tiers.map((tier) => {
                if (tier.id === newTierID) {
                  return {
                    ...tier,
                    isVisible: true,
                    bays: tier.bays.map((bay) => {
                      if (bay.id === newID) {
                        return {
                          ...bay,
                          isVisible: true,
                        };
                      }
                      return {
                        ...bay,
                        isVisible: false,
                      };
                    }),
                  };
                }
                return {
                  ...tier,
                  isVisible: false,
                };
              }),
            };
          } else {
            return {
              ...db,
              isVisible: false,
              tiers: db.tiers.map((tier) => {
                return {
                  ...tier,
                  isVisible: false,
                  bays: tier.bays.map((bay) => {
                    return {
                      ...bay,
                      isVisible: false,
                    };
                  }),
                };
              }),
            };
          }
        });
        setQuotingDB(updatedQuotingDB);
      } else {
        setQuotingDB(newQuotingDB);
      }
    }
  };

  /**
   * Deletes a bay from the tier.
   * @param {number} dbID - The ID of the DB.
   * @param {number} tierID - The ID of the tier.
   * @param {number} bayID - The ID of the bay to delete.
   */
  const deleteBay = (dbID, tierID, bayID) => {
    const tier = quotingDB
      .find((db) => db.id === dbID)
      .tiers.find((tier) => tier.id === tierID);

    if (tier.bays.length === 1) {
      deleteTier(dbID, tierID);
    } else {
      const newQuotingDB = quotingDB.map((db) => {
        if (db.id === dbID) {
          return {
            ...db,
            tiers: db.tiers.map((tier) => {
              if (tier.id === tierID) {
                return {
                  ...tier,
                  bays: tier.bays.filter((bay) => bay.id !== bayID),
                };
              }
              return tier;
            }),
          };
        }
        return db;
      });

      if (bayID === currentID) {
        const newBayID = getPreviousBayID(currentID);
        const dbID = Math.floor(newBayID);
        const tierID = Math.floor((newBayID - dbID) * 10) / 10;
        const newTierID = dbID + tierID;
        const newID = newBayID;
        updateCurrentIDs(dbID, newTierID, newID);

        // set the visibility of the db, tier, and bay to true only for the current db, tier, and bay

        const updatedQuotingDB = newQuotingDB.map((db) => {
          if (db.id === dbID) {
            return {
              ...db,
              isVisible: true,
              tiers: db.tiers.map((tier) => {
                if (tier.id === newTierID) {
                  return {
                    ...tier,
                    isVisible: true,
                    bays: tier.bays.map((bay) => {
                      if (bay.id === newID) {
                        return {
                          ...bay,
                          isVisible: true,
                        };
                      }
                      return {
                        ...bay,
                        isVisible: false,
                      };
                    }),
                  };
                }
                return {
                  ...tier,
                  isVisible: false,
                };
              }),
            };
          } else {
            return {
              ...db,
              isVisible: false,
              tiers: db.tiers.map((tier) => {
                return {
                  ...tier,
                  isVisible: false,
                  bays: tier.bays.map((bay) => {
                    return {
                      ...bay,
                      isVisible: false,
                    };
                  }),
                };
              }),
            };
          }
        });
        setQuotingDB(updatedQuotingDB);
      } else {
        setQuotingDB(newQuotingDB);
      }
    }
  };

  // function to set isVisible to true for the quotingDb dbs, tiers and bay from an array of strings corresponding to the db, tier, and bay ids. else set isVisible to false
  const toggleTreeMenu = (ids) => {
    const newQuotingDB = quotingDB.map((db) => {
      if (ids.includes(db.id.toString())) {
        return {
          ...db,
          isVisible: true,
          tiers: db.tiers.map((tier) => {
            if (ids.includes(tier.id.toString())) {
              return {
                ...tier,
                isVisible: true,
                bays: tier.bays.map((bay) => {
                  if (ids.includes(bay.id.toString())) {
                    return {
                      ...bay,
                      isVisible: true,
                    };
                  }
                  return {
                    ...bay,
                    isVisible: false,
                  };
                }),
              };
            }
            return {
              ...tier,
              isVisible: false,
            };
          }),
        };
      } else {
        return {
          ...db,
          isVisible: false,
          tiers: db.tiers.map((tier) => {
            return {
              ...tier,
              isVisible: false,
              bays: tier.bays.map((bay) => {
                return {
                  ...bay,
                  isVisible: false,
                };
              }),
            };
          }),
        };
      }
    });
    setQuotingDB(newQuotingDB);
  };

  // function to change the name of the DB
  const changeDBName = (dbID, newName) => {
    const newQuotingDB = quotingDB.map((db) => {
      if (db.id === dbID) {
        return {
          ...db,
          name: newName,
        };
      }
      return db;
    });
    setQuotingDB(newQuotingDB);
  };

  // function to count the number of DB's with the same name
  const countDBsWithSameName = (name) => {
    const dbsWithSameName = quotingDB.filter(
      (db) => db.name.replace(/\s*\([^)]*\)\s*/g, "").trim() === name.trim()
    );
    if (dbsWithSameName.length === 0) {
      return 0;
    }
    const sortedSameDB = dbsWithSameName.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    let currentCount = 1;
    let prevDBName = "";

    for (let db of sortedSameDB) {
      const dbNameSuffix = parseInt(db.name.slice(-2, -1));
      if (isNaN(dbNameSuffix)) {
        // check if orginal name exists and save it
        prevDBName = "og found";
        currentCount = currentCount + 1;
        continue;
      } else if (prevDBName !== "") {
        // orginal name exists, keep checking until a spare number is available
        if (currentCount === dbNameSuffix) {
          currentCount = currentCount + 1;
          continue;
        } else {
          break;
        }
      } else {
        // if orginal name does not exist, use it as the new copy name
        currentCount = 0;
        break;
      }
    }
    return currentCount;
  };

  // duplicate db
  const duplicateDBNew = (dbID) => {
    // find the allAddCostings item with id = dbID
    const allAddCostingsItem = allAddCostings.find((item) => item.id === dbID);

    const foundDB = quotingDB.find((db) => db.id === dbID);
    const db = { ...foundDB };
    const newID = quotingDB[quotingDB.length - 1].id + 1;
    const currentCount = countDBsWithSameName(
      db.name.replace(/\s*\([^)]*\)\s*/g, "")
    );
    const newDB = {
      ...db,
      id: newID,
      name: `${db.name.replace(/\s*\([^)]*\)\s*/g, "")}${
        currentCount === 0 ? "" : `(${currentCount})`
      }`,
      isVisible: true,
      quantity: db.hasOwnProperty("quantity") ? db.quantity : 1,
      tiers: db.tiers.map((tier) => {
        const newTierID = newID + Number(tier.name.slice(-1)) / 10;
        return {
          ...tier,
          id: Math.round(newTierID * 10) / 10,
          name: tier.name,
          isVisible: true,
          bays: tier.bays.map((bay) => {
            const newCopiedBayID = newTierID + Number(bay.name.slice(-1)) / 100;
            return {
              ...bay,
              chassis: {
                ...bay.chassis,
                chassis1:
                  bay.chassis.chassis1 !== null
                    ? {
                        ...bay.chassis.chassis1,
                        poleConfig: bay.chassis.chassis1.poleConfig.map(
                          (pole) => {
                            return { id: pole.id, breaker: pole.breaker };
                          }
                        ),
                      }
                    : null,
                chassis2:
                  bay.chassis.chassis2 !== null
                    ? {
                        ...bay.chassis.chassis2,
                        poleConfig: bay.chassis.chassis2.poleConfig.map(
                          (pole) => {
                            return { id: pole.id, breaker: pole.breaker };
                          }
                        ),
                      }
                    : null,
              },
              id: Math.round(newCopiedBayID * 100) / 100,
              name: bay.name,
              isVisible: true,
            };
          }),
        };
      }),
    };
    setQuotingDB([...quotingDB, newDB]);

    setAllAddCostings([
      ...allAddCostings,
      {
        id: newID,
        unitPrice: {
          labour: allAddCostingsItem.unitPrice.labour,
          markup: allAddCostingsItem.unitPrice.markup,
          labels: allAddCostingsItem.unitPrice.labels,
          drawings: allAddCostingsItem.unitPrice.drawings,
          freight: allAddCostingsItem.unitPrice.freight,
        },
        quantity: {
          labels: allAddCostingsItem.quantity.labels,
          drawings: allAddCostingsItem.quantity.drawings,
          freight: allAddCostingsItem.quantity.freight,
        },
      },
    ]);
  };

  // function to add assemblies to the bay using the bayID and the assemblies
  const addAssembliesToBay = useCallback((bayID, newAssemblies) => {
    setQuotingDB((prevQuotingDB) => {
      return prevQuotingDB.map((db) => {
        return {
          ...db,
          tiers: db.tiers.map((tier) => {
            return {
              ...tier,
              bays: tier.bays.map((bay) => {
                if (bay.id === bayID) {
                  const updatedAssemblies = newAssemblies.map((assembly) => {
                    const newAssembly = bay.assemblies.find(
                      (newAsm) =>
                        newAsm.assembly_name === assembly.assembly_name
                    );
                    return newAssembly
                      ? { ...newAssembly, ...assembly }
                      : assembly;
                  });
                  return {
                    ...bay,
                    assemblies: updatedAssemblies,
                  };
                }
                return bay;
              }),
            };
          }),
        };
      });
    });
  }, []);

  // function to create an array of pole objects with length = number of poles
  const generateInitialPoleConfiguration = (chassisPole) => {
    const poleConfig = [];
    for (let i = 0; i < chassisPole; i++) {
      poleConfig.push({
        id: i + 1,
        breaker: null,
      });
    }
    return poleConfig;
  };

  // function to initialize chassis1 part number of the bay using the bayID
  const updateChassis1Part = (bayID, chassis1Part) => {
    const { numberOfPoles, isHybridChassis, type } =
      getChassisSpecsFromPartNumber(chassis1Part);
    const newQuotingDB = quotingDB.map((db) => {
      return {
        ...db,
        tiers: db.tiers.map((tier) => {
          return {
            ...tier,
            bays: tier.bays.map((bay) => {
              if (bay.id === bayID) {
                return {
                  ...bay,
                  chassis: {
                    ...bay.chassis,
                    chassis1: {
                      ...bay.chassis.chassis1,
                      partNumber: chassis1Part,
                      poles: numberOfPoles,
                      spareCount: 20,
                      circuitBreakers: [],
                      isHybridChassis: isHybridChassis,
                      type: type,
                      poleConfig:
                        generateInitialPoleConfiguration(numberOfPoles),
                    },
                  },
                };
              }
              return bay;
            }),
          };
        }),
      };
    });
    setQuotingDB(newQuotingDB);
  };

  // function to initialize chassis2 part number of the bay using the bayID
  const updateChassis2Part = (bayID, chassis2Part) => {
    const { numberOfPoles, isHybridChassis, type } =
      getChassisSpecsFromPartNumber(chassis2Part);
    const newQuotingDB = quotingDB.map((db) => {
      return {
        ...db,
        tiers: db.tiers.map((tier) => {
          return {
            ...tier,
            bays: tier.bays.map((bay) => {
              if (bay.id === bayID) {
                return {
                  ...bay,
                  chassis: {
                    ...bay.chassis,
                    chassis2: {
                      ...bay.chassis.chassis2,
                      partNumber: chassis2Part,
                      poles: numberOfPoles,
                      spareCount: 20,
                      circuitBreakers: [],
                      isHybridChassis: isHybridChassis,
                      type: type,
                      poleConfig:
                        generateInitialPoleConfiguration(numberOfPoles),
                    },
                  },
                };
              }
              return bay;
            }),
          };
        }),
      };
    });
    setQuotingDB(newQuotingDB);
  };

  // function to update chassis1 and chassis2 partNumbers of the bay using the bayID
  const updateBothChassisParts = (bayID, chassis1Part, chassis2Part) => {
    const { numberOfPoles, isHybridChassis, type } = chassis1Part
      ? getChassisSpecsFromPartNumber(chassis1Part)
      : { numberOfPoles: null, isHybridChassis: null, type: null };
    const {
      numberOfPoles: numberOfPoles2,
      isHybridChassis: isHybridChassis2,
      type: type2,
    } = chassis2Part
      ? getChassisSpecsFromPartNumber(chassis2Part)
      : { numberOfPoles: null, isHybridChassis: null, type: null };

    const newQuotingDB = quotingDB.map((db) => {
      return {
        ...db,
        tiers: db.tiers.map((tier) => {
          return {
            ...tier,
            bays: tier.bays.map((bay) => {
              if (bay.id === bayID) {
                return {
                  ...bay,
                  chassis: {
                    chassis1: chassis1Part
                      ? {
                          ...bay.chassis.chassis1,
                          partNumber: chassis1Part,
                          poles: numberOfPoles,
                          spareCount: 20,
                          circuitBreakers: [],
                          isHybridChassis: isHybridChassis,
                          type: type,
                          poleConfig:
                            generateInitialPoleConfiguration(numberOfPoles),
                        }
                      : null,
                    chassis2: chassis2Part
                      ? {
                          ...bay.chassis.chassis2,
                          partNumber: chassis2Part,
                          poles: numberOfPoles2,
                          spareCount: 20,
                          circuitBreakers: [],
                          isHybridChassis: isHybridChassis2,
                          type: type2,
                          poleConfig:
                            generateInitialPoleConfiguration(numberOfPoles2),
                        }
                      : null,
                  },
                };
              }
              return bay;
            }),
          };
        }),
      };
    });
    setQuotingDB(newQuotingDB);
  };

  // removes all chassis configurations
  const removeChassis = (bayID) => {
    const newQuotingDB = quotingDB.map((db) => {
      return {
        ...db,
        tiers: db.tiers.map((tier) => {
          return {
            ...tier,
            bays: tier.bays.map((bay) => {
              if (bay.id === bayID) {
                return {
                  ...bay,
                  chassis: {
                    chassis1: null,
                    chassis2: null,
                  },
                };
              }
              return bay;
            }),
          };
        }),
      };
    });
    setQuotingDB(newQuotingDB);
  };

  // function to add an array of circuit breakers to the chassis
  const addCircuitBreakersToChassis = useCallback(
    (bayID, chassisNumber, circuitBreakers, newPoleConfig) => {
      const newQuotingDB = quotingDB.map((db) => {
        return {
          ...db,
          tiers: db.tiers.map((tier) => {
            return {
              ...tier,
              bays: tier.bays.map((bay) => {
                if (bay.id === bayID) {
                  return {
                    ...bay,
                    chassis: {
                      ...bay.chassis,
                      [chassisNumber]: {
                        ...bay.chassis[chassisNumber],
                        circuitBreakers: circuitBreakers
                          ? circuitBreakers
                          : bay.chassis[chassisNumber].circuitBreakers,
                        poleConfig: newPoleConfig
                          ? newPoleConfig
                          : bay.chassis[chassisNumber].poleConfig,
                      },
                    },
                  };
                }
                return bay;
              }),
            };
          }),
        };
      });
      setQuotingDB(newQuotingDB);
    },
    [quotingDB]
  );

  // function to return the names of the current db, tier and bay
  const getCurrentDBTierBayNames = () => {
    const db = quotingDB.find((db) => db.id === currentDB);
    const tier = db.tiers.find((tier) => tier.id === currentTier);
    const bay = tier.bays.find((bay) => bay.id === currentID);
    return { db: db.name, tier: tier.name, bay: bay.name };
  };

  // update client details
  const updateClient = useCallback((client) => {
    setCurrentClient(client);
  }, []);

  //update project details
  const updateProject = useCallback((project) => {
    setCurrentProject(project);
  }, []);

  // update titleList
  const updateTitleList = (titleList) => {
    setTitleList(titleList);
  };

  const updateCurrentQuoteNum = (quoteNumber) => {
    setCurrentQuoteNum(quoteNumber);
  };

  const getNewCurrentID = (quotingDB) => {
    const db = quotingDB[0];
    const tier = db.tiers[0];
    const bay = tier.bays[0];
    return bay.id;
  };

  const getNewCurrentDB = (quotingDB) => {
    const db = quotingDB[0];
    return db.id;
  };

  const getNewCurrentTier = (quotingDB) => {
    const db = quotingDB[0];
    const tier = db.tiers[0];
    return tier.id;
  };

  // function to overwrite the current context with new values from a loaded quote
  const overwriteContext = useCallback((newContext) => {
    // eliminate precision error for id (tier.id & bay.id)
    if (newContext.quotingDB[0].hasOwnProperty("quantity")) {
      const newQuotingDB = newContext.quotingDB.map((db) => {
        return {
          ...db,
          isVisible: true,
          tiers: db.tiers.map((tier) => {
            return {
              ...tier,
              isVisible: true,
              id: Math.round(tier.id * 10) / 10,
              bays: tier.bays.map((bay) => {
                return { ...bay, id: Math.round(bay.id * 100) / 100 };
              }),
            };
          }),
        };
      });
      setQuotingDB(newQuotingDB);
    } else {
      // for loading old quotes:
      // did not have the quantity property
      const newQuotingDB = newContext.quotingDB.map((db) => {
        return {
          ...db,
          isVisible: true,
          quantity: 1,
          tiers: db.tiers.map((tier) => {
            return {
              ...tier,
              isVisible: true,
              id: Math.round(tier.id * 10) / 10,
              bays: tier.bays.map((bay) => {
                return { ...bay, id: Math.round(bay.id * 100) / 100 };
              }),
            };
          }),
        };
      });
      setQuotingDB(newQuotingDB);
    }
    setCurrentID(Math.round(getNewCurrentID(newContext.quotingDB) * 100) / 100);
    setCurrentDB(getNewCurrentDB(newContext.quotingDB));
    setCurrentTier(getNewCurrentTier(newContext.quotingDB));
    setTitleList(newContext.tc_list);
    const loadedQuotingClient = {
      deb_acc: newContext.customers[0].deb_acc,
      businessName: newContext.customers[0].Name,
      city: newContext.customers[0].City,
      state: newContext.customers[0].State,
      customerName: newContext.customerName,
      contactName: newContext.estimator.Name,
      contactNumber: newContext.estimator.OfficePhone,
      contactEmail: newContext.estimator.APSEmail,
      directEmail: newContext.estimator.DirectEmail,
      mobilePhone: newContext.estimator.MobilePhone,
      discountGroup: "None",
      specialPriceSchedule: "None",
    };
    setCurrentClient(loadedQuotingClient);
    const loadedProjectDetails = {
      projectName: newContext.projectName,
      subProjectName: newContext.project_description,
      crmOpp: newContext.crm,
    };
    setCurrentProject(loadedProjectDetails);
    setAllAddCostings(newContext.additional_costs);
    setCurrentQuoteNum(newContext.quoteNumber);
    setRevisionVersion(newContext.revision);
    setOGRevisionVersion(newContext.revision);
    setApprovalStatus(newContext.approvalStatus);
    setQuoteID(newContext.id);
    if (newContext.line_items !== null) {
      setLineItems(newContext.line_items);
    } else {
      setLineItems([]);
    }
    setQuoteCreator(newContext.user.name);
  }, []);

  // udpate additional costings for DB's
  const updateAllAddCostings = (costings) => {
    setAllAddCostings(costings);
  };

  const updateClientProject = (values) => {
    setCurrentClient(values.client);
    setCurrentProject(values.project);
  };

  useEffect(() => {
    if (currentProject.crmOpp === "" || currentQuoteNum === "") {
      updateCurrentQuoteNum(`APS-${idGenCap(4) + idGenNum(4)}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject.crmOpp]);

  const resetTCList = async () => {
    if (fetchedDefaultTCList.length === 0) {
      const fetchedNewTCList = await crudsService.getDefaultTCList();
      if (fetchedNewTCList.data) {
        const newTCList = fetchedNewTCList.data;
        const foundObject = newTCList.find(
          (title) =>
            title.title ===
            "This Quotation was prepared based on the following information"
        );
        const foundObject2 = newTCList.find(
          (title) => title.title === "Exclusions & Departures"
        );
        const formattedTCList = [
          foundObject,
          ...newTCList.filter(
            (item) =>
              item.title !== foundObject.title &&
              item.title !== foundObject2.title
          ),
          foundObject2,
        ];
        setTitleList(formattedTCList);
        setFetchedDefaultTCList(formattedTCList);
        return formattedTCList;
      }
      return [];
    } else {
      return fetchedDefaultTCList;
    }
  };

  // function to reset all context values back to initial state
  const resetContext = async () => {
    if (quotingDB !== exampleQuotingDBs) setQuotingDB(exampleQuotingDBs);
    if (currentID !== 1.11) setCurrentID(Math.round(1.11 * 100) / 100);
    if (currentDB !== 1) setCurrentDB(1);
    if (currentTier !== 1.1) setCurrentTier(1.1);
    if (currentClient !== currentQuotingClient)
      setCurrentClient(currentQuotingClient);
    if (currentProject !== currentProjectDetails)
      setCurrentProject(currentProjectDetails);
    if (allAddCostings !== additionalCostings)
      setAllAddCostings(additionalCostings);
    if (currentQuoteNum !== "") setCurrentQuoteNum("");
    if (revisionVersion !== "A") setRevisionVersion("A");
    if (approvalStatus !== "draft") setApprovalStatus("draft");
    if (quoteID !== "") setQuoteID("");

    if (fetchedDefaultTCList.length === 0) {
      const tcList = await resetTCList();
      setTitleList(tcList);
    } else {
      setTitleList(fetchedDefaultTCList);
    }
    if (lineItems.length !== 0) {
      setLineItems([]);
    }
    if (quoteCreator !== "") {
      setQuoteCreator("");
    }
  };

  // src\examples\Sidenav\index.js in handleNavAway
  // when nav to new quote, reset to the first active step of the quote wizard.
  const updateResetQuoteStep = () => {
    setResetQuoteStep(!resetQuoteStep);
  };

  // function to change to chassis2 as the currentChassis
  const swapChassis = useCallback(() => {
    if (currentChassis === "chassis1") {
      setCurrentChassis("chassis2");
    } else {
      setCurrentChassis("chassis1");
    }
  }, [currentChassis]);

  const changeCurrentChassis = useCallback((chassis) => {
    setCurrentChassis(chassis);
  }, []);

  // ------------------------------------------------------------------

  // function to return the chassis of the bay using the bayID
  const getChassisList = useCallback(
    (bayID) => {
      for (let db of quotingDB) {
        for (let tier of db.tiers) {
          for (let bay of tier.bays) {
            if (bay.id === bayID) {
              return bay.chassis;
            }
          }
        }
      }
      return null; // return null if no matching bay is found
    },
    [quotingDB]
  );

  // chassisList for the chassis of the current bay
  const [chassisList, setChassisList] = useState(getChassisList(currentID));

  useEffect(() => {
    setChassisList(getChassisList(currentID));
  }, [currentID, getChassisList]);

  // function to get the current chassis
  const getCurrentChassis = useCallback(() => {
    // circuitBreakers contains the details and quantities of each breaker on the chassis
    // poleConfig contains the location of each breaker in an array
    /*  {
          id = int (position of breaker),
          breaker = {
            id: breakerID,
            type: "single",
            funcText: breakerText.funcText,
            other: breakerText.other,
          };  
        } one cell of the poleConfig array */
    if (chassisList[currentChassis] === null) {
      return { poleConfig: [], circuitBreakers: [] };
    } else {
      return chassisList[currentChassis];
    }
  }, [chassisList, currentChassis]);

  // function to update the current chassis pole configuration
  const updateCurrentChassisPoleConfig = useCallback(
    (newPoleConfig) => {
      const chassisNumber =
        currentChassis === "chassis1" ? "chassis1" : "chassis2";
      addCircuitBreakersToChassis(
        currentID,
        chassisNumber,
        null,
        newPoleConfig
      );
    },
    [currentChassis, currentID, addCircuitBreakersToChassis]
  );

  // function to handle updating the current chassis pole configuration and the circuit breakers of the chassis when a circuit breaker is deleted
  const handleUpdateChassisPoleConfigAndCircuitBreakers = useCallback(
    (newPoleConfig, newCircuitBreakers) => {
      const chassisNumber =
        currentChassis === "chassis1" ? "chassis1" : "chassis2";
      addCircuitBreakersToChassis(
        currentID,
        chassisNumber,
        newCircuitBreakers,
        newPoleConfig
      );
    },
    [addCircuitBreakersToChassis, currentChassis, currentID]
  );

  const updateDefaultTCList = useCallback(
    (tcListData) => {
      if (titleList?.length > 0) {
        return;
      }
      setTitleList(tcListData);
    },
    [titleList?.length]
  );

  const updateLineItems = useCallback((lineItemsData) => {
    if (lineItemsData !== null) {
      setLineItems(lineItemsData);
    }
  }, []);

  // only used for updating pricing via apsTreeMenu.
  // Use other functions to update quotingDB if possible.
  const updateQuotingDB = (quotingDbData) => {
    setQuotingDB(quotingDbData);
  };

  const promptNotification = useCallback(
    (notification, type) => {
      setMessage(notification);
      setMessageType(type);
      setNotifyEvent(!notifyEvent);
    },
    [notifyEvent]
  );

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <ApsContext.Provider
      value={{
        quotingDB,
        updateQuotingDB,
        currentID,
        updateQtyDB,
        addNewDB,
        addNewTier,
        addNewBay,
        deleteDB,
        deleteTier,
        deleteBay,
        changeDBName,
        handleUpdateAssemblyNaming,
        assemblyNaming,
        duplicateDBNew,
        getChassisList,
        updateChassis1Part,
        updateChassis2Part,
        updateBothChassisParts,
        removeChassis,
        getChassisSpecsFromPartNumber,
        updateCurrentIDs,
        getCurrentDBTierBayNames,
        addCircuitBreakersToChassis,
        updateClient,
        updateProject,
        currentClient,
        currentProject,
        updateTitleList,
        titleList,
        idGen,
        currentUser,
        overwriteContext,
        updateAllAddCostings,
        allAddCostings,
        idGenCap,
        idGenNum,
        currentQuoteNum,
        updateCurrentQuoteNum,
        marginLimit,
        addAssembliesToBay,
        getAssembliesInBay,
        revisionVersion,
        ogRevisionVersion,
        updateRevision,
        approvalStatus,
        updateApprovalStatus,
        resetContext,
        quoteID,
        updateQuoteID,
        toggleTreeMenu,
        updateClientProject,
        userProfile,
        currentRole,
        chassisList,
        flattenTierIDsInDB,
        flattenDBIDsInQuote,
        flattenBayIDs,
        triggerChassisSave,
        savedChassisConfig,
        getCurrentChassis,
        currentChassis,
        updateCurrentChassisPoleConfig,
        swapChassis,
        updateDefaultTCList,
        handleUpdateChassisPoleConfigAndCircuitBreakers,
        changeCurrentChassis,
        lineItems,
        updateLineItems,
        promptNotification,
        message,
        messageType,
        notifyEvent,
        assemblyFilterCategories,
        partGroupItemsColumns,
        blankPart,
        quoteCreator,
        partCostLimit,
        partQtyLimit,
        partLabourLimit,
        resetTCList,
        resetQuoteStep,
        updateResetQuoteStep,
      }}
    >
      {children}
    </ApsContext.Provider>
  );
};

export { ApsContextProvider };
