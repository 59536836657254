import { useContext } from "react";
import { ApsContext } from "ApsContext";
import { useAPSDB } from "./useAPSDB";
import ExcelJS from "exceljs";
import apsLogo from "assets/images/aps/aps-logo-retina.png";

const defaultColTitleRowHeight = 25;
const defaultItemFontSize = 12;
const defaultFooterFontSize = 12;

const defaultBorderTitle = {
  top: { style: "thick" },
  left: { style: "thick" },
  right: { style: "thick" },
  bottom: { style: "thick" },
};

const marginUnit = "%";
// note: the value is in terms of days (/24) for the numFmt [h]:mm as it considers 1.00 as 24 hours
const hoursUnit = "hrs";
const costUnit = "$";

// control precision for rounding calcs for hours
const roundingFactorHours = 100000;

export function QuoteExports() {
  const {
    quotingDB,
    lineItems,
    currentClient,
    currentProject,
    titleList,
    revisionVersion,
    currentQuoteNum,
    allAddCostings,
    quoteCreator,
  } = useContext(ApsContext);
  const {
    getCurrentDate,
    partAssembler,
    handleDbPartsMapped,
    getDateValidity,
    handleLineItemCosts,
  } = useAPSDB();

  // for excel download
  // calculate width of content
  const calculateColumnWidth = (columnData, maxLengthWrap = 50) => {
    let maxLength = columnData.reduce((max, item) => {
      let length;
      if (item instanceof Date) {
        length = item.toLocaleDateString().length; // Format date to local date string
      } else if (item && typeof item === "number") {
        length = item.toString().length; // Numbers as strings
      } else {
        length = item ? item.toString().length : 0;
      }
      return Math.max(max, length);
    }, 0);
    if (maxLength > maxLengthWrap) {
      maxLength = maxLengthWrap;
    }
    return maxLength + 8; // Adding a little extra padding
  };

  // for excel download
  // adjust width of content
  const adjustColumnWidths = (worksheet, colTitleRow, maxLengthWrap) => {
    worksheet.columns.forEach((column) => {
      const columnData = column.values;
      const columnHeader =
        typeof columnData[colTitleRow] === "number"
          ? columnData[colTitleRow].toString()
          : columnData[colTitleRow]; // Column header is at colTitleRow
      const headerLength = columnHeader ? columnHeader.length * 1.2 : 0;
      const dataStart = colTitleRow + 1;
      const maxLength = Math.max(
        headerLength,
        calculateColumnWidth(columnData.slice(dataStart), maxLengthWrap)
      ); // Data starts from colTitleRow + 1
      column.width = maxLength;
    });
  };

  // worksheet exceljs
  // tableTitle tl cell of top row or title bar
  // tableSecondTitle tr cell of top row or title bar
  // typeStatus prelim or const
  // type (scope), "quote" or "db"
  // dbTitle used for type === "db", row DB Board Title
  // dbPartNumber used for type === "db", row DB Part No.
  const quoteInfoTableExcel = (
    worksheet,
    tableTitle,
    tableSecondTitle = "<job_no>",
    typeStatus = "",
    type = "quote",
    dbTitle = "",
    dbPartNumber = ""
  ) => {
    worksheet.addRow([null]); // empty row for spacing
    const titleTag =
      dbPartNumber !== null && dbPartNumber !== undefined && dbPartNumber !== ""
        ? `${dbPartNumber}-`
        : dbPartNumber;
    const titleRow = worksheet.addRow([
      null,
      null,
      tableTitle,
      `${titleTag}${tableSecondTitle}`,
    ]);
    titleRow.eachCell((cell) => {
      if (cell.value === tableTitle) {
        cell.font = {
          size: 14,
          bold: true,
          /* color: { argb: "FFFF0000" }, */
        };
        cell.border = {
          top: { style: "thick" },
          left: { style: "thick" },
          right: { style: "thin" },
          bottom: { style: "thick" },
        };
      } else {
        cell.font = {
          size: 14,
          color: { argb: "FF0070C0" },
        };
        cell.border = {
          top: { style: "thick" },
          left: { style: "thin" },
          right: { style: "thick" },
          bottom: { style: "thick" },
        };
      }
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFF00" },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
    });
    const customerName = `${currentClient.businessName}`;
    const customerRow = worksheet.addRow([
      null,
      null,
      "Customer",
      customerName,
    ]);
    customerRow.eachCell((cell) => {
      if (cell.value === customerName) {
        cell.font = { size: defaultItemFontSize, color: { argb: "FF0070C0" } };
      } else {
        cell.font = { size: defaultItemFontSize, bold: true };
      }
      if (cell.value === customerName) {
        cell.border = {
          left: { style: "thin" },
          right: { style: "thick" },
        };
      } else {
        cell.border = {
          left: { style: "thick" },
          right: { style: "thin" },
        };
      }
    });
    const projectName = `${currentProject.projectName}`;
    const projectNameRow = worksheet.addRow([
      null,
      null,
      "Project Name",
      projectName,
    ]);
    projectNameRow.eachCell((cell) => {
      if (cell.value === projectName) {
        cell.font = { size: defaultItemFontSize, color: { argb: "FF0070C0" } };
      } else {
        cell.font = { size: defaultItemFontSize, bold: true };
      }
      if (cell.value === projectName) {
        cell.border = {
          left: { style: "thin" },
          right: { style: "thick" },
        };
      } else {
        cell.border = {
          left: { style: "thick" },
          right: { style: "thin" },
        };
      }
    });
    if (type === "quote") {
      const bdmName = `${currentClient.contactName}`;
      const bdmRow = worksheet.addRow([null, null, "BDM", bdmName]);
      bdmRow.eachCell((cell) => {
        if (cell.value === bdmName) {
          cell.font = {
            size: defaultItemFontSize,
            color: { argb: "FF0070C0" },
          };
        } else {
          cell.font = { size: defaultItemFontSize, bold: true };
        }
        if (cell.value === bdmName) {
          cell.border = {
            left: { style: "thin" },
            right: { style: "thick" },
          };
        } else {
          cell.border = {
            left: { style: "thick" },
            right: { style: "thin" },
          };
        }
      });
      const jobNoRow = worksheet.addRow([
        null,
        null,
        "Job Number",
        tableSecondTitle,
      ]);
      jobNoRow.eachCell((cell) => {
        if (cell.value === tableSecondTitle) {
          cell.font = {
            size: defaultItemFontSize,
            color: { argb: "FF0070C0" },
          };
        } else {
          cell.font = { size: defaultItemFontSize, bold: true };
        }
        if (cell.value === tableSecondTitle) {
          cell.border = {
            left: { style: "thin" },
            right: { style: "thick" },
          };
        } else {
          cell.border = {
            left: { style: "thick" },
            right: { style: "thin" },
          };
        }
      });
    } else {
      const dbTitleRow = worksheet.addRow([
        null,
        null,
        "DB Board Title",
        dbTitle,
      ]);
      dbTitleRow.eachCell((cell) => {
        if (cell.value === dbTitle) {
          cell.font = {
            size: defaultItemFontSize,
            color: { argb: "FF0070C0" },
          };
        } else {
          cell.font = { size: defaultItemFontSize, bold: true };
        }
        if (cell.value === dbTitle) {
          cell.border = {
            left: { style: "thin" },
            right: { style: "thick" },
          };
        } else {
          cell.border = {
            left: { style: "thick" },
            right: { style: "thin" },
          };
        }
      });
      const dbNoRow = worksheet.addRow([
        null,
        null,
        "DB Part No.",
        tableSecondTitle,
      ]);
      dbNoRow.eachCell((cell) => {
        if (cell.value === tableSecondTitle) {
          cell.font = {
            size: defaultItemFontSize,
            color: { argb: "FF0070C0" },
          };
        } else {
          cell.font = { size: defaultItemFontSize, bold: true };
        }
        if (cell.value === tableSecondTitle) {
          cell.border = {
            left: { style: "thin" },
            right: { style: "thick" },
          };
        } else {
          cell.border = {
            left: { style: "thick" },
            right: { style: "thin" },
          };
        }
      });
    }
    const currentDate = getCurrentDate();
    const dateGenRow = worksheet.addRow([
      null,
      null,
      "Date Report Generated",
      currentDate,
    ]);
    dateGenRow.eachCell((cell) => {
      if (cell.value === currentDate) {
        cell.font = { size: defaultItemFontSize, color: { argb: "FF0070C0" } };
      } else {
        cell.font = { size: defaultItemFontSize, bold: true };
      }
      if (cell.value === currentDate) {
        cell.border = {
          left: { style: "thin" },
          right: { style: "thick" },
        };
      } else {
        cell.border = {
          left: { style: "thick" },
          right: { style: "thin" },
        };
      }
    });
    const statusInfo = `${
      typeStatus === "const" ? "FOR CONSTRUCTION" : "PRELIMINARY"
    }`;
    const mwoPhaseRow = worksheet.addRow([null, null, "MWO Phase", statusInfo]);
    mwoPhaseRow.eachCell((cell) => {
      if (cell.value === statusInfo) {
        cell.font = { size: defaultItemFontSize, color: { argb: "FF0070C0" } };
      } else {
        cell.font = { size: defaultItemFontSize, bold: true };
      }
      if (cell.value === statusInfo) {
        cell.border = {
          left: { style: "thin" },
          right: { style: "thick" },
          bottom: { style: "thick" },
        };
      } else {
        cell.border = {
          left: { style: "thick" },
          right: { style: "thin" },
          bottom: { style: "thick" },
        };
      }
    });
    const lastRow = worksheet.addRow([null]); // empty row for spacing
    return lastRow.number + 1;
  };

  const printLayout = (
    worksheet,
    orientation = "portrait",
    fitToWidth = 1,
    fitToHeight = 0,
    footer = true
  ) => {
    // Set up page layout options
    worksheet.pageSetup = {
      paperSize: 9, // A4 size
      orientation: orientation, // 'portrait' or 'landscape'
      fitToPage: true,
      fitToWidth: fitToWidth,
      fitToHeight: fitToHeight,
    };
    const currentDate = getCurrentDate();
    // Add headers and footers
    /* worksheet.headerFooter.oddHeader =
      '&L&"Arial,Bold"&16Left Header&C&"Arial,Bold"&16Center Header&R&"Arial,Bold"&16Right Header'; */
    if (footer) {
      worksheet.headerFooter.oddFooter = `&L&"Calibri"&${defaultFooterFontSize} @APS Industrial Pty Ltd |\nDocument ID : MAN-FRM-0002 Version 1.2&C&"Calibri"&${defaultFooterFontSize} ${worksheet.name} &R&"Calibri"&${defaultFooterFontSize} Printed: ${currentDate} Page &P of &N`;
    }
  };

  // function to produce esg data in excel
  const esgAutoGenExcelData = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("ESG Master Data");

    let tierID = "";
    let bayID = "";
    quotingDB.forEach((db) => {
      worksheet.addRow([db.name]);
      db.tiers.forEach((tier) => {
        tierID = Math.abs(Number(tier.id.toFixed(1)))
          .toString()
          .charAt(Number(tier.id.toFixed(1)).toString().length - 1);
        tier.bays.forEach((bay) => {
          bayID = Math.abs(Number(bay.id.toFixed(2)))
            .toString()
            .charAt(Number(bay.id.toFixed(2)).toString().length - 1);
          worksheet.addRow([`T${tierID}B${bayID}`]);
          bay.assemblies.forEach((assembly) => {
            assembly.parts.forEach((part) => {
              worksheet.addRow([
                part.partNumber,
                part.AssembliesRelation.quantity * assembly.quantity,
              ]);
            });
          });
          worksheet.addRow([""]);
          if (bay.chassis.chassis1) {
            worksheet.addRow([`T${tierID}B${bayID}C1`]);
            bay.chassis.chassis1.poleConfig.forEach((pole) => {
              if (pole.breaker) {
                if (
                  pole.breaker.type === "triple" &&
                  pole.breaker.phase !== 1
                ) {
                  worksheet.addRow(["", pole.id]);
                } else {
                  let breakerId = pole.breaker.id;
                  let index = breakerId.indexOf(
                    "-",
                    breakerId.indexOf("-") + 1
                  );
                  let partNumber = breakerId.substring(0, index);
                  worksheet.addRow([
                    partNumber,
                    pole.id,
                    pole.breaker.funcText,
                  ]);
                }
              } else {
                worksheet.addRow(["", pole.id]);
              }
            });
            worksheet.addRow([""]);
          }
          if (bay.chassis.chassis2) {
            worksheet.addRow([`T${tierID}B${bayID}C2`]);
            bay.chassis.chassis2.poleConfig.forEach((pole) => {
              if (pole.breaker) {
                if (
                  pole.breaker.type === "triple" &&
                  pole.breaker.phase !== 1
                ) {
                  worksheet.addRow(["", pole.id]);
                } else {
                  let breakerId = pole.breaker.id;
                  let index = breakerId.indexOf(
                    "-",
                    breakerId.indexOf("-") + 1
                  );
                  let partNumber = breakerId.substring(0, index);
                  worksheet.addRow([
                    partNumber,
                    pole.id,
                    pole.breaker.funcText,
                  ]);
                }
              } else {
                worksheet.addRow(["", pole.id]);
              }
            });
            worksheet.addRow([""]);
          }
        });
      });
    });
    adjustColumnWidths(worksheet, 1);
    workbook.xlsx
      .writeBuffer()
      .then((buffer) => {
        const blob = new Blob([buffer], { type: "application/octet-stream" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${currentClient.deb_acc} - ${currentProject.projectName} - ESG.xlsx`;
        link.click();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // all parts in a quote bom
  const quoteBOMExcel = (worksheet) => {
    // project table
    const tableTitle = "PROJECT BOM SUMMARY REPORT";
    const nextRowNo = quoteInfoTableExcel(worksheet, tableTitle);

    // allParts:
    // partNumber, description, quantity
    let allParts = [];
    quotingDB.forEach((db) => {
      db.tiers.forEach((tier) => {
        tier.bays.forEach((bay) => {
          bay.assemblies.forEach((assembly) => {
            assembly.parts.forEach((part) => {
              const foundPart = allParts.find(
                (findPart) => findPart.partNumber === part.partNumber
              );
              if (foundPart === undefined) {
                // no matching part (doesnt exist)
                allParts = [
                  ...allParts,
                  {
                    partNumber: part.partNumber,
                    description: part.description,
                    supplier: part.supplier,
                    quantity:
                      part.AssembliesRelation.quantity *
                      assembly.quantity *
                      db.quantity,
                    stock: part.stock ? part.stock : "",
                  },
                ];
              } else {
                // part already exists; add quantity
                allParts = allParts.map((matchingPart) => {
                  if (matchingPart.partNumber === foundPart.partNumber) {
                    return {
                      ...foundPart,
                      quantity:
                        foundPart.quantity +
                        part.AssembliesRelation.quantity *
                          assembly.quantity *
                          db.quantity,
                    };
                  } else {
                    return matchingPart;
                  }
                });
              }
            });
          });
          if (bay.chassis.chassis1) {
            bay.chassis.chassis1.circuitBreakers.forEach((breaker) => {
              const foundPart = allParts.find(
                (findPart) => findPart.partNumber === breaker.part.partNumber
              );
              if (foundPart === undefined) {
                // no matching part (doesnt exist)
                allParts = [
                  ...allParts,
                  {
                    partNumber: breaker.part.partNumber,
                    description: breaker.part.description,
                    supplier: breaker.part.supplier,
                    quantity: breaker.quantity * db.quantity,
                    stock: breaker.part.stock ? breaker.part.stock : "",
                  },
                ];
              } else {
                // part already exists; add quantity
                allParts = allParts.map((matchingPart) => {
                  if (matchingPart.partNumber === foundPart.partNumber) {
                    return {
                      ...foundPart,
                      quantity:
                        foundPart.quantity + breaker.quantity * db.quantity,
                    };
                  } else {
                    return matchingPart;
                  }
                });
              }
            });
          }
          if (bay.chassis.chassis2) {
            bay.chassis.chassis2.circuitBreakers.forEach((breaker) => {
              const foundPart = allParts.find(
                (findPart) => findPart.partNumber === breaker.part.partNumber
              );
              if (foundPart === undefined) {
                // no matching part (doesnt exist)
                allParts = [
                  ...allParts,
                  {
                    partNumber: breaker.part.partNumber,
                    description: breaker.part.description,
                    supplier: breaker.part.supplier,
                    quantity: breaker.quantity * db.quantity,
                    stock: breaker.part.stock ? breaker.part.stock : "",
                  },
                ];
              } else {
                // part already exists; add quantity
                allParts = allParts.map((matchingPart) => {
                  if (matchingPart.partNumber === foundPart.partNumber) {
                    return {
                      ...foundPart,
                      quantity:
                        foundPart.quantity + breaker.quantity * db.quantity,
                    };
                  } else {
                    return matchingPart;
                  }
                });
              }
            });
          }
        });
      });
    });

    if (lineItems.length > 0) {
      lineItems.forEach((item) => {
        const foundPart = allParts.find(
          (findPart) => findPart.partNumber === item.partNumber
        );
        if (foundPart === undefined) {
          // no matching part (doesnt exist)
          allParts = [
            ...allParts,
            {
              partNumber: item.partNumber,
              description: item.description,
              supplier: item.supplier,
              quantity: item.quantity,
              stock: "",
            },
          ];
        }
      });
    }

    allParts = allParts.sort((a, b) =>
      a.quantity < b.quantity ? 1 : a.quantity > b.quantity ? -1 : 0
    );

    const materialTitleCell = worksheet.getCell(`B${nextRowNo}:G${nextRowNo}`);
    worksheet.mergeCells(`B${nextRowNo}:G${nextRowNo}`);
    worksheet.getRow(nextRowNo).height = defaultColTitleRowHeight;
    materialTitleCell.value = "MATERIAL LIST";
    materialTitleCell.font = { size: defaultItemFontSize, bold: true };
    materialTitleCell.alignment = { vertical: "middle", horizontal: "center" };
    materialTitleCell.border = defaultBorderTitle;
    const bomColTitleRow = worksheet.addRow([
      null,
      "ITEM No.",
      "PART NUMBER",
      "DESCRIPTION",
      "SUPPLIER",
      "QTY",
      "STOCK",
    ]);
    bomColTitleRow.height = defaultColTitleRowHeight;
    bomColTitleRow.eachCell((cell) => {
      cell.font = { size: defaultItemFontSize, bold: true };
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.border = defaultBorderTitle;
    });
    let itemCount = 1;
    let rowInfo;
    allParts.forEach((part, index) => {
      rowInfo = worksheet.addRow([
        null,
        itemCount,
        part.partNumber,
        part.description,
        part.supplier,
        part.quantity,
        part.stock,
      ]);
      itemCount++;
      if (index === allParts.length - 1) {
        rowInfo.eachCell((cell) => {
          cell.border = {
            left: { style: "thick" },
            right: { style: "thick" },
            bottom: { style: "thick" },
          };
        });
      } else {
        rowInfo.eachCell((cell) => {
          cell.border = {
            left: { style: "thick" },
            right: { style: "thick" },
            bottom: { style: "thin" },
          };
        });
      }
    });

    worksheet.eachRow((row) => {
      row.eachCell((cell) => {
        cell.alignment = cell.alignment ? cell.alignment : { wrapText: true };
        cell.font = cell.font
          ? cell.font
          : {
              size: defaultItemFontSize,
            };
      });
    });

    adjustColumnWidths(worksheet, bomColTitleRow.number);

    printLayout(worksheet);
  };

  // function to return a excel file for all parts in the quote
  const bomExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(`PROJECT BOM SUMMARY REPORT`);
    quoteBOMExcel(worksheet);
    workbook.xlsx
      .writeBuffer()
      .then((buffer) => {
        const blob = new Blob([buffer], { type: "application/octet-stream" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${currentClient.deb_acc} - ${currentProject.projectName} - BOM.xlsx`;
        link.click();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // for hour formatting
  function decimalToHoursMinutes(decimal) {
    // Extract the hours from the decimal
    const hours = Math.floor(decimal);

    // Extract the minutes from the decimal and convert to minutes
    const minutes = Math.round((decimal - hours) * 60);

    // Format hours and minutes to be two digits
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");

    // Combine and return the result in HH:MM format
    return `${formattedHours}:${formattedMinutes}`;
  }

  // mwo
  // bdm
  // customer (businessName)
  // project name (projectName)
  // total bays config (four DB has total 2 bays each, 2 DB has total 4 bays each =>
  // becomes 2 bay quantity 4, and 4 bay quantity 2)
  // total
  const mwoMainExcelWorksheet = (worksheet, imageId, typeStatus) => {
    let quoteTotalLabour = 0;
    let allDB = [];
    let bayCountArray = [];
    let bayCount = 0;

    const mergedTitle = worksheet.getCell("A1:D4");
    worksheet.mergeCells("A1:D4");
    mergedTitle.value =
      typeStatus === "const" ? "FOR CONSTRUCTION" : "PRELIMINARY";
    mergedTitle.font = {
      size: 36,
      color: { argb: "FFFF0000" },
    };
    mergedTitle.alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    const mergedSubtitle = worksheet.getCell("A5:D5");
    worksheet.mergeCells("A5:D5");
    mergedSubtitle.value = "Work Order Request - Manufacturing";
    worksheet.getRow(5).height = 50;
    mergedSubtitle.font = {
      size: 28,
      color: { argb: "FFC9282D" },
    };
    mergedSubtitle.alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    const jobDetails = worksheet.addRow(["Job Details - FIMCEN"]);
    jobDetails.eachCell((cell) => {
      cell.font = { size: defaultItemFontSize, bold: true };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFDDDDDD" },
      };
    });
    worksheet.mergeCells("A6:D6");
    worksheet.addRow([
      "Project Number",
      "",
      "Business Developer Name",
      `${currentClient.contactName}`,
    ]);
    worksheet.addRow([
      "Estimation (Quote) ZOHO Number",
      `${currentProject.crmOpp}`,
      "FIMCEN Estimator Name",
      `${quoteCreator}`,
    ]);
    worksheet.addRow([
      "Drafting ZOHO Number",
      "",
      "FIMCEN Designer/Drafter Name",
      "",
    ]);
    worksheet.addRow(["Customer PO", "", "PO Received Date", ""]);
    worksheet.addRow([
      "Approved DWG received date",
      "",
      "Construction DWG Issue Date",
      "",
    ]);
    worksheet.addRow([
      "Free Issue Materials",
      "",
      "Requested Delivery Date",
      "",
    ]);
    const designRow = worksheet.addRow(["Design/Compliance"]);
    worksheet.mergeCells(`B${designRow.number}:D${designRow.number}`);
    const projectTitleRow = worksheet.addRow([
      "Project Title",
      `${currentProject.projectName}`,
    ]);
    worksheet.mergeCells(
      `B${projectTitleRow.number}:D${projectTitleRow.number}`
    );
    const customerRow = worksheet.addRow([
      "Customer",
      `${currentClient.businessName}`,
    ]);
    worksheet.mergeCells(`B${customerRow.number}:D${customerRow.number}`);
    worksheet.spliceRows(customerRow.number + 1, 0, []);
    const manufacturingRow = worksheet.addRow(["Manufacturing"]);
    manufacturingRow.eachCell((cell) => {
      cell.font = { size: defaultItemFontSize, bold: true };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFDDDDDD" },
      };
    });
    worksheet.mergeCells(
      `A${manufacturingRow.number}:D${manufacturingRow.number}`
    );
    worksheet.addRow(["Start Date", "", "Dispatch Date", ""]);
    worksheet.addRow(["Eng FAT Date", "", "Site Due Date", ""]);
    const custFATRow = worksheet.addRow(["Customer FAT Date"]);
    worksheet.mergeCells(`B${custFATRow.number}:D${custFATRow.number}`);
    const deliveryRow = worksheet.addRow(["Delivery Location"]);
    worksheet.mergeCells(`B${deliveryRow.number}:D${deliveryRow.number}`);
    worksheet.spliceRows(deliveryRow.number + 1, 0, []);
    const scopeRow = worksheet.addRow(["Overall scope of supply - FIMCEN"]);
    scopeRow.eachCell((cell) => {
      cell.font = { size: defaultItemFontSize, bold: true };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFDDDDDD" },
      };
    });
    worksheet.mergeCells(`A${scopeRow.number}:D${scopeRow.number}`);
    const bayTitleRow = worksheet.addRow(["Type", "Quantity", "Description"]);
    bayTitleRow.eachCell((cell) => {
      cell.font = { size: defaultItemFontSize, bold: true };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFDDDDDD" },
      };
    });
    worksheet.mergeCells(`C${bayTitleRow.number}:D${bayTitleRow.number}`);
    quotingDB.forEach((db) => {
      let totalLabour = 0;
      db.tiers.forEach((tier) => {
        tier.bays.forEach((bay) => {
          bayCount++;
          bay.assemblies.forEach((assembly) => {
            assembly.parts.forEach((part) => {
              const foundPart = allDB.find(
                (findPart) => findPart.partNumber === part.partNumber
              );
              if (foundPart === undefined) {
                // no matching part (doesnt exist)
                allDB = [
                  ...allDB,
                  {
                    ...partAssembler(part),
                    quantity:
                      part.AssembliesRelation.quantity *
                      assembly.quantity *
                      db.quantity,
                  },
                ];
              } else {
                // part already exists; add quantity
                allDB = allDB.map((matchingPart) => {
                  if (matchingPart.partNumber === foundPart.partNumber) {
                    return {
                      ...foundPart,
                      quantity:
                        foundPart.quantity +
                        part.AssembliesRelation.quantity *
                          assembly.quantity *
                          db.quantity,
                    };
                  } else {
                    return matchingPart;
                  }
                });
              }
            });
          });
          if (bay.chassis.chassis1) {
            bay.chassis.chassis1.circuitBreakers.forEach((breaker) => {
              const foundPart = allDB.find(
                (findPart) => findPart.partNumber === breaker.part.partNumber
              );
              if (foundPart === undefined) {
                // no matching part (doesnt exist)

                allDB = [
                  ...allDB,
                  {
                    ...partAssembler(breaker.part),
                    quantity: breaker.quantity * db.quantity,
                  },
                ];
              } else {
                // part already exists; add quantity
                allDB = allDB.map((matchingPart) => {
                  if (matchingPart.partNumber === foundPart.partNumber) {
                    return {
                      ...foundPart,
                      quantity:
                        foundPart.quantity + breaker.quantity * db.quantity,
                    };
                  } else {
                    return matchingPart;
                  }
                });
              }
            });
          }
          if (bay.chassis.chassis2) {
            bay.chassis.chassis2.circuitBreakers.forEach((breaker) => {
              const foundPart = allDB.find(
                (findPart) => findPart.partNumber === breaker.part.partNumber
              );
              if (foundPart === undefined) {
                // no matching part (doesnt exist)
                allDB = [
                  ...allDB,
                  {
                    ...partAssembler(breaker.part),
                    quantity: breaker.quantity * db.quantity,
                  },
                ];
              } else {
                // part already exists; add quantity
                allDB = allDB.map((matchingPart) => {
                  if (matchingPart.partNumber === foundPart.partNumber) {
                    return {
                      ...foundPart,
                      quantity:
                        foundPart.quantity + breaker.quantity * db.quantity,
                    };
                  } else {
                    return matchingPart;
                  }
                });
              }
            });
          }
        });
      });
      const foundBayCount = bayCountArray.find(
        (bayCountIndex) => bayCountIndex.bayType === bayCount
      );

      if (foundBayCount === undefined) {
        // add bay type if does not exist
        bayCountArray = [
          ...bayCountArray,
          { bayType: bayCount, quantity: db.quantity },
        ];
      } else {
        // exists, increase quantity of bay type
        const foundBayCountIndex = bayCountArray.findIndex(
          (bayCountIndex) => foundBayCount.bayType === bayCountIndex.bayType
        );
        bayCountArray[foundBayCountIndex] = {
          ...foundBayCount,
          quantity: foundBayCount.quantity + db.quantity,
        };
      }
      bayCount = 0;
      allDB.forEach((part) => {
        totalLabour += part.quantity * part.labour;
      });
      quoteTotalLabour += totalLabour;
      totalLabour = 0;
      allDB = [];
    });
    // bay type, quantity, desc lists
    bayCountArray.forEach((bayCountIndex) => {
      const bayRow = worksheet.addRow([
        `${bayCountIndex.bayType} Bay`,
        bayCountIndex.quantity,
      ]);
      bayRow.getCell(1).font = { size: defaultItemFontSize };
      worksheet.mergeCells(`C${bayRow.number}:D${bayRow.number}`);
    });
    const totalHoursRow = worksheet.addRow([
      "Total Hours",
      `${Math.round((quoteTotalLabour / 60 / 24) * 1000) / 1000}${hoursUnit}`,
    ]);
    totalHoursRow.eachCell((cell) => {
      cell.font = { size: defaultItemFontSize, bold: true };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFDDDDDD" },
      };
    });
    worksheet.spliceRows(totalHoursRow.number, 0, []);
    worksheet.spliceRows(totalHoursRow.number + 2, 0, []);
    const notesRow = worksheet.addRow(["Notes"]);
    notesRow.eachCell((cell) => {
      cell.font = { size: defaultItemFontSize, bold: true };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFDDDDDD" },
      };
    });
    worksheet.mergeCells(`A${notesRow.number}:D${notesRow.number}`);
    worksheet.mergeCells(`A${notesRow.number + 1}:D${notesRow.number + 4}`);
    const notesBlankCell = worksheet.getCell(
      `A${notesRow.number + 1}:D${notesRow.number + 4}`
    );
    notesBlankCell.alignment = {
      vertical: "top",
      horizontal: "left",
      wrapText: true,
    };
    adjustColumnWidths(worksheet, 10);

    worksheet.eachRow((row, rowNumber) => {
      row.height = row.height ? row.height : defaultColTitleRowHeight;
      row.eachCell((cell, colNumber) => {
        if (
          cell.value &&
          typeof cell.value === "string" &&
          cell.value.includes(hoursUnit)
        ) {
          cell.value = parseFloat(cell.value.split(hoursUnit));
          cell.numFmt = "[h]:mm";
        }
        if (
          (colNumber === 1 && cell.font === undefined) ||
          (colNumber === 3 &&
            cell.font === undefined &&
            cell.value !== null &&
            (rowNumber !== projectTitleRow || rowNumber !== customerRow))
        ) {
          cell.font = cell.font ? cell.font : { size: defaultItemFontSize };
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFDDDDDD" },
          };
        } else if (cell.font === undefined) {
          cell.font = { size: defaultItemFontSize };
        }
        if (cell.alignment === undefined) {
          cell.alignment = { vertical: "middle", wrapText: true };
        }
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
    });

    worksheet.addImage(imageId, {
      tl: { col: 0, row: 2 },
      ext: { width: 80, height: 65 },
    });

    printLayout(worksheet);

    return quoteTotalLabour;
  };

  // Summary for DB's
  const mwoSummaryExcelWorksheet = (
    worksheet,
    quoteTotalLabour,
    typeStatus
  ) => {
    let tierCount = 0;
    let totalLabour = 0; // for each DB
    let bayCount = 0;
    let allDB = [];
    const tableTitle = "PROJECT TOTAL LIST SUMMARY REPORT";
    quoteInfoTableExcel(
      worksheet,
      tableTitle,
      undefined,
      typeStatus,
      "quote",
      undefined,
      undefined
    );
    const summaryColTitleRow = worksheet.addRow([
      null,
      "APS DB PART NUMBER",
      "BOARD TITLE",
      "BAYS/CONFIGURATION & TIERS",
      `DB HOURS TOTAL = ${decimalToHoursMinutes(
        Math.round((quoteTotalLabour / 60) * 100) / 100
      )}`,
    ]);
    summaryColTitleRow.eachCell((cell) => {
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
      cell.font = { bold: true, size: 14 };
      cell.border = defaultBorderTitle;
    });
    quotingDB.forEach((db, index) => {
      db.tiers.forEach((tier) => {
        tierCount++;
        tier.bays.forEach((bay) => {
          bayCount++;
          bay.assemblies.forEach((assembly) => {
            assembly.parts.forEach((part) => {
              const foundPart = allDB.find(
                (findPart) => findPart.partNumber === part.partNumber
              );
              if (foundPart === undefined) {
                // no matching part (doesnt exist)
                allDB = [
                  ...allDB,
                  {
                    ...partAssembler(part),
                    quantity:
                      part.AssembliesRelation.quantity * assembly.quantity,
                  },
                ];
              } else {
                // part already exists; add quantity
                allDB = allDB.map((matchingPart) => {
                  if (matchingPart.partNumber === foundPart.partNumber) {
                    return {
                      ...foundPart,
                      quantity:
                        foundPart.quantity +
                        part.AssembliesRelation.quantity * assembly.quantity,
                    };
                  } else {
                    return matchingPart;
                  }
                });
              }
            });
          });
          if (bay.chassis.chassis1) {
            bay.chassis.chassis1.circuitBreakers.forEach((breaker) => {
              const foundPart = allDB.find(
                (findPart) => findPart.partNumber === breaker.part.partNumber
              );
              if (foundPart === undefined) {
                // no matching part (doesnt exist)
                allDB = [
                  ...allDB,
                  {
                    ...partAssembler(breaker.part),
                    quantity: breaker.quantity,
                  },
                ];
              } else {
                // part already exists; add quantity
                allDB = allDB.map((matchingPart) => {
                  if (matchingPart.partNumber === foundPart.partNumber) {
                    return {
                      ...foundPart,
                      quantity: foundPart.quantity + breaker.quantity,
                    };
                  } else {
                    return matchingPart;
                  }
                });
              }
            });
          }
          if (bay.chassis.chassis2) {
            bay.chassis.chassis2.circuitBreakers.forEach((breaker) => {
              const foundPart = allDB.find(
                (findPart) => findPart.partNumber === breaker.part.partNumber
              );
              if (foundPart === undefined) {
                // no matching part (doesnt exist)
                allDB = [
                  ...allDB,
                  {
                    ...partAssembler(breaker.part),
                    quantity: breaker.quantity,
                  },
                ];
              } else {
                // part already exists; add quantity
                allDB = allDB.map((matchingPart) => {
                  if (matchingPart.partNumber === foundPart.partNumber) {
                    return {
                      ...foundPart,
                      quantity: foundPart.quantity + breaker.quantity,
                    };
                  } else {
                    return matchingPart;
                  }
                });
              }
            });
          }
        });
      });
      allDB.forEach((part) => {
        totalLabour += part.quantity * part.labour;
      });
      for (let qtyIndex = 1; qtyIndex <= db.quantity; qtyIndex++) {
        const dbRow = worksheet.addRow([
          null,
          "",
          `${qtyIndex}-${db.name}`,
          `${tierCount}T${bayCount}B`,
          `${
            Math.round((totalLabour / 60 / 24) * roundingFactorHours) /
            roundingFactorHours
          }${hoursUnit}`,
        ]);
        if (quotingDB.length - 1 === index && qtyIndex === db.quantity) {
          dbRow.eachCell((cell) => {
            cell.border = {
              left: { style: "thick" },
              bottom: { style: "thick" },
              right: { style: "thick" },
            };
          });
        } else {
          dbRow.eachCell((cell) => {
            cell.border = {
              left: { style: "thick" },
              bottom: { style: "thin" },
              right: { style: "thick" },
            };
          });
        }
      }

      allDB = [];
      bayCount = 0;
      totalLabour = 0;
      tierCount = 0;
    });
    adjustColumnWidths(worksheet, 7);
    worksheet.eachRow((row) => {
      row.eachCell((cell) => {
        if (cell.value.includes(hoursUnit)) {
          cell.value = parseFloat(cell.value.split(hoursUnit)[0]);
          cell.numFmt = "[h]:mm";
        }
        cell.alignment = cell.alignment ? cell.alignment : { wrapText: true };
        cell.font = {
          ...cell.font,
          size:
            cell.font && cell.font.size ? cell.font.size : defaultItemFontSize,
        };
        cell.border = cell.border
          ? cell.border
          : {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
      });
    });
    printLayout(worksheet);
  };

  // cell = Excel.cell
  // colNumber = Excel.colNumber
  // start = start colNumber
  // end = end colNumber
  const reportBodyBorder = (cell, colNumber, start, end) => {
    if (colNumber === start) {
      cell.border = {
        left: { style: "thick" },
        right: { style: "thin" },
        bottom: { style: "thin" },
      };
    } else if (colNumber === end) {
      cell.border = {
        left: { style: "thin" },
        right: { style: "thick" },
        bottom: { style: "thin" },
      };
    } else {
      cell.border = {
        right: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
      };
    }
  };

  // cell = Excel.cell
  // colNumber = Excel.colNumber
  // start = start colNumber
  // end = end colNumber
  const reportHeadBorder = (cell, colNumber, start, end) => {
    if (colNumber === start) {
      cell.border = {
        top: { style: "thick" },
        left: { style: "thick" },
        right: { style: "thin" },
        bottom: { style: "thick" },
      };
    } else if (colNumber === end) {
      cell.border = {
        top: { style: "thick" },
        left: { style: "thin" },
        right: { style: "thick" },
        bottom: { style: "thick" },
      };
    } else {
      cell.border = {
        left: { style: "thin" },
        right: { style: "thin" },
        top: { style: "thick" },
        bottom: { style: "thick" },
      };
    }
    cell.alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    };
  };

  // used for per db in MWO
  const leadReportTableExcel = (worksheet, allParts) => {
    const startCol = 3;
    const endCol = 7;
    worksheet.addRow([null]); // empty row for spacing
    const titleRow = worksheet.addRow([null]);
    titleRow.height = defaultColTitleRowHeight;
    worksheet.mergeCells(titleRow.number, startCol, titleRow.number, endCol);
    const titleCell = worksheet.getCell(titleRow.number, startCol);
    titleCell.value = "LEADING HAND REPORT";
    titleCell.font = {
      size: 14,
      bold: true,
      /* color: { argb: "FFFF0000" }, */
    };
    titleCell.border = defaultBorderTitle;
    titleCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFFFFF00" },
    };
    titleCell.alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    };
    const leadReportColTitleRow = worksheet.addRow([
      null,
      null,
      "Manufacturing Classification # 1",
      "Manufacturing Classification # 2",
      "Mounting Hrs",
      "Wiring Hrs",
      "Total Time",
    ]);
    leadReportColTitleRow.height = defaultColTitleRowHeight;
    leadReportColTitleRow.eachCell((cell, colNumber) => {
      cell.font = { size: defaultItemFontSize, bold: true };
      reportHeadBorder(cell, colNumber, startCol, endCol);
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
    });

    let manuClasses = [];
    allParts.forEach((part) => {
      const foundManuClass = manuClasses.find(
        (manuClass) =>
          manuClass.class1 === part.manuClass1 &&
          manuClass.class2 === part.manuClass2
      );
      if (foundManuClass !== undefined) {
        manuClasses = manuClasses.map((manuClass) => {
          if (
            manuClass.class1 === foundManuClass.class1 &&
            manuClass.class2 === foundManuClass.class2
          ) {
            return {
              ...manuClass,
              mountTime: manuClass.mountTime + part.labourMount * part.quantity,
              wireTime: manuClass.wireTime + part.labourWire * part.quantity,
              totalTime: manuClass.totalTime + part.labour * part.quantity,
            };
          }
          return manuClass;
        });
      } else {
        manuClasses = [
          ...manuClasses,
          {
            class1: part.manuClass1,
            class2: part.manuClass2,
            mountTime: part.labourMount * part.quantity,
            wireTime: part.labourWire * part.quantity,
            totalTime: part.labour * part.quantity,
          },
        ];
      }
    });
    manuClasses = manuClasses.sort((a, b) => {
      const contains1 = (str) => str.includes("1");
      const contains2 = (str) => str.includes("2");

      const aContains1 = contains1(a.class1);
      const bContains1 = contains1(b.class1);
      const aContains2 = contains2(a.class1);
      const bContains2 = contains2(b.class1);

      if (aContains1 && !bContains1) return -1;
      if (!aContains1 && bContains1) return 1;
      if (aContains2 && !bContains2) return -1;
      if (!aContains2 && bContains2) return 1;

      // If both or neither contain '1' or '2', sort by string1 alphabetically within the same category
      if (aContains1 === bContains1 && aContains2 === bContains2) {
        if (a.class1 < b.class1) return -1;
        if (a.class1 > b.class1) return 1;
      }

      // If within the same group (string1 containing '1' or '2'), sort by string2 alphabetically
      if (a.string1 === b.string1) {
        if (a.class2 < b.class2) return -1;
        if (a.class2 > b.class2) return 1;
      }

      return 0;
    });
    let sumManuClasses1 = [];
    let prevClass = "";
    manuClasses.forEach((manuClass) => {
      const { class1, class2, mountTime, wireTime, totalTime } = manuClass;
      if (
        (prevClass.includes("1") && class1.includes("2")) ||
        (prevClass.includes("2") && class1.includes("custom"))
      ) {
        const gapRow = worksheet.addRow([null, null, "", "", "", "", ""]);
        gapRow.eachCell((cell, colNumber) => {
          reportBodyBorder(cell, colNumber, startCol, endCol);
        });
      }
      const manuClassRow = worksheet.addRow([
        null,
        null,
        class1,
        class2,
        `${
          Math.round((mountTime / 60 / 24) * roundingFactorHours) /
          roundingFactorHours
        }${hoursUnit}`,
        `${
          Math.round((wireTime / 60 / 24) * roundingFactorHours) /
          roundingFactorHours
        }${hoursUnit}`,
        `${
          Math.round((totalTime / 60 / 24) * roundingFactorHours) /
          roundingFactorHours
        }${hoursUnit}`,
      ]);
      manuClassRow.eachCell((cell, colNumber) => {
        if (cell.value.includes(hoursUnit)) {
          cell.value = parseFloat(cell.value.split(hoursUnit)[0]);

          cell.numFmt = "[h]:mm";
        }
        reportBodyBorder(cell, colNumber, startCol, endCol);
        if (colNumber === 7) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFFF00" },
          };
        }
      });
      prevClass = class1;

      // sum stages
      const foundManuClass = sumManuClasses1.find(
        (sumManuClass) => sumManuClass.class1 === manuClass.class1
      );
      if (foundManuClass !== undefined) {
        sumManuClasses1 = sumManuClasses1.map((sumManuClass) => {
          if (sumManuClass.class1 === foundManuClass.class1) {
            return {
              ...sumManuClass,
              mountTime: sumManuClass.mountTime + manuClass.mountTime,
              wireTime: sumManuClass.wireTime + manuClass.wireTime,
              totalTime: sumManuClass.totalTime + manuClass.totalTime,
            };
          }
          return sumManuClass;
        });
      } else {
        sumManuClasses1 = [
          ...sumManuClasses1,
          {
            class1: manuClass.class1,
            mountTime: manuClass.mountTime,
            wireTime: manuClass.wireTime,
            totalTime: manuClass.totalTime,
          },
        ];
      }
    });
    const gapRow = worksheet.addRow([null, null, "", "", "", "", ""]);
    gapRow.eachCell((cell, colNumber) => {
      reportBodyBorder(cell, colNumber, startCol, endCol);
    });

    // variables for managers report stages
    let manuClassManagerSums = [];
    // print to excel sum of stages
    sumManuClasses1.forEach((manuClass) => {
      const { class1, mountTime, wireTime, totalTime } = manuClass;
      const mountHrs =
        Math.round((mountTime / 60 / 24) * roundingFactorHours) /
        roundingFactorHours;
      const wireHrs =
        Math.round((wireTime / 60 / 24) * roundingFactorHours) /
        roundingFactorHours;
      const classTotalHrs =
        Math.round((totalTime / 60 / 24) * roundingFactorHours) /
        roundingFactorHours;
      manuClassManagerSums.push({
        className: class1,
        totalTime: totalTime,
      });
      const manuClassRow = worksheet.addRow([
        null,
        null,
        `Total ${class1}`,
        "",
        `${mountHrs}${hoursUnit}`,
        `${wireHrs}${hoursUnit}`,
        `${classTotalHrs}${hoursUnit}`,
      ]);
      manuClassRow.eachCell((cell, colNumber) => {
        if (cell.value.includes(hoursUnit)) {
          cell.value = parseFloat(cell.value.split(hoursUnit)[0]);
          cell.numFmt = "[h]:mm";
        }
        reportBodyBorder(cell, colNumber, startCol, endCol);
        if (colNumber === startCol || colNumber === endCol) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFFF00" },
          };
        }
      });
    });

    // final total row
    const mountTotal =
      Math.round(
        (sumManuClasses1.reduce(
          (accumulator, currentValue) => accumulator + currentValue.mountTime,
          0
        ) /
          60 /
          24) *
          roundingFactorHours
      ) / roundingFactorHours;
    const wireTotal =
      Math.round(
        (sumManuClasses1.reduce(
          (accumulator, currentValue) => accumulator + currentValue.wireTime,
          0
        ) /
          60 /
          24) *
          roundingFactorHours
      ) / roundingFactorHours;
    const sumTotal =
      Math.round(
        (sumManuClasses1.reduce(
          (accumulator, currentValue) => accumulator + currentValue.totalTime,
          0
        ) /
          60 /
          24) *
          roundingFactorHours
      ) / roundingFactorHours;
    const totalRow = worksheet.addRow([
      null,
      null,
      "TOTAL",
      "",
      `${mountTotal}${hoursUnit}`,
      `${wireTotal}${hoursUnit}`,
      `${sumTotal}${hoursUnit}`,
    ]);
    totalRow.eachCell((cell, colNumber) => {
      if (cell.value.includes(hoursUnit)) {
        cell.value = parseFloat(cell.value.split(hoursUnit)[0]);
        cell.numFmt = "[h]:mm";
      }
      reportHeadBorder(cell, colNumber, startCol, endCol);
      cell.font = { size: defaultItemFontSize, bold: true };
      cell.alignment = { wrapText: true };
      if (colNumber === startCol || colNumber === endCol) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFFFF00" },
        };
      }
    });

    worksheet.addRow([null]); // empty row for spacing
    return manuClassManagerSums;
  };

  // for manufacturing classification managers report
  // need all unique names to add to account for all manufacturing classifications
  const findUniqueClassNames = (objects, prop) => {
    if (!Array.isArray(objects) || objects.length === 0) {
      return [];
    }

    const classNames = new Set();

    objects.forEach((obj) => {
      if (Array.isArray(obj[prop])) {
        obj[prop].forEach((item) => {
          if (typeof item.className === "string") {
            classNames.add(item.className);
          }
        });
      }
    });

    return Array.from(classNames);
  };

  // for managers report and most cell units in total column
  // array of strings
  const formatUnitsTotal = (array, unit) => {
    const value =
      Math.round(
        array.reduce(
          (accumulator, currentValue) =>
            accumulator +
            parseFloat(
              unit === costUnit
                ? currentValue.slice(unit === costUnit ? 1 : 0)
                : currentValue.split(hoursUnit)[0]
            ),
          0
        ) * roundingFactorHours
      ) / roundingFactorHours;

    return `${unit === costUnit ? costUnit : ""}${value}${
      unit === hoursUnit || unit === marginUnit ? unit : ""
    }`;
  };

  // excel worksheet of managers report
  // typeStatus prelim or const
  // managersReportClasses = [] created with manufacturing class 1
  const managersReportTableExcel = (
    worksheet,
    typeStatus,
    managersReportClasses = []
  ) => {
    if (managersReportClasses.length < 1) {
      return;
    }
    let colDBTitles = [];
    quotingDB.forEach((db) => {
      for (let i = 1; i <= db.quantity; i++) {
        colDBTitles.push(`${i}-${db.name}`);
      }
    });
    const titles = [null, null, "TASK", ...colDBTitles, "TOTAL"];
    const startCol = 3;
    const endCol = titles.length;
    const tableTitle = "MANAGERS REPORT";
    quoteInfoTableExcel(worksheet, tableTitle, "<job_no>", typeStatus, "quote");

    const titleRow = worksheet.addRow(titles);
    titleRow.height = defaultColTitleRowHeight;
    titleRow.eachCell((cell, colNumber) => {
      reportHeadBorder(cell, colNumber, startCol, endCol);
      cell.font = { size: defaultItemFontSize, bold: true };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFF00" },
      };
    });

    const mappedDbs = handleDbPartsMapped(allAddCostings, quotingDB);

    // column names for manufacturing classes
    const typesOfClassNames = findUniqueClassNames(
      managersReportClasses,
      "manuClassArray"
    );

    // create the columns of info
    const taskNames = [
      "Material",
      "Consumables",
      "Freight",
      "Design Labour",
      ...typesOfClassNames,
      "Testing Labour",
      "Total DB Build Hours",
      "Labour",
      " ",
      "Cost",
      "Margin",
      "Sell",
    ];
    let dbMaterialCosts = [];
    let dbLabelsCosts = [];
    let dbFreightCosts = [];
    let dbDrawingHrs = [];
    let dbOtherAddCosts = [];
    let gapRow = [];
    let dbSubTotalCosts = [];
    let dbMarginValues = [];
    let dbFinalCosts = [];

    mappedDbs.forEach((db) => {
      for (let i = 0; i < db.quantity; i++) {
        dbMaterialCosts.push(`${costUnit}${db.unitPrice}`);
        dbLabelsCosts.push(`${costUnit}${db.labelsCosts}`);
        dbFreightCosts.push(`${costUnit}${db.freightCosts}`);
        dbDrawingHrs.push(
          `${
            Math.round((db.drawingHrs / 24) * roundingFactorHours) /
            roundingFactorHours
          }${hoursUnit}`
        );
        dbOtherAddCosts.push(`${costUnit}${db.otherAddCosts}`);
        gapRow.push(" ");
        dbSubTotalCosts.push(`${costUnit}${db.unitPrice + db.otherAddCosts}`);
        dbMarginValues.push(`${db.margin}${marginUnit}`);
        dbFinalCosts.push(`${costUnit}${db.totalUnitPrice}`);
      }
    });
    const totalMatCosts = formatUnitsTotal(dbMaterialCosts, costUnit);
    const totalLabelsCosts = formatUnitsTotal(dbLabelsCosts, costUnit);
    const totalFreightCosts = formatUnitsTotal(dbFreightCosts, costUnit);
    const totalDrawingHrs = formatUnitsTotal(dbDrawingHrs, hoursUnit);

    // testing labour = 10% of the sum of all manufacturing classifications
    const dbTestingLabours = managersReportClasses.map(
      (db) =>
        `${
          Math.round(
            (db.manuClassArray.reduce(
              (accumulator, currentValue) =>
                accumulator + currentValue.totalTime,
              0
            ) /
              60 /
              24 /
              10) *
              roundingFactorHours
          ) / roundingFactorHours
        }${hoursUnit}`
    );
    const totalTestingLabours = formatUnitsTotal(dbTestingLabours, hoursUnit);

    // all manufacturing classifications + testing labour
    const dbTotalLabours = managersReportClasses.map(
      (db) =>
        `${
          Math.round(
            (db.manuClassArray.reduce(
              (accumulator, currentValue) =>
                accumulator + currentValue.totalTime,
              0
            ) /
              60 /
              24) *
              1.1 *
              roundingFactorHours
          ) / roundingFactorHours
        }${hoursUnit}`
    );
    const totalTotalLabours = `${
      Math.round(
        dbTotalLabours.reduce(
          (accumulator, currentValue) =>
            accumulator + parseFloat(currentValue.split(hoursUnit)[0]),
          0
        ) * roundingFactorHours
      ) / roundingFactorHours
    }${hoursUnit}`;

    // labour costs + other additional costs
    const totalOtherAddCosts = formatUnitsTotal(dbOtherAddCosts, costUnit);

    const totalGapRow = " ";

    // sub total material costs + additional costs
    const totalSubTotalCosts = formatUnitsTotal(dbSubTotalCosts, costUnit);

    // margin
    const totalMarginValue = " ";

    // final costs
    const totalFinalCosts = formatUnitsTotal(dbFinalCosts, costUnit);

    const dbCostBreakdownArrays = [
      dbMaterialCosts,
      dbLabelsCosts,
      dbFreightCosts,
      dbDrawingHrs,
      ...typesOfClassNames,
      dbTestingLabours,
      dbTotalLabours,
      dbOtherAddCosts,
      gapRow,
      dbSubTotalCosts,
      dbMarginValues,
      dbFinalCosts,
    ];
    const totalTaskArray = [
      totalMatCosts,
      totalLabelsCosts,
      totalFreightCosts,
      totalDrawingHrs,
      ...typesOfClassNames,
      totalTestingLabours,
      totalTotalLabours,
      totalOtherAddCosts,
      totalGapRow,
      totalSubTotalCosts,
      totalMarginValue,
      totalFinalCosts,
    ];

    for (let i = 0; i < taskNames.length; i++) {
      const manuClassification = typesOfClassNames.find(
        (className) => className === taskNames[i]
      );
      if (manuClassification || manuClassification === "") {
        // manu class row
        const manuClasses = managersReportClasses.map((db) => {
          return db.manuClassArray.filter((manuClass) => {
            if (manuClass.className === taskNames[i]) {
              return true;
            } else {
              return false;
            }
          });
        });
        const formatManuClassRows = manuClasses.map((manuClass) => {
          if (manuClass.length === 0) {
            // does not have the classification add an empty one
            return `${0}${hoursUnit}`;
          }

          return `${
            Math.round(
              (manuClass[0].totalTime / 60 / 24) * roundingFactorHours
            ) / roundingFactorHours
          }${hoursUnit}`;
        });

        const totalManuClassTime = formatUnitsTotal(
          formatManuClassRows,
          hoursUnit
        );

        const inputRow = [
          null,
          null,
          taskNames[i],
          ...formatManuClassRows,
          totalManuClassTime,
        ];
        const manuClassRow = worksheet.addRow(inputRow);
        manuClassRow.eachCell((cell, colNumber) => {
          reportBodyBorder(cell, colNumber, startCol, endCol);
          if (cell.value.includes("$")) {
            cell.numFmt = '"$"#,##0.00';
          } else if (cell.value.includes(hoursUnit)) {
            cell.value = parseFloat(cell.value.split(hoursUnit)[0]);
            cell.numFmt = "[h]:mm";
          }
          if (colNumber === endCol) {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFFFFF99" },
            };
          }
        });
      } else {
        const inputRow = [
          null,
          null,
          taskNames[i],
          ...dbCostBreakdownArrays[i],
          totalTaskArray[i],
        ];
        const manageRow = worksheet.addRow(inputRow);

        manageRow.eachCell((cell, colNumber) => {
          if (taskNames[i] === "Sell") {
            reportHeadBorder(cell, colNumber, startCol, endCol);
            cell.alignment = { wrapText: true };
          } else {
            reportBodyBorder(cell, colNumber, startCol, endCol);
          }
          if (cell.value.includes(costUnit)) {
            cell.value = parseFloat(cell.value.slice(1));
            cell.numFmt = '"$"#,##0.00';
          } else if (cell.value.includes(hoursUnit)) {
            cell.value = parseFloat(cell.value.split(hoursUnit)[0]);
            cell.numFmt = "[h]:mm";
          } else if (cell.value.includes(marginUnit)) {
            cell.value =
              parseFloat(cell.value.slice(0, cell.value.length - 1)) / 100;
            cell.numFmt = "0.00%";
          }
          if (
            (taskNames[i] === "Cost" ||
              taskNames[i] === "Margin" ||
              taskNames[i] === "Sell") &&
            colNumber === startCol
          ) {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFFFFF00" },
            };
            cell.font = {
              size: defaultItemFontSize,
              bold: true,
            };
          } else if (colNumber === endCol) {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFFFFF99" },
            };
          }
        });
      }
    }

    adjustColumnWidths(worksheet, 7);
    printLayout(worksheet, "landscape", 0);
  };

  // function to return an Excel file for the all parts per db
  const manufactureWorkPackExcel = (typeStatus) => {
    const workbook = new ExcelJS.Workbook();
    const worksheetMWO = workbook.addWorksheet(`MWO`);
    // Add an image to the header
    const imageId = workbook.addImage({
      base64: apsLogo,
      extension: "png",
    });
    const quoteTotalLabour = mwoMainExcelWorksheet(
      worksheetMWO,
      imageId,
      typeStatus
    );

    const worksheetSummary = workbook.addWorksheet("DB SUMMARY");

    mwoSummaryExcelWorksheet(worksheetSummary, quoteTotalLabour, typeStatus);

    // allParts:
    // partNumber, description, quantity

    let managersReport = []; // contains stage 1

    // each db has a worksheet breakdown
    quotingDB.forEach((db) => {
      for (let qtyIndex = 1; qtyIndex <= db.quantity; qtyIndex++) {
        let allParts = [];
        let itemCount = 1;
        let totalLabour = 0; // for each DB
        let totalCost = 0; // for each DB
        const worksheetDB = workbook.addWorksheet(`${qtyIndex}-${db.name}`);
        const lastRowNumber = quoteInfoTableExcel(
          worksheetDB,
          "DB BOM REPORT",
          `${qtyIndex}-${db.name}`,
          typeStatus,
          "db",
          `${qtyIndex}-${db.name}`
        );
        const tableGroupTitle = worksheetDB.getRow(lastRowNumber);
        worksheetDB.mergeCells(lastRowNumber, 2, lastRowNumber, 6);
        tableGroupTitle.getCell(2).value = "MATERIAL LIST";
        const totalLabourColNo = 12;
        const totalCostColNo = 8;
        if (typeStatus === "const") {
          worksheetDB.mergeCells(lastRowNumber, 7, lastRowNumber, 8);
          tableGroupTitle.getCell(7).value = "MATERIAL COST";
          worksheetDB.mergeCells(
            lastRowNumber,
            9,
            lastRowNumber,
            totalLabourColNo
          );
          tableGroupTitle.getCell(9).value = "LABOUR TIME (MIN.)";
          worksheetDB.mergeCells(lastRowNumber, 13, lastRowNumber, 15);
          tableGroupTitle.getCell(13).value = "MANUFACTURING CLASSIFICATION";
        }
        tableGroupTitle.height = defaultColTitleRowHeight;
        tableGroupTitle.eachCell((cell) => {
          cell.border = defaultBorderTitle;
          cell.font = { size: defaultItemFontSize, bold: true };
          cell.alignment = { vertical: "middle", horizontal: "center" };
        });
        const dbColTitleRow =
          typeStatus === "const"
            ? worksheetDB.addRow([
                null,
                "ITEM",
                "PART NUMBER",
                "DESCRIPTION",
                "SUPPLIER",
                "QTY",
                "Each",
                "Total",
                "Mounting ea.",
                "Wiring ea.",
                "Total ea.",
                "Total",
                "Stage No.",
                "Stage - Sub Category",
                "Description of what is included",
                "Stock",
              ])
            : worksheetDB.addRow([
                null,
                "ITEM",
                "PART NUMBER",
                "DESCRIPTION",
                "SUPPLIER",
                "QTY",
                "Stock",
              ]);
        dbColTitleRow.height = defaultColTitleRowHeight;
        dbColTitleRow.eachCell((cell) => {
          cell.border = defaultBorderTitle;
          cell.font = { size: defaultItemFontSize, bold: true };
          cell.alignment = { vertical: "middle", horizontal: "center" };
        });
        db.tiers.forEach((tier) => {
          tier.bays.forEach((bay) => {
            bay.assemblies.forEach((assembly) => {
              assembly.parts.forEach((part) => {
                const foundPart = allParts.find(
                  (findPart) => findPart.partNumber === part.partNumber
                );
                if (foundPart === undefined) {
                  // no matching part (doesnt exist)
                  allParts = [
                    ...allParts,
                    {
                      ...partAssembler(part),
                      quantity:
                        part.AssembliesRelation.quantity * assembly.quantity,
                    },
                  ];
                } else {
                  // part already exists; add quantity
                  allParts = allParts.map((matchingPart) => {
                    if (matchingPart.partNumber === foundPart.partNumber) {
                      return {
                        ...foundPart,
                        quantity:
                          foundPart.quantity +
                          part.AssembliesRelation.quantity * assembly.quantity,
                      };
                    } else {
                      return matchingPart;
                    }
                  });
                }
              });
            });
            if (bay.chassis.chassis1) {
              bay.chassis.chassis1.circuitBreakers.forEach((breaker) => {
                const foundPart = allParts.find(
                  (findPart) => findPart.partNumber === breaker.part.partNumber
                );
                if (foundPart === undefined) {
                  // no matching part (doesnt exist)
                  allParts = [
                    ...allParts,
                    {
                      ...partAssembler(breaker.part),
                      quantity: breaker.quantity,
                    },
                  ];
                } else {
                  // part already exists; add quantity
                  allParts = allParts.map((matchingPart) => {
                    if (matchingPart.partNumber === foundPart.partNumber) {
                      return {
                        ...foundPart,
                        quantity: foundPart.quantity + breaker.quantity,
                      };
                    } else {
                      return matchingPart;
                    }
                  });
                }
              });
            }
            if (bay.chassis.chassis2) {
              bay.chassis.chassis2.circuitBreakers.forEach((breaker) => {
                const foundPart = allParts.find(
                  (findPart) => findPart.partNumber === breaker.part.partNumber
                );
                if (foundPart === undefined) {
                  // no matching part (doesnt exist)
                  allParts = [
                    ...allParts,
                    {
                      ...partAssembler(breaker.part),
                      quantity: breaker.quantity,
                    },
                  ];
                } else {
                  // part already exists; add quantity
                  allParts = allParts.map((matchingPart) => {
                    if (matchingPart.partNumber === foundPart.partNumber) {
                      return {
                        ...foundPart,
                        quantity: foundPart.quantity + breaker.quantity,
                      };
                    } else {
                      return matchingPart;
                    }
                  });
                }
              });
            }
          });
        });
        let itemRow;
        allParts.forEach((part, index) => {
          if (typeStatus === "const") {
            itemRow = worksheetDB.addRow([
              null,
              itemCount,
              part.partNumber,
              part.description,
              part.supplier,
              part.quantity,
              part.cost,
              part.cost * part.quantity,
              part.labourMount,
              part.labourWire,
              part.labour,
              part.labour * part.quantity,
              part.manuClass1,
              part.manuClass2,
              part.manuDesc,
              part.stock,
            ]);
            totalLabour += part.quantity * part.labour;
            totalCost += part.cost * part.quantity;
          } else {
            itemRow = worksheetDB.addRow([
              null,
              itemCount,
              part.partNumber,
              part.description,
              part.supplier,
              part.quantity,
              part.stock,
            ]);
          }
          if (index === allParts.length - 1) {
            itemRow.eachCell((cell) => {
              cell.border = {
                left: { style: "thick" },
                bottom: { style: "thick" },
                right: { style: "thick" },
              };
            });
          } else {
            itemRow.eachCell((cell) => {
              cell.border = {
                left: { style: "thick" },
                bottom: { style: "thin" },
                right: { style: "thick" },
              };
            });
          }
          itemRow.eachCell((cell, colNumber) => {
            // material costs
            if (colNumber === 7 || colNumber === 8) {
              cell.numFmt = "0.00";
            }
            cell.alignment = {
              wrapText: true,
              vertical: "middle",
              horizontal: "center",
            };
          });
          itemCount++;
        });

        if (itemRow && typeStatus === "const") {
          const totalCostCell = worksheetDB.getCell(
            itemRow.number + 1,
            totalCostColNo - 1
          );
          totalCostCell.value = "Total Cost ($) ";
          totalCostCell.font = { size: defaultItemFontSize, bold: true };
          totalCostCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFFF00" },
          };
          totalCostCell.border = defaultBorderTitle;
          totalCostCell.numFmt = "0.00";
          const totalCostValueCell = worksheetDB.getCell(
            itemRow.number + 1,
            totalCostColNo
          );
          totalCostCell.alignment = { horizontal: "center" };

          totalCostValueCell.value = Math.round(totalCost * 100) / 100;
          totalCostValueCell.font = { size: defaultItemFontSize, bold: true };
          totalCostValueCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFFF00" },
          };
          totalCostValueCell.border = defaultBorderTitle;
          const totalLabourCell = worksheetDB.getCell(
            itemRow.number + 1,
            totalLabourColNo - 1
          );
          totalCostValueCell.alignment = { horizontal: "center" };

          totalLabourCell.value = "Total Labour (Hrs) ";
          totalLabourCell.font = { size: defaultItemFontSize, bold: true };
          totalLabourCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFFF00" },
          };
          totalLabourCell.border = defaultBorderTitle;
          const totalLabourValueCell = worksheetDB.getCell(
            itemRow.number + 1,
            totalLabourColNo
          );
          totalLabourCell.alignment = { horizontal: "center" };

          totalLabourValueCell.value = decimalToHoursMinutes(
            Math.round((totalLabour / 60) * 100) / 100
          );
          totalLabourValueCell.font = { size: defaultItemFontSize, bold: true };
          totalLabourValueCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFFF00" },
          };
          totalLabourValueCell.border = defaultBorderTitle;
          totalLabourValueCell.alignment = { horizontal: "center" };
        }
        const managerClassArray = leadReportTableExcel(worksheetDB, allParts);
        if (managerClassArray.length === 0) {
          managersReport.push({
            dbName: db.name,
            manuClassArray: [{ className: "", totalTime: 0 }],
          });
        } else {
          managersReport.push({
            dbName: db.name,
            manuClassArray: managerClassArray,
          });
        }

        allParts = [];
        itemCount = 1;
        totalLabour = 0;
        totalCost = 0;
        adjustColumnWidths(worksheetDB, dbColTitleRow.number);
        worksheetDB.eachRow((row) => {
          row.eachCell((cell) => {
            cell.alignment = cell.alignment
              ? cell.alignment
              : { wrapText: true };
            cell.font = cell.font
              ? cell.font
              : {
                  size: defaultItemFontSize,
                };
            cell.border = cell.border
              ? cell.border
              : {
                  top: { style: "thin" },
                  left: { style: "thin" },
                  bottom: { style: "thin" },
                  right: { style: "thin" },
                };
          });
        });
        printLayout(
          worksheetDB,
          typeStatus === "const" ? "landscape" : "portrait"
        );
      }
    });
    const quoteBOMWorksheet = workbook.addWorksheet(
      "PROJECT BOM SUMMARY REPORT"
    );
    quoteBOMExcel(quoteBOMWorksheet);
    const worksheetManager = workbook.addWorksheet("MANAGERS REPORT");
    managersReportTableExcel(worksheetManager, typeStatus, managersReport);
    worksheetManager.eachRow((row) => {
      row.eachCell((cell) => {
        cell.alignment = cell.alignment ? cell.alignment : { wrapText: true };
        cell.font = cell.font
          ? cell.font
          : {
              size: defaultItemFontSize,
            };
        cell.border = cell.border
          ? cell.border
          : {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
      });
    });

    workbook.xlsx
      .writeBuffer()
      .then((buffer) => {
        const blob = new Blob([buffer], { type: "application/octet-stream" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${currentClient.deb_acc} - ${
          currentProject.projectName
        } - MWO (${
          typeStatus === "const" ? "FOR CONSTRUCTION" : "PRELIMINARY"
        }).xlsx`;
        link.click();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // excel version of quote
  const quoteExcel = () => {
    const workbook = new ExcelJS.Workbook();
    //#region Project title
    const colNo = 1;
    const projWorksheet = workbook.addWorksheet(`Project`);
    const apsTitleRow = projWorksheet.getRow(4);
    apsTitleRow.getCell(colNo).value = `APS Industrial`;
    apsTitleRow.getCell(colNo).font = { size: 25, bold: true };
    apsTitleRow.getCell(colNo).alignment = {
      wrapText: true,
      horizontal: "center",
    };

    const projectNameRow = projWorksheet.getRow(20);
    projectNameRow.getCell(colNo).value = `${currentProject.projectName}`;
    projectNameRow.getCell(colNo).font = { size: 40, bold: true };
    projectNameRow.getCell(colNo).alignment = {
      wrapText: true,
      horizontal: "center",
    };

    const subProjectNameRow = projWorksheet.getRow(22);
    subProjectNameRow.getCell(colNo).value = `${currentProject.subProjectName}`;
    subProjectNameRow.getCell(colNo).font = { size: 15, bold: true };
    subProjectNameRow.getCell(colNo).alignment = {
      wrapText: true,
      horizontal: "center",
    };

    const customerRow = projWorksheet.getRow(35);
    customerRow.getCell(colNo).value = `${currentClient.businessName}`;
    customerRow.getCell(colNo).font = { size: 15, bold: true };
    customerRow.getCell(colNo).alignment = {
      wrapText: true,
      horizontal: "center",
    };

    const dateRow = projWorksheet.getRow(38);
    dateRow.getCell(colNo).value = `${getCurrentDate()}`;
    dateRow.getCell(colNo).font = { size: 15, bold: true };
    dateRow.getCell(colNo).alignment = {
      wrapText: true,
      horizontal: "center",
    };

    const titleCol = projWorksheet.getColumn(colNo);
    titleCol.width = 100;

    //#endregion

    //#region Terms and Conditions
    const tandCWorksheet = workbook.addWorksheet(`Terms and Conditions`);
    const tncRow = tandCWorksheet.addRow([null, "Terms and Conditions"]);
    tncRow.getCell(2).font = { size: defaultItemFontSize, bold: true };
    titleList.forEach((title) => {
      if (title.checkedTitle) {
        const titleRow = tandCWorksheet.addRow([null, `${title.title}`]);
        titleRow.getCell(2).font = { bold: true };
        title.points.forEach((point) => {
          if (point.checked) {
            tandCWorksheet.addRow([null, `${point.point}`]);
          }
        });
        tandCWorksheet.addRow([null]);
      }
    });

    tandCWorksheet.eachRow((row) => {
      row.eachCell((cell) => {
        if (cell.value === "Terms and Conditions") {
          cell.border = defaultBorderTitle;
        } else {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
            bottom: { style: "thin" },
          };
          cell.alignment = { wrapText: true };
        }
      });
    });
    tncRow.getCell(2).alignment = {
      wrapText: true,
      horizontal: "center",
    };
    //#endregion

    //#region Quote Details
    const quoteDetailsWorksheet = workbook.addWorksheet("Quote Details");
    const quoteDetailsRow = quoteDetailsWorksheet.addRow([
      null,
      `Quote Details`,
    ]);
    quoteDetailsWorksheet.mergeCells(
      `B${quoteDetailsRow.number}:C${quoteDetailsRow.number}`
    );

    quoteDetailsRow.getCell(2).font = { size: defaultItemFontSize, bold: true };
    const oppRow = quoteDetailsWorksheet.addRow([
      null,
      "Opportunity:",
      `${currentProject.crmOpp}`,
    ]);
    oppRow.getCell(2).font = { bold: true };
    const attRow = quoteDetailsWorksheet.addRow([
      null,
      "Attention:",
      `${currentClient.customerName}`,
    ]);
    attRow.getCell(2).font = { bold: true };
    const comRow = quoteDetailsWorksheet.addRow([
      null,
      "Company:",
      `${currentClient.businessName}`,
    ]);
    comRow.getCell(2).font = { bold: true };
    const preRow = quoteDetailsWorksheet.addRow([
      null,
      "Prepared by:",
      `${currentClient.contactName}`,
    ]);
    preRow.getCell(2).font = { bold: true };
    const quoRow = quoteDetailsWorksheet.addRow([
      null,
      "Quote Number:",
      `${currentQuoteNum}`,
    ]);
    quoRow.getCell(2).font = { bold: true };
    const revRow = quoteDetailsWorksheet.addRow([
      null,
      "Revision:",
      `${revisionVersion}`,
    ]);
    revRow.getCell(2).font = { bold: true };
    const datRow = quoteDetailsWorksheet.addRow([
      null,
      "Date:",
      `${getCurrentDate()}`,
    ]);
    datRow.getCell(2).font = { bold: true };
    const valRow = quoteDetailsWorksheet.addRow([
      null,
      "Valid Until:",
      `${getDateValidity()}`,
    ]);
    valRow.getCell(2).font = { bold: true };
    quoteDetailsWorksheet.addRow([null]);

    quoteDetailsWorksheet.eachRow((row) => {
      row.eachCell((cell) => {
        if (cell.value === "Quote Details") {
          cell.border = defaultBorderTitle;
          cell.alignment = { wrapText: true, horizontal: "center" };
        } else {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
            bottom: { style: "thin" },
          };
          cell.alignment = { wrapText: true };
        }
      });
    });
    //#endregion

    //#region DB Details
    let dbNameCost = [];
    const mappedDbs = handleDbPartsMapped(allAddCostings, quotingDB);
    const dbDetailsWorksheet = workbook.addWorksheet("DB Details");
    const dbDetailsRow = dbDetailsWorksheet.addRow([null, `DB Details`]);
    dbDetailsWorksheet.mergeCells(
      `B${dbDetailsRow.number}:F${dbDetailsRow.number}`
    );
    dbDetailsRow.getCell(2).font = { size: defaultItemFontSize, bold: true };
    mappedDbs.forEach((db) => {
      dbNameCost.push({
        identifier: db.identifier,
        quantity: db.quantity,
        Name: db.Name,
        cost: db.unitPrice,
        totalUnitPrice: db.totalUnitPrice,
        totalPrice: db.totalPrice,
        margin: db.margin,
        otherAddCosts: db.otherAddCosts,
      });
      const dbTitleRow = dbDetailsWorksheet.addRow([
        null,
        "ID",
        "Name",
        "Unit Price (excl. GST)",
        "Qty",
        "Total (excl. GST)",
      ]);
      dbTitleRow.eachCell((cell) => {
        cell.font = { bold: true };
      });
      const inputRow = [
        null,
        `${db.identifier}`,
        `${db.Name}`,
        `$${db.totalUnitPrice.toFixed(2)}`,
        `${db.quantity}`,
        `$${db.totalPrice.toFixed(2)}`,
      ];
      dbDetailsWorksheet.addRow(inputRow);
      const descRow = dbDetailsWorksheet.addRow([
        null,
        "Description",
        "",
        "",
        "Part Qty",
        "Stock",
      ]);
      descRow.eachCell((cell) => {
        cell.font = { bold: true };
      });
      db.UniqueParts.forEach((part) => {
        if (part.part.inclDesc === "Y") {
          const inputRow = [
            null,
            `${part.part.description}`,
            "",
            "",
            `${part.quantity}`,
            `${part.part.stock ? part.part.stock : ""}`,
          ];
          dbDetailsWorksheet.addRow(inputRow);
        }
      });
      dbDetailsWorksheet.addRow([null]);
    });
    let dbTotalCost =
      Math.round(
        dbNameCost.reduce(
          (accumulator, currentValue) => accumulator + currentValue.totalPrice,
          0
        ) * 100
      ) / 100;

    dbDetailsWorksheet.eachRow((row) => {
      row.eachCell((cell) => {
        if (cell.value === "DB Details") {
          cell.border = defaultBorderTitle;
          cell.alignment = { wrapText: true, horizontal: "center" };
        } else {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
            bottom: { style: "thin" },
          };
          cell.alignment = { wrapText: true };
        }
      });
    });
    //#endregion

    //#region DB Summary
    const dbSummaryWorksheet = workbook.addWorksheet("DB Summary");
    const dbSummaryRow = dbSummaryWorksheet.addRow([null, `DB Summary`]);
    dbSummaryWorksheet.mergeCells(
      `B${dbSummaryRow.number}:F${dbSummaryRow.number}`
    );
    dbSummaryRow.getCell(2).font = { size: defaultItemFontSize, bold: true };
    const dbSummaryColTitleRow = dbSummaryWorksheet.addRow([
      null,
      "ID",
      "Name",
      "Unit Price (excl GST)",
      "Qty",
      "Total (excl GST)",
    ]);
    dbSummaryColTitleRow.eachCell((cell) => {
      cell.font = { bold: true };
    });
    dbNameCost.forEach((db) => {
      const inputRow = [
        null,
        `${db.identifier}`,
        `${db.Name}`,
        `${db.totalUnitPrice.toFixed(2)}`,
        `${db.quantity}`,
        db.totalPrice.toFixed(2),
      ];
      dbSummaryWorksheet.addRow(inputRow);
    });
    const dbSummaryTotalRow = dbSummaryWorksheet.addRow([
      null,
      null,
      null,
      null,
      null,
      `$${dbTotalCost.toFixed(2)}`,
    ]);
    dbSummaryTotalRow.getCell(5).font = { bold: true };
    dbSummaryWorksheet.addRow([null]);
    dbSummaryWorksheet.eachRow((row) => {
      row.eachCell((cell) => {
        if (cell.value === "DB Summary") {
          cell.border = defaultBorderTitle;
          cell.alignment = { wrapText: true, horizontal: "center" };
        } else {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
            bottom: { style: "thin" },
          };
          cell.alignment = { wrapText: true };
        }
      });
    });
    //#endregion

    //#region Factory Installed Items
    if (lineItems.length !== 0) {
      const lineItemsWorksheet = workbook.addWorksheet(
        `Factory Installed Items`
      );
      const lineItemsRow = lineItemsWorksheet.addRow([
        null,
        `Factory Installed Items`,
      ]);
      lineItemsWorksheet.mergeCells(
        `B${lineItemsRow.number}:F${lineItemsRow.number}`
      );
      lineItemsRow.getCell(2).font = { size: defaultItemFontSize, bold: true };
      const lineItemsColTitleRow = lineItemsWorksheet.addRow([
        null,
        "ID",
        "Description",
        "Unit Price (excl. GST)",
        "Qty",
        "Total Price (excl. GST)",
      ]);
      lineItemsColTitleRow.eachCell((cell) => {
        cell.font = { bold: true };
      });
      let idTracker = quotingDB[quotingDB.length - 1].id;
      idTracker++;
      lineItems.forEach((item) => {
        const inputRow = [
          null,
          `${idTracker++}`,
          `${item.description}`,
          `$${item.finalPrice.toFixed(2)}`,
          `${item.quantity}`,
          `$${(item.finalPrice * item.quantity).toFixed(2)}`,
        ];
        lineItemsWorksheet.addRow(inputRow);
      });
      const lineItemsTotalRow = lineItemsWorksheet.addRow([
        null,
        null,
        null,
        null,
        "Total",
        `$${handleLineItemCosts(lineItems).totalLineItemsCost.toFixed(2)}`,
      ]);
      lineItemsTotalRow.eachCell((cell) => {
        cell.font = { bold: true };
      });
      lineItemsWorksheet.addRow([null]);
      lineItemsWorksheet.eachRow((row) => {
        row.eachCell((cell) => {
          if (cell.value === "Factory Installed Items") {
            cell.border = defaultBorderTitle;
            cell.alignment = { wrapText: true, horizontal: "center" };
          } else {
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
              bottom: { style: "thin" },
            };
            cell.alignment = { wrapText: true };
          }
        });
      });
      adjustColumnWidths(lineItemsWorksheet, 2, 80);
      printLayout(lineItemsWorksheet, "portrait", 1, 0, false);
    }
    //#endregion

    //#region Quote Total
    const quoteTotalWorksheet = workbook.addWorksheet("Quote Total");
    const quoteTotalRow = quoteTotalWorksheet.addRow([null, `Quote Total`]);
    quoteTotalWorksheet.mergeCells(
      `B${quoteTotalRow.number}:C${quoteTotalRow.number}`
    );
    quoteTotalRow.getCell(2).font = { size: defaultItemFontSize, bold: true };
    quoteTotalWorksheet.addRow([null, "DB's", `$${dbTotalCost.toFixed(2)}`]);
    if (lineItems.length > 0) {
      quoteTotalWorksheet.addRow([
        null,
        "Factory Installed Items",
        `$${handleLineItemCosts(lineItems).totalLineItemsCost.toFixed(2)}`,
      ]);
    }
    quoteTotalWorksheet.addRow([
      null,
      "TOTAL",
      `$${(
        dbTotalCost + handleLineItemCosts(lineItems).totalLineItemsCost
      ).toFixed(2)}`,
    ]);
    quoteTotalWorksheet.addRow([
      null,
      "GST Amount",
      `$${(
        Math.round(
          ((dbTotalCost + handleLineItemCosts(lineItems).totalLineItemsCost) /
            10) *
            100
        ) / 100
      ).toFixed(2)}`,
    ]);
    const quoteTotalTOTALRow = quoteTotalWorksheet.addRow([
      null,
      "TOTAL Including GST",
      `$${(
        Math.round(
          (dbTotalCost + handleLineItemCosts(lineItems).totalLineItemsCost) *
            1.1 *
            100
        ) / 100
      ).toFixed(2)}`,
    ]);
    quoteTotalTOTALRow.eachCell((cell) => {
      cell.font = { bold: true };
    });

    quoteTotalWorksheet.eachRow((row) => {
      row.eachCell((cell) => {
        if (cell.value === "Quote Total") {
          cell.border = defaultBorderTitle;
          cell.alignment = { wrapText: true, horizontal: "center" };
        } else {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
            bottom: { style: "thin" },
          };
          cell.alignment = { wrapText: true };
        }
      });
    });
    //#endregion

    // Note: line_items / factory installed items
    // adjustColumnWidths and printLayout under its own region of code
    adjustColumnWidths(tandCWorksheet, 2, 80);
    adjustColumnWidths(quoteDetailsWorksheet, 2, 80);
    adjustColumnWidths(dbDetailsWorksheet, 2, 50);
    adjustColumnWidths(dbSummaryWorksheet, 2, 80);
    adjustColumnWidths(quoteTotalWorksheet, 2, 80);

    printLayout(projWorksheet, "portrait", 1, 0, false);
    printLayout(tandCWorksheet, "portrait", 1, 0, false);
    printLayout(quoteDetailsWorksheet, "portrait", 1, 0, false);
    printLayout(dbDetailsWorksheet, "portrait", 1, 0, false);
    printLayout(dbSummaryWorksheet, "portrait", 1, 0, false);
    printLayout(quoteTotalWorksheet, "portrait", 1, 0, false);

    workbook.xlsx
      .writeBuffer()
      .then((buffer) => {
        const blob = new Blob([buffer], { type: "application/octet-stream" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${currentQuoteNum}_Rev${revisionVersion}_${getCurrentDate()}_${
          currentClient.customerName
        }_${currentProject.projectName}.xlsx`;
        link.click();
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return {
    esgAutoGenExcelData,
    quoteExcel,
    bomExcel,
    manufactureWorkPackExcel,
  };
}
