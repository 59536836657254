import {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ApsContext } from "ApsContext";
import { useCircuitBreakers } from "services/query-service";

export const DragContext = createContext({
  moveBreaker: () => {},
  canDropBreaker: () => {},
  poles: [],
  makePoles: () => {},
  getFirstAvailableSinglePole: () => {},
  getFirstAvailableTriplePole: () => {},
  getPoleID: () => {},
  handleCloseBreakerDialog: () => {},
  handleOpenBreakerDialog: () => {},
  breakerDialogOpen: false,
  breakerToEdit: null,
  handleDeleteBreakerFuncText: () => {},
  handleNewBreakerFuncText: () => {},
  breakerFuncText: [],
  updateFuncText: () => {},
  getBreakerFuncText: () => {},
  getBreakerFuncTextAndOther: () => {},
  deleteBreaker: () => {},
  currentOverPole: null,
  updateCurrentOverPole: () => {},
  deleteTriplePoleBreaker: () => {},
});

const DragContextProvider = ({ children }) => {
  const { data, isSuccess } = useCircuitBreakers();
  const [currentOverPole, setCurrentOverPole] = useState(null);
  const [breakerDialogOpen, setBreakerDialogOpen] = useState(false);
  const [breakerToEdit, setBreakerToEdit] = useState(null);
  const [maxPitch27Pole, setMaxPitch27Pole] = useState(0);
  const [allCircuitBreakers, setAllCircuitBreakers] = useState([]);

  const {
    getCurrentChassis,
    updateCurrentChassisPoleConfig,
    handleUpdateChassisPoleConfigAndCircuitBreakers,
  } = useContext(ApsContext);

  const { poleConfig, circuitBreakers, isHybridChassis, partNumber } =
    getCurrentChassis();

  const moveBreaker = useCallback(
    (breakerID, newPoleID, breakerType) => {
      // Create a copy of poleConfig
      const newPoleConfig = [...poleConfig];

      let breakerText = {
        funcText: "",
        other: "",
      };

      // find the existing breaker in the poleConfig for the function text and other text
      const existingBreaker = newPoleConfig.find(
        (pole) => pole.breaker && pole.breaker.id === breakerID
      );

      if (existingBreaker) {
        breakerText = {
          funcText: existingBreaker.breaker.funcText,
          other: existingBreaker.breaker.other,
        };
      }

      const oldPoles = newPoleConfig.filter(
        (pole) => pole.breaker && pole.breaker.id === breakerID
      );

      oldPoles.forEach((oldPole) => {
        oldPole.breaker = null;
      });

      if (breakerType === "triple") {
        const newPole = newPoleConfig.find((pole) => pole.id === newPoleID);
        const nextPole = newPoleConfig.find(
          (pole) => pole.id === newPoleID + 2
        );
        const nextNextPole = newPoleConfig.find(
          (pole) => pole.id === newPoleID + 4
        );
        if (newPole && nextPole && nextNextPole) {
          newPole.breaker = {
            id: breakerID,
            type: "triple",
            phase: 1,
            funcText: breakerText.funcText,
            other: breakerText.other,
          };
          nextPole.breaker = {
            id: breakerID,
            type: "triple",
            phase: 2,
            funcText: breakerText.funcText,
            other: breakerText.other,
          };
          nextNextPole.breaker = {
            id: breakerID,
            type: "triple",
            phase: 3,
            funcText: breakerText.funcText,
            other: breakerText.other,
          };
        }
      } else {
        const newPole = newPoleConfig.find((pole) => pole.id === newPoleID);
        if (newPole) {
          newPole.breaker = {
            id: breakerID,
            type: "single",
            funcText: breakerText.funcText,
            other: breakerText.other,
          };
        }
      }
      updateCurrentChassisPoleConfig(newPoleConfig);
    },
    [poleConfig, updateCurrentChassisPoleConfig]
  );

  // check pitch to prevent the breaker to be placed in invalid position on chassis config
  // 27mm breaker only in 27mm poles and 18mm breaker only in 18mm poles
  const checkPartPitch = useCallback(
    (breakerItem) => {
      const foundBreaker = allCircuitBreakers.find(
        (breaker) =>
          breaker.partNumber ===
          breakerItem.id.substring(0, breakerItem.id.lastIndexOf("-"))
      );
      if (foundBreaker) {
        const breakerPitch = foundBreaker.partNumber.includes("5SP");
        return breakerPitch;
      } else {
        return null;
      }
    },
    [allCircuitBreakers]
  );

  /* const checkPartPitch = useCallback(
    (breakerItem) => {
      const breakerPitch = allCircuitBreakers
        .find(
          (breaker) =>
            breaker.partNumber ===
            breakerItem.id.substring(0, breakerItem.id.lastIndexOf("-"))
        )
        .filter1.includes("27mm");
      return breakerPitch;
    },
    [allCircuitBreakers]
  ); */

  const canDropBreaker = useCallback(
    (breakerItem) => {
      const is27PitchBreaker = checkPartPitch(breakerItem);
      const validPosition =
        maxPitch27Pole !== 0 // is a hybrid chassis > 0
          ? is27PitchBreaker // whether its 27mm breaker
            ? currentOverPole <= maxPitch27Pole &&
              currentOverPole + 2 <= maxPitch27Pole &&
              currentOverPole + 4 <= maxPitch27Pole
            : currentOverPole > maxPitch27Pole
          : !is27PitchBreaker
          ? currentOverPole >= maxPitch27Pole
          : currentOverPole < maxPitch27Pole;
      // For a triple phase breaker, check if the next two poles are also free
      if (breakerItem.type === "triple") {
        const firstPole = poleConfig[currentOverPole - 1];
        const secondPole = poleConfig[currentOverPole + 1];
        const thirdPole = poleConfig[currentOverPole + 3];

        return (
          ((currentOverPole - 1) % 6 === 0 ||
            (currentOverPole - 2) % 6 === 0) &&
          !firstPole.breaker &&
          !secondPole.breaker &&
          !thirdPole.breaker &&
          validPosition
          // uncomment below and comment out above to remove RWB restriction
          /* firstPole.breaker !== undefined &&
          secondPole.breaker !== undefined &&
          thirdPole.breaker !== undefined &&
          (firstPole.breaker === null ||
            firstPole.breaker.id === breakerItem.id) &&
          (secondPole.breaker === null ||
            secondPole.breaker.id === breakerItem.id) &&
          (thirdPole.breaker === null ||
            thirdPole.breaker.id === breakerItem.id) &&
          validPosition */
        );
      }
      // For a single phase breaker, check if the pole is free
      return (
        poleConfig[currentOverPole - 1] &&
        !poleConfig[currentOverPole - 1].breaker &&
        validPosition
      );
    },
    [checkPartPitch, currentOverPole, maxPitch27Pole, poleConfig]
  );

  // function to return the first available pole for a triple phase breaker (i.e. 3 consecutive poles)
  const getFirstAvailableTriplePole = useCallback(
    (breakerItem) => {
      const is27PitchBreaker = checkPartPitch(breakerItem);

      // find the first available pole for a triple phase breaker where the pole is the first of a set of 3 consecutive poles and the next two poles are also free
      const firstAvailableWay = poleConfig.find(
        (pole, index) =>
          pole &&
          // ------
          (index === 0 ||
            index % 6 === 0 ||
            index === 1 ||
            (index - 1) % 6 === 0) &&
          // ------
          // comment code above to add breakers without RWB restrictions
          !pole.breaker &&
          poleConfig[index + 2] &&
          !poleConfig[index + 2].breaker &&
          poleConfig[index + 4] &&
          !poleConfig[index + 4].breaker &&
          (is27PitchBreaker && maxPitch27Pole !== 0 // is 27mm breaker but is hybrid chassis
            ? index < maxPitch27Pole
            : is27PitchBreaker && maxPitch27Pole === 0 // is 27mm breaker but is hybrid chassis
            ? false
            : !is27PitchBreaker && maxPitch27Pole !== 0 // is 18mm breaker but is hybrid chassis
            ? index >= maxPitch27Pole
            : !is27PitchBreaker && maxPitch27Pole === 0 // is 18mm breaker but is not hybrid chassis
            ? true
            : false)
      );

      if (firstAvailableWay) {
        return firstAvailableWay.id;
      }

      return null;
    },
    [checkPartPitch, maxPitch27Pole, poleConfig]
  );

  // function to return the first available pole for a single phase breaker
  const getFirstAvailableSinglePole = useCallback(
    (breakerItem) => {
      const is27PitchBreaker = checkPartPitch(breakerItem);
      const firstAvailablePole = poleConfig.find(
        (pole, index) =>
          !pole.breaker &&
          (is27PitchBreaker && maxPitch27Pole !== 0 // is 27mm breaker but is hybrid chassis
            ? index < maxPitch27Pole
            : is27PitchBreaker && maxPitch27Pole === 0 // is 27mm breaker but is hybrid chassis
            ? false
            : !is27PitchBreaker && maxPitch27Pole !== 0 // is 18mm breaker but is hybrid chassis
            ? index >= maxPitch27Pole
            : !is27PitchBreaker && maxPitch27Pole === 0 // is 18mm breaker but is not hybrid chassis
            ? true
            : false)
      );
      if (firstAvailablePole) {
        return firstAvailablePole.id;
      }
      return null;
    },
    [checkPartPitch, maxPitch27Pole, poleConfig]
  );

  // function to return the pole id given a breaker id
  const getPoleID = (breakerID) => {
    const pole = poleConfig.find(
      (pole) => pole.breaker && pole.breaker.id === breakerID
    );
    if (pole) {
      return pole.id;
    }
    return null;
  };

  const handleOpenBreakerDialog = (breaker) => {
    setBreakerDialogOpen(true);
    setBreakerToEdit(breaker);
  };

  const handleCloseBreakerDialog = () => {
    setBreakerDialogOpen(false);
    setBreakerToEdit(null);
  };

  const updateFuncText = (breakerID, newText) => {
    // add the newText to the breakerFuncText array { id: breakerID, funcText: newText.funcText, other: newText.other } if it doesn't exist, else update the existing breakerFuncText

    const existingBreakerFuncText = poleConfig.find(
      (pole) => pole.breaker && pole.breaker.id === breakerID
    );

    if (existingBreakerFuncText) {
      // update the existing breakerFuncText in the poleConfig
      const newPoleConfig = [...poleConfig];
      const pole = newPoleConfig.find(
        (pole) => pole.breaker && pole.breaker.id === breakerID
      );
      pole.breaker.funcText = newText.funcText;
      pole.breaker.other = newText.other;
      updateCurrentChassisPoleConfig(newPoleConfig);
      return { success: true, message: "Breaker function text updated" };
    }
  };

  // function to return the breaker function text given a breaker id
  const getBreakerFuncText = (breakerID) => {
    const breaker = poleConfig.find(
      (pole) => pole.breaker && pole.breaker.id === breakerID
    );
    if (breaker) {
      return breaker.breaker.funcText;
    }
    return null;
  };

  // function to return the breaker function text and part properties given a breaker id. This is used to display the part properties in the part properties dialog and on the chassis diagram
  const getBreakerFuncTextAndOther = (breakerID) => {
    const breakerTextRef = poleConfig.find(
      (pole) => pole && pole.breaker && pole.breaker.id === breakerID
    ).breaker;
    // example breaker id: "5SY6102-7CC-0"
    // based of the part number: "5SY6102-7CC"
    // remove characters after the last dash and search for the part in the currentBreakers array
    const partRef = breakerID.substring(0, breakerID.lastIndexOf("-"));
    const breakerPartSpecs = circuitBreakers.find(
      (breaker) => breaker.part.partNumber === partRef
    );

    if (breakerTextRef && breakerPartSpecs) {
      return {
        funcText: breakerTextRef.funcText,
        other: breakerTextRef.other,
        partNum: breakerPartSpecs.part.partNumber,
        partDesc: breakerPartSpecs.part.description,
        partType: breakerPartSpecs.part.type,
        curve: breakerPartSpecs.part.curve,
        rating: breakerPartSpecs.part.rating,
        ka: breakerPartSpecs.part.ka,
      };
    }
    return {
      funcText: "",
      other: "",
      partNum: "",
      partDesc: "",
      partType: "",
      curve: "",
      rating: "",
      ka: "",
    };
  };

  const deleteBreaker = (breakerID) => {
    const newPoleConfig = [...poleConfig];
    const deletedPartNumber = breakerID.substring(
      0,
      breakerID.lastIndexOf("-")
    );
    const pole = newPoleConfig.find(
      (pole) => pole.breaker && pole.breaker.id === breakerID
    );
    pole.breaker = null;

    // update the ids of the breakers with the same part number of the deleted breaker. The id is the part number + the index of the breaker. As the quantity has changed, the index of the breakers will change and need to be updated
    const breakersWithSamePartNumber = newPoleConfig.filter(
      (pole) =>
        pole.breaker &&
        pole.breaker.id.substring(0, pole.breaker.id.lastIndexOf("-")) ===
          deletedPartNumber
    );

    breakersWithSamePartNumber.forEach((breaker, index) => {
      breaker.breaker.id = `${deletedPartNumber}-${index}`;
    });

    // update the circuitBreakers array
    const newCircuitBreakers = [...circuitBreakers];
    const breakerToDelete = newCircuitBreakers.find(
      (breaker) => breaker.part.partNumber === deletedPartNumber
    );
    if (breakerToDelete) {
      // if the quantity after deleting the breaker is 0, remove the breaker from the circuitBreakers array
      if (breakerToDelete.quantity === 1) {
        const newCircuitBreakers = circuitBreakers.filter(
          (breaker) => breaker.part.partNumber !== deletedPartNumber
        );
        handleUpdateChassisPoleConfigAndCircuitBreakers(
          newPoleConfig,
          newCircuitBreakers
        );
        return;
      } else {
        breakerToDelete.quantity = breakerToDelete.quantity - 1;

        handleUpdateChassisPoleConfigAndCircuitBreakers(
          newPoleConfig,
          newCircuitBreakers
        );
      }
    }
  };

  // function to delete a triple pole breaker
  const deleteTriplePoleBreaker = (breakerID) => {
    let newPoleConfig = [...poleConfig];

    const deletedPartNumber = breakerID.substring(
      0,
      breakerID.lastIndexOf("-")
    );

    const threePoleBreaker = newPoleConfig.find(
      (pole) =>
        pole.breaker !== null &&
        pole.breaker.id === breakerID &&
        pole.breaker.phase === 1
    );
    threePoleBreaker.breaker = null;
    const nextPole = newPoleConfig.find(
      (pole) =>
        pole.breaker !== null &&
        pole.breaker.id === breakerID &&
        pole.breaker.phase === 2
    );
    nextPole.breaker = null;
    const nextNextPole = newPoleConfig.find(
      (pole) =>
        pole.breaker !== null &&
        pole.breaker.id === breakerID &&
        pole.breaker.phase === 3
    );
    nextNextPole.breaker = null;

    // update the ids of the breakers with the same part number of the deleted breaker. The id is the part number + the index of the breaker. As the quantity has changed, the index of the breakers will change and need to be updated
    const breakersWithSamePartNumber = newPoleConfig.filter(
      (pole) =>
        pole.breaker !== null &&
        pole.breaker.id.substring(0, pole.breaker.id.lastIndexOf("-")) ===
          deletedPartNumber &&
        pole.breaker.phase === 1
    );

    // every pole of triple pole breaker is placed 2 elements greater than the last
    // pole.id === breaker.id + 2;
    // pole.id === phase2breaker.id + 2
    breakersWithSamePartNumber.forEach((breaker, index) => {
      const phase2breaker = newPoleConfig.find(
        (pole) =>
          pole.breaker !== null &&
          pole.breaker.id === breaker.breaker.id &&
          pole.id === breaker.id + 2 &&
          pole.breaker.phase === 2
      );
      phase2breaker.breaker.id = `${deletedPartNumber}-${index}`;
      const phase3breaker = newPoleConfig.find(
        (pole) =>
          pole.breaker !== null &&
          pole.breaker.id === breaker.breaker.id &&
          pole.id === phase2breaker.id + 2 &&
          pole.breaker.phase === 3
      );
      phase3breaker.breaker.id = `${deletedPartNumber}-${index}`;
      breaker.breaker.id = `${deletedPartNumber}-${index}`;
    });

    // update the circuitBreakers array
    const newCircuitBreakers = [...circuitBreakers];
    const breakerToDelete = newCircuitBreakers.find(
      (breaker) => breaker.part.partNumber === deletedPartNumber
    );
    if (breakerToDelete) {
      // if the quantity after deleting the breaker is 0, remove the breaker from the circuitBreakers array
      if (breakerToDelete.quantity === 1) {
        const newCircuitBreakers = circuitBreakers.filter(
          (breaker) => breaker.part.partNumber !== deletedPartNumber
        );
        handleUpdateChassisPoleConfigAndCircuitBreakers(
          newPoleConfig,
          newCircuitBreakers
        );
        return;
      } else {
        breakerToDelete.quantity = breakerToDelete.quantity - 1;

        handleUpdateChassisPoleConfigAndCircuitBreakers(
          newPoleConfig,
          newCircuitBreakers
        );
      }
    }
  };

  const assignBreaker = (breaker) => {
    if (breaker.type === "triple") {
      const pole = getFirstAvailableTriplePole(breaker);
      if (pole) {
        moveBreaker(breaker.id, pole, breaker.type);
      }
    } else {
      const pole = getFirstAvailableSinglePole(breaker);
      if (pole) {
        moveBreaker(breaker.id, pole, breaker.type);
      }
    }
  };

  const breakerData = () => {
    let breakerDragData = [];
    const tempCopyCircuitBreakers = [...circuitBreakers];
    tempCopyCircuitBreakers.forEach((breaker) => {
      if (breaker.part.type === "MCB") {
        if (breaker.part.poles === 1) {
          for (let i = 0; i < breaker.quantity; i++) {
            breakerDragData.push({
              id: `${breaker.part.partNumber}-${i}`,
              type: "single",
              partNumber: breaker.part.partNumber,
            });
          }
        } else {
          for (let i = 0; i < breaker.quantity; i++) {
            breakerDragData.push({
              id: `${breaker.part.partNumber}-${i}`,
              type: "triple",
              partNumber: breaker.part.partNumber,
            });
          }
        }
      } else {
        for (let i = 0; i < breaker.quantity; i++) {
          breakerDragData.push({
            id: `${breaker.part.partNumber}-${i}`,
            type: "single",
            partNumber: breaker.part.partNumber,
          });
        }
      }
    });
    return breakerDragData;
  };

  // function to check if a breaker id is already on the chassis
  const breakerAlreadyOnChassis = (breakerID) => {
    const breaker = poleConfig.find(
      (pole) => pole.breaker && pole.breaker.id === breakerID
    );
    if (breaker) {
      return true;
    }
    return false;
  };

  const breakerDragList = () => {
    breakerData().forEach((breaker) => {
      if (!breakerAlreadyOnChassis(breaker.id)) {
        assignBreaker(breaker);
      }
    });
  };

  const updateCurrentOverPole = (poleID) => {
    setCurrentOverPole(poleID);
  };

  useEffect(() => {
    breakerDragList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [circuitBreakers]);

  useEffect(() => {
    if (isHybridChassis) {
      setMaxPitch27Pole(parseInt(partNumber.substring(8, 10)));
    } else {
      setMaxPitch27Pole(0);
    }
  }, [isHybridChassis, partNumber]);

  useEffect(() => {
    if (isSuccess) {
      setAllCircuitBreakers(data);
    }
  }, [data, isSuccess]);

  return (
    <DndProvider backend={HTML5Backend}>
      <DragContext.Provider
        value={{
          moveBreaker,
          canDropBreaker,
          getFirstAvailableSinglePole,
          getFirstAvailableTriplePole,
          getPoleID,
          handleCloseBreakerDialog,
          handleOpenBreakerDialog,
          breakerDialogOpen,
          breakerToEdit,
          updateFuncText,
          getBreakerFuncText,
          getBreakerFuncTextAndOther,
          deleteBreaker,
          updateCurrentOverPole,
          currentOverPole,
          deleteTriplePoleBreaker,
        }}
      >
        {children}
      </DragContext.Provider>
    </DndProvider>
  );
};

export { DragContextProvider };
