import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Grid, InputLabel, CircularProgress, Tooltip } from "@mui/material";
import MDInput from "components/MDInput";
import { useState, useEffect } from "react";
import { AbilityContext } from "Can";
import { useAbility } from "@casl/react";
import crudsService from "services/cruds-service";
import { useDBInfo } from "services/query-service";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import Footer from "examples/Footer";
import QueryCustomTable from "customInputs/queryTables/QueryCustomTable";

function UploadDB() {
  const ability = useAbility(AbilityContext);
  const [loading, setLoading] = useState(false);
  const [fileState, setFileState] = useState(null);
  const [notification, setNotification] = useState({
    value: false,
    color: "info",
    message: "",
  });
  const [inputKey, setInputKey] = useState(Date.now());

  const {
    data: dbInfo,
    isLoading: dbInfoIsLoading,
    isSuccess: dbInfoIsSuccess,
  } = useDBInfo();

  useEffect(() => {
    if (notification.value === true) {
      setTimeout(() => {
        setNotification({ value: false, color: "info", message: "" });
      }, 10000);
    }
  }, [notification]);

  // check file type is .accdb or .mdb
  const checkFileDBType = (fileInput) => {
    const file = fileInput.files[0]; // Access the first file selected by the user
    if (!file) {
      return false;
    }
    const fileName = file.name;
    if (
      fileName &&
      ![".accdb", ".mdb", ".xlsx"].some((ext) => fileName.endsWith(ext))
    ) {
      return false;
    }
    return true;
  };

  const changeHandler = (e) => {
    const fileInput = document.getElementById("file-input");
    if (!checkFileDBType(fileInput)) {
      setNotification({
        value: true,
        color: "info",
        message:
          "Invalid file type. Only .accdb, .mdb or .xlsx files are allowed.",
      });
      setFileState(null);
      return;
    }
    const formData = new FormData();
    formData.append("attachment", e.target.files[0]);
    setFileState(formData);
  };

  const submitHandler = async (e) => {
    setInputKey(Date.now());
    e.preventDefault();
    setLoading(true);
    if (fileState === null) {
      setNotification({
        value: true,
        color: "info",
        message: "Please select the file to upload.",
      });
      setLoading(false);
      return;
    }
    try {
      // first upload and save the file then update
      await crudsService.dbUpload(fileState);

      const updatePromise = crudsService.dbUpdate();
      const timeoutPromise = new Promise((resolve, reject) => {
        setTimeout(() => {
          reject(new Error("Timeout error"));
        }, 10 * 60 * 1000); // 10 minutes timeout
      });

      const res = await Promise.race([updatePromise, timeoutPromise]);

      setNotification({
        value: true,
        color: "info",
        message: res.message,
      });
    } catch (err) {
      setNotification({
        value: true,
        color: "info",
        message: err.message,
      });
      return null;
    } finally {
      // Whether successful or not, set loading state back to false
      setLoading(false);
      setFileState(null);
    }
  };

  const dbFileUploadRows = () => {
    if (!dbInfoIsLoading && dbInfoIsSuccess) {
      const dbData = dbInfo.data.map((data) => {
        return {
          db_user: data.user.name,
          db_last_updated: data.db_upload_last_updated
            .split("-")
            .reverse()
            .join("-"),
          db_file_name: data.file_name,
        };
      });
      return dbData;
    } else {
      return [];
    }
  };

  return (
    <DashboardLayout>
      {ability.can("edit", "database") ? (
        <MDBox
          p={2}
          component="form"
          onSubmit={submitHandler}
          encType="multipart/form-data"
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MDBox component="h1" fontWeight="bold" fontSize={24}>
                Upload Database Export
              </MDBox>
            </Grid>

            <Grid item xs={12}>
              <InputLabel id="demo-simple-select-label">
                Upload .accdb, .mdb or .xlsx file
              </InputLabel>
              <MDInput
                type="file"
                key={inputKey}
                onChange={changeHandler}
                id="file-input"
                name="attachment"
                inputProps={{ accept: ".accdb, .mcb, .xlsx" }}
                sx={{ mt: "16px" }}
              />
            </Grid>
            {loading ? (
              <>
                <Grid item xs={12}>
                  <MDTypography variant="body2">
                    Please do not close or refresh this window. Do not attempt
                    to upload another file while this upload is in progress.
                  </MDTypography>
                </Grid>
                <Grid item xs={12}>
                  <CircularProgress color="info" />
                </Grid>
              </>
            ) : fileState === null ? (
              <Grid item xs={12}>
                <Tooltip title="Select the file to upload" placement="right">
                  <MDBox width="5rem">
                    <MDButton
                      variant="gradient"
                      color="dark"
                      size="small"
                      type="submit"
                      disabled={fileState === null}
                    >
                      Upload
                    </MDButton>
                  </MDBox>
                </Tooltip>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <MDButton
                  variant="gradient"
                  color="dark"
                  size="small"
                  type="submit"
                >
                  Upload
                </MDButton>
              </Grid>
            )}
            <Grid item xs={12}>
              <MDTypography variant="body2">
                Before clicking the upload button:
              </MDTypography>
              <MDTypography variant="caption" component="ul">
                <li>
                  Verify the file is not corrupted and can be accessed normally
                </li>
                <li>There is no other file upload in progress</li>
                <li>Allow up to ten minutes for the upload to complete</li>
              </MDTypography>
            </Grid>
            {!dbInfoIsLoading && dbInfoIsSuccess && (
              <Grid item xs={12}>
                <QueryCustomTable
                  data={{
                    columns: [
                      {
                        accessorKey: "db_user",
                        header: "User",
                        size: 80,
                      },
                      {
                        accessorKey: "db_last_updated",
                        header: "Updated",
                        size: 100,
                      },
                      {
                        accessorKey: "db_file_name",
                        header: "File Name",
                        size: 100,
                      },
                    ],
                    rows: dbFileUploadRows(),
                    title: "Latest Successful File Uploads",
                  }}
                />
              </Grid>
            )}
          </Grid>
        </MDBox>
      ) : (
        <MDBox component="h1" fontWeight="bold" fontSize={24} p={2}>
          Unauthorised
        </MDBox>
      )}
      {notification.value === true && (
        <MDAlert color={notification.color} mt="20px">
          <MDTypography variant="body2" color="white">
            {notification.message}
          </MDTypography>
        </MDAlert>
      )}
      <Footer />
    </DashboardLayout>
  );
}

export default UploadDB;
