import { useEffect, useContext, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApsContext } from "ApsContext";
import { useParams } from "react-router-dom";
import crudsService from "services/cruds-service";
import NewEstimate from "layouts/November/NewEstimate";

function EstimateEdit() {
  const navigate = useNavigate();
  const { overwriteContext, quoteNumber } = useContext(ApsContext);
  const { id } = useParams();
  const [notFound, setNotFound] = useState(false);

  const getQuote = useCallback(async () => {
    const quote = await crudsService.getQuotingTool(id);
    if (quote.data) {
      setNotFound(false);
      if (quoteNumber !== quote.data.quoteNumber) {
        overwriteContext(quote.data);
      }
    } else {
      setNotFound(true);
    }
  }, [id, overwriteContext, quoteNumber]);

  useEffect(() => {
    (async () => {
      await getQuote();
    })();
    if (notFound) {
      navigate("/configurator/new-quote");
    }
  }, [getQuote, id, navigate, notFound]);

  return <NewEstimate />;
}

export default EstimateEdit;
