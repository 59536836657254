import { useDrag } from "react-dnd";
import { itemTypes } from "customInputs/DragNDropChassis/dragNdropItemTypes";
import MDBox from "components/MDBox";
import threePMCB from "assets/images/aps/3pmcb.png";
import three1PMCB from "assets/images/aps/31pmcb.png";
import three2PMCB from "assets/images/aps/32pmcb.png";
import three3PMCB from "assets/images/aps/33pmcb.png";
import { Tooltip, Grid } from "@mui/material";
import MDButton from "components/MDButton";
import { useContext } from "react";
import { DragContext } from "./DragContext";
import MDTypography from "components/MDTypography";
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";

import pxToRem from "assets/theme/functions/pxToRem";

function TriplePhaseBreaker({ id, phase, flip }) {
  const {
    handleOpenBreakerDialog,
    getBreakerFuncTextAndOther,
    deleteTriplePoleBreaker,
  } = useContext(DragContext);
  const [{ tripleBreakerDragging }, drag] = useDrag(
    () => ({
      type: itemTypes.BREAKER,
      item: { id: id, type: "triple" },
      //canDrag: () => phase === 1,
      collect: (monitor) => {
        const item = monitor.getItem();
        return {
          isDragging: !!monitor.isDragging(),
          tripleBreakerDragging: item && item.id === id,
        };
      },
    }),
    [id, phase, flip]
  );

  const handleEditClick = () => {
    handleOpenBreakerDialog(id);
  };

  const handleDeleteClick = () => {
    deleteTriplePoleBreaker(id);
  };

  const { funcText, other, partNum } = getBreakerFuncTextAndOther(id);

  const toolTipPlacement = flip ? "right" : "left";
  const opToolTipPlacement = flip ? "left" : "right";

  const functionDisplayText = funcText === "" ? "" : `${funcText} ${other}`;

  const displayText = `${partNum}`;

  const innerWidth = window.innerWidth;

  const buttonsSpacing =
    innerWidth > 2200
      ? 1.5
      : innerWidth > 2000
      ? 2
      : innerWidth > 1700
      ? 2.5
      : 3.5;

  const functionDisplayTextSpacing =
    innerWidth > 2200
      ? 10.5
      : innerWidth > 2000
      ? 10
      : innerWidth > 1700
      ? 9.5
      : 8.5;

  const buttonFunctionDisplayTextGridSpacing =
    innerWidth > 2200
      ? 12
      : innerWidth > 2000
      ? 11
      : innerWidth > 1800
      ? 10
      : innerWidth > 1700
      ? 9
      : 8;

  const imageDisplayTextGridSpacing =
    innerWidth > 2200
      ? 8
      : innerWidth > 2000
      ? 9
      : innerWidth > 1800
      ? 10
      : innerWidth > 1700
      ? 11
      : 12;

  return (
    <Grid
      container
      columns={20}
      spacing={1}
      display="flex"
      justifyContent="space-between"
    >
      {!flip && (
        <Grid
          item
          display="flex"
          justifyContent="flex-start"
          xs={buttonFunctionDisplayTextGridSpacing}
        >
          <Grid container display="flex" justifyContent="flex-start">
            {phase === 1 && (
              <Grid
                item
                xs={buttonsSpacing}
                display="flex"
                justifyContent="flex-start"
              >
                <Tooltip title="Edit" placement={toolTipPlacement} arrow>
                  <MDButton
                    size="small"
                    iconOnly
                    circular
                    variant="text"
                    color="info"
                    onClick={() => handleEditClick()}
                  >
                    <Edit />
                  </MDButton>
                </Tooltip>
                <Tooltip title="Delete" placement={opToolTipPlacement} arrow>
                  <MDButton
                    size="small"
                    iconOnly
                    circular
                    variant="text"
                    color="error"
                    onClick={() => handleDeleteClick()}
                  >
                    <Delete color="error" />
                  </MDButton>
                </Tooltip>
              </Grid>
            )}
            {!flip && phase === 1 && (
              <Grid
                item
                xs={functionDisplayTextSpacing}
                pt={1}
                display="flex"
                justifyContent="flex-start"
                sx={{ overflow: "hidden" }}
              >
                <MDTypography
                  variant="caption"
                  fontWeight="medium"
                  color="error"
                  align="right"
                  noWrap
                  sx={{ fontFamily: "monospace" }}
                >
                  {functionDisplayText}
                </MDTypography>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
      <Grid item xs={imageDisplayTextGridSpacing}>
        <Grid
          container
          display="flex"
          columns={16}
          justifyContent={
            phase === 1 ? "space-between" : !flip ? "flex-end" : "flex-start"
          }
        >
          {!flip && phase === 1 && (
            <Grid item xs={6} display="flex" pt={1} justifyContent="flex-start">
              <MDTypography
                variant="caption"
                fontWeight="medium"
                color="text"
                noWrap
                sx={{ fontFamily: "monospace" }}
              >
                {displayText}
              </MDTypography>
            </Grid>
          )}
          <Grid
            item
            xs={8}
            justifyContent={!flip ? "flex-end" : "flex-start"}
            display="flex"
          >
            <MDBox
              component="img"
              src={
                tripleBreakerDragging && phase === 1
                  ? threePMCB
                  : phase === 1
                  ? three1PMCB
                  : phase === 2
                  ? three2PMCB
                  : three3PMCB
              }
              alt={id}
              minHeight={tripleBreakerDragging ? pxToRem(90) : pxToRem(30)}
              maxHeight={tripleBreakerDragging ? pxToRem(90) : pxToRem(30)}
              maxWidth={pxToRem(132)}
              ref={drag}
              sx={{
                opacity:
                  tripleBreakerDragging && phase === 1
                    ? 0.1
                    : tripleBreakerDragging && phase === 2
                    ? 0
                    : tripleBreakerDragging && phase === 3
                    ? 0
                    : 1,
                cursor: "move",
                transform: flip ? "scaleX(-1)" : null,
              }}
            />
          </Grid>
          {flip && phase === 1 && (
            <Grid item xs={6} display="flex" pt={1} justifyContent="flex-end">
              <MDTypography
                variant="caption"
                fontWeight="medium"
                color="text"
                align="right"
                noWrap
                sx={{ fontFamily: "monospace" }}
              >
                {displayText}
              </MDTypography>
            </Grid>
          )}
        </Grid>
      </Grid>
      {flip && phase === 1 && (
        <Grid item xs={buttonFunctionDisplayTextGridSpacing}>
          <Grid container display="flex" justifyContent="flex-end">
            {flip && phase === 1 && (
              <Grid
                item
                xs={functionDisplayTextSpacing}
                display="flex"
                pt={1}
                justifyContent="flex-end"
                sx={{ overflow: "hidden" }}
              >
                <MDTypography
                  variant="caption"
                  fontWeight="medium"
                  color="error"
                  align="left"
                  noWrap
                  sx={{ fontFamily: "monospace" }}
                >
                  {functionDisplayText}
                </MDTypography>
              </Grid>
            )}
            {phase === 1 && (
              <Grid
                item
                xs={buttonsSpacing}
                display="flex"
                justifyContent="flex-end"
              >
                <Tooltip title="Delete" placement={opToolTipPlacement} arrow>
                  <MDButton
                    size="small"
                    iconOnly
                    circular
                    variant="text"
                    color="error"
                    onClick={() => handleDeleteClick()}
                  >
                    <Delete color="error" />
                  </MDButton>
                </Tooltip>
                <Tooltip title="Edit" placement={toolTipPlacement} arrow>
                  <MDButton
                    size="small"
                    iconOnly
                    circular
                    variant="text"
                    color="info"
                    onClick={() => handleEditClick()}
                  >
                    <Edit />
                  </MDButton>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default TriplePhaseBreaker;
