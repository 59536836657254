/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import MDInput from "components/MDInput";

import AuthService from "services/auth-service";

function BasicInfo({ user, updateInfo }) {
  const [info, setInfo] = useState({ name: "", email: "" });
  const [notification, setNotification] = useState({
    value: false,
    color: "info",
    message: "",
  });
  const [errors, setErrors] = useState({
    nameError: false,
    emailError: false,
    textError: "",
  });

  useEffect(() => {
    setInfo({
      name: user.name,
      email: user.email,
    });
  }, [user]);

  useEffect(() => {
    if (notification.value === true) {
      setTimeout(() => {
        setNotification({ value: false, color: "info", message: "" });
      }, 5000);
    }
  }, [notification]);

  const changeHandler = (e) => {
    setInfo({
      ...info,
      [e.target.name]: e.target.value,
    });
    setErrors({ nameError: false, emailError: false, textError: "" });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    // validation
    const mailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (info.name.trim().length === 0) {
      setErrors({
        ...errors,
        nameError: true,
        textError: "Name can not be empty",
      });
      return;
    }

    if (
      info.email.trim().length === 0 ||
      !info.email.trim().match(mailFormat)
    ) {
      setErrors({
        ...errors,
        emailError: true,
        textError: "Email is invalid format",
      });
      return;
    }

    // set new user data for call
    let userData;
    userData = {
      data: {
        type: "profile",
        attributes: {
          name: info.name,
          email: info.email,
          profile_image: user.profile_image ?? null,
        },
      },
    };

    // call api for update
    await AuthService.updateProfile(JSON.stringify(userData));

    updateInfo();
    // reset errors
    setErrors({
      nameError: false,
      emailError: false,
      textError: "",
    });

    setNotification({
      value: true,
      color: "info",
      message: "Your profile has been updated",
    });
  };

  return (
    <>
      <Card id="basic-info" sx={{ overflow: "visible" }}>
        <MDBox p={3}>
          <MDTypography variant="h5">Basic Info</MDTypography>
        </MDBox>
        <MDBox
          component="form"
          pb={3}
          px={3}
          method="POST"
          onSubmit={submitHandler}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <MDInput
                fullWidth
                name="name"
                variant="standard"
                label="First Name"
                placeholder="John Smith"
                value={info.name}
                onChange={changeHandler}
                error={errors.nameError}
              />
              {errors.nameError && (
                <MDTypography
                  variant="caption"
                  color="error"
                  fontWeight="light"
                >
                  {errors.textError}
                </MDTypography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDInput
                fullWidth
                name="email"
                label="Email"
                variant="standard"
                placeholder="example@email.com"
                inputProps={{ type: "email" }}
                value={info.email}
                onChange={changeHandler}
                error={errors.emailError}
              />
            </Grid>
          </Grid>
          <MDBox ml="auto" mt={2} display="flex" justifyContent="flex-end">
            <MDButton
              variant="gradient"
              color="dark"
              size="small"
              type="submit"
            >
              update
            </MDButton>
          </MDBox>
        </MDBox>
      </Card>
      {notification.value === true && (
        <MDAlert color={notification.color} mt="20px">
          <MDTypography variant="body2" color="white">
            {notification.message}
          </MDTypography>
        </MDAlert>
      )}
    </>
  );
}

export default BasicInfo;
