import PropTypes from "prop-types";
import MDTypography from "components/MDTypography";

function DBHeightInfo({ value }) {
  return (
    <MDTypography
      variant="body2"
      textAlign="center"
      color={value > 0 ? "error" : "success"} /* {
        Math.abs(value) > 1500
          ? "error"
          : Math.abs(value) >= 1200
          ? "warning"
          : Math.abs(value) >= 800
          ? "info"
          : "success"
      } */
    >
      {`${
        value > 0
          ? `Current Assemblies require ${Math.abs(value)}mm height`
          : value === 0
          ? ""
          : `Current Bay has ${Math.abs(value)}mm spare height`
      }
      `}
      {/* `${
      value > 1500
        ? `Current Bay has ${
            value - 1500
          }mm height over the largest 1500H enclosure. Reduce/remove assemblies that occupy height`
        : value > 1200
        ? `Requires at least 1500H enclosure or if enclosure already exists, reduce/remove assemblies that occupy height`
        : value > 1000
        ? `Requires at least 1200H enclosure or if enclosure already exists, reduce/remove assemblies that occupy height`
        : value > 800
        ? `Requires at least 1000H enclosure or if enclosure already exists, reduce/remove assemblies that occupy height`
        : value > 0
        ? `Current Bay has assemblies occupying ${Math.abs(
            value
          )}mm height. Requires at least 800H enclosure or if enclosure already exists, reduce/remove assemblies that occupy height.`
        : value === 0
        ? ""
        : `Current Bay has ${Math.abs(
            value
          )}mm spare height. Add more assemblies to fill the bay`
    }` */}
    </MDTypography>
  );
}

DBHeightInfo.defaultProps = {
  value: 0,
};

DBHeightInfo.propTypes = {
  value: PropTypes.number,
};

export default DBHeightInfo;
