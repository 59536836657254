/* eslint-disable react/react-in-jsx-scope */
import { useState, useContext, useCallback } from "react";
import crudsService from "services/cruds-service";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDButton from "components/MDButton";
import { DialogContentText, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AbilityContext } from "Can";
import { useAbility } from "@casl/react";
import { ApsContext } from "ApsContext";
import { Can } from "Can";
import { subject } from "@casl/ability";
import Grid from "@mui/material/Grid";
import { useAssemblies, useCurrentUser } from "services/query-service";

import AssemblyProductCell from "customInputs/customTableCells/assemblyProductCell";
import AddAssemblyCell from "customInputs/customTableCells/addAssemblyCell";
import QueryAssembliesTable from "customInputs/queryTables/QueryAssembliesTable";
import Footer from "examples/Footer";
import CrudDialog from "customInputs/crud-dialog";
import AssemblyCard from "../components/AssemblyCard";

function AssemblyLanding() {
  const navigate = useNavigate();
  const ability = useAbility(AbilityContext);
  const { handleUpdateAssemblyNaming, promptNotification, assemblyNaming } =
    useContext(ApsContext);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [assemblyToDelete, setAssemblyToDelete] = useState(null);
  const [viewState, setViewState] = useState(false);
  const [copyDialogOpen, setCopyDialogOpen] = useState(false);
  const [copyAssembly, setCopyAssembly] = useState(null);
  const [copyLoading, setCopyLoading] = useState(false);

  const {
    data: assemblies,
    isLoading,
    isSuccess,
    refetch: refetchAssemblies,
  } = useAssemblies();

  const { data: userId, isSuccess: userSuccess } = useCurrentUser();

  const handleNewAssemblyClick = () => {
    handleUpdateAssemblyNaming("", "", 0, true, "", "");
    navigate("/configurator/new-assembly");
  };

  const handleEditAssemblyClick = useCallback(
    (assembly) => {
      navigate(`/configurator/edit-assembly/${assembly.id}`);
    },
    [navigate]
  );

  const handleDeleteAssemblyClick = (assembly) => {
    setDeleteDialogOpen(true);
    setAssemblyToDelete(assembly);
  };

  const handleActionDeleteAssembly = async () => {
    try {
      await crudsService.deletePartAssembly(assemblyToDelete.id);
      setDeleteDialogOpen(false);
      setAssemblyToDelete(null);
      refetchAssemblies();
      promptNotification("Assembly deleted", "success");
    } catch (error) {
      console.error("Error DELETE assembly: ", error);
      promptNotification("Assembly delete failed", "error");
    }
  };

  const handleCancelDeleteAssembly = () => {
    setDeleteDialogOpen(false);
    setAssemblyToDelete(null);
  };

  const handleCopyAssemblyClick = useCallback(
    (assemblyToCopy) => {
      setCopyDialogOpen(true);
      if (isSuccess && assemblies) {
        const assemblyFound = assemblies.data.find(
          (assembly) => assembly.id === assemblyToCopy.id
        );
        setCopyAssembly(assemblyFound);
      } else {
        setCopyAssembly(null);
      }
    },
    [assemblies, isSuccess]
  );

  const handleCancelCopyAssembly = () => {
    setCopyDialogOpen(false);
    setCopyAssembly(null);
  };

  const handleActionCopyAssembly = async (e) => {
    e.preventDefault();
    if (copyAssembly === null) {
      setCopyLoading(false);
      promptNotification("Error occured during copying process", "error");
      return;
    }

    if (assemblyNaming.name.trim().length === 0) {
      setCopyLoading(false);
      promptNotification("The name cannot be empty", "error");
      return;
    }

    if (assemblyNaming.description.trim().length === 0) {
      setCopyLoading(false);
      promptNotification("The description cannot be empty", "error");
      return;
    }

    if (assemblyNaming.height.length === 0) {
      setCopyLoading(false);
      promptNotification("The height cannot be empty", "error");
      return;
    }
    if (assemblyNaming.filter_1 === "") {
      setCopyLoading(false);
      promptNotification("The filter 1 cannot be empty", "error");
      return;
    }

    if (assemblyNaming.filter_2 === "") {
      setCopyLoading(false);
      promptNotification("The filter 2 cannot be empty", "error");
      return;
    }

    if (userSuccess) {
      const payload = {
        data: {
          attributes: {
            name: assemblyNaming.name,
            description: assemblyNaming.description,
            height: assemblyNaming.height,
            global: assemblyNaming.global,
            filter_1: assemblyNaming.filter_1,
            filter_2: assemblyNaming.filter_2,
            creator: userId,
            parts: copyAssembly.parts,
          },
        },
      };
      try {
        await crudsService.createPartAssembly(payload);
        promptNotification("Assembly copied and saved", "success");
        setCopyDialogOpen(false);
        refetchAssemblies();
        setCopyAssembly(null);
      } catch (error) {
        if (error.hasOwnProperty("message")) {
          promptNotification(`${error.message}`, "error");
        } else {
          promptNotification("Copying assembly failed", "error");
        }
      } finally {
        setCopyLoading(false);
      }
    } else {
      setCopyLoading(false);
      promptNotification("Unable to verify user", "error");
    }
  };

  const handleViewState = async () => {
    viewState ? setViewState(false) : setViewState(true);
  };

  // function to make the table rows for the existing assemblies with the subrows for the parts. This is the data that will be passed to the table component.
  const newPartAssemblyTableRows = useCallback(() => {
    if (isSuccess && assemblies.data) {
      return assemblies.data
        .map((assembly, index) => {
          return {
            name: assembly.assembly_name,
            description: assembly.description,
            height: `${assembly.height}`,
            state: assembly.state,
            scope: assembly.state ? "Public" : "Restricted",
            filter_1: assembly.filter_1,
            filter_2: assembly.filter_2,
            creatorOverview: (
              <AssemblyProductCell
                name={assembly.user.name}
                image={assembly.user.profile_image}
                description={assembly.created_at.split("-").reverse().join("-")}
                id={index}
              />
            ),
            updatedOverview: assembly.updated_at.split("-").reverse().join("-"),
            actionsOverview: (
              <Grid container>
                <Grid item xs={3}>
                  <Can I="edit" this={subject("assemblies", assembly)}>
                    <AddAssemblyCell
                      icon="content_copy"
                      color="dark"
                      onIconClick={handleCopyAssemblyClick}
                      assembly={assembly}
                    />
                  </Can>
                </Grid>
                <Grid item xs={3}>
                  <Can I="edit" this={subject("assemblies", assembly)}>
                    <AddAssemblyCell
                      icon="edit"
                      color="info"
                      onIconClick={handleEditAssemblyClick}
                      assembly={assembly}
                    />
                  </Can>
                </Grid>
                <Grid item xs={3}>
                  <Can I="delete" this={subject("assemblies", assembly)}>
                    <AddAssemblyCell
                      icon="delete"
                      color="error"
                      onIconClick={handleDeleteAssemblyClick}
                      assembly={assembly}
                    />
                  </Can>
                </Grid>
                <Grid item xs={3}></Grid>
              </Grid>
            ),
            subRows: assembly.parts.map((part) => {
              return {
                partNumber: part.partNumber,
                description: part.description,
                supplier: part.supplier,
                cost: part.cost.toFixed(2),
                qty: part.AssembliesRelation.quantity,
                stock: part.stock,
              };
            }),
          };
        })
        .filter((assembly) => assembly !== undefined)
        .filter((assembly) => (!viewState && assembly.state) || viewState);
    } else {
      return [
        {
          name: "",
          description: "",
          height: "",
          available: "",
          creator: "",
          updated: "",
          actions: "",
          subRows: [
            {
              partNumber: "",
              description: "",
              supplier: "",
              cost: "",
              qty: "",
              stock: "",
            },
          ],
        },
      ];
    }
  }, [
    assemblies,
    handleCopyAssemblyClick,
    handleEditAssemblyClick,
    isSuccess,
    viewState,
  ]);

  const requiredFields =
    copyLoading ||
    assemblyNaming.name === "" ||
    assemblyNaming.name === null ||
    assemblyNaming.name === undefined ||
    assemblyNaming.description === "" ||
    assemblyNaming.description === null ||
    assemblyNaming.description === undefined ||
    assemblyNaming.height === "" ||
    assemblyNaming.height === null ||
    assemblyNaming.height === undefined ||
    assemblyNaming.filter_1 === "" ||
    assemblyNaming.filter_1 === null ||
    assemblyNaming.filter_1 === undefined ||
    assemblyNaming.filter_2 === "" ||
    assemblyNaming.filter_2 === null ||
    assemblyNaming.filter_2 === undefined;

  return (
    <DashboardLayout>
      <Grid container spacing={2} my={2}>
        <Grid item xs={5}>
          <MDBox component="h1" fontWeight="bold" fontSize={24}>
            {viewState ? "All Assemblies" : "Public Assemblies"}
          </MDBox>
        </Grid>
        <Grid item xs={2}>
          {ability.can("view", "assemblies") && (
            <MDButton
              variant="gradient"
              size="small"
              color={viewState ? "secondary" : "dark"}
              fullWidth
              onClick={handleViewState}
            >
              {viewState ? "View public assemblies" : "View all assemblies"}
            </MDButton>
          )}
        </Grid>
        <Grid item xs={1.5} sx={{ ml: "auto" }}>
          {ability.can("create", "assemblies") && (
            <MDButton
              variant="gradient"
              size="small"
              color="info"
              fullWidth
              onClick={handleNewAssemblyClick}
            >
              +&nbsp; New Assembly
            </MDButton>
          )}
        </Grid>
        <Grid item xs={12}>
          {isLoading || assemblies == null ? (
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              height={300}
            >
              <CircularProgress color="info" />
            </MDBox>
          ) : assemblies.data.length < 1 ? (
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              height={300}
            >
              <MDBox component="h2" fontSize={24}>
                No Assemblies Found
              </MDBox>
            </MDBox>
          ) : (
            <QueryAssembliesTable
              assemblyData={{
                columns: [
                  { header: "Assembly Name", accessorKey: "name", size: 150 },
                  {
                    header: "Assembly Description",
                    accessorKey: "description",
                    size: 300,
                  },
                  {
                    header: "hgt (mm)",
                    accessorKey: "height",
                    size: 100,
                  },
                  {
                    header: "Scope",
                    accessorKey: "scope",
                    size: 100,
                  },
                  {
                    header: "created by",
                    accessorKey: "creatorOverview",
                    size: 200,
                  },
                  {
                    header: "updated",
                    accessorKey: "updatedOverview",
                    size: 100,
                  },
                  {
                    header: "actions",
                    accessorKey: "actionsOverview",
                    size: 100,
                  },
                ],
                rows: newPartAssemblyTableRows(),
              }}
            />
          )}
        </Grid>
      </Grid>
      <CrudDialog
        title="Copy Assembly"
        control={{
          open: copyDialogOpen,
          onClose: handleCancelCopyAssembly,
        }}
        required={requiredFields}
        content={
          <AssemblyCard
            assemblyFetched={copyAssembly ? { ...copyAssembly } : undefined}
          />
        }
        actions={{
          confirmName: "Copy",
          confirmHandler: (e) => {
            setCopyLoading(true);
            handleActionCopyAssembly(e);
          },
          confirmLoading: copyLoading,
        }}
      />
      <CrudDialog
        title="Delete Assembly"
        size="xs"
        control={{
          open: deleteDialogOpen,
          onClose: handleCancelDeleteAssembly,
        }}
        content={
          <DialogContentText>
            Are you sure you want to delete this assembly?
          </DialogContentText>
        }
        actions={{
          confirmName: "Delete",
          confirmHandler: handleActionDeleteAssembly,
        }}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default AssemblyLanding;
