import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import ClientInfo from "layouts/November/NewEstimate/EstimateInfo/components/ClientInfo";
import ProjectInfo from "layouts/November/NewEstimate/EstimateInfo/components/ProjectInfo";

function EstimateInfo() {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <MDBox mt={2} mb={1} textAlign="center">
          <MDTypography variant="h5" fontWeight="regular" color="secondary">
            Search and select a client and fill project details.
          </MDTypography>
        </MDBox>
      </Grid>
      <Grid item xs={11}>
        <MDBox borderRadius="xl" p={2} pb={4} shadow="xl" mb={2}>
          <ClientInfo />
        </MDBox>
      </Grid>
      <Grid item xs={11}>
        <MDBox borderRadius="xl" p={2} pb={4} shadow="xl" mb={2}>
          <ProjectInfo />
        </MDBox>
      </Grid>
    </Grid>
  );
}

export default EstimateInfo;
