import { useContext, useState } from "react";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import AuthService from "services/auth-service";
import { AuthContext } from "context";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Image
import bgImage from "assets/images/aps/DB-Ultimate.jpg";

function Login() {
  const authContext = useContext(AuthContext);
  // const [rememberMe, setRememberMe] = useState(false);
  const [inputs, setInputs] = useState({
    email: "name@apsindustrial.com.au",
    password: "secret",
  });
  const [errors, setErrors] = useState({
    emailError: false,
    passwordError: false,
    credentialsErros: false,
    textError: "",
  });

  // const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const changeHandler = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
    setErrors({
      emailError: false,
      passwordError: false,
      credentialsErros: false,
      textError: "",
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const mailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (
      inputs.email.trim().length === 0 ||
      !inputs.email.trim().match(mailFormat)
    ) {
      setErrors({
        ...errors,
        credentialsErros: true,
        textError:
          "Unable to login. Please check your email and password and try again.",
      });
      return;
    }

    if (inputs.password.trim().length < 8) {
      setErrors({
        ...errors,
        credentialsErros: true,
        textError:
          "Unable to login. Please check your email and password and try again.",
      });
      return;
    }

    const newUser = { email: inputs.email, password: inputs.password };

    const myData = {
      data: {
        type: "token",
        attributes: { ...newUser },
      },
    };

    try {
      const response = await AuthService.login(myData);
      if (response.hasOwnProperty("enable2fa")) {
        authContext.navigateLogin2fa(inputs.email);
      } else {
        authContext.login(response.access_token, response.refresh_token);
      }
    } catch (res) {
      if (res.hasOwnProperty("message")) {
        setErrors({
          ...errors,
          credentialsErros: true,
          textError: res.message,
        });
      } else {
        setErrors({
          ...errors,
          credentialsErros: true,
          textError: res.errors[0].detail,
        });
      }
    }

    return () => {
      setInputs({
        email: "",
        password: "",
      });

      setErrors({
        emailError: false,
        passwordError: false,
        credentialsErros: false,
        textError: "",
      });
    };
  };

  return (
    <IllustrationLayout
      title="Sign In"
      description="Enter your email and password to sign in"
      illustration={bgImage}
      disableNavBar={true}
    >
      <MDBox
        component="form"
        role="form"
        method="POST"
        onSubmit={submitHandler}
      >
        <MDBox mb={2}>
          <MDInput
            type="email"
            label="Email"
            fullWidth
            name="email"
            value={inputs.email}
            onChange={changeHandler}
            error={errors.emailError}
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="password"
            label="Password"
            fullWidth
            name="password"
            value={inputs.password}
            onChange={changeHandler}
            error={errors.passwordError}
          />
        </MDBox>
        {/* <MDBox display="flex" alignItems="center" ml={-1}>
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <MDTypography
            variant="button"
            fontWeight="regular"
            color="text"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
          >
            &nbsp;&nbsp;Remember me
          </MDTypography>
        </MDBox> */}
        {errors.credentialsErros && (
          <MDTypography variant="caption" color="error" fontWeight="light">
            {errors.textError}
          </MDTypography>
        )}
        <MDBox mt={4} mb={1}>
          <MDButton
            variant="gradient"
            color="info"
            size="large"
            fullWidth
            type="submit"
          >
            sign in
          </MDButton>
        </MDBox>
        {/* <MDBox mt={3} mb={1} textAlign="center">
          <MDTypography variant="button" color="text">
            Forgot your password? Reset it{" "}
            <MDTypography
              component={Link}
              to="/auth/forgot-password"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              here
            </MDTypography>
          </MDTypography>
        </MDBox> */}
        {/* <MDBox mt={3} textAlign="center">
          <MDTypography variant="button" color="text">
            Don&apos;t have an account?{" "}
            <MDTypography
              component={Link}
              to="/auth/register"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign up
            </MDTypography>
          </MDTypography>
        </MDBox> */}
      </MDBox>
    </IllustrationLayout>
  );
}

export default Login;
