import MDInput from "components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";

/*    Single select autocomplete component
    options (required): [string]; options to select from
    selected (required): string; selected option
    selectedAction (required): () => {}; function to call when an option is selected
    customLabel (optional): string; title for this component
    requiredField (optional): boolean; puts an * to show its required
    disable (optional): boolean; condition to disable component to read-only
    renderOption (optional): () => {}; render custom options to select options from
    getOptionLabel (optional): () => {}; label for selected option
    filterOptions (optional): () => {}; logic to filter options
 */

function SelectAutocomplete({
  options = [""],
  selected = "",
  selectedAction = () => {},
  customLabel = "",
  requiredField = false,
  disable = false,
  renderOption = (props, option) => (
    <li {...props} key={option}>{`${option}`}</li>
  ),
  getOptionLabel = (option) => option,
  filterOptions = (options, params) => {
    const filtered = options.filter((option) =>
      `${option}`.toLowerCase().includes(params.inputValue.toLowerCase())
    );
    if (filtered.length === 0) {
      filtered.push();
    }
    return filtered;
  },
}) {
  return (
    <Autocomplete
      value={selected}
      autoHighlight
      autoComplete
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      disabled={disable}
      getOptionLabel={getOptionLabel}
      options={options}
      onChange={(e, value) => {
        if (value !== null) {
          selectedAction(value);
        }
      }}
      renderOption={renderOption}
      renderInput={(params) => (
        <MDInput
          {...params}
          required={requiredField}
          label={typeof customLabel === "string" ? customLabel : ""}
          variant="standard"
        />
      )}
      filterOptions={filterOptions}
    />
  );
}

export default SelectAutocomplete;
