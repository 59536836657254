import { useMemo } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import QueryAssembliesDetailTable from "customInputs/queryTables/QueryAssembliesDetailTable";

const INITIAL_STATE = {
  density: "compact",
  showGlobalFilter: true,
  showToolbarDropZone: false,
};

function QueryAssembliesAddedTable({ assemblyData, setData }) {
  const columns = useMemo(() => assemblyData.columns, [assemblyData.columns]);
  const tableData = useMemo(() => assemblyData.rows, [assemblyData.rows]);

  const table = useMaterialReactTable({
    columns,
    data: tableData,
    initialState: INITIAL_STATE,
    enableHiding: false,
    enableFullScreenToggle: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableDensityToggle: false,
    enableTopToolbar: false,
    layoutMode: "grid",
    autoResetPageIndex: false,
    enablePagination: false,
    enableRowOrdering: true,
    enableSorting: false,
    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();
        if (hoveredRow && draggingRow) {
          assemblyData.rows.splice(
            hoveredRow.index,
            0,
            assemblyData.rows.splice(draggingRow.index, 1)[0]
          );
          setData([...assemblyData.rows]);
        }
      },
    }),
    muiTableHeadProps: {
      sx: {
        display: "table-header-group",
      },
    },
    muiTableHeadRowProps: {
      sx: {
        boxShadow: "none",
        marginLeft: -2,
        marginRight: -2,
      },
    },
    muiTablePaperProps: {
      sx: {
        width: "100%",
        px: 2,
        elevation: 0,
        backgroundColor: "transparent",
      },
    },
    muiTableContainerProps: {
      sx: {
        elevation: 0,
        borderRadius: 0,
        boxShadow: "none",
      },
    },
    muiPaginationProps: {
      showRowsPerPage: false,
      showFirstButton: false,
      showLastButton: false,
      variant: "text",
    },
    paginationDisplayMode: "pages",
    muiTableBodyCellProps: {
      sx: {
        paddingY: 0,
        borderTop: "0.1px solid #E0E0E0",
        fontSize: "0.75rem",
        fontWeight: "400",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        fontSize: "0.8rem",
        fontWeight: "600",
        textTransform: "uppercase",
      },
    },
    muiDetailPanelProps: {
      style: {
        padding: 0,
        height: "auto",
        width: "auto",
        overflowY: "auto",
      },
    },
    mrtTheme: {
      baseBackgroundColor: "#ffffff00",
    },
    renderDetailPanel: ({ row }) => (
      <QueryAssembliesDetailTable assemblyData={row} />
    ),
  });

  return <MaterialReactTable table={table} />;
}

export default QueryAssembliesAddedTable;
