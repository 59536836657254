import {
  Card,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import { Tooltip, IconButton } from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import CrudService from "services/cruds-service";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { AbilityContext } from "Can";
import { useAbility } from "@casl/react";
import { Can } from "Can";
import { subject } from "@casl/ability";
import PermissionRoleTip from "customInputs/PermissionRoleTip";
import { ApsContext } from "ApsContext";

function UserManagement() {
  const [user, setUsers] = useState([]);
  const [notification, setNotification] = useState({
    value: false,
    color: "info",
    message: "",
  });

  const { state } = useLocation();
  const navigate = useNavigate();
  const ability = useAbility(AbilityContext);
  const { promptNotification } = useContext(ApsContext);

  const [tableData, setTableData] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  useEffect(() => {
    if (!state) return;
    setNotification({
      value: state.value,
      text: state.text,
    });
  }, [state]);

  useEffect(() => {
    (async () => {
      try {
        const response = await CrudService.getUsers();
        setUsers(response.data);
      } catch (error) {
        navigate("/configurator/new-quote");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (notification.value === true) {
      setTimeout(() => {
        setNotification({ value: false, color: "info", message: "" });
      }, 5000);
    }
  }, [notification]);

  useEffect(() => {
    setTableData(getRows(user));
  }, [user]);

  const clickAddHandler = () => {
    navigate("/management/user-management/new-user");
  };

  const clickEditHandler = (id) => {
    navigate(`/management/user-management/edit-user/${id}`);
  };

  const handleActionDelete = async () => {
    try {
      await CrudService.deleteUser(userToDelete);
      const response = await CrudService.getUsers();
      setUsers(response.data);
      promptNotification("User deleted", "success");
      setDeleteDialogOpen(false);
    } catch (err) {
      // it sends error is the category is associated with an item
      console.error(err);
      if (err.hasOwnProperty("errors")) {
        promptNotification(`${err.errors[0].title}`, "error");
      }
      return null;
    }
  };

  const clickDeleteHandler = async (id) => {
    setDeleteDialogOpen(true);
    setUserToDelete(id);
  };

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false);
    setUserToDelete(null);
  };

  const getRows = (info) => {
    let updatedInfo = info
      .map((row) => {
        return {
          type: "users",
          id: row.id,
          image: row.profile_image,
          name: row.name,
          email: row.email,
          role: row.role.role_name,
          created_at: row.created_at.split("-").reverse().join("-"),
        };
      })
      .reverse();
    return updatedInfo;
  };

  const dataTableData = {
    columns: [
      {
        Header: "image",
        accessor: "image",
        width: "15%",
        disableSortBy: true,
        Cell: ({ cell: { value } }) => {
          return (
            <>
              <MDAvatar src={value} alt="profile-image" size="xl" shadow="sm" />
            </>
          );
        },
      },
      { Header: "name", accessor: "name", width: "15%" },
      { Header: "email", accessor: "email", width: "20%" },
      { Header: "role", accessor: "role", width: "15%" },
      { Header: "created at", accessor: "created_at", width: "15%" },
      {
        Header: "actions",
        disableSortBy: true,
        accessor: "",
        Cell: (info) => {
          return info.cell.row.original.role === "Admin" ? (
            ability.can("modify", "admin") && (
              <MDBox display="flex" alignItems="center">
                <Can I="delete" this={subject("users", info.cell.row.original)}>
                  <Tooltip
                    title="Delete User"
                    onClick={() =>
                      clickDeleteHandler(info.cell.row.original.id)
                    }
                  >
                    <IconButton>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Can>
                <Can I="edit" this={subject("users", info.cell.row.original)}>
                  <Tooltip
                    title="Edit User"
                    onClick={() => clickEditHandler(info.cell.row.original.id)}
                  >
                    <IconButton>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </Can>
              </MDBox>
            )
          ) : (
            <MDBox display="flex" alignItems="center">
              <Can I="delete" this={subject("users", info.cell.row.original)}>
                <Tooltip
                  title="Delete User"
                  onClick={() => clickDeleteHandler(info.cell.row.original.id)}
                >
                  <IconButton>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Can>
              <Can I="edit" this={subject("users", info.cell.row.original)}>
                <Tooltip
                  title="Edit User"
                  onClick={() => clickEditHandler(info.cell.row.original.id)}
                >
                  <IconButton>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Can>
            </MDBox>
          );
        },
      },
    ],

    rows: tableData,
  };

  return (
    <DashboardLayout>
      {notification.value && (
        <MDAlert color="info" my="20px">
          <MDTypography variant="body2" color="white">
            {notification.text}
          </MDTypography>
        </MDAlert>
      )}
      {ability.can("view", "users") ? (
        <MDBox pt={6} pb={3}>
          <PermissionRoleTip />
          <MDBox mb={3}>
            <Card>
              <MDBox
                p={3}
                lineHeight={1}
                display="flex"
                justifyContent="space-between"
              >
                <MDTypography variant="h5" fontWeight="medium">
                  User Management
                </MDTypography>

                {ability.can("create", "users") && (
                  <MDButton
                    variant="gradient"
                    color="dark"
                    size="small"
                    type="submit"
                    onClick={clickAddHandler}
                  >
                    + Add User
                  </MDButton>
                )}
              </MDBox>
              <DataTable table={dataTableData} />
            </Card>
          </MDBox>
          <Dialog
            open={deleteDialogOpen}
            onClose={() => setDeleteDialogOpen(false)}
          >
            <DialogTitle>Delete User</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete this user?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <MDButton
                variant="gradient"
                color="error"
                onClick={() => handleCancelDelete()}
              >
                Cancel
              </MDButton>
              <MDButton
                variant="gradient"
                color="success"
                onClick={() => handleActionDelete()}
              >
                Delete
              </MDButton>
            </DialogActions>
          </Dialog>
        </MDBox>
      ) : (
        <MDBox component="h1" fontWeight="bold" fontSize={24} p={2}>
          Unauthorised
        </MDBox>
      )}
      <Footer />
    </DashboardLayout>
  );
}

export default UserManagement;
