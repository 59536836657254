import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import QueryCustomTable from "customInputs/queryTables/QueryCustomTable";
import MDButton from "components/MDButton";

// lineItemsData: lineItems from database
// data: line items added for the quote
function LineItemsDialog({ open, onClose, lineItemsData, data }) {
  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <DialogTitle>Factory Installed Items</DialogTitle>
      <DialogContent dividers sx={{}}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <QueryCustomTable
              data={{
                columns: [
                  {
                    accessorKey: "partNumberLineItems",
                    header: "Item Number",
                    size: 150,
                  },
                  {
                    accessorKey: "descriptionLineItems",
                    header: "Item Description",
                    size: 500,
                  },
                  {
                    accessorKey: "supplierLineItems",
                    header: "Supplier",
                    size: 80,
                  },
                  {
                    accessorKey: "basePriceLineItems",
                    header: "Base Price ($)",
                    size: 100,
                    muiTableHeadCellProps: {
                      align: "right",
                    },
                    muiTableBodyCellProps: {
                      align: "right",
                    },
                  },
                  {
                    accessorKey: "actionsLineItems",
                    header: "Actions",
                    size: 80,
                    muiTableHeadCellProps: {
                      align: "right",
                    },
                    muiTableBodyCellProps: {
                      align: "right",
                    },
                  },
                ],
                rows: lineItemsData,
                title: "Add Factory Installed Items",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <QueryCustomTable
              data={{
                columns: [
                  {
                    accessorKey: "partNumberAddedLineItems",
                    header: "Item Number",
                    size: 150,
                  },
                  {
                    accessorKey: "descriptionAddedLineItems",
                    header: "Item Description",
                    size: 300,
                  },
                  {
                    accessorKey: "supplierAddedLineItems",
                    header: "Supplier",
                    size: 80,
                  },
                  {
                    accessorKey: "basePriceAddedLineItems",
                    header: "Base Price ($)",
                    size: 100,
                    muiTableHeadCellProps: {
                      align: "right",
                    },
                    muiTableBodyCellProps: {
                      align: "right",
                    },
                  },
                  {
                    accessorKey: "discountAddedLineItems",
                    header: "Discount (%)",
                    size: 100,
                    muiTableHeadCellProps: {
                      align: "right",
                    },
                    muiTableBodyCellProps: {
                      align: "right",
                    },
                  },
                  {
                    accessorKey: "quantityAddedLineItems",
                    header: "Qty",
                    size: 50,
                    muiTableHeadCellProps: {
                      align: "right",
                    },
                    muiTableBodyCellProps: {
                      align: "right",
                    },
                  },
                  {
                    accessorKey: "finalPriceAddedLineItems",
                    header: "Price ($)",
                    size: 100,
                    muiTableHeadCellProps: {
                      align: "right",
                    },
                    muiTableBodyCellProps: {
                      align: "right",
                    },
                  },
                  {
                    accessorKey: "actionsAddedLineItems",
                    header: "Actions",
                    size: 80,
                    muiTableHeadCellProps: {
                      align: "right",
                    },
                    muiTableBodyCellProps: {
                      align: "right",
                    },
                  },
                ],
                rows: data,
                title: "Current Factory Installed Items",
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <MDButton onClick={onClose}>Close</MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default LineItemsDialog;
