import { useContext } from "react";
import ChassisDropZone from "customInputs/DragNDropChassis/ChassisDropZone";
import TriplePhaseBreaker from "customInputs/DragNDropChassis/TriplePhaseBreaker";
import SinglePhaseBreaker from "customInputs/DragNDropChassis/SinglePhaseBreaker";
import Grid from "@mui/material/Grid";
import { ApsContext } from "ApsContext";

function DragTestBaseChassis() {
  const { getCurrentChassis } = useContext(ApsContext);
  const { poleConfig, circuitBreakers } = getCurrentChassis();

  function RenderBreaker({ poleID }) {
    const pole = poleConfig.find((pole) => pole.id === poleID);
    const breakerID =
      pole.hasOwnProperty("breaker") && pole.breaker
        ? pole.breaker.id.substring(0, pole.breaker.id.lastIndexOf("-"))
        : "";
    const breaker = circuitBreakers.find(
      (breaker) => breaker.part.partNumber === breakerID
    );
    if (pole && pole.breaker && breaker) {
      let flip = false;
      if (poleID % 2 === 0) {
        flip = true;
      }
      if (pole.breaker.type === "triple") {
        if (pole.breaker.phase === 1) {
          return (
            <TriplePhaseBreaker id={pole.breaker.id} phase={1} flip={flip} />
          );
        } else if (pole.breaker.phase === 2) {
          return (
            <TriplePhaseBreaker id={pole.breaker.id} phase={2} flip={flip} />
          );
        }
        return (
          <TriplePhaseBreaker id={pole.breaker.id} phase={3} flip={flip} />
        );
      } else if (breaker && breaker.part.type === "RCBO") {
        return (
          <SinglePhaseBreaker
            id={pole.breaker.id}
            flip={flip}
            type={breaker.part.type}
          />
        );
      } else {
        return (
          <SinglePhaseBreaker
            id={pole.breaker.id}
            flip={flip}
            type={breaker.part.type}
          />
        );
      }
    }
    return null;
  }

  return (
    <Grid container spacing={0.2}>
      {poleConfig.map((pole, index) => {
        return (
          <Grid item xs={6} key={index}>
            <ChassisDropZone key={pole.id} poleID={pole.id}>
              <RenderBreaker poleID={pole.id} />
            </ChassisDropZone>
          </Grid>
        );
      })}
    </Grid>
  );
}

export default DragTestBaseChassis;
