import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import PropTypes from "prop-types";
import { useState, useContext } from "react";
import { ApsContext } from "ApsContext";
import { useAPSDB } from "hooks/useAPSDB";

function SelectedPartCardDialog({
  currentTableSelection,
  handleQuantityChange,
  handleAddPart,
  open,
  onClose,
}) {
  const { partQtyLimit } = useContext(ApsContext);
  const { preventKeys, preventPasteNegative } = useAPSDB();
  const { part } = currentTableSelection;
  const [localQty, setLocalQty] = useState("");

  const submitHandler = async (e) => {
    if (
      e.key === "Enter" &&
      currentTableSelection.quantity > 0 &&
      localQty !== undefined &&
      localQty !== null &&
      localQty !== "" &&
      localQty !== 0
    ) {
      e.preventDefault();
      setLocalQty("");
      await handleAddPart();
    }
  };

  const handleQtyComponentChange = (e) => {
    const inputValue = Number(e.target.value);
    const validQtyValue =
      inputValue <= partQtyLimit ? inputValue : partQtyLimit;
    handleQuantityChange(validQtyValue);
    setLocalQty(validQtyValue);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle>Part Details</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {/* <MDBox
              height="100%"
              width="100%"
              coloredShadow="error"
              borderRadius="lg"
              sx={{
                backgroundImage: `url(${demoPartImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
          </Grid>
          <Grid item xs={7}> */}
            {currentTableSelection.part.partNumber !== "" ? (
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <MDTypography
                    variant="body2"
                    fontWeight="medium"
                    align="center"
                  >
                    PART NUMBER
                  </MDTypography>
                  <MDTypography
                    variant="body2"
                    fontWeight="regular"
                    align="center"
                  >
                    {part.partNumber}
                  </MDTypography>
                </Grid>
                <Grid item xs={12}>
                  <MDTypography
                    variant="body2"
                    fontWeight="medium"
                    align="center"
                  >
                    DESCRIPTION
                  </MDTypography>
                  <MDTypography
                    variant="body2"
                    fontWeight="regular"
                    align="center"
                  >
                    {part.description}
                  </MDTypography>
                </Grid>
                <Grid item xs={12}>
                  <MDTypography
                    variant="body2"
                    fontWeight="medium"
                    align="center"
                  >
                    SUPPLIER
                  </MDTypography>
                  <MDTypography
                    variant="body2"
                    fontWeight="regular"
                    align="center"
                  >
                    {part.supplier}
                  </MDTypography>
                </Grid>
                <Grid item xs={12}>
                  <MDTypography
                    variant="body2"
                    fontWeight="medium"
                    align="center"
                  >
                    COST {"("}${")"}
                  </MDTypography>
                  <MDTypography
                    variant="body2"
                    fontWeight="regular"
                    align="center"
                  >
                    {part.cost}
                  </MDTypography>
                </Grid>
                <Grid item xs={12}>
                  <MDTypography
                    variant="body2"
                    fontWeight="medium"
                    align="center"
                  >
                    LABOUR {"("}MINS{")"}
                  </MDTypography>
                  <MDTypography
                    variant="body2"
                    fontWeight="regular"
                    align="center"
                  >
                    {part.labour}
                  </MDTypography>
                </Grid>
              </Grid>
            ) : (
              <MDTypography variant="h6" fontWeight="medium">
                No Part Selected
              </MDTypography>
            )}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <MDTypography
          variant="body2"
          fontWeight="regular"
          sx={{ mr: "1rem", mt: "1rem" }}
        >
          ENTER QTY:
        </MDTypography>
        <MDInput
          autoFocus
          onKeyDown={submitHandler}
          variant="standard"
          disabled={currentTableSelection.part.partNumber === ""}
          size="small"
          label="Item Qty"
          type="number"
          value={localQty}
          onChange={handleQtyComponentChange}
          InputProps={{
            inputProps: { min: 0 },
            onKeyPress: preventKeys,
            onPaste: preventPasteNegative,
          }}
          sx={{ maxWidth: "8rem", mr: "1rem" }}
        />
        <MDButton
          color="error"
          size="small"
          variant="gradient"
          onClick={() => {
            setLocalQty("");
            onClose();
          }}
        >
          Cancel
        </MDButton>
        <MDButton
          disabled={
            currentTableSelection.quantity <= 0 ||
            localQty === undefined ||
            localQty === null ||
            localQty === "" ||
            localQty === 0
          }
          color="success"
          size="small"
          variant="gradient"
          onClick={() => {
            setLocalQty("");
            handleAddPart();
          }}
        >
          Add
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

// Setting default values for the props of SinglePartCard
SelectedPartCardDialog.defaultProps = {
  currentTableSelection: {
    part: {
      filter1: "",
      filter2: "",
      partNumber: "",
      description: "",
      supplier: "",
      cost: 0,
      labour: 0,
    },
    quantity: 0,
  },
  handleQuantityChange: () => {},
  handleAddPart: () => {},
  open: false,
  onClose: () => {},
};

// Typechecking props for the SinglePartCard
SelectedPartCardDialog.propTypes = {
  currentTableSelection: PropTypes.shape({
    part: PropTypes.shape({
      partNumber: PropTypes.string,
      description: PropTypes.string,
      supplier: PropTypes.string,
      cost: PropTypes.number,
      labour: PropTypes.number,
    }),
    quantity: PropTypes.number,
  }),
  handleQuantityChange: PropTypes.func,
  handleAddPart: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default SelectedPartCardDialog;
