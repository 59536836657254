import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import { useAPSDB } from "hooks/useAPSDB";

// function to create a table cell for the part quantity as a number input/stepper
/**
 * Renders a cell with a quantity input field for a part group item.
 * @param {Object} props - The component props.
 * @param {number} props.currentQty - The current quantity value.
 * @param {Function} props.handleUpdateQty - The function to handle quantity updates.
 * @param {Object} props.partGroupItem - The part group item object.
 * @returns {JSX.Element} - The JSX element for the part quantity cell.
 */
function PartQtyCell({ currentQty, handleUpdateQty, partGroupItem }) {
  const { preventPasteNegative, preventKeys } = useAPSDB();
  const localHandleUpdateQty = (e, partGroupItem) => {
    handleUpdateQty(e, partGroupItem);
  };

  return (
    <MDBox
      display="flex"
      alignItems="center"
      onClick={(e) => e.stopPropagation()}
    >
      <MDInput
        variant="standard"
        size="small"
        type="number"
        value={currentQty}
        InputProps={{
          inputProps: { min: 1, style: { textAlign: "right" } },
          onKeyPress: preventKeys,
          onKeyDown: preventKeys,
          onPaste: preventPasteNegative,
        }}
        onChange={(e) => {
          e.preventDefault();
          const inputValue = e.target.value;
          if (inputValue > 0) {
            if (inputValue > 500) {
              localHandleUpdateQty({ target: { value: 500 } }, partGroupItem);
            } else {
              localHandleUpdateQty(e, partGroupItem);
            }
          } else if (inputValue === "") {
            localHandleUpdateQty({ target: { value: 1 } }, partGroupItem);
          }
        }}
      />
    </MDBox>
  );
}

// Typechecking props for the PartQtyCell
PartQtyCell.propTypes = {
  currentQty: PropTypes.number.isRequired,
  handleUpdateQty: PropTypes.func.isRequired,
  partGroupItem: PropTypes.object.isRequired,
};

export default PartQtyCell;
