import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Tooltip } from "@mui/material";

function DeleteCell({ icon, color, tip, tipPlacement, handleDelete, part }) {
  return (
    <MDBox display="flex" alignItems="center">
      <Tooltip title={tip} placement={tipPlacement} arrow>
        <MDButton
          variant="text"
          color={color}
          size="small"
          iconOnly
          circular
          onClick={() => handleDelete(part)}
        >
          <Icon>{icon}</Icon>
        </MDButton>
      </Tooltip>
    </MDBox>
  );
}

DeleteCell.defaultProps = {
  icon: "delete",
  tipPlacement: "right",
  color: "error",
  tip: "Delete",
};

// Typechecking props for the StatusCell
DeleteCell.propTypes = {
  icon: PropTypes.string,
  color: PropTypes.string,
  tip: PropTypes.string,
  tipPlacement: PropTypes.string,
  handleDelete: PropTypes.func.isRequired,
  part: PropTypes.object.isRequired,
};

export default DeleteCell;
