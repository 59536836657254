import { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import Header from "cruds/user-profile/components/Header";
import BasicInfo from "cruds/user-profile/components/BasicInfo";
import ChangePassword from "cruds/user-profile/components/ChangePassword";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import AuthService from "services/auth-service";
import Footer from "examples/Footer";
import Setup2FA from "./components/2fa-setup";
import { ApsContext } from "ApsContext";

function UserProfile() {
  const { promptNotification } = useContext(ApsContext);
  const [user, setUser] = useState({
    id: "",
    name: "",
    email: "",
    password: "",
    newPassword: "",
    currentPassword: "",
    confirmPassword: "",
    profile_image: "",
    verified: false,
    enable2fa: false,
    require2fa: null,
  });

  const [updateInfo, setUpdateInfo] = useState(true);

  const handleUpdateInfo = () => {
    setUpdateInfo(true);
  };

  useEffect(() => {
    if (updateInfo) {
      (async () => {
        try {
          const response = await AuthService.getProfile();
          setUser((prevUser) => ({
            ...prevUser,
            id: response.data.id,
            name: response.data.attributes.name,
            email: response.data.attributes.email,
            profile_image: response.data.attributes.profile_image,
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
            verified: response.data.attributes.verified,
            enable2fa: response.data.attributes.enable2fa,
            require2fa: response.data.attributes.require2fa,
          }));
        } catch (error) {
          promptNotification(
            `Error: ${
              error.hasOwnProperty("message")
                ? error.message
                : "Unable to load details"
            }`,
            "error"
          );
        }
      })();
      setUpdateInfo(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateInfo]);

  return (
    <DashboardLayout>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} mt={2}>
          <MDBox component="h1" fontWeight="bold" fontSize={24}>
            User Profile
          </MDBox>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={10}>
          <MDBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Header user={user} updateInfo={handleUpdateInfo} />
              </Grid>
              <Grid item xs={12}>
                <Setup2FA user={user} updateInfo={handleUpdateInfo} />
              </Grid>
              <Grid item xs={12}>
                <BasicInfo user={user} updateInfo={handleUpdateInfo} />
              </Grid>
              <Grid item xs={12}>
                <ChangePassword user={user} />
              </Grid>
            </Grid>
          </MDBox>
        </Grid>
      </Grid>
      <Footer />
    </DashboardLayout>
  );
}

export default UserProfile;
