import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

function AssemblySkeleton() {
  return (
    <Stack spacing={1}>
      {/* For variant="text", adjust the height via font-size */}
      <Skeleton variant="text" sx={{ fontSize: "20rem" }} />
      {/* For other variants, adjust the size with `width` and `height` */}
      <Skeleton variant="circular" width={140} height={140} />
      <Skeleton variant="rectangular" width={420} height={120} />
      <Skeleton variant="rounded" width={420} height={200} />
    </Stack>
  );
}

export default AssemblySkeleton;
