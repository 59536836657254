/* eslint-disable react/react-in-jsx-scope */

import EditUser from "cruds/user-management/edit-user";
import NewUser from "cruds/user-management/new-user";
import EstimateEdit from "layouts/November/EstimateEdit";
import AssemblyEdit from "layouts/November/Assembly/AssemblyEdit";
import AssemblyNew from "layouts/November/Assembly/AssemblyNew";

// Material Dashboard 2 PRO React layouts

const crudRoutes = [
  {
    key: "new-user",
    route: "/management/user-management/new-user",
    component: <NewUser />,
    type: "users",
  },
  {
    key: "edit-user",
    route: "/management/user-management/edit-user/:id",
    component: <EditUser />,
    type: "users",
  },
  {
    key: "edit-assembly",
    route: "/configurator/edit-assembly/:id",
    component: <AssemblyEdit />,
    type: "assembly",
  },
  {
    key: "new-assembly",
    route: "/configurator/new-assembly",
    component: <AssemblyNew />,
    type: "assembly",
  },
  {
    key: "edit-quote",
    route: "/configurator/edit-quote/:id",
    component: <EstimateEdit />,
    type: "quote",
  },
];

export default crudRoutes;
