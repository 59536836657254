import HttpService from "./http.service";

class CrudService {
  // categories requests
  getCategories = async () => {
    const categoriesEndpoint = "categories";
    return await HttpService.get(categoriesEndpoint);
  };

  deleteCategory = async (id) => {
    const endpoint = `categories/${id}`;
    return await HttpService.delete(endpoint);
  };

  createCategory = async (payload) => {
    const endpoint = "categories";
    return await HttpService.post(endpoint, payload);
  };

  getCategory = async (id) => {
    const categoriesEndpoint = `categories/${id}`;
    return await HttpService.get(categoriesEndpoint);
  };

  updateCategory = async (payload, id) => {
    const categoriesEndpoint = `categories/${id}`;
    return await HttpService.patch(categoriesEndpoint, payload);
  };

  // tag requests
  getTags = async () => {
    const tagsEndpoint = "tags";
    return await HttpService.get(tagsEndpoint);
  };

  deleteTag = async (id) => {
    const endpoint = `tags/${id}`;
    return await HttpService.delete(endpoint);
  };

  createTag = async (payload) => {
    const endpoint = "tags";
    return await HttpService.post(endpoint, payload);
  };

  getTag = async (id) => {
    const endpoint = `tags/${id}`;
    return await HttpService.get(endpoint);
  };

  updateTag = async (payload, id) => {
    const endpoint = `tags/${id}`;
    return await HttpService.patch(endpoint, payload);
  };

  // item requests
  getItems = async () => {
    const tagsEndpoint = "items";
    return await HttpService.get(tagsEndpoint);
  };

  deleteItem = async (id) => {
    const endpoint = `items/${id}`;
    return await HttpService.delete(endpoint);
  };

  getCategoryOfItem = async (id) => {
    const endpoint = `items/${id}/category`;
    return await HttpService.get(endpoint);
  };

  getTagsOfItem = async (id) => {
    const endpoint = `items/${id}/tags`;
    return await HttpService.get(endpoint);
  };

  createItem = async (payload) => {
    const endpoint = "items";
    return await HttpService.post(endpoint, payload);
  };

  itemImageUpload = async (formData, id) => {
    const imageUpdate = `uploads/items/${id}/image`;
    return await HttpService.post(imageUpdate, formData);
  };

  getItem = async (id) => {
    const endpoint = `items/${id}?include=category,tags`;
    return await HttpService.get(endpoint);
  };

  updateItem = async (payload, id) => {
    const endpoint = `items/${id}`;
    return await HttpService.patch(endpoint, payload);
  };

  createQuote = async (payload) => {
    const endpoint = "apsquote";
    return await HttpService.post(endpoint, payload);
  };
  getQuotes = async () => {
    const endpoint = "apsquote";
    return await HttpService.get(endpoint);
  };
  getQuote = async (id) => {
    const endpoint = `apsquote/${id}`;
    return await HttpService.get(endpoint);
  };
  updateQuote = async (payload, id) => {
    const endpoint = `apsquote/${id}`;
    return await HttpService.patch(endpoint, payload);
  };
  deleteQuote = async (id) => {
    const endpoint = `apsquote/${id}`;
    return await HttpService.delete(endpoint);
  };
  getUserQuotingTools = async (id) => {
    const endpoint = `quotingtool/user/${id}`;
    return await HttpService.get(endpoint);
  };

  // ------------------------

  // users services
  // -----------------------------------------------
  imageUpload = async (formData, id) => {
    const imageUpdate = `users/${id}/profile-image`;
    return await HttpService.post(imageUpdate, formData);
  };

  getUsers = async () => {
    const usersEndpoint = "users";
    return await HttpService.get(usersEndpoint);
  };

  deleteUser = async (id) => {
    const endpoint = `users/${id}`;
    return await HttpService.delete(endpoint);
  };

  createUser = async (payload) => {
    const endpoint = "users";
    return await HttpService.post(endpoint, payload);
  };

  getUser = async (id) => {
    const endpoint = `users/${id}`;
    return await HttpService.get(endpoint);
  };

  getUserWithPermissions = async (id) => {
    const endpoint = `users/${id}/permissions`;
    return await HttpService.get(endpoint);
  };

  updateUser = async (payload, id) => {
    const endpoint = `users/${id}`;
    return await HttpService.patch(endpoint, payload);
  };
  // -----------------------------------------------

  // assemblies services
  // -----------------------------------------------
  getPartAssemblies = async () => {
    const endpoint = `partassembly`;
    return await HttpService.get(endpoint);
  };

  getUserPartAssemblies = async (id) => {
    const endpoint = `partassembly/user/${id}`;
    return await HttpService.get(endpoint);
  };

  getPartAssembly = async (id) => {
    const endpoint = `partassembly/${id}`;
    return await HttpService.get(endpoint);
  };

  createPartAssembly = async (payload) => {
    const endpoint = `partassembly`;
    return await HttpService.post(endpoint, payload);
  };

  updatePartAssembly = async (payload, id) => {
    const endpoint = `partassembly/${id}`;
    return await HttpService.patch(endpoint, payload);
  };

  deletePartAssembly = async (id) => {
    const endpoint = `partassembly/${id}`;
    return await HttpService.delete(endpoint);
  };
  // -----------------------------------------------

  // parts services
  // -----------------------------------------------
  getParts = async () => {
    const partsendpoint = `parts`;
    return await HttpService.get(partsendpoint);
  };

  getCircuitBreakers = async () => {
    const endpoint = "parts/circuit-breakers";
    return await HttpService.get(endpoint);
  };

  // line items (NewItems table)
  getNewItems = async () => {
    const endpoint = `parts/new-items`;
    return await HttpService.get(endpoint);
  };

  // line items discounts using customer deb_acc as id
  getNewItemsDiscounts = async (id) => {
    const endpoint = `parts/new-items/discounts/${id}`;
    return await HttpService.get(endpoint);
  };
  // -----------------------------------------------

  // quoting services
  // -----------------------------------------------
  getQuotingTools = async () => {
    const endpoint = `quotingtool`;
    return await HttpService.get(endpoint);
  };

  getQuotingTool = async (id) => {
    const endpoint = `quotingtool/${id}`;
    return await HttpService.get(endpoint);
  };

  createQuotingTool = async (payload) => {
    const endpoint = `quotingtool`;
    return await HttpService.post(endpoint, payload);
  };

  updateQuotingTool = async (payload, id) => {
    const endpoint = `quotingtool/${id}`;
    return await HttpService.patch(endpoint, payload);
  };

  deleteQuotingTool = async (id) => {
    const endpoint = `quotingtool/${id}`;
    return await HttpService.delete(endpoint);
  };
  // -----------------------------------------------

  // terms and conditions default list
  getDefaultTCList = async () => {
    const endpoint = "tclist";
    return await HttpService.get(endpoint);
  };

  // roles services
  // -----------------------------------------------
  getRoles = async () => {
    const rolesEndpoint = "roles";
    return await HttpService.get(rolesEndpoint);
  };

  deleteRole = async (id) => {
    const endpoint = `roles/${id}`;
    return await HttpService.delete(endpoint);
  };

  createRole = async (payload) => {
    const endpoint = "roles";
    return await HttpService.post(endpoint, payload);
  };

  updateRole = async (payload, id) => {
    const endpoint = `roles/${id}`;
    return await HttpService.patch(endpoint, payload);
  };

  getRole = async (id) => {
    const endpoint = `roles/${id}`;
    return await HttpService.get(endpoint);
  };
  // -----------------------------------------------

  // get customers
  getCustomers = async (subString) => {
    const endpoint = `customers/${subString}`;
    return await HttpService.get(endpoint);
  };

  // get estimators
  getEstimators = async () => {
    const endpoint = "customers/customer-details/estimators";
    return await HttpService.get(endpoint);
  };

  // upload db file
  dbUpload = async (payload) => {
    const endpoint = `dbupload/`;
    return await HttpService.post(endpoint, payload);
  };

  // update db file
  dbUpdate = async () => {
    const endpoint = `dbupload/`;
    return await HttpService.patch(endpoint);
  };

  getdbInfo = async () => {
    const endpoint = `dbupload/update`;
    return await HttpService.get(endpoint);
  };
}

export default new CrudService();
