import { useMemo, useState, useEffect } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Autocomplete } from "@mui/material";
import { useParts } from "services/query-service";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import PropTypes from "prop-types";
import AddAssemblyCell from "customInputs/customTableCells/addAssemblyCell";

const COLUMNS = [
  {
    accessorKey: "filter1",
    header: "Filter 1",
  },
  {
    accessorKey: "filter2",
    header: "Filter 2",
  },
  {
    accessorKey: "partNumber",
    header: "Part Number",
    size: 150,
  },
  {
    accessorKey: "description",
    header: "Part Description",
    size: 500,
  },
  {
    accessorKey: "supplier",
    header: "Supplier",
    size: 150,
  },
  {
    accessorKey: "stock",
    header: "Stock",
    size: 80,
  },
  {
    accessorKey: "cost",
    header: "Cost ($)",
    size: 100,
    muiTableHeadCellProps: {
      align: "right",
    },
    muiTableBodyCellProps: {
      align: "right",
    },
  },
  {
    accessorKey: "labour",
    header: "Labour (min.)",
    size: 140,
    muiTableHeadCellProps: {
      align: "right",
    },
    muiTableBodyCellProps: {
      align: "right",
    },
  },

  {
    header: "Actions",
    accessorKey: "add",
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableSorting: false,
    size: 100,
    muiTableHeadCellProps: {
      align: "right",
    },
    muiTableBodyCellProps: {
      align: "right",
    },
  },
];

const INITIAL_STATE = {
  density: "compact",
  columnVisibility: { filter1: false, filter2: false },
  showGlobalFilter: true,
  showToolbarDropZone: false,
};

function QueryTable({ addSinglePart }) {
  const { data, isSuccess } = useParts();
  const [currentFilter1, setCurrentFilter1] = useState("");
  const [currentFilter2, setCurrentFilter2] = useState("");

  const getFilter1Options = (data) => {
    if (!isSuccess) return [];

    const filter1Options = new Set();
    filter1Options.add("");
    data.forEach((part) => {
      filter1Options.add(part.filter1);
    });

    return Array.from(filter1Options);
  };

  const getFilter2Options = (data, filter1Val) => {
    if (!isSuccess) return [];

    const filter2Options = new Set();
    filter2Options.add("");
    data.forEach((part) => {
      if (part.filter1 === filter1Val) {
        filter2Options.add(part.filter2);
      }
    });

    return Array.from(filter2Options);
  };

  const tableData = useMemo(() => {
    if (!isSuccess) return [];

    return data.map((part) => ({
      filter1: part.filter1,
      filter2: part.filter2,
      partNumber: part.partNumber,
      description: part.description,
      supplier: part.supplier,
      cost: part.cost.toFixed(2),
      labour: part.labour,
      stock: part.stock,
      add: (
        <AddAssemblyCell
          icon="add"
          color="success"
          onIconClick={addSinglePart}
          assembly={part}
        />
      ),
    }));
  }, [isSuccess, data, addSinglePart]);

  const columns = useMemo(() => COLUMNS, []);

  const table = useMaterialReactTable({
    columns,
    data: tableData,
    initialState: INITIAL_STATE,
    enableHiding: false,
    enableFullScreenToggle: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableDensityToggle: false,
    layoutMode: "grid",
    autoResetPageIndex: false,

    renderTopToolbarCustomActions: ({ table }) => {
      return (
        <MDBox
          display="flex"
          width="50%"
          justifyContent="space-between"
          alignItems="center"
        >
          <MDBox width="15rem">
            <Autocomplete
              autoHighlight
              value={currentFilter1}
              options={getFilter1Options(data)}
              onChange={(e, val) => {
                if (val === null || val === "") {
                  table.resetColumnFilters();
                  setCurrentFilter1("");
                  setCurrentFilter2("");
                } else {
                  table.setColumnFilters([
                    { id: "filter1", value: val },
                    { id: "filter2", value: getFilter2Options(data, val)[0] },
                  ]);
                  setCurrentFilter1(val);
                  setCurrentFilter2(getFilter2Options(data, val)[0]);
                }
              }}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  size="medium"
                  variant="standard"
                  label="Filter 1"
                />
              )}
            />
          </MDBox>
          <MDBox width="15rem">
            <Autocomplete
              autoHighlight
              value={currentFilter2}
              options={getFilter2Options(data, currentFilter1)}
              onChange={(e, val) => {
                if (val === null || val === "") {
                  table.setColumnFilters((currentFilters) =>
                    currentFilters.filter((filter) => filter.id !== "filter2")
                  );

                  setCurrentFilter2(val);
                  return;
                } else {
                  table.setColumnFilters((currentFilters) => [
                    ...currentFilters.filter(
                      (filter) => filter.id !== "filter2"
                    ),
                    { id: "filter2", value: val },
                  ]);
                  setCurrentFilter2(val);
                }
              }}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  size="medium"
                  variant="standard"
                  label="Filter 2"
                />
              )}
            />
          </MDBox>
        </MDBox>
      );
    },
    muiTableHeadProps: {
      sx: {
        display: "table-header-group",
        padding: 0,
      },
    },
    muiTableHeadRowProps: {
      sx: {
        boxShadow: "none",
      },
    },
    muiTablePaperProps: {
      sx: {
        width: "100%",
        px: 2,
        elevation: 0,
        backgroundColor: "transparent",
      },
    },
    muiTableContainerProps: {
      sx: {
        mt: 2,
        elevation: 0,
        borderRadius: 0,
        boxShadow: "none",
      },
    },
    muiPaginationProps: {
      rowsPerPageOptions: [10, 20, 30],
      showRowsPerPage: false,
      showFirstButton: false,
      showLastButton: false,
      variant: "text",
    },
    paginationDisplayMode: "pages",
    muiTableBodyCellProps: {
      align: "left",
      sx: {
        paddingY: 0,
        borderTop: "0.1px solid #E0E0E0",
        fontSize: "0.75rem",
        fontWeight: "400",
      },
    },
    muiTableHeadCellProps: {
      align: "left",
      sx: {
        fontSize: "0.8rem",
        fontWeight: "600",
        textTransform: "uppercase",
      },
    },
    mrtTheme: {
      baseBackgroundColor: "#ffffff00",
    },
  });

  useEffect(() => {
    if (isSuccess) {
      // Set the column filters here
      table.setColumnFilters((currentFilters) => [
        ...currentFilters.filter((filter) => filter.id !== "filter1"),
        { id: "filter1", value: currentFilter1 },
      ]);
      table.setColumnFilters((currentFilters) => [
        ...currentFilters.filter((filter) => filter.id !== "filter2"),
        { id: "filter2", value: currentFilter2 },
      ]);
    }
  }, [isSuccess, currentFilter1, currentFilter2, table]);

  return <MaterialReactTable table={table} />;
}

QueryTable.defaultProps = {
  addSinglePart: () => {},
};

QueryTable.propTypes = {
  addSinglePart: PropTypes.func,
};

export default QueryTable;
