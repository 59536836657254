import { useState, useContext, useEffect } from "react";
import { ApsContext } from "ApsContext";
import { useAPSDB } from "hooks/useAPSDB";
import { DragContextProvider } from "customInputs/DragNDropChassis/DragContext";
import { useCurrentUser } from "services/query-service";
import crudsService from "services/cruds-service";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import EstimateInfo from "layouts/November/NewEstimate/EstimateInfo";
import EstimateSave from "layouts/November/NewEstimate/EstimateSave";
import EstimatePartSelection from "layouts/November/NewEstimate/EstimatePartSelection";
import EstimateTerms from "layouts/November/NewEstimate/EstimateTerms";
import EstimateChassis from "layouts/November/NewEstimate/EstimateChassis";

import UltimateLayout from "examples/LayoutContainers/UltimateLayout";
import {
  StepButton,
  Step,
  Stepper,
  StepLabel,
  Tooltip,
  CircularProgress,
} from "@mui/material";
/* import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share"; */
import Grid from "@mui/material/Grid";
import ApsTreeMenu from "customInputs/apsTreeMenu";
import MDTypography from "components/MDTypography";

/* const actions = [
  { icon: <FileCopyIcon />, name: "Copy" },
  { icon: <SaveIcon />, name: "Save" },
  { icon: <PrintIcon />, name: "Print" },
  { icon: <ShareIcon />, name: "Share" },
]; */

function getSteps() {
  return [
    "Quote Information",
    "Part Selection",
    "Chassis Configuration",
    "Terms and Conditions",
    "Review and Save",
  ];
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return <EstimateInfo />;
    case 1:
      return <EstimatePartSelection />;
    case 2:
      return <EstimateChassis />;
    case 3:
      return <EstimateTerms />;
    case 4:
      return <EstimateSave />;
    default:
      return null;
  }
}

function NewEstimateB() {
  const {
    quotingDB,
    currentClient,
    currentProject,
    titleList,
    currentQuoteNum,
    revisionVersion,
    ogRevisionVersion,
    allAddCostings,
    quoteID,
    updateQuoteID,
    approvalStatus,
    lineItems,
    promptNotification,
    resetQuoteStep,
    updateResetQuoteStep,
    updateTitleList,
    resetContext,
  } = useContext(ApsContext);
  const { calcTotalPrice } = useAPSDB();
  const [activeStep, setActiveStep] = useState(0);
  const [saveLoading, setSaveLoading] = useState(false);
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;

  /*  const { data: quote, isLoading: quoteIsLoading } = useQuote(); */

  const { data: user, isSuccess: userSuccess } = useCurrentUser();

  // function to save the quote
  const handleSaveQuotingTool = async () => {
    if (userSuccess) {
      // create a new object to save the quote
      const payload = {
        quoteNumber: currentQuoteNum,
        revision: revisionVersion,
        additionalCodes: allAddCostings,
        customer: currentClient,
        project: currentProject,
        quotingDB: quotingDB,
        titleList: titleList,
        creator: user,
        approvalStatus: approvalStatus,
        approved_by: "not_approved_yet",
        line_items: lineItems,
        total_price: calcTotalPrice(),
      };

      // save the quote to the database
      try {
        const res = await crudsService.createQuotingTool(payload);
        updateQuoteID(res.data.id);
        promptNotification("Quote created and saved", "success");
      } catch (error) {
        promptNotification("Quote failed to create", "error");
      }
    }
    setTimeout(() => {
      setSaveLoading(false);
    }, 1000);
  };

  // function to update the quote using the quoteID. Using crudsService.updateQuotingTool(payload, id)
  // payload contains customer, quotingDB, titleList, additionalCodes, project, revision, approvalStatus, approved_by
  // id is the quoteID

  const handleUpdateQuotingTool = async () => {
    if (userSuccess) {
      const currentUser = await crudsService.getUser(user);
      // create a new object to update the quote
      const payload = {
        customer: currentClient,
        project: currentProject,
        quotingDB: quotingDB,
        titleList: titleList,
        additionalCodes: allAddCostings,
        revision: revisionVersion,
        approvalStatus: approvalStatus,
        approved_by:
          approvalStatus !== "draft"
            ? currentUser.data.attributes.name
            : "not_approved_yet",
        line_items: lineItems,
        total_price: calcTotalPrice(),
      };
      // save the quote to the database
      try {
        await crudsService.updateQuotingTool(payload, quoteID);
        promptNotification("Quote updated and saved", "success");
      } catch (error) {
        promptNotification("Quote failed to save changes", "error");
      }
    }
    setTimeout(() => {
      setSaveLoading(false);
    }, 1000);
  };

  // reset title list and active step
  // when navigating to new quote tab. handleNavAway in src\examples\Sidenav\index.js
  useEffect(() => {
    if (resetQuoteStep) {
      (async () => {
        setActiveStep(0);
        await resetContext();
        updateResetQuoteStep();
      })();
    }
  }, [resetQuoteStep, updateResetQuoteStep, updateTitleList, resetContext]);

  // first render to fetch title list
  // only call when not editing a quote (empty quote number)
  useEffect(() => {
    (async () => {
      if (currentQuoteNum === "") {
        await resetContext();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuoteNum]);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const viewingHeight = window.innerHeight;

  return (
    <UltimateLayout>
      <Grid container minHeight={viewingHeight} sx={{ maxHeight: "100vh" }}>
        <Grid item xs={2.5} minHeight={viewingHeight} maxHeight={viewingHeight}>
          <ApsTreeMenu viewingHeight={viewingHeight} />
        </Grid>
        <Grid
          item
          xs={9.5}
          minHeight={viewingHeight}
          maxHeight={viewingHeight}
          display="flex"
          justifyContent="space-between"
        >
          <Grid container pl={2}>
            <Grid item xs={12} pb={2}>
              <Stepper
                activeStep={activeStep}
                alternativeLabel
                sx={{
                  background: "none",
                  boxShadow: "none",
                  borderRadius: "0px",
                }}
              >
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepButton
                      color="inherit"
                      onClick={handleStep(index)}
                      sx={{
                        maxHeight: "40px",
                      }}
                    >
                      <StepLabel
                        sx={{
                          maxHeight: "40px",
                        }}
                      >
                        {index === activeStep ? (
                          <MDTypography variant="h6" color="info">
                            {label}
                          </MDTypography>
                        ) : index < activeStep ? (
                          <MDTypography
                            variant="button"
                            fontWeight="regular"
                            color="success"
                            opacity={0.8}
                          >
                            {label}
                          </MDTypography>
                        ) : (
                          <MDTypography
                            variant="button"
                            fontWeight="regular"
                            opacity={0.6}
                          >
                            {label}
                          </MDTypography>
                        )}
                      </StepLabel>
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="flex-start">
              <Grid container display="flex" justifyContent="space-between">
                <Grid
                  item
                  xs={12}
                  maxHeight={viewingHeight - 175}
                  minHeight={viewingHeight - 175}
                  sx={{ overflowY: "auto", overflowX: "clip" }}
                >
                  {getStepContent(activeStep)}
                </Grid>
                <Grid item xs={12}>
                  <Grid container my={2}>
                    <Grid
                      item
                      xs={4}
                      display="flex"
                      justifyContent="flex-start"
                    >
                      {activeStep !== 0 && (
                        <MDButton
                          variant="gradient"
                          color="dark"
                          size="medium"
                          onClick={handleBack}
                        >
                          back
                        </MDButton>
                      )}
                    </Grid>
                    <Grid item xs={4} display="flex" justifyContent="center">
                      {saveLoading ? (
                        <MDBox
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <CircularProgress color="info" />
                        </MDBox>
                      ) : (
                        currentClient.customerName !== "" &&
                        currentClient.customerName !== undefined &&
                        currentClient.customerName !== null &&
                        currentClient.contactName !== "" &&
                        currentClient.contactName !== undefined &&
                        currentClient.contactName !== null &&
                        currentClient.businessName !== "" &&
                        currentClient.contactName !== undefined &&
                        currentClient.contactName !== null &&
                        currentProject.projectName !== "" &&
                        currentProject.projectName !== undefined &&
                        currentProject.projectName !== null &&
                        currentProject.crmOpp !== "" &&
                        currentProject.crmOpp !== undefined &&
                        currentProject.crmOpp !== null &&
                        (quoteID === "" ||
                        ogRevisionVersion !== revisionVersion ? (
                          <MDButton
                            color="success"
                            size="medium"
                            variant="gradient"
                            onClick={() => {
                              setSaveLoading(true);
                              handleSaveQuotingTool();
                            }}
                          >
                            Save Quote
                          </MDButton>
                        ) : (
                          <MDButton
                            color="success"
                            size="medium"
                            variant="gradient"
                            onClick={() => {
                              setSaveLoading(true);
                              handleUpdateQuotingTool();
                            }}
                          >
                            Save Changes
                          </MDButton>
                        ))
                      )}
                    </Grid>
                    <Grid item xs={4} display="flex" justifyContent="flex-end">
                      {isLastStep ? (
                        <>
                          {/* <SpeedDial
                          ariaLabel="SpeedDial basic example"
                          sx={{
                            position: "absolute",
                            bottom: 16,
                            right: 16,
                          }}
                          icon={<SpeedDialIcon />}
                        >
                          {actions.map((action) => (
                            <SpeedDialAction
                              key={action.name}
                              icon={action.icon}
                              tooltipTitle={action.name}
                            />
                          ))}
                        </SpeedDial> */}
                        </>
                      ) : currentClient.customerName === "" ||
                        currentClient.customerName === undefined ||
                        currentClient.customerName === null ||
                        currentClient.contactName === "" ||
                        currentClient.contactName === undefined ||
                        currentClient.contactName === null ||
                        currentClient.businessName === "" ||
                        currentClient.businessName === undefined ||
                        currentClient.businessName === null ||
                        currentProject.projectName === "" ||
                        currentProject.projectName === undefined ||
                        currentProject.projectName === null ||
                        currentProject.crmOpp === "" ||
                        currentProject.crmOpp === undefined ||
                        currentProject.crmOpp === null ? (
                        <Tooltip
                          title="Fill out all the required fields before proceeding"
                          placement="left"
                        >
                          <MDBox>
                            <MDButton
                              variant="gradient"
                              color="dark"
                              size="medium"
                              disabled
                            >
                              next
                            </MDButton>
                          </MDBox>
                        </Tooltip>
                      ) : (
                        <MDButton
                          variant="gradient"
                          color="dark"
                          size="medium"
                          onClick={!isLastStep ? handleNext : undefined}
                        >
                          next
                        </MDButton>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </UltimateLayout>
  );
}

function NewEstimate() {
  return (
    <DragContextProvider>
      <NewEstimateB />
    </DragContextProvider>
  );
}

export default NewEstimate;
