import { itemTypes } from "customInputs/DragNDropChassis/dragNdropItemTypes";
import { useDrop } from "react-dnd";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import { useContext, useEffect, useState, useCallback } from "react";
import { DragContext } from "customInputs/DragNDropChassis/DragContext";
import pxToRem from "assets/theme/functions/pxToRem";
import { ApsContext } from "ApsContext";
import MDTypography from "components/MDTypography";

function ChassisDropZone({ poleID, children }) {
  const {
    moveBreaker,
    canDropBreaker,
    currentOverPole,
    updateCurrentOverPole,
  } = useContext(DragContext);
  const { getCurrentChassis } = useContext(ApsContext);
  const { poleConfig, isHybridChassis, partNumber } = getCurrentChassis();
  const [wayCounts, setWayCounts] = useState(0);
  const [maxpitch27Pole, setMaxPitch27Pole] = useState(0);
  const [hasBreaker, setHasBreaker] = useState(
    poleConfig.find((pole) => pole.id === poleID && pole.breaker)
  );

  // counts the way number for the drop zone
  const wayCount = useCallback(() => {
    // based on pole count set the wayCount
    return poleID % 6 === 0 // 6, 12, 18, 24...
      ? (poleID / 6) * 2
      : (poleID + 1) % 6 === 0 // 5, 11, 17, 23...
      ? (poleID + 1) / 3 - 1
      : (poleID + 2) % 6 === 0 // 4, 10, 16, 22...
      ? ((poleID + 2) / 6) * 2
      : poleID % 3 === 0 // 3, 9, 15, 21...
      ? poleID / 3
      : (poleID + 4) % 6 === 0 // 2, 8, 14, 20...
      ? ((poleID + 4) / 6) * 2
      : (poleID + 2) % 3 === 0 // 1, 7, 13, 19...
      ? (poleID + 2) / 3
      : 0;
  }, [poleID]);

  const [{ isOverCurrent }, drop] = useDrop(
    () => ({
      accept: itemTypes.BREAKER,
      canDrop: (item) => canDropBreaker(item),
      drop: (item) => {
        moveBreaker(item.id, poleID, item.type);
      },
      collect: (monitor) => ({
        isOverCurrent: !!monitor.isOver(),
      }),
    }),
    [
      /* poleConfig,
      poleID,
      moveBreaker,
      currentOverPole,
      updateCurrentOverPole, */
      canDropBreaker,
    ]
  );

  useEffect(() => {
    setWayCounts(wayCount());
  }, [wayCount]);

  useEffect(() => {
    if (isOverCurrent && currentOverPole !== poleID) {
      updateCurrentOverPole(poleID);
    }
  }, [currentOverPole, isOverCurrent, poleID, updateCurrentOverPole]);

  useEffect(() => {
    setHasBreaker(
      poleConfig.find((pole) => pole.id === poleID && pole.breaker)
    );
  }, [poleConfig, poleID]);

  useEffect(() => {
    if (isHybridChassis) {
      setMaxPitch27Pole(parseInt(partNumber.substring(8, 10)));
    } else {
      setMaxPitch27Pole(0);
    }
  }, [isHybridChassis, partNumber]);

  const innerWidth = window.innerWidth;

  const poleSpacing = isHybridChassis
    ? innerWidth > 2000
      ? 1.5
      : innerWidth > 1900
      ? 1.75
      : 2
    : innerWidth > 2100
    ? 1
    : innerWidth > 2000
    ? 0.75
    : innerWidth > 1900
    ? 1
    : 1.25;

  const childrenSpacing = isHybridChassis
    ? innerWidth > 2000
      ? 10.5
      : innerWidth > 1900
      ? 10.25
      : 10
    : innerWidth > 2100
    ? 11
    : innerWidth > 2000
    ? 11.25
    : innerWidth > 1900
    ? 11
    : 10.75;

  return (
    <Grid
      // remove border if pole has a breaker
      border={hasBreaker ? 0 : "1px dashed black"}
      ref={drop}
      minHeight={pxToRem(30)}
      maxHeight={pxToRem(30)}
      sx={{ position: "relative" }}
      container
    >
      {isOverCurrent && (
        <MDBox
          p={0}
          bgColor="light"
          variant="gradient"
          width="100%"
          minHeight={pxToRem(30)}
          height={pxToRem(30)}
          sx={{
            position: "absolute",
            top: 0, // Add these to cover the entire parent
            left: 0,
            opacity: 0.5,
            zIndex: -10,
          }}
        ></MDBox>
      )}
      {poleID % 2 !== 0 && (
        <Grid
          item
          xs={poleSpacing}
          display="flex"
          justifyContent="flex-start"
          textAlign="left"
          pl={1}
          pt={1}
        >
          {/* left side of chassis config */}
          <MDTypography
            verticalAlign="baseline"
            fontWeight="bold"
            sx={{ fontFamily: "monospace" }}
            variant="caption"
            color={
              (poleID - 5) % 6 === 0
                ? "info"
                : poleID % 3 === 0
                ? "dark"
                : "error"
            }
          >
            {`${poleID < 10 ? `0${poleID}` : poleID}-${
              (poleID - 5) % 6 === 0 ? "B" : poleID % 3 === 0 ? "W" : "R"
            }${wayCounts < 10 ? `0${wayCounts}` : wayCounts} ${
              poleID <= maxpitch27Pole ? "27mm" : ""
            }`}
          </MDTypography>
        </Grid>
      )}
      <Grid item xs={childrenSpacing}>
        {children}
      </Grid>
      {poleID % 2 === 0 && (
        <Grid
          item
          xs={poleSpacing}
          display="flex"
          justifyContent="flex-end"
          textAlign="right"
          pr={1}
          pt={1}
        >
          {/* right side of chassis config */}
          <MDTypography
            verticalAlign="baseline"
            fontWeight="bold"
            sx={{ fontFamily: "monospace" }}
            variant="caption"
            color={
              poleID % 6 === 0
                ? "info"
                : (poleID - 4) % 6 === 0
                ? "dark"
                : "error"
            }
          >
            {`${poleID <= maxpitch27Pole ? "27mm" : ""} ${
              poleID % 6 === 0 ? "B" : (poleID - 4) % 6 === 0 ? "W" : "R"
            }${wayCounts < 10 ? `0${wayCounts}` : wayCounts}-${
              poleID < 10 ? `0${poleID}` : poleID
            } `}
          </MDTypography>
        </Grid>
      )}
    </Grid>
  );
}

export default ChassisDropZone;
