import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import PropTypes from "prop-types";
import { useState, useContext } from "react";
import { useAPSDB } from "hooks/useAPSDB";
import { ApsContext } from "ApsContext";

function PartDetailDialog({
  currentTableSelection,
  handlePartDetailChange,
  handleAddPart,
  open,
  onClose,
}) {
  const { partQtyLimit, partCostLimit, partLabourLimit } =
    useContext(ApsContext);
  const { preventKeys, preventPasteNegative } = useAPSDB();
  const [partNumber, setPartNumber] = useState("default");
  const [supplier, setSupplier] = useState("default");
  const [cost, setCost] = useState("-1");
  const [description, setDescription] = useState("default");
  const [labour, setLabour] = useState("-1");
  const [localQty, setLocalQty] = useState("");

  const submitHandler = async (e) => {
    if (
      e.key === "Enter" &&
      currentTableSelection.quantity > 0 &&
      partNumber !== undefined &&
      partNumber !== null &&
      partNumber !== "" &&
      supplier !== undefined &&
      supplier !== null &&
      supplier !== "" &&
      cost !== undefined &&
      cost !== null &&
      cost !== "" &&
      description !== undefined &&
      description !== null &&
      description !== "" &&
      labour !== undefined &&
      labour !== null &&
      labour !== "" &&
      localQty !== undefined &&
      localQty !== null &&
      localQty !== ""
    ) {
      e.preventDefault();
      setPartNumber("default");
      setSupplier("default");
      setCost("-1");
      setDescription("default");
      setLabour("-1");
      setLocalQty("");
      await handleAddPart();
    }
  };

  const handleQtyComponentChange = (e) => {
    const inputValue = Number(e.target.value);
    const validQtyValue =
      inputValue <= partQtyLimit ? inputValue : partQtyLimit;
    handlePartDetailChange(validQtyValue, "quantity");
    setLocalQty(validQtyValue);
  };

  const handleLabourComponentChange = (e) => {
    const inputValue = Number(e.target.value);
    const validLabourValue =
      inputValue <= partLabourLimit ? inputValue : partLabourLimit;
    if (inputValue !== "") {
      handlePartDetailChange(validLabourValue, "labour");
      setLabour(validLabourValue);
    } else {
      handlePartDetailChange(0, "labour");
      setLabour(0);
    }
  };

  const handleCostComponentChange = (e) => {
    const inputValue = Number(e.target.value);
    const validCostValue =
      inputValue <= partCostLimit ? inputValue : partCostLimit;
    if (inputValue !== "") {
      handlePartDetailChange(validCostValue, "cost");
      setCost(validCostValue);
    } else {
      handlePartDetailChange(0, "cost");
      setCost(0);
    }
  };

  const resetCancelAction = () => {
    setPartNumber("default");
    setDescription("default");
    setSupplier("default");
    setCost("-1");
    setLabour("-1");
    setLocalQty("");
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      onKeyDown={submitHandler}
    >
      <DialogTitle>Part Details</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {/* <MDBox
              height="100%"
              width="100%"
              coloredShadow="error"
              borderRadius="lg"
              sx={{
                backgroundImage: `url(${demoPartImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            /> */}
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <MDInput
                  label="Part Number"
                  autoFocus
                  type="text"
                  fullWidth
                  required
                  value={currentTableSelection.part.partNumber}
                  onChange={(e) => {
                    handlePartDetailChange(e.target.value, "partNumber");
                    setPartNumber(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <MDInput
                  label="Supplier"
                  type="text"
                  fullWidth
                  required
                  disabled={currentTableSelection.part.partNumber === ""}
                  value={currentTableSelection.part.supplier}
                  onChange={(e) => {
                    handlePartDetailChange(e.target.value, "supplier");
                    setSupplier(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <MDInput
                  label="Cost ($)"
                  type="number"
                  fullWidth
                  disabled={currentTableSelection.part.partNumber === ""}
                  value={
                    !isNaN(currentTableSelection.part.cost)
                      ? currentTableSelection.part.cost
                      : 0
                  }
                  InputProps={{
                    inputProps: { min: 0, step: 0.01 },
                    onKeyPress: preventKeys,
                    onPaste: preventPasteNegative,
                  }}
                  onChange={handleCostComponentChange}
                />
              </Grid>
              <Grid item xs={4}>
                <MDInput
                  label="Labour (min.)"
                  type="number"
                  fullWidth
                  disabled={currentTableSelection.part.partNumber === ""}
                  value={
                    !isNaN(currentTableSelection.part.labour)
                      ? currentTableSelection.part.labour
                      : 0
                  }
                  InputProps={{
                    inputProps: { min: 0 },
                    onKeyPress: preventKeys,
                    onPaste: preventPasteNegative,
                  }}
                  onChange={handleLabourComponentChange}
                />
              </Grid>
              <Grid item xs={12}>
                <MDInput
                  label="Description"
                  type="text"
                  fullWidth
                  required
                  multiline
                  minRows={2}
                  disabled={currentTableSelection.part.partNumber === ""}
                  value={currentTableSelection.part.description}
                  onChange={(e) => {
                    handlePartDetailChange(e.target.value, "description");
                    setDescription(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <MDTypography variant="body2" fontWeight="regular" sx={{ mr: "1rem" }}>
          Type in Quantity Required:
        </MDTypography>
        <MDInput
          variant="standard"
          disabled={currentTableSelection.part.partNumber === ""}
          size="small"
          label="Item Qty"
          type="number"
          required
          defaultValue={""}
          InputProps={{
            inputProps: { min: 1 },
            onKeyPress: preventKeys,
            onPaste: preventPasteNegative,
          }}
          onChange={handleQtyComponentChange}
          sx={{ maxWidth: "5rem", mr: "1rem" }}
        />
        <MDButton
          color="error"
          size="small"
          variant="contained"
          onClick={() => {
            resetCancelAction();
            onClose();
          }}
        >
          Cancel
        </MDButton>
        <MDButton
          disabled={
            currentTableSelection.quantity <= 0 ||
            partNumber === undefined ||
            partNumber === null ||
            partNumber === "" ||
            supplier === undefined ||
            supplier === null ||
            supplier === "" ||
            cost === undefined ||
            cost === null ||
            cost === "" ||
            description === undefined ||
            description === null ||
            description === "" ||
            labour === undefined ||
            labour === null ||
            labour === "" ||
            localQty === undefined ||
            localQty === null ||
            localQty === ""
          }
          color="success"
          size="small"
          variant="contained"
          onClick={() => {
            resetCancelAction();
            handleAddPart();
          }}
        >
          Add
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

// Setting default values for the props of SinglePartCard
PartDetailDialog.defaultProps = {
  currentTableSelection: {
    part: {
      filter1: "",
      filter2: "",
      partNumber: "",
      description: "",
      supplier: "",
      cost: 0,
      labour: 0,
    },
    quantity: 0,
  },
  handlePartDetailChange: () => {},
  handleAddPart: () => {},
  open: false,
  onClose: () => {},
};

// Typechecking props for the SinglePartCard
PartDetailDialog.propTypes = {
  currentTableSelection: PropTypes.shape({
    part: PropTypes.shape({
      partNumber: PropTypes.string,
      description: PropTypes.string,
      supplier: PropTypes.string,
      cost: PropTypes.number,
      labour: PropTypes.number,
    }),
    quantity: PropTypes.number,
  }),
  handlePartDetailChange: PropTypes.func,
  handleAddPart: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default PartDetailDialog;
