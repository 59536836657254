import UserProfile from "cruds/user-profile";
import UserManagement from "cruds/user-management";
import Icon from "@mui/material/Icon";
import EstimateLanding from "layouts/November/EstimateLanding";
import NewEstimate from "layouts/November/NewEstimate";
import AssemblyLanding from "layouts/November/Assembly/AssemblyLanding";
import UploadDB from "layouts/November/UploadDB";
import AssemblyNew from "layouts/November/Assembly/AssemblyNew";

const routes = [
  {
    type: "collapse",
    name: "Aps User",
    key: "user",
    collapse: [
      {
        name: "Profile",
        key: "user-profile",
        route: "user/user-profile",
        component: <UserProfile />,
      },
      {
        name: "Logout",
        key: "logout",
      },
    ],
  },
  { type: "divider", key: "divider-1" },
  {
    type: "collapse",
    name: "Main Tools",
    key: "configurator",
    icon: <Icon fontSize="medium">developer_board</Icon>,
    collapse: [
      {
        name: "New Quote",
        key: "new-quote",
        route: "/configurator/new-quote",
        component: <NewEstimate />,
      },
      {
        name: "Quote List",
        key: "quote-landing",
        route: "/configurator/quote-landing",
        component: <EstimateLanding />,
      },
      {
        name: "Create Assembly",
        key: "new-assembly",
        route: "/configurator/new-assembly",
        component: <AssemblyNew />,
        type: "assemblies",
      },
      {
        name: "Assembly List",
        key: "assembly-landing",
        route: "/configurator/assembly-landing",
        component: <AssemblyLanding />,
      },
    ],
  },
  { type: "divider", key: "divider-2" },
  {
    type: "collapse",
    name: "Management",
    key: "management",
    icon: <Icon fontSize="medium">engineering</Icon>,
    collapse: [
      {
        name: "User List",
        key: "user-management",
        route: "management/user-management",
        component: <UserManagement />,
        type: "users",
      },
      {
        name: "Database Export Upload",
        key: "upload-dbexport",
        route: "management/upload-dbexport",
        component: <UploadDB />,
        type: "database",
      },
    ],
  },
];

export default routes;
