// generate pdf quote documentation
import jsPDF from "jspdf";
import "jspdf-autotable";

import landingPage from "assets/images/aps/quoteTitlePage.png";
import apsLogo from "assets/images/aps/aps-logo-retina.png";
import tandC from "assets/images/aps/tandC.jpg";
import { useContext } from "react";
import { ApsContext } from "ApsContext";
import { customerLetter } from "layouts/November/NewEstimate/EstimateTerms/Data";
import { useAPSDB } from "hooks/useAPSDB";

const defaultPDFStart = 15;
// Structure of titleList below:
// titleList is
// const titleList: {
//  checkedTitle: boolean;
//  idTitle: string;
//  title: string;
//  points: {
//      checked: boolean;
//      id: string;
//      point: string;
//  }[];
// }[]
function PDFListGenerator() {
  const {
    currentClient,
    currentProject,
    revisionVersion,
    titleList,
    currentQuoteNum,
    allAddCostings,
    quotingDB,
    lineItems,
  } = useContext(ApsContext);
  const {
    getDateValidity,
    getCurrentDate,
    handleDbPartsMapped,
    handleLineItemCosts,
  } = useAPSDB();

  // table of parts for one db
  const generateApsPartsTable = (doc, data, startY) => {
    const makeTableRows = () => {
      let rows = [
        [
          // generate the table header
          { content: data.identifier, styles: { halign: "left" } },
          { content: data.Name, styles: { halign: "left" } },
          "                                                                                   ",
          `$${data.totalUnitPrice.toFixed(2)}`,
          `${data.quantity}`,
          `$${data.totalPrice.toFixed(2)}`,
        ],
      ];
      return rows;
    };

    doc.autoTable({
      startY: startY,
      head: [
        [
          { content: "ID", styles: { halign: "left" } },
          { content: "Name", styles: { halign: "left" } },
          "                                                                                   ",
          "Unit Price (excl. GST)",
          "Qty",
          "Total (excl. GST)",
        ],
      ],
      body: makeTableRows(),
      theme: "striped",
      headStyles: {
        fillColor: [255, 0, 0],
        textColor: [255, 255, 255],
        fontSize: 7,
        cellPadding: 0.75,
        overflow: "linebreak",
        valign: "bottom",
        halign: "right",
      },
      styles: {
        fontSize: 7,
        cellPadding: 0.75,
        overflow: "linebreak",
        halign: "right",
      },
    });

    const makeDescTableRows = () => {
      const descRows = data.UniqueParts.filter(
        (part) => part.part.inclDesc === "Y"
      ).map(({ quantity, part }) => [
        `${quantity} x ${part.description}`,
        part.stock || "",
      ]);
      return descRows;
    };

    doc.autoTable({
      startY: doc.lastAutoTable.finalY,
      head: [["Description", "Stock"]],
      body: makeDescTableRows(),
      theme: "striped",
      headStyles: {
        fillColor: [255, 0, 0],
        textColor: [255, 255, 255],
        fontSize: 7,
        cellPadding: 0.75,
        overflow: "linebreak",
        halign: "left",
      },
      styles: {
        fontSize: 7,
        cellPadding: 0.75,
        overflow: "linebreak",
        halign: "left",
      },
    });
  };

  // table of line items
  const generateLineItemsTable = (doc, startY) => {
    doc.autoTable({
      startY: startY,
      head: [["Factory Installed Items"]],
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [255, 0, 0],
        fontSize: 12,
        cellPadding: 1,
        overflow: "linebreak",
        valign: "bottom",
        halign: "center",
      },
    });

    const makeDescTableRows = () => {
      let idTracker = quotingDB[quotingDB.length - 1].id;
      idTracker++;
      let bodyRows = lineItems.map((item) => {
        return [
          { content: `${idTracker++}`, styles: { halign: "left" } },
          { content: `${item.description}`, styles: { halign: "left" } },
          `$${item.finalPrice.toFixed(2)}`,
          `${item.quantity}`,
          `$${(item.finalPrice * item.quantity).toFixed(2)}`,
        ];
      });
      bodyRows.push([
        "",
        "",
        "",
        { content: "Total", styles: { fontStyle: "bold" } },
        {
          content: `$${handleLineItemCosts(
            lineItems
          ).totalLineItemsCost.toFixed(2)}`,
          styles: { fontStyle: "bold" },
        },
      ]);
      return bodyRows;
    };

    doc.autoTable({
      startY: doc.lastAutoTable.finalY,
      head: [
        [
          { content: "ID", styles: { halign: "left" } },
          { content: "Description", styles: { halign: "left" } },
          "Unit Price (excl. GST)",
          "Qty",
          "Total Price (excl. GST)",
        ],
      ],
      body: makeDescTableRows(),
      theme: "striped",
      headStyles: {
        fillColor: [255, 0, 0],
        textColor: [255, 255, 255],
        fontSize: 8,
        cellPadding: 1,
        overflow: "linebreak",
        halign: "right",
      },
      styles: {
        fontSize: 8,
        cellPadding: 1,
        overflow: "linebreak",
        halign: "right",
      },
    });
  };

  // total cost of dbs
  const generateDbTotalCostTable = (doc, dbTotalCost, dbName, startY) => {
    const makeTableRows = () => {
      let rows = [];
      dbName.forEach((db) => {
        rows.push([
          { content: db.identifier, styles: { halign: "left" } },
          { content: db.Name, styles: { halign: "left" } },
          "                                                                ",
          {
            content: db.totalUnitPrice.toFixed(2),
            styles: { halign: "right" },
          },
          `${db.quantity}`,
          `$${db.totalPrice.toFixed(2)}`,
        ]);
      });
      rows.push([
        "",
        "",
        "",
        "",
        "",
        {
          content: `Total: $${dbTotalCost.toFixed(2)}`,
          styles: { fontStyle: "bold" },
        },
      ]);
      return rows;
    };
    doc.autoTable({
      startY: startY,
      head: [[`DB Summary`]],
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [255, 0, 0],
        fontSize: 12,
        cellPadding: 1,
        overflow: "linebreak",
        halign: "center",
      },
    });
    doc.autoTable({
      startY: doc.lastAutoTable.finalY || 30,
      head: [
        [
          { content: "ID", styles: { halign: "left" } },
          { content: "Name", styles: { halign: "left" } },
          "                                                                ",
          "Unit Price (excl GST)",
          "Qty",
          "Total (excl GST)",
        ],
      ],
      // the first row of the body is the db name, quantity of the db (always 1) and the total cost of the db excl GST
      body: makeTableRows(),
      theme: "striped",
      headStyles: {
        fillColor: [255, 0, 0],
        textColor: [255, 255, 255],
        fontSize: 8,
        cellPadding: 1,
        overflow: "linebreak",
        valign: "bottom",
        halign: "right",
      },
      styles: {
        fontSize: 8,
        cellPadding: 1,
        overflow: "linebreak",
        halign: "right",
      },
    });
  };

  // final total table
  const generateTotalTable = (doc, dbTotalCost, startY) => {
    let rows = [
      ["DB's", `$${dbTotalCost.toFixed(2)}`],
      [
        "Factory Installed Items",
        `$${handleLineItemCosts(lineItems).totalLineItemsCost.toFixed(2)}`,
      ],
      [
        "TOTAL",
        `$${(
          dbTotalCost + handleLineItemCosts(lineItems).totalLineItemsCost
        ).toFixed(2)}`,
      ],
      [
        "GST Amount",
        `$${(
          Math.round(
            ((dbTotalCost + handleLineItemCosts(lineItems).totalLineItemsCost) /
              10) *
              100
          ) / 100
        ).toFixed(2)}`,
      ],
      [
        {
          content: "TOTAL Including GST",
          styles: { fontStyle: "bold" },
        },
        {
          content: `$${(
            Math.round(
              (dbTotalCost +
                handleLineItemCosts(lineItems).totalLineItemsCost) *
                1.1 *
                100
            ) / 100
          ).toFixed(2)}`,
          styles: { fontStyle: "bold" },
        },
      ],
    ].filter((row, index) => (lineItems.length === 0 ? index !== 1 : true));
    // filter out factory installed when no line_items
    doc.autoTable({
      startY: startY,
      head: [[`Quote Total`]],
      headStyles: {
        fillColor: [255, 0, 0],
        textColor: [255, 255, 255],
        fontSize: 12,
        cellPadding: 1,
        overflow: "linebreak",
        halign: "center",
      },
    });
    doc.autoTable({
      startY: doc.lastAutoTable.finalY,
      body: rows,
      theme: "striped",
      headStyles: {
        fillColor: [255, 0, 0],
        textColor: [255, 255, 255],
        fontSize: 10,
        cellPadding: 1,
        overflow: "linebreak",
        halign: "right",
      },
      styles: {
        fontSize: 10,
        cellPadding: 1,
        overflow: "linebreak",
        halign: "right",
      },
    });
  };

  // title list (TandCs) table
  const generateTitleTable = (doc, data, startY) => {
    let calcY = startY;
    // filteredItems = points that are checked in the TCList()
    if (data.checkedTitle) {
      const filteredItems = data.points
        .filter((point) => {
          if (point.checked) {
            // lines is number of lines a point takes on the pdf if it takes more than one line
            // point.point.length - point.point.replace(/[A-Z]/g, "").length = number of capital letters in string (they take up more space)
            if (
              point.point.length +
                point.point.length -
                point.point.replace(/[A-Z]/g, "").length >=
              140
            ) {
              const lines =
                (point.point.length +
                  point.point.length -
                  point.point.replace(/[A-Z]/g, "").length) /
                140;
              calcY = calcY + lines * 2.5;
            }
            return true;
          }
          return false;
        })
        .map((filteredPoint) => {
          return filteredPoint;
        });

      // continueY = starting y position for the next table
      calcY = calcY + 6 + filteredItems.length * 5.5;

      if (calcY >= 265 && calcY - startY >= 20) {
        // only resets for this=>table if it doesn't fit in the small gap on the remaining page
        calcY = calcY - startY; // <=20
        startY = defaultPDFStart;
        calcY = calcY + startY; // starting y position for the next table after this=>table
        // new page
        doc.addPage();
        //insert the aps logo to the bottom left of every page
        doc.setFontSize(8);
      }
      doc.autoTable({
        startY: startY,
        head: [[data.title]],
        body: filteredItems.map((point) => point.point.split("\n")),
        theme: "grid",
        headStyles: {
          fillColor: [255, 0, 0],
          textColor: [255, 255, 255],
          fontSize: 10,
          cellPadding: 1,
          overflow: "linebreak",
          halign: "left",
        },

        styles: {
          fontSize: 8,
          cellPadding: 1,
          overflow: "linebreak",
          halign: "left",
        },
      });

      // if the starting y position is too far down, start on a new page
      if (calcY >= 265) {
        calcY = defaultPDFStart; // start the top of pdf page for the next table/list
        // new page
        doc.addPage();
        //insert the aps logo to the bottom left of every page
        doc.setFontSize(8);
      }
    }
  };

  // generate table for Quote Summary details shown in EstimateSave
  const generateSummaryTable = (doc, startY) => {
    let rows = [
      ["Opportunity:", `${currentProject.crmOpp}`],
      ["Attention:", `${currentClient.customerName}`],
      ["Company:", `${currentClient.businessName}`],
      ["Prepared by:", `${currentClient.contactName}`],
      ["Quote Number:", `${currentQuoteNum}`],
      ["Revision:", `${revisionVersion}`],
      ["Date:", `${getCurrentDate()}`],
      ["Valid Until:", `${getDateValidity()}`],
    ];
    doc.autoTable({
      startY: startY,
      head: [[`Quote Details`]],
      headStyles: {
        fillColor: [255, 0, 0],
        textColor: [255, 255, 255],
        fontSize: 12,
        cellPadding: 1,
        overflow: "linebreak",
        halign: "center",
      },
    });
    doc.autoTable({
      startY: startY + 7,
      body: rows,
      theme: "striped",
      styles: {
        fontSize: 10,
        cellPadding: 1,
        overflow: "linebreak",
        halign: "left",
      },
    });
  };

  const generateProjectTitle = (doc) => {
    const pageWidth = doc.internal.pageSize.getWidth();
    doc.setFontSize(12);
    doc.setTextColor(255, 0, 0);
    doc.text(currentProject.projectName, pageWidth / 2, 10, {
      align: "center",
    });
    doc.setFontSize(8);
    doc.text(currentProject.subProjectName, pageWidth / 2, 13, {
      align: "center",
    });
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(8);
  };

  // generate a pdf quote documentation
  const generatePDFQuote = () => {
    const doc = new jsPDF();
    //insert the landing page as image on the initial page
    doc.addImage(landingPage, "PNG", 0, 0, 210, 297);
    //add red title text on the landing page
    doc.setTextColor(255, 0, 0);
    doc.setFontSize(20);
    const mainProjTitleYPos = 130;
    doc.text(
      `${currentProject.projectName}${
        currentProject.subProjectName !== ""
          ? `-${currentProject.subProjectName}`
          : ""
      }`,
      200,
      mainProjTitleYPos,
      {
        maxWidth: 80,
        align: "right",
      }
    );

    //add new page
    doc.addPage();

    // addressing customer page with 'Dear Customer....

    // insert the body of the letter
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    let customerDetailsY = 0;
    customerLetter.forEach((data, index) => {
      if (data === "Dear Customer,") {
        doc.text(
          `Dear ${currentClient.customerName}, `,
          35,
          75 + index * 5,
          null,
          null,
          "left"
        );
      } else if (
        data ===
        "we do ask that you please review in detail and check for accuracy."
      ) {
        doc.setFont("Helvetica", "bold");
        doc.text(data, 35, 75 + index * 5, null, null, "left");
        doc.setFont("Helvetica", "");
      } else {
        doc.text(data, 35, 75 + index * 5, null, null, "left");
      }
      customerDetailsY = index;
    });
    customerDetailsY = customerDetailsY + 2;
    doc.setFontSize(20);
    doc.setFont("Helvetica", "italic");
    doc.text(
      `${currentClient.contactName}`,
      35,
      75 + customerDetailsY * 5,
      null,
      null,
      "left"
    );
    doc.setFont("Helvetica", "");
    doc.setFontSize(10);
    customerDetailsY = customerDetailsY + 2;
    const contactInfo = [
      `Office Phone: ${currentClient.contactNumber}`,
      `Office Email: ${currentClient.contactEmail}`,
      `Direct Email: ${currentClient.directEmail}`,
      `Direct Phone: ${currentClient.mobilePhone}`,
    ];
    contactInfo.forEach((info) => {
      doc.text(info, 35, 75 + customerDetailsY * 5, null, null, "left");
      customerDetailsY++;
    });
    doc.setTextColor(255, 0, 0);
    doc.setFontSize(16);
    doc.text(
      "APS Industrial",
      35,
      75 + customerDetailsY * 5 + 5,
      null,
      null,
      "left"
    );
    customerDetailsY++;
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(8);
    doc.text(
      "ABN: 13 623 475 481",
      35,
      75 + customerDetailsY * 5 + 5,
      null,
      null,
      "left"
    );

    // new page with the table of terms and conditions
    doc.addPage();

    doc.autoTable({
      startY: defaultPDFStart,
      head: [[`Terms and Conditions`]],
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [255, 0, 0],
        fontSize: 12,
        cellPadding: 1,
        overflow: "linebreak",
        halign: "center",
      },
    });
    let continueY = doc.lastAutoTable.finalY || defaultPDFStart;
    // generateTable:
    // doc=pdf document
    // data=object obtaining the titles and points
    // startY=position the category group starts of the pdf in terms of axis-y
    // goes through all lists and generates their points (if checked = true in titleList)
    // the order can be changed in TCList() index.js where titleList is initialised with useState
    titleList.forEach((title) => {
      generateTitleTable(doc, title, continueY);
      continueY = doc.lastAutoTable.finalY || defaultPDFStart;
    });
    doc.addPage();

    // quote summary details
    // opportunity = currentProject.crmOpp
    // attention = currentClient.customerName
    // company = currentClient.businessName
    // prepared by = currentClient.contactName
    // quote number = currentQuoteNum
    // revision = revisionVersion
    // date and valid until = generate in generateSummaryTable
    generateSummaryTable(doc, defaultPDFStart);
    const tableSummaryPageCount = doc.internal.getNumberOfPages();
    doc.addPage();

    // generate a table of parts for each db
    const mappedDbs = handleDbPartsMapped(allAddCostings, quotingDB);
    let dbNameCost = [];

    doc.autoTable({
      startY: defaultPDFStart,
      head: [[`DB Details`]],
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [255, 0, 0],
        fontSize: 12,
        cellPadding: 1,
        overflow: "linebreak",
        halign: "center",
      },
    });
    let nextDbStart = doc.lastAutoTable.finalY || defaultPDFStart; // update according to the generateSummaryTable
    mappedDbs.forEach((db) => {
      dbNameCost.push({
        identifier: db.identifier,
        quantity: db.quantity,
        Name: db.Name,
        cost: db.unitPrice,
        totalUnitPrice: db.totalUnitPrice,
        totalPrice: db.totalPrice,
        margin: db.margin,
        otherAddCosts: db.otherAddCosts,
      });
      generateApsPartsTable(doc, db, nextDbStart);
      doc.addPage();
      nextDbStart = defaultPDFStart;
    });
    let dbTotalCost = 0;
    dbTotalCost =
      Math.round(
        dbNameCost.reduce(
          (accumulator, currentValue) => accumulator + currentValue.totalPrice,
          0
        ) * 100
      ) / 100;

    // db total table
    generateDbTotalCostTable(doc, dbTotalCost, dbNameCost, nextDbStart);
    if (doc.lastAutoTable.finalY > 250) {
      nextDbStart = defaultPDFStart;
      doc.addPage();
    } else {
      nextDbStart = doc.lastAutoTable.finalY + 10;
    }

    if (lineItems.length > 0) {
      // line items (factory installed)
      generateLineItemsTable(doc, nextDbStart);
    }

    if (doc.lastAutoTable.finalY > 250) {
      nextDbStart = defaultPDFStart;
      doc.addPage();
    } else {
      nextDbStart = doc.lastAutoTable.finalY + 10;
    }
    // final total table including GST and factory installed items
    generateTotalTable(doc, dbTotalCost, nextDbStart);

    // new page with the aps header image and the apstandC1 then another page with the header and apsTandC2
    doc.addPage();
    doc.addImage(tandC, "PNG", 0, 0, 210, 300);

    // Page numbering
    const pageCount = doc.internal.getNumberOfPages();
    const pageWidth = doc.internal.pageSize.getWidth();

    for (var i = 2; i < pageCount; i++) {
      doc.setFontSize(10);
      doc.setPage(i);
      if (i < pageCount - 1 && i >= tableSummaryPageCount) {
        generateProjectTitle(doc);
      }
      doc.text(`${i} of ${pageCount}`, pageWidth / 2, 285, "center");
      doc.addImage(apsLogo, "PNG", 3, 280, 10, 7.5);
    }

    doc.save(
      `${currentQuoteNum}_Rev${revisionVersion}_${getCurrentDate()}_${
        currentClient.customerName
      }_${currentProject.projectName}.pdf`
    );
  };
  return { generatePDFQuote };
}

export default PDFListGenerator;
