// functional component to return a table for the final quote. Each row will be a db from the quote. The columns will be id, name, qty, unit price, margin, total price, and actions
import { useContext } from "react";
import { ApsContext } from "ApsContext";
import QueryCustomTable from "customInputs/queryTables/QueryCustomTable";
import { useNewItemsDiscounts } from "services/query-service";
import PartQtyCell from "customInputs/customTableCells/partQtyCell";

function FinalFactoryItemsTable() {
  const { currentClient, lineItems, quotingDB, updateLineItems } =
    useContext(ApsContext);

  const { data: discounts, isSuccess: isSuccessDiscounts } =
    useNewItemsDiscounts(
      currentClient.deb_acc !== "" ? currentClient.deb_acc : "newquote"
    );

  const handleUpdateQty = (e, partGroupItem) => {
    const val = e.target.value;
    if (val !== undefined && val !== null && val !== "" && !isNaN(val)) {
      const updatedLineItems = lineItems.map((item) => {
        if (item.partNumber === partGroupItem.partNumber) {
          return { ...item, quantity: parseInt(val) };
        } else {
          return item;
        }
      });
      updateLineItems(updatedLineItems);
    }
  };

  const makeAddedNewItemsRows = () => {
    if (isSuccessDiscounts) {
      if (discounts.length >= 0) {
        let idTracker = quotingDB[quotingDB.length - 1].id;
        idTracker++;
        return lineItems.map((item) => {
          const foundDiscount = discounts.find(
            (discount) => discount.prod_price_code === item.priceCode
          );
          if (foundDiscount !== undefined) {
            return {
              partNumberFinalLineItems: idTracker++,
              descriptionFinalLineItems: item.description,
              supplierFinalLineItems: item.supplier,
              basePriceFinalLineItems: item.basePrice.toFixed(2),
              discountFinalLineItems: foundDiscount.discountPercent,
              quantityFinalLineItems: (
                <PartQtyCell
                  currentQty={item.quantity}
                  handleUpdateQty={handleUpdateQty}
                  partGroupItem={item}
                />
              ),
              finalPriceFinalLineItems: (
                Math.round(
                  item.basePrice *
                    (1 - foundDiscount.discountPercent / 100) *
                    item.quantity *
                    100
                ) / 100
              ).toFixed(2),
            };
          } else {
            return {
              partNumberFinalLineItems: idTracker++,
              descriptionFinalLineItems: item.description,
              supplierFinalLineItems: item.supplier,
              basePriceFinalLineItems: item.basePrice.toFixed(2),
              discountFinalLineItems: 0,
              quantityFinalLineItems: (
                <PartQtyCell
                  currentQty={item.quantity}
                  handleUpdateQty={handleUpdateQty}
                  partGroupItem={item}
                />
              ),
              finalPriceFinalLineItems: (
                Math.round(item.basePrice * item.quantity * 100) / 100
              ).toFixed(2),
            };
          }
        });
      } else {
        return [
          {
            partNumberFinalLineItems: "",
            descriptionFinalLineItems: "",
            supplierFinalLineItems: "",
            basePriceFinalLineItems: "",
            discountFinalLineItems: "",
            quantityFinalLineItems: "",
            finalPriceFinalLineItems: "",
          },
        ];
      }
    } else {
      return [
        {
          partNumberFinalLineItems: "",
          descriptionFinalLineItems: "",
          supplierFinalLineItems: "",
          basePriceFinalLineItems: "",
          discountFinalLineItems: "",
          quantityFinalLineItems: "",
          finalPriceFinalLineItems: "",
        },
      ];
    }
  };

  return (
    <QueryCustomTable
      data={{
        columns: [
          {
            accessorKey: "partNumberFinalLineItems",
            header: "ID",
            size: 5,
          },
          {
            accessorKey: "descriptionFinalLineItems",
            header: "Item Description",
            size: 500,
          },
          {
            accessorKey: "supplierFinalLineItems",
            header: "Supplier",
            size: 80,
          },
          {
            accessorKey: "basePriceFinalLineItems",
            header: "Base Price ($)",
            size: 100,
            muiTableHeadCellProps: {
              align: "right",
            },
            muiTableBodyCellProps: {
              align: "right",
            },
          },
          {
            accessorKey: "discountFinalLineItems",
            header: "Discount (%)",
            size: 100,
            muiTableHeadCellProps: {
              align: "right",
            },
            muiTableBodyCellProps: {
              align: "right",
            },
          },
          {
            accessorKey: "quantityFinalLineItems",
            header: "Qty",
            size: 50,
            muiTableHeadCellProps: {
              align: "right",
            },
            muiTableBodyCellProps: {
              align: "right",
            },
          },
          {
            accessorKey: "finalPriceFinalLineItems",
            header: "Price ($)",
            size: 100,
            muiTableHeadCellProps: {
              align: "right",
            },
            muiTableBodyCellProps: {
              align: "right",
            },
          },
        ],
        rows: makeAddedNewItemsRows(),
        title: `Factory Installed Items`,
      }}
    />
  );
}

export default FinalFactoryItemsTable;
