import { useState, useEffect, useContext, useMemo, useCallback } from "react";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { ApsContext } from "ApsContext";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useAPSDB } from "hooks/useAPSDB";
import { useMaterialUIController } from "context";
import rgba from "assets/theme/functions/rgba";
import theme from "assets/theme";
import { useAssemblies } from "services/query-service";
import Grid from "@mui/material/Grid";
import AddAssemblyCell from "customInputs/customTableCells/addAssemblyCell";
import AssemblyProductCell from "customInputs/customTableCells/assemblyProductCell";
import DeleteCell from "customInputs/customTableCells/deleteCell";
import PartQtyCell from "customInputs/customTableCells/partQtyCell";
import QueryTable from "customInputs/queryTables/QueryTable";
import QueryAssembliesAddedTable from "customInputs/queryTables/QueryAssembliesAddedTable";
import QueryAssembliesTable from "customInputs/queryTables/QueryAssembliesTable";
import DBHeightInfo from "../components/DBHeightInfo";
import PartDetailDialog from "customInputs/partDetailDialog";
import SelectedPartCardDialog from "layouts/November/Assembly/components/SelectedPartCardDialog";
import { Divider, Tooltip } from "@mui/material";
import CustomSpreadSheet from "customInputs/customSpreadSheet";
import { useAbility } from "@casl/react";
import { AbilityContext } from "Can";

function EstimatePartSelection() {
  const ability = useAbility(AbilityContext);
  const {
    currentID,
    updateChassis1Part,
    updateChassis2Part,
    updateBothChassisParts,
    addAssembliesToBay,
    getChassisList,
    changeCurrentChassis,
    promptNotification,
    blankPart,
  } = useContext(ApsContext);
  const { getAssembliesInBay, partAssembler } = useAPSDB();
  const [currentTableSelection, setCurrentTableSelection] = useState({
    part: blankPart,
    quantity: 0,
  });
  const [miscAssemblyColumns] = useState([
    {
      type: "text",
      title: "PART NUMBER",
      width: 150,
      align: "left",
    },
    {
      type: "text",
      title: "DESCRIPTION",
      width: 400,
      align: "left",
    },
    { type: "text", title: "SUPPLIER", width: 100, align: "left" },
    { type: "numeric", title: "QTY", width: 40, align: "right" },
    {
      type: "numeric",
      title: "COST ($)",
      width: 60,
      align: "right",
      mask: "0.00",
      decimal: ".",
    },
    {
      type: "numeric",
      title: "LABOUR (MIN.)",
      width: 100,
      align: "right",
    },
    {
      type: "text",
      title: "STOCK",
      width: 80,
      align: "left",
      readOnly: true,
    },
  ]);
  const [addPartModalOpen, setAddPartModalOpen] = useState(false);
  const [addAssemblyModalOpen, setAddAssemblyModalOpen] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { data: assemblies, isSuccess } = useAssemblies();
  const [openPartDetailDialog, setOpenPartDetailDialog] = useState(false);
  const [openSelectedPartCardDialog, setOpenSelectedPartCardDialog] =
    useState(false);
  const [pendingUpdates, setPendingUpdates] = useState([]);
  const [newAssembliesAddedRows, setNewAssembliesAddedRows] = useState([]);
  const [viewingHeight, setViewingHeight] = useState(window.innerHeight);

  const chassisList = useMemo(
    () => getChassisList(currentID),
    [currentID, getChassisList]
  );

  const [assembliesAdded, setAssembliesAdded] = useState(
    getAssembliesInBay(currentID)
  );

  const closeAssemblyModal = () => {
    setAddAssemblyModalOpen(false);
  };

  const closePartModal = () => {
    setAddPartModalOpen(false);
  };

  // function to check if any of the parts in the assemblies added to the bay are chassis parts. If they are, then update the chassisList for the bay. If there are no chassis parts in the assemblies added to the bay or the parts added to the bay, then do not update the chassisList for the bay.
  const checkForChassis = useCallback(() => {
    if (!assembliesAdded || assembliesAdded.length === 0) return;

    let chassisParts = assembliesAdded.flatMap((assembly) =>
      assembly.parts
        .filter(
          (part) =>
            part.filter1.includes("Chassis") &&
            !part.partNumber.includes("1P") &&
            !part.partNumber.includes("2P") &&
            !part.partNumber.includes("4P")
        )
        .flatMap((part) => Array(part.AssembliesRelation.quantity).fill(part))
    );

    if (chassisParts.length === 0) return;

    if (chassisParts.length === 1) {
      if (chassisList.chassis1 === null) {
        updateChassis1Part(currentID, chassisParts[0].partNumber);
        changeCurrentChassis("chassis1");
      }
      return;
    } else if (chassisParts.length >= 2) {
      if (chassisList.chassis1 === null && chassisList.chassis2 === null) {
        updateBothChassisParts(
          currentID,
          chassisParts[0].partNumber,
          chassisParts[1].partNumber
        );
        changeCurrentChassis("chassis1");
      } else if (
        chassisList.chassis1 === null &&
        chassisList.chassis2 !== null
      ) {
        updateChassis1Part(currentID, chassisParts[0].partNumber);
        changeCurrentChassis("chassis1");
      } else if (
        chassisList.chassis1 !== null &&
        chassisList.chassis2 === null
      ) {
        updateChassis2Part(currentID, chassisParts[1].partNumber);
        changeCurrentChassis("chassis2");
      }
      return;
    }
  }, [
    assembliesAdded,
    changeCurrentChassis,
    chassisList.chassis1,
    chassisList.chassis2,
    currentID,
    updateBothChassisParts,
    updateChassis1Part,
    updateChassis2Part,
  ]);

  // function to add an assembly from the assembly modal
  const handleAddAssemblyClick = useCallback(
    (assembly) => {
      const assemblyInBay = assembliesAdded.find(
        (bayAssembly) => bayAssembly.assembly_name === assembly.assembly_name
      );
      if (assemblyInBay) {
        const updatedAssembly = {
          ...assemblyInBay,
          quantity: assemblyInBay.quantity + 1,
        };
        const updatedAssembliesAdded = assembliesAdded.map((bayAssembly) => {
          if (bayAssembly.assembly_name === assembly.assembly_name) {
            return updatedAssembly;
          } else {
            return bayAssembly;
          }
        });

        addAssembliesToBay(currentID, updatedAssembliesAdded);
      } else {
        const newAssembly = {
          ...assembly,
          quantity: 1,
        };

        const updateSortedAssemblies = [...assembliesAdded, newAssembly];

        const miscAssembly = assembliesAdded.find(
          (bayAssembly) => bayAssembly.assembly_name === "misc"
        );

        if (miscAssembly) {
          addAssembliesToBay(currentID, [
            ...updateSortedAssemblies.filter(
              (assembly) => assembly.assembly_name !== "misc"
            ),
            miscAssembly,
          ]);
        } else {
          addAssembliesToBay(currentID, updateSortedAssemblies);
        }
      }
      promptNotification(`Added assembly ${assembly.assembly_name}`, "success");
    },
    [addAssembliesToBay, assembliesAdded, currentID, promptNotification]
  );

  // Add a custom part
  const handlePartDetailChange = (val, type) => {
    switch (type) {
      case "quantity":
        val = parseInt(val);
        setCurrentTableSelection((prevState) => ({
          ...prevState,
          quantity: val,
        }));
        break;
      case "partNumber":
        setCurrentTableSelection((prevState) => ({
          ...prevState,
          part: {
            ...prevState.part,
            partNumber: val,
          },
        }));
        break;
      case "description":
        setCurrentTableSelection((prevState) => ({
          ...prevState,
          part: {
            ...prevState.part,
            description: val,
          },
        }));
        break;
      case "supplier":
        setCurrentTableSelection((prevState) => ({
          ...prevState,
          part: {
            ...prevState.part,
            supplier: val,
          },
        }));
        break;
      case "cost":
        val = parseFloat(val);
        setCurrentTableSelection((prevState) => ({
          ...prevState,
          part: {
            ...prevState.part,
            cost: val,
          },
        }));
        break;
      case "labour":
        val = parseInt(val);
        setCurrentTableSelection((prevState) => ({
          ...prevState,
          part: {
            ...prevState.part,
            labour: val,
            labourMount: prevState.part.labourWire
              ? val - prevState.part.labourWire
              : val,
            labourWire: prevState.part.labourWire
              ? prevState.part.labourWire
              : 0,
          },
        }));
        break;
      default:
        console.error("Unknown part detail received in handlePartDetailChange");
        break;
    }
  };

  // Handle quantity changes when adding a part from "Add Parts" button
  const handleQuantityChange = (val) => {
    // if val is a string, convert it to a number
    if (typeof val === "string") {
      val = parseInt(val);
    }
    setCurrentTableSelection((prevState) => ({
      ...prevState,
      quantity: val,
    }));
  };

  // function to add a single part from the part modal to the misc assembly in the bay. If there is no assembly cnamed 'misc' then create it and populate with the part. If the misc assembly already exists then add the part to the parts array of the misc assembly, if the part already exists in the array the increase the quantity by 1.  This will be used for misc parts. For assemblies, use handleAddAssemblyClick.
  // this function is used for the "Add Parts" button.
  const addSinglePart = useCallback(() => {
    const miscAssembly = assembliesAdded.find(
      (assembly) => assembly.assembly_name === "misc"
    );
    if (miscAssembly) {
      const partInMiscAssembly = miscAssembly.parts.find(
        (partInAssembly) =>
          partInAssembly.partNumber === currentTableSelection.part.partNumber
      );
      if (partInMiscAssembly) {
        const updatedPart = {
          ...partInMiscAssembly,
          AssembliesRelation: {
            quantity:
              partInMiscAssembly.AssembliesRelation.quantity +
              currentTableSelection.quantity,
          },
        };
        const updatedMiscAssembly = {
          ...miscAssembly,
          parts: miscAssembly.parts.map((eachpart) => {
            if (eachpart.partNumber === updatedPart.partNumber) {
              return updatedPart;
            } else {
              return eachpart;
            }
          }),
        };

        const updatedAssembliesAdded = assembliesAdded.map((assembly) => {
          if (assembly.assembly_name === "misc") {
            return updatedMiscAssembly;
          } else {
            return assembly;
          }
        });

        addAssembliesToBay(currentID, updatedAssembliesAdded);
      } else {
        const newPart = {
          ...partAssembler({
            ...currentTableSelection.part,
            labourMount:
              currentTableSelection.part.labour !==
              currentTableSelection.part.labourWire +
                currentTableSelection.part.labourMount
                ? currentTableSelection.part.labour
                : currentTableSelection.part.labourMount,
          }),
          AssembliesRelation: {
            quantity: currentTableSelection.quantity,
          },
        };
        const updatedMiscAssembly = {
          ...miscAssembly,
          parts: [...miscAssembly.parts, newPart],
        };
        const updatedAssembliesAdded = assembliesAdded.map((assembly) => {
          if (assembly.assembly_name === "misc") {
            return updatedMiscAssembly;
          } else {
            return assembly;
          }
        });
        addAssembliesToBay(currentID, updatedAssembliesAdded);
      }
    } else {
      const newPart = {
        ...partAssembler({
          ...currentTableSelection.part,
          labourMount:
            currentTableSelection.part.labour !==
            currentTableSelection.part.labourWire +
              currentTableSelection.part.labourMount
              ? currentTableSelection.part.labour
              : currentTableSelection.part.labourMount,
        }),
        AssembliesRelation: { quantity: currentTableSelection.quantity },
      };
      const newMiscAssembly = {
        assembly_name: "misc",
        description: "miscellaneous parts",
        height: 0,
        quantity: 1,
        parts: [newPart],
      };
      addAssembliesToBay(currentID, [...assembliesAdded, newMiscAssembly]);
    }
    promptNotification(
      `Added ${currentTableSelection.quantity} of ${currentTableSelection.part.partNumber}`,
      "success"
    );
    setOpenPartDetailDialog(false);
    setOpenSelectedPartCardDialog(false);
  }, [
    addAssembliesToBay,
    assembliesAdded,
    currentID,
    currentTableSelection.part,
    currentTableSelection.quantity,
    partAssembler,
    promptNotification,
  ]);

  // clicked on plus (add) for a part from the table
  const handleLogTable = (row) => {
    const miscAssembly = assembliesAdded.find(
      (assembly) => assembly.assembly_name === "misc"
    );
    if (miscAssembly) {
      const partInMiscAssembly = miscAssembly.parts.find(
        (partInAssembly) => partInAssembly.partNumber === row.partNumber
      );
      if (partInMiscAssembly) {
        setCurrentTableSelection({
          part: row,
          quantity: partInMiscAssembly.AssembliesRelation.quantity,
        });
      } else {
        setCurrentTableSelection({ part: row, quantity: 1 });
      }
    } else {
      setCurrentTableSelection({ part: row, quantity: 1 });
    }
    setOpenSelectedPartCardDialog(true);
  };

  // function to update the quantity of the part in the misc assembly using addAssembliesToBay
  const handleMiscPartQtyChange = (e, part) => {
    let val = e.target.value;
    if (
      val !== undefined &&
      val !== null &&
      val !== "" &&
      !isNaN(val) &&
      parseInt(val) !== 0
    ) {
      val = parseInt(val);
    } else {
      val = 1;
    }

    setPendingUpdates((prev) => [...prev, { type: "updateQty", part, val }]);
  };

  // function to update the details of the part in the misc assembly using addAssembliesToBay
  const handleMiscPartDetailChange = (e, part, mode) => {
    const val = e.target.value;
    setPendingUpdates((prev) => [
      ...prev,
      { type: "updateDetail", part, val, mode },
    ]);
  };

  // function to remove a part from the misc assembly
  const removePart = (part) => {
    setPendingUpdates((prev) => [...prev, { type: "removePart", part }]);
  };

  // pasted part number into spreadsheet; add the part
  const handleNewPartMisc = (spreadsheetPart) => {
    const part = {
      ...partAssembler(spreadsheetPart.part),
      AssembliesRelation: {
        quantity: spreadsheetPart.quantity,
      },
    };

    setPendingUpdates((prev) => [...prev, { type: "newPart", part }]);
  };

  // moving a single part on the spreadsheet to a new position
  const handleMoveMiscPart = (part, newRowIndex) => {
    setPendingUpdates((prev) => [
      ...prev,
      { type: "movePart", part, newRowIndex },
    ]);
  };

  // function to update the quantity of the assembly using addAssembliesToBay
  const handleBayAssemblyQtyChange = useCallback(
    (e, assembly) => {
      // if val is a string, convert it to a number
      let val = e.target.value;
      if (val === "" || val === null || val === undefined) {
        return;
      }
      if (typeof val === "string") {
        val = parseInt(val);
      }
      const assemblyInBayToUpdate = assembliesAdded.find(
        (bayAssembly) => bayAssembly.assembly_name === assembly.assembly_name
      );
      const updatedAssembly = {
        ...assemblyInBayToUpdate,
        quantity: val,
      };

      // update the assembly in the bay using addAssembliesToBay. This will update the assembly in the bay and the assembliesAdded array. Tge addAssembliesToBay takes the id of the bay and the array of assemblies to add to the bay. The array of assemblies to add to the bay is the assembliesAdded array with the updated assembly. The updated assembly is the assembly in the bay with the updated quantity.

      const makeUpdatedAssemblyArray = assembliesAdded.map((bayAssembly) => {
        if (bayAssembly.assembly_name === assembly.assembly_name) {
          return updatedAssembly;
        } else {
          return bayAssembly;
        }
      });

      addAssembliesToBay(currentID, makeUpdatedAssemblyArray);
    },
    [addAssembliesToBay, assembliesAdded, currentID]
  );

  // function to remove an assembly from the bay
  const removeAssembly = useCallback(
    (assembly) => {
      const updatedAssembliesAdded = assembliesAdded.filter(
        (bayAssembly) => bayAssembly.assembly_name !== assembly.assembly_name
      );
      addAssembliesToBay(currentID, updatedAssembliesAdded);
    },
    [addAssembliesToBay, assembliesAdded, currentID]
  );

  // function to create the rows in the assembly modal table from the assemblies in the database
  const newAssemblyModalRows = useMemo(() => {
    if (isSuccess && assemblies.data) {
      return assemblies.data
        .map((assembly) => {
          return {
            name: assembly.assembly_name,
            description: assembly.description,
            height: assembly.height,
            state: assembly.state,
            filter_1: assembly.filter_1,
            filter_2: assembly.filter_2,
            creator: (
              <AssemblyProductCell
                name={assembly.user.name}
                image={assembly.user.profile_image}
                description={assembly.created_at.split("-").reverse().join("-")}
              />
            ),
            updated: assembly.updated_at.split("-").reverse().join("-"),
            actions: (
              <AddAssemblyCell
                icon="add"
                color="success"
                onIconClick={handleAddAssemblyClick}
                assembly={assembly}
              />
            ),
            subRows: assembly.parts.map((part) => {
              return {
                partNumber: part.partNumber,
                description: part.description,
                supplier: part.supplier,
                cost: part.cost,
                stock: part.stock,
                qty: part.AssembliesRelation.quantity,
              };
            }),
          };
        })
        .filter(
          (assembly) =>
            assembly.state ||
            (!assembly.state && ability.can("view", "assemblies"))
        );
    } else {
      return [
        {
          name: "",
          description: "",
          height: "",
          creator: "",
          updated: "",
          actions: "",
          subRows: [
            {
              partNumber: "",
              description: "",
              supplier: "",
              cost: "",
              stock: "",
              qty: "",
            },
          ],
        },
      ];
    }
  }, [ability, assemblies, handleAddAssemblyClick, isSuccess]);

  const handlePartDetailDialogClose = () => {
    setOpenPartDetailDialog(false);
  };

  const handlePartDetailDialogOpen = () => {
    setCurrentTableSelection({
      part: { ...blankPart, partNumber: "" },
      quantity: 1,
    });
    setOpenPartDetailDialog(true);
  };

  const handlePartDialogClose = () => {
    setOpenSelectedPartCardDialog(false);
  };

  const handleUpdateOrderAssemblies = (assemblies) => {
    setNewAssembliesAddedRows(assemblies);
    if (assembliesAdded) {
      let orderedAssemblies = [];
      for (let tableAssembly of assemblies) {
        const nextAssembly = assembliesAdded.find(
          (assembly) => assembly.assembly_name === tableAssembly.name
        );
        if (nextAssembly !== undefined) {
          orderedAssemblies.push(nextAssembly);
        }
      }
      const miscAssembly = assembliesAdded.find(
        (assembly) => assembly.assembly_name === "misc"
      );
      if (miscAssembly !== undefined) {
        orderedAssemblies.push(miscAssembly);
      }
      if (Array.isArray(orderedAssemblies) && orderedAssemblies.length > 0) {
        addAssembliesToBay(currentID, orderedAssemblies);
      }
    }
  };

  // function to create the rows to show the assemblies that have already been added to the quote
  const initNewAssembliesAddedRows = useMemo(() => {
    if (assembliesAdded) {
      return assembliesAdded
        .map((assembly) => {
          if (assembly.assembly_name !== "misc") {
            return {
              name: assembly.assembly_name,
              description: assembly.description,
              height: assembly.height,
              qty: (
                <PartQtyCell
                  currentQty={assembly.quantity}
                  handleUpdateQty={handleBayAssemblyQtyChange}
                  partGroupItem={assembly}
                />
              ),
              actions: (
                <DeleteCell handleDelete={removeAssembly} part={assembly} />
              ),
              subRows:
                assembly.parts && assembly.parts.length > 0
                  ? assembly.parts.map((part) => {
                      return {
                        partNumber: part.partNumber,
                        description: part.description,
                        supplier: part.supplier,
                        cost: part.cost.toFixed(2),
                        labour: part.labour,
                        stock: part.stock,
                        qty: part.AssembliesRelation.quantity,
                      };
                    })
                  : [
                      {
                        partNumber: "",
                        description: "",
                        supplier: "",
                        cost: "",
                        labour: "",
                        stock: "",
                        qty: "",
                      },
                    ],
            };
          } else {
            return { name: assembly.assembly_name };
          }
        })
        .filter((assembly) => assembly.name !== "misc");
    } else {
      return [
        {
          name: "",
          description: "",
          height: "",
          qty: "",
          actions: "",
          subRows: [
            {
              partNumber: "",
              description: "",
              supplier: "",
              cost: "",
              labour: "",
              stock: "",
              qty: "",
            },
          ],
        },
      ];
    }
  }, [assembliesAdded, handleBayAssemblyQtyChange, removeAssembly]);

  // format misc assembly to fit the spreadsheet
  const miscAssemblyData = useMemo(() => {
    if (assembliesAdded) {
      const miscAssemblyFound = assembliesAdded.find(
        (assembly) => assembly.assembly_name === "misc"
      );

      return miscAssemblyFound !== undefined &&
        miscAssemblyFound.parts &&
        miscAssemblyFound.parts.length > 0
        ? miscAssemblyFound.parts.map((part) => {
            return [
              part.partNumber,
              part.description,
              part.supplier,
              part.AssembliesRelation.quantity,
              part.cost,
              part.labour,
              part.stock,
            ];
          })
        : Array(20)
            .fill(null)
            .map(() => Array(miscAssemblyColumns.length).fill(""));
    } else {
      return Array(20)
        .fill(null)
        .map(() => Array(miscAssemblyColumns.length).fill(""));
    }
  }, [assembliesAdded, miscAssemblyColumns.length]);

  const calcLatestHeight = () => {
    const notMiscAssemblies = assembliesAdded.filter(
      (assembly) => assembly.assembly_name !== "misc"
    );
    if (notMiscAssemblies.length > 0) {
      return notMiscAssemblies.reduce((acc, curr) => {
        return (
          acc + (curr.height !== 0 ? curr.height * curr.quantity : curr.height)
        );
      }, 0);
    } else {
      return 0;
    }
  };

  useEffect(() => {
    setAssembliesAdded(getAssembliesInBay(currentID));
  }, [currentID, getAssembliesInBay]);

  useEffect(() => {
    setNewAssembliesAddedRows(initNewAssembliesAddedRows);
  }, [initNewAssembliesAddedRows]);

  useEffect(() => {
    checkForChassis();
  }, [assembliesAdded, checkForChassis]);

  useEffect(() => {
    if (pendingUpdates.length > 0) {
      let updatedAssemblies = assembliesAdded.map((assembly) => assembly);
      const miscAssembly = assembliesAdded.find(
        (assembly) => assembly.assembly_name === "misc"
      );
      // add misc assembly if not present
      if (miscAssembly === undefined) {
        updatedAssemblies.push({
          assembly_name: "misc",
          description: "miscellaneous parts",
          height: 0,
          quantity: 1,
          parts: [],
        });
      }
      updatedAssemblies = updatedAssemblies.map((assembly) => {
        if (assembly.assembly_name === "misc") {
          let miscAssembly = { ...assembly };

          pendingUpdates.forEach((update) => {
            const partInMiscAssembly = miscAssembly.parts.find(
              (part) => part.partNumber === update.part.partNumber
            );

            switch (update.type) {
              case "updateQty":
                if (partInMiscAssembly) {
                  partInMiscAssembly.AssembliesRelation.quantity = update.val;
                }
                break;
              case "updateDetail":
                if (partInMiscAssembly) {
                  switch (update.mode) {
                    case "partNumber":
                      partInMiscAssembly.partNumber = update.val;

                      break;
                    case "description":
                      partInMiscAssembly.description = update.val;
                      break;
                    case "supplier":
                      partInMiscAssembly.supplier = update.val;
                      break;
                    case "cost":
                      partInMiscAssembly.cost =
                        update.val === "" ? 0 : parseFloat(update.val);
                      break;
                    case "labour":
                      // more labour = add to labourMount
                      // less labour = set labourWire to zero and labourMount = labour
                      const inputValue = parseInt(update.val);
                      partInMiscAssembly.labour =
                        update.val === "" ? 0 : inputValue;
                      partInMiscAssembly.labourMount =
                        partInMiscAssembly.labourWire
                          ? inputValue - partInMiscAssembly.labourWire < 0
                            ? inputValue
                            : inputValue - partInMiscAssembly.labourWire
                          : inputValue;
                      partInMiscAssembly.labourWire =
                        partInMiscAssembly.labourWire
                          ? inputValue - partInMiscAssembly.labourWire < 0
                            ? 0
                            : partInMiscAssembly.labourWire
                          : 0;
                      break;
                    case "stock":
                      partInMiscAssembly.stock = update.val;
                      break;
                    default:
                      break;
                  }
                }
                break;
              case "removePart":
                miscAssembly.parts = miscAssembly.parts.filter(
                  (part) => part.partNumber !== update.part.partNumber
                );
                break;
              case "newPart":
                if (partInMiscAssembly) {
                  partInMiscAssembly.AssembliesRelation.quantity +=
                    update.part.AssembliesRelation.quantity;
                } else {
                  miscAssembly.parts.push(update.part);
                }
                break;
              case "movePart":
                if (partInMiscAssembly) {
                  const miscAssemblyCopy = JSON.parse(
                    JSON.stringify(miscAssembly)
                  );
                  const ogIndex = miscAssemblyCopy.parts.findIndex(
                    (part) => part.partNumber === partInMiscAssembly.partNumber
                  );
                  const partsBefore = miscAssemblyCopy.parts
                    .slice(
                      0,
                      update.newRowIndex +
                        (ogIndex < update.newRowIndex ? 1 : 0)
                    )
                    .filter(
                      (part) => part.partNumber !== update.part.partNumber
                    );
                  const partsAfter = miscAssemblyCopy.parts
                    .slice(
                      update.newRowIndex +
                        (ogIndex > update.newRowIndex ? 0 : 1)
                    )
                    .filter(
                      (part) => part.partNumber !== update.part.partNumber
                    );
                  miscAssembly = {
                    ...miscAssembly,
                    parts: [...partsBefore, partInMiscAssembly, ...partsAfter],
                  };
                }
                break;
              default:
                break;
            }
          });

          return miscAssembly;
        } else {
          return assembly;
        }
      });

      addAssembliesToBay(currentID, updatedAssemblies);
      setPendingUpdates([]);
    }
  }, [pendingUpdates, assembliesAdded, currentID, addAssembliesToBay]);

  useEffect(() => {
    const handleResize = () => {
      setViewingHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array to run only once on mount

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container>
          <Grid
            item
            xs={3.5}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <MDTypography
              variant="body2"
              fontWeight="regular"
              color="secondary"
              align="center"
            >
              Add parts using assemblies or single parts
            </MDTypography>
          </Grid>
          <Grid item xs={8.5}>
            <Grid container spacing={1}>
              <Grid item xs={2.5} display="flex" justifyContent="center">
                <MDButton
                  color="info"
                  variant="gradient"
                  size="small"
                  onClick={() => setAddAssemblyModalOpen(true)}
                  fullWidth
                >
                  Add Assembly
                </MDButton>
              </Grid>
              <Grid item xs={2.5} display="flex" justifyContent="center">
                <MDButton
                  size="small"
                  color="info"
                  variant="gradient"
                  onClick={() => setAddPartModalOpen(true)}
                  fullWidth
                >
                  Add Parts
                </MDButton>
              </Grid>
              <Grid item xs={2.5} display="flex" justifyContent="center">
                <Tooltip
                  placement="top"
                  title="Adding parts via this method will only exist within this quote"
                >
                  <MDButton
                    size="small"
                    variant="gradient"
                    color="info"
                    onClick={handlePartDetailDialogOpen}
                    fullWidth
                  >
                    Add Custom Part
                  </MDButton>
                </Tooltip>
              </Grid>
              <Grid
                item
                xs={4.5}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <DBHeightInfo value={calcLatestHeight()} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ m: 0 }} />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={0.5}>
          <Grid item xs={12} display="flex" justifyContent="center">
            <MDTypography
              variant="caption"
              fontWeight="bold"
              color="secondary"
              align="center"
            >
              MISCELLANEOUS PARTS
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <CustomSpreadSheet
              data={miscAssemblyData}
              columns={miscAssemblyColumns}
              removeRow={removePart}
              handleUpdateDetail={handleMiscPartDetailChange}
              handleUpdateQty={handleMiscPartQtyChange}
              handleNewPart={handleNewPartMisc}
              handleMoveRow={handleMoveMiscPart}
              viewingHeight={viewingHeight}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ m: 0 }} />
      </Grid>
      {newAssembliesAddedRows.some((assembly) => assembly.name !== "misc") && (
        <Grid item xs={12}>
          <QueryAssembliesAddedTable
            assemblyData={{
              columns: [
                { header: "assembly name", accessorKey: "name", size: 150 },
                {
                  header: "assembly description",
                  accessorKey: "description",
                  size: 250,
                },
                {
                  header: "hgt (mm)",
                  accessorKey: "height",
                  size: 80,
                  muiTableHeadCellProps: {
                    align: "right",
                  },
                  muiTableBodyCellProps: {
                    align: "right",
                  },
                },
                {
                  header: "qty",
                  accessorKey: "qty",
                  size: 50,
                  muiTableHeadCellProps: {
                    align: "right",
                  },
                  muiTableBodyCellProps: {
                    align: "right",
                  },
                },
                {
                  header: "actions",
                  accessorKey: "actions",
                  size: 80,
                },
              ],
              rows: newAssembliesAddedRows,
            }}
            setData={handleUpdateOrderAssemblies}
          />
        </Grid>
      )}
      <Dialog
        open={addPartModalOpen}
        onClose={closePartModal}
        maxWidth="xxl"
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: rgba(
              darkMode ? theme.palette.dark.main : theme.palette.white.main,
              1
            ),
          },
        }}
        disablePortal
      >
        <DialogTitle>Add Parts</DialogTitle>
        <DialogContent>
          <QueryTable addSinglePart={handleLogTable} />
        </DialogContent>
        <DialogActions>
          <MDButton onClick={closePartModal}>Close</MDButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={addAssemblyModalOpen}
        onClose={closeAssemblyModal}
        fullWidth
        maxWidth="xxl"
        PaperProps={{
          style: {
            backgroundColor: rgba(
              darkMode ? theme.palette.dark.main : theme.palette.white.main,
              1
            ),
          },
        }}
      >
        <DialogTitle>Add Assembly</DialogTitle>
        <DialogContent>
          <QueryAssembliesTable
            assemblyData={{
              columns: [
                { header: "Assembly Name", accessorKey: "name", size: 100 },
                {
                  header: "Assembly Description",
                  accessorKey: "description",
                  size: 250,
                },
                {
                  header: "hgt (mm)",
                  accessorKey: "height",
                  size: 80,
                  muiTableHeadCellProps: {
                    align: "right",
                  },
                  muiTableBodyCellProps: {
                    align: "right",
                  },
                },
                { header: "creator", accessorKey: "creator", size: 100 },
                { header: "updated", accessorKey: "updated", size: 50 },
                {
                  header: "actions",
                  accessorKey: "actions",
                  size: 50,
                  muiTableHeadCellProps: {
                    align: "right",
                  },
                  muiTableBodyCellProps: {
                    align: "right",
                  },
                },
              ],
              rows: newAssemblyModalRows,
            }}
          />
        </DialogContent>
        <DialogActions>
          <MDButton onClick={closeAssemblyModal}>Close</MDButton>
        </DialogActions>
      </Dialog>
      <PartDetailDialog
        currentTableSelection={currentTableSelection}
        handlePartDetailChange={handlePartDetailChange}
        handleAddPart={addSinglePart}
        open={openPartDetailDialog}
        onClose={handlePartDetailDialogClose}
      />
      <SelectedPartCardDialog
        currentTableSelection={currentTableSelection}
        handleQuantityChange={handleQuantityChange}
        handleAddPart={addSinglePart}
        open={openSelectedPartCardDialog}
        onClose={handlePartDialogClose}
      />
    </Grid>
  );
}

export default EstimatePartSelection;
