// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { ApsContext } from "ApsContext";
import { useContext } from "react";

function ApsBreadcrumbs({ icon, title, route, light }) {
  const routes = route.slice(0, -1);

  return (
    <MDBox mr={{ xs: 0, xl: 0 }}>
      <MuiBreadcrumbs
        sx={{
          "& .MuiBreadcrumbs-separator": {
            color: ({ palette: { white, grey } }) =>
              light ? white.main : grey[600],
          },
        }}
      >
        <MDTypography
          component="span"
          variant="body2"
          color={light ? "white" : "dark"}
          opacity={light ? 0.8 : 0.5}
          sx={{ lineHeight: 0 }}
        >
          <Icon>{icon}</Icon>
        </MDTypography>
        {routes.map((el, index) => (
          <MDTypography
            key={index}
            component="span"
            variant="button"
            fontWeight="regular"
            textTransform="capitalize"
            color={light ? "white" : "dark"}
            opacity={light ? 0.8 : 0.5}
            sx={{ lineHeight: 0 }}
          >
            {el.length > 20 ? `${el.substring(0, 20)}...` : el}
          </MDTypography>
        ))}
        <MDTypography
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          color={light ? "white" : "dark"}
          sx={{ lineHeight: 0 }}
        >
          {title.replace("-", " ")}
        </MDTypography>
      </MuiBreadcrumbs>
    </MDBox>
  );
}

// Setting default values for the props of Breadcrumbs
ApsBreadcrumbs.defaultProps = {
  light: false,
};

// Typechecking props for the Breadcrumbs
ApsBreadcrumbs.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  light: PropTypes.bool,
};

function QuoteDbBreadcrumbs() {
  const { getCurrentDBTierBayNames } = useContext(ApsContext);

  const { db, tier, bay } = getCurrentDBTierBayNames();

  return (
    <ApsBreadcrumbs
      icon="developer_board"
      title={bay}
      route={[db, tier, bay]}
    />
  );
}

export default QuoteDbBreadcrumbs;
