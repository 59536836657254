// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import dbUltimateImage from "assets/images/aps/DB-Ultimate-Part.jpg";

function AssemblyProductCell({ image, name, description }) {
  return (
    <MDBox display="flex" alignItems="center" pr={2}>
      <MDBox mr={2}>
        <MDAvatar src={image} alt={name} size="sm" />
      </MDBox>
      <MDBox display="flex" flexDirection="column">
        <MDTypography variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="button" fontWeight="regular" color="secondary">
          {description}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

AssemblyProductCell.defaultProps = {
  image: dbUltimateImage,
  name: "",
  description: "",
};

// Typechecking props for the ProductCell
AssemblyProductCell.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default AssemblyProductCell;
