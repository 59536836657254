import { ApsContext } from "ApsContext";
import { useState, useEffect, useContext, useMemo, useCallback } from "react";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import NoChassisFound from "layouts/November/NewEstimate/EstimateChassis/components/NoChassisFound";
import { useAPSDB } from "hooks/useAPSDB";
import { DragContext } from "customInputs/DragNDropChassis/DragContext";

import ChassisDnD from "customInputs/DragNDropChassis/ChassisDnD";
import QueryBreakerTable from "customInputs/queryTables/QueryBreakerTable";

import QueryCustomTable from "customInputs/queryTables/QueryCustomTable";
import AddAssemblyCell from "customInputs/customTableCells/addAssemblyCell";
import AddBreakerCell from "customInputs/customTableCells/addBreakerCell";
import { Grid, DialogContentText } from "@mui/material";
import CrudDialog from "customInputs/crud-dialog";

function EstimateChassisW() {
  const {
    quotingDB,
    currentID,
    chassisList,
    addCircuitBreakersToChassis,
    swapChassis,
    currentChassis,
    changeCurrentChassis,
    updateBothChassisParts,
    removeChassis,
  } = useContext(ApsContext);
  const { getFirstAvailableSinglePole, getFirstAvailableTriplePole } =
    useContext(DragContext);
  const {
    getCircuitBreakersOfChassis,
    getChassisSpecsFromPartNumber,
    getAssembliesInBay,
  } = useAPSDB();
  const [currentSelectedChassis, setCurrentSelectedChassis] = useState(
    chassisList[currentChassis]
  );

  const [partGroupItems, setPartGroupItems] = useState([]);
  const [chassisSpecs, setChassisSpecs] = useState({
    numberOfPoles: 0,
    isHybridChassis: false,
    type: "",
    rating: "",
  });

  // const [usedPoles, setUsedPoles] = useState(0);
  const [confirmUpdateDialogOpen, setConfirmUpdateDialogOpen] = useState(false);

  const callBackgetCircuitBreakersOfChassis = useCallback(() => {
    const chassis = chassisList[currentChassis];
    if (chassis !== null) {
      const circuitBreakers = chassis.circuitBreakers;
      setPartGroupItems(circuitBreakers);
      setCurrentSelectedChassis(chassis);
    } else {
      const otherChassis =
        currentChassis === "chassis1" ? "chassis2" : "chassis1";
      // swap to other chassis
      changeCurrentChassis(otherChassis);
      const otherChassisDetails = chassisList[otherChassis];
      if (otherChassisDetails !== null) {
        const circuitBreakers = otherChassisDetails.circuitBreakers;
        setPartGroupItems(circuitBreakers);
        setCurrentSelectedChassis(otherChassisDetails);
      }
    }
  }, [changeCurrentChassis, chassisList, currentChassis]);

  // function to calculate the number of used poles from the partGroupItems array where number of used poles is the quantity * the number of phases
  const calculateUsedPoles = useCallback(() => {
    const occupiedPoles = Math.round(
      partGroupItems.reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue.part.poles * currentValue.quantity,
        0
      )
    );
    // setUsedPoles(occupiedPoles);
    return occupiedPoles;
  }, [partGroupItems]);

  useEffect(() => {
    callBackgetCircuitBreakersOfChassis();
  }, [callBackgetCircuitBreakersOfChassis]);

  useEffect(() => {
    const specs = getChassisSpecsFromPartNumber(
      currentSelectedChassis.partNumber
    );
    setChassisSpecs(
      specs === null
        ? { numberOfPoles: 0, isHybridChassis: false, type: "", rating: "" }
        : specs
    );
  }, [currentSelectedChassis.partNumber, getChassisSpecsFromPartNumber]);

  const handleNextChassis = useCallback(() => {
    const nextChassisKey =
      currentChassis === "chassis1" ? "chassis2" : "chassis1";

    const nextChassis = chassisList[nextChassisKey];
    const breakersToAdd = getCircuitBreakersOfChassis(
      currentID,
      nextChassisKey
    );
    swapChassis();
    setCurrentSelectedChassis(nextChassis);
    //setIsChassis1(!isChassis1);

    setPartGroupItems(
      breakersToAdd && breakersToAdd.length > 0 ? breakersToAdd : []
    );
  }, [
    currentChassis,
    chassisList,
    getCircuitBreakersOfChassis,
    currentID,
    swapChassis,
  ]);

  /* const availablePoles = useCallback(() => {
    const sparePoleCount = Math.round(
      (currentSelectedChassis.spareCount * currentSelectedChassis.poles) / 100
    );
    const availablePoles =
      currentSelectedChassis.poles - usedPoles - sparePoleCount;
    return availablePoles;
  }, [currentSelectedChassis, usedPoles]); */

  const addPartToPartGroupItems = useCallback(
    (tempPart, tempQty) => {
      if (
        partGroupItems.find(
          (partGroupItem) =>
            partGroupItem.part.partNumber === tempPart.partNumber
        )
      ) {
        // if it does, update the quantity of the partGroupItem
        const newPartGroupItems = partGroupItems.map((partGroupItem) => {
          if (partGroupItem.part.partNumber === tempPart.partNumber) {
            return {
              ...partGroupItem,
              quantity: parseInt(partGroupItem.quantity) + parseInt(tempQty),
            };
          }
          return partGroupItem;
        });
        setPartGroupItems(newPartGroupItems);
        //const updatingChassis = isChassis1 ? "chassis1" : "chassis2";

        if (newPartGroupItems.length > 0) {
          addCircuitBreakersToChassis(
            currentID,
            currentChassis,
            newPartGroupItems
          );
        }
      } else {
        // if it doesn't, add the currentTableSelection to the partGroupItems array
        const newPartGroupItems = [
          ...partGroupItems,
          {
            part: tempPart,
            quantity: parseInt(tempQty),
          },
        ];
        setPartGroupItems(newPartGroupItems);
        //const updatingChassis = isChassis1 ? "chassis1" : "chassis2";
        if (newPartGroupItems.length > 0) {
          addCircuitBreakersToChassis(
            currentID,
            currentChassis,
            newPartGroupItems
          );
        }
      }
    },
    [addCircuitBreakersToChassis, currentChassis, currentID, partGroupItems]
  );

  /* const handleQtyCellChange = useCallback(
    (e, updatingPart) => {
      // if val is a string, convert it to a number
      let val = e.target.value;
      if (typeof val === "string") {
        val = parseInt(val);
      }
      const newPartGroupItems = partGroupItems.map((partGroupItem) => {
        if (partGroupItem.part.partNumber === updatingPart.partNumber) {
          return {
            ...partGroupItem,
            quantity: val,
          };
        } else {
          return partGroupItem;
        }
      });

      setPartGroupItems(newPartGroupItems);
    },
    [partGroupItems, setPartGroupItems]
  );

  const removePartFromGroup = useCallback(
    (part) => {
      const newPartGroupItems = partGroupItems.filter(
        (partGroupItem) => partGroupItem.part.partNumber !== part.partNumber
      );
      setPartGroupItems(newPartGroupItems);
      //const updatingChassis = isChassis1 ? "chassis1" : "chassis2";

      addCircuitBreakersToChassis(currentID, currentChassis, newPartGroupItems);
    },
    [
      partGroupItems,
      setPartGroupItems,
      currentChassis,
      currentID,
      addCircuitBreakersToChassis,
    ]
  ); */

  const handleConfirmAdd = useCallback(
    (part) => {
      if (calculateUsedPoles() + part.poles <= currentSelectedChassis.poles) {
        addPartToPartGroupItems(part, 1);
      }
    },
    [addPartToPartGroupItems, calculateUsedPoles, currentSelectedChassis.poles]
  );

  const chassisHasSpace = useCallback(
    (poles, breakerItem) => {
      if (poles === 3) {
        return getFirstAvailableTriplePole(breakerItem);
      }
      return getFirstAvailableSinglePole(breakerItem);
    },
    [getFirstAvailableSinglePole, getFirstAvailableTriplePole]
  );

  const makePartAssemblyTableRows = useMemo(() => {
    return partGroupItems.length > 0
      ? partGroupItems.map((partGroupItem) => {
          const ch = chassisHasSpace(partGroupItem.part.poles, {
            id: `${partGroupItem.part.partNumber}-`,
            type: partGroupItem.part.poles === 3 ? "triple" : "single",
          });
          return {
            partNumber: partGroupItem.part.partNumber,
            description: partGroupItem.part.description,
            type: partGroupItem.part.filter1.slice(
              partGroupItem.part.filter1.indexOf(" ") + 1
            ),
            cost: partGroupItem.part.cost,
            stock: partGroupItem.part.stock,
            qty: partGroupItem.quantity,
            add:
              ch === null ? (
                <AddBreakerCell />
              ) : (
                <AddAssemblyCell
                  icon="add"
                  color="success"
                  onIconClick={handleConfirmAdd}
                  assembly={partGroupItem.part}
                />
              ),
          };
        })
      : [];
  }, [partGroupItems, chassisHasSpace, handleConfirmAdd]);

  // function to check if any of the parts in the assemblies added to the bay are chassis parts. If they are, then update the chassisList for the bay. If there are no chassis parts in the assemblies added to the bay or the parts added to the bay, then do not update the chassisList for the bay.
  const checkForChassis = useCallback(() => {
    const assembliesAdded = getAssembliesInBay(currentID);
    if (!assembliesAdded || assembliesAdded.length === 0) {
      removeChassis(currentID);
      return;
    }
    let chassisParts = assembliesAdded.flatMap((assembly) =>
      assembly.parts
        .filter(
          (part) =>
            part.filter1.includes("Chassis") &&
            !part.partNumber.includes("1P") &&
            !part.partNumber.includes("2P") &&
            !part.partNumber.includes("4P")
        )
        .flatMap((part) => Array(part.AssembliesRelation.quantity).fill(part))
    );

    if (chassisParts.length === 0) {
      removeChassis(currentID);
      return;
    }

    if (chassisParts.length === 1) {
      updateBothChassisParts(currentID, chassisParts[0].partNumber, null);
      changeCurrentChassis("chassis1");
      return;
    } else if (chassisParts.length >= 2) {
      updateBothChassisParts(
        currentID,
        chassisParts[0].partNumber,
        chassisParts[1].partNumber
      );
      changeCurrentChassis("chassis1");
      return;
    }
  }, [
    changeCurrentChassis,
    currentID,
    getAssembliesInBay,
    removeChassis,
    updateBothChassisParts,
  ]);

  const handleUpdateChassisConfig = () => {
    setConfirmUpdateDialogOpen(true);
  };

  const handleConfirmClose = () => {
    setConfirmUpdateDialogOpen(false);
  };
  const handleConfirmAction = () => {
    if (quotingDB.length > 0) {
      checkForChassis();
      setConfirmUpdateDialogOpen(false);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <QueryBreakerTable
          addSinglePart={handleConfirmAdd}
          chassisHasSpace={chassisHasSpace}
        />
      </Grid>
      <Grid item xs={12}>
        <QueryCustomTable
          data={{
            customTopActions: (
              <Grid container spacing={1} pt={1}>
                <Grid item xs={2.5}>
                  <MDTypography variant="h5">
                    {currentChassis === "chassis1" ? "Chassis 1" : "Chassis 2"}
                    &nbsp;
                  </MDTypography>
                </Grid>
                <Grid item xs={3}>
                  {chassisList["chassis1"] !== null &&
                    chassisList["chassis2"] !== null && (
                      <MDButton
                        variant="gradient"
                        color="dark"
                        size="small"
                        fullWidth
                        onClick={() => handleNextChassis()}
                      >
                        Next Chassis
                      </MDButton>
                    )}
                </Grid>
                <Grid item xs={3}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    size="small"
                    fullWidth
                    onClick={handleUpdateChassisConfig}
                  >
                    Update Chassis Config
                  </MDButton>
                </Grid>
                <Grid item xs={3.5}></Grid>
                <Grid item xs={2.5}>
                  <MDTypography variant="caption">
                    {`Part: ${currentSelectedChassis.partNumber}`}
                  </MDTypography>
                </Grid>
                <Grid item xs={2.5}>
                  <MDTypography variant="caption">
                    {`Type: ${chassisSpecs.type}`}
                  </MDTypography>
                </Grid>
                <Grid item xs={2}>
                  <MDTypography variant="caption">
                    {`Rating: ${chassisSpecs.rating}`}
                  </MDTypography>
                </Grid>
                <Grid item xs={2}>
                  <MDTypography variant="caption">
                    {`Size: ${chassisSpecs.numberOfPoles} Poles`}
                  </MDTypography>
                </Grid>
              </Grid>
            ),
            columns: [
              {
                header: "Part Number",
                accessorKey: "partNumber",
                size: 50,
              },
              {
                header: "Description",
                accessorKey: "description",
                size: 130,
              },
              { header: "Type", accessorKey: "type", size: 50 },
              {
                header: "Stock",
                accessorKey: "stock",
                size: 20,
              },
              {
                header: "Qty",
                accessorKey: "qty",
                size: 20,
                muiTableHeadCellProps: {
                  align: "right",
                },
                muiTableBodyCellProps: {
                  align: "right",
                },
              },
              {
                header: "Actions",
                accessorKey: "add",
                size: 20,
                muiTableHeadCellProps: {
                  align: "right",
                },
                muiTableBodyCellProps: {
                  align: "right",
                },
              },
            ],
            rows: makePartAssemblyTableRows,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <ChassisDnD />
      </Grid>
      <CrudDialog
        title="Update Chassis Configuration"
        size="xs"
        control={{
          open: confirmUpdateDialogOpen,
          onClose: handleConfirmClose,
        }}
        content={
          <DialogContentText>
            {`This action will replace existing chassis configurations with new chassis configuration templates based on (if any) chassis parts in the current bay.`}
          </DialogContentText>
        }
        actions={{
          confirmName: "Update",
          confirmHandler: handleConfirmAction,
        }}
      />
    </Grid>
  );
}

function EstimateChassis() {
  const { chassisList } = useContext(ApsContext);

  if (chassisList.chassis1 === null) return <NoChassisFound />;

  return <EstimateChassisW />;
}

export default EstimateChassis;
