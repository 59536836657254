export const customerLetter = [
  "Dear Customer,",
  " ",
  "Thank you for the opportunity to provide you with pricing on this project",
  " ",
  "At APS Industrial, we're the gateway that connects the world's leading",
  "industrial manufacturers to the local industry. We serve the local market",
  "as a connected technology partner intent on delivering quality products",
  "and the ultimate in customer service. We're committed to offering our",
  "customers an experience that is consistent and reliable as we together",
  "create solutions that power Australia.",
  " ",
  "We are pleased to provide the following quote as per your request. Whilst",
  "every effort is taken to ensure this quotation meets your requirements,",
  "we do ask that you please review in detail and check for accuracy.",
  "We also ask that you please note the inclusions and exclusions detailed",
  "hereafter.",
  " ",
  "Should you have any questions or require further information, please",
  "do not hesitate to contact me on the details provided below.",
  " ",
  "Thank you once again and best regards,",
];
