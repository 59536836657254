import { useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Autocomplete } from "@mui/material";
import { useCircuitBreakers } from "services/query-service";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import PropTypes from "prop-types";
import AddAssemblyCell from "customInputs/customTableCells/addAssemblyCell";
import AddBreakerCell from "customInputs/customTableCells/addBreakerCell";

const COLUMNS = [
  /* {
    accessorKey: "type",
    header: "Type",
    filterFn: "equals",
  },
  {
    accessorKey: "poles",
    header: "Poles",
    filterFn: "equals",
  },
  {
    accessorKey: "rating",
    header: "Rating",
    filterFn: "equals",
  },
  {
    accessorKey: "curve",
    header: "Curve",
    filterFn: "equals",
  },
  {
    accessorKey: "ka",
    header: "KA",
    filterFn: "equals",
  }, */
  {
    accessorKey: "filter1",
    header: "Filter 1",
  },
  {
    accessorKey: "filter2",
    header: "Filter 2",
  },
  {
    accessorKey: "partNumber",
    header: "Part Number",
    size: 75,
  },
  {
    accessorKey: "description",
    header: "Description",
    size: 175,
  },
  {
    header: "Stock",
    accessorKey: "stock",
    size: 20,
  },
  {
    accessorKey: "cost",
    header: "Cost ($)",
    size: 50,
    muiTableHeadCellProps: {
      align: "right",
    },
    muiTableBodyCellProps: {
      align: "right",
    },
  },

  {
    accessorKey: "add",
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableSorting: false,
    header: "Actions",
    size: 25,
    muiTableHeadCellProps: {
      align: "right",
    },
    muiTableBodyCellProps: {
      align: "right",
    },
  },
];

const INITIAL_STATE = {
  density: "compact",
  columnVisibility: {
    type: false,
    poles: false,
    rating: false,
    curve: false,
    ka: false,
    filter1: false,
    filter2: false,
  },
  showGlobalFilter: true,
  showToolbarDropZone: false,
};

function QueryBreakerTable({ addSinglePart, chassisHasSpace }) {
  const { data, isSuccess } = useCircuitBreakers();
  const [currentFilter1, setCurrentFilter1] = useState("");
  const [currentFilter2, setCurrentFilter2] = useState("");

  const getFilter1Options = (data) => {
    if (!isSuccess) return [];

    const filter1Options = new Set();
    filter1Options.add("");
    data.forEach((part) => {
      filter1Options.add(part.filter1);
    });

    return Array.from(filter1Options);
  };

  const getFilter2Options = (data, filter1Val) => {
    if (!isSuccess) return [];

    const filter2Options = new Set();
    filter2Options.add("");
    data.forEach((part) => {
      if (part.filter1 === filter1Val) {
        filter2Options.add(part.filter2);
      }
    });

    return Array.from(filter2Options);
  };

  /* const [currentType, setCurrentType] = useState(null);
  const [currentPoles, setCurrentPoles] = useState(null);
  const [currentRating, setCurrentRating] = useState(null);
  const [currentCurve, setCurrentCurve] = useState(null);
  const [currentKa, setCurrentKa] = useState(null); */
  /* const filteredParts = useMemo(() => {
    if (!isSuccess) return [];

    return data.filter((part) => {
      return (
        (currentType === null ||
          currentType === "" ||
          part.type === currentType) &&
        (currentPoles === null ||
          currentPoles === "" ||
          part.poles === currentPoles) &&
        (currentRating === null ||
          currentRating === "" ||
          part.rating === currentRating) &&
        (currentCurve === null ||
          currentCurve === "" ||
          part.curve === currentCurve) &&
        (currentKa === null || currentKa === "" || part.ka === currentKa)
      );
    });
  }, [
    isSuccess,
    data,
    currentType,
    currentPoles,
    currentRating,
    currentCurve,
    currentKa,
  ]); 

  const getTypes = () => {
    if (!isSuccess) return [];

    const typeOptions = new Set();
    filteredParts.forEach((part) => {
      typeOptions.add(part.type);
    });

    return Array.from(typeOptions);
  };

  const getPoles = () => {
    if (!isSuccess) return [];

    const polesOptions = new Set();
    filteredParts.forEach((part) => {
      polesOptions.add(part.poles);
    });

    return Array.from(polesOptions);
  };

  const getRating = () => {
    if (!isSuccess) return [];

    const ratingOptions = new Set();
    filteredParts.forEach((part) => {
      ratingOptions.add(part.rating);
    });

    return Array.from(ratingOptions);
  };

  const getCurve = () => {
    if (!isSuccess) return [];

    const curveOptions = new Set();
    filteredParts.forEach((part) => {
      curveOptions.add(part.curve);
    });

    return Array.from(curveOptions);
  };

  const getKa = () => {
    if (!isSuccess) return [];

    const kaOptions = new Set();
    filteredParts.forEach((part) => {
      kaOptions.add(part.ka);
    });

    return Array.from(kaOptions);
  }; */

  const tableData = useMemo(() => {
    if (!isSuccess) return [];
    const breakers = data.map((part) => {
      const ch = chassisHasSpace(part.poles, {
        id: `${part.partNumber}-`,
        type: part.poles === 3 ? "triple" : "single",
      });
      return {
        filter1: part.filter1,
        filter2: part.filter2,
        partNumber: part.partNumber,
        description: part.description,
        cost: part.cost,
        type: part.type,
        poles: part.poles,
        rating: part.rating,
        curve: part.curve,
        ka: part.ka,
        stock: part.stock,
        add:
          ch === null ? (
            <AddBreakerCell />
          ) : (
            <AddAssemblyCell
              icon="add"
              color="success"
              onIconClick={addSinglePart}
              assembly={part}
            />
          ),
      };
    });
    return breakers;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, chassisHasSpace, addSinglePart]);

  const columns = useMemo(() => COLUMNS, []);

  const table = useMaterialReactTable({
    columns,
    data: tableData,
    initialState: INITIAL_STATE,
    enableHiding: false,
    enableFullScreenToggle: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableDensityToggle: false,
    layoutMode: "grid",
    autoResetPageIndex: false,

    renderTopToolbarCustomActions: ({ table }) => {
      return (
        <MDBox
          display="flex"
          width="50%"
          justifyContent="space-between"
          alignItems="center"
        >
          <MDBox width="15rem">
            <Autocomplete
              value={currentFilter1}
              options={getFilter1Options(data)}
              onChange={(e, val) => {
                if (val === null || val === "") {
                  table.resetColumnFilters();
                  setCurrentFilter1("");
                  setCurrentFilter2("");
                } else {
                  table.setColumnFilters([
                    { id: "filter1", value: val },
                    { id: "filter2", value: getFilter2Options(data, val)[0] },
                  ]);
                  setCurrentFilter1(val);
                  setCurrentFilter2(getFilter2Options(data, val)[0]);
                }
              }}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  size="medium"
                  variant="standard"
                  label="Filter 1"
                />
              )}
            />
          </MDBox>
          <MDBox width="15rem">
            <Autocomplete
              value={currentFilter2}
              options={getFilter2Options(data, currentFilter1)}
              onChange={(e, val) => {
                if (val === null || val === "") {
                  table.setColumnFilters((currentFilters) =>
                    currentFilters.filter((filter) => filter.id !== "filter2")
                  );

                  setCurrentFilter2(val);
                  return;
                } else {
                  table.setColumnFilters((currentFilters) => [
                    ...currentFilters.filter(
                      (filter) => filter.id !== "filter2"
                    ),
                    { id: "filter2", value: val },
                  ]);
                  setCurrentFilter2(val);
                }
              }}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  size="medium"
                  variant="standard"
                  label="Filter 2"
                />
              )}
            />
          </MDBox>
          {/* <MDBox
          display="flex"
          width="75%"
          justifyContent="space-between"
          alignItems="center"
        >
          <MDBox width="5rem">
            <Autocomplete
              value={currentType}
              options={getTypes()}
              onChange={(e, val) => {
                if (val === null || val === "") {
                  table.setColumnFilters((currentFilters) =>
                    currentFilters.filter((filter) => filter.id !== "type")
                  );

                  setCurrentType(val);
                  return;
                } else {
                  table.setColumnFilters((currentFilters) => [
                    ...currentFilters.filter((filter) => filter.id !== "type"),
                    { id: "type", value: val },
                  ]);
                  setCurrentType(val);
                }
              }}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  size="medium"
                  variant="standard"
                  label="Type"
                />
              )}
            />
          </MDBox>
          <MDBox width="5rem">
            <Autocomplete
              value={currentPoles}
              options={getPoles()}
              onChange={(e, val) => {
                if (val === null || val === "") {
                  table.setColumnFilters((currentFilters) =>
                    currentFilters.filter((filter) => filter.id !== "poles")
                  );

                  setCurrentPoles(val);
                  return;
                } else {
                  table.setColumnFilters((currentFilters) => [
                    ...currentFilters.filter((filter) => filter.id !== "poles"),
                    { id: "poles", value: val },
                  ]);
                  setCurrentPoles(val);
                }
              }}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  size="medium"
                  variant="standard"
                  label="Poles"
                />
              )}
            />
          </MDBox>
          <MDBox width="5rem">
            <Autocomplete
              value={currentRating}
              options={getRating()}
              onChange={(e, val) => {
                if (val === null || val === "") {
                  table.setColumnFilters((currentFilters) =>
                    currentFilters.filter((filter) => filter.id !== "rating")
                  );

                  setCurrentRating(val);
                  return;
                } else {
                  table.setColumnFilters((currentFilters) => [
                    ...currentFilters.filter(
                      (filter) => filter.id !== "rating"
                    ),
                    { id: "rating", value: val },
                  ]);
                  setCurrentRating(val);
                }
              }}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  size="medium"
                  variant="standard"
                  label="Rating"
                />
              )}
            />
          </MDBox>
          <MDBox width="5rem">
            <Autocomplete
              value={currentCurve}
              options={getCurve()}
              onChange={(e, val) => {
                if (val === null || val === "") {
                  table.setColumnFilters((currentFilters) =>
                    currentFilters.filter((filter) => filter.id !== "curve")
                  );

                  setCurrentCurve(val);
                  return;
                } else {
                  table.setColumnFilters((currentFilters) => [
                    ...currentFilters.filter((filter) => filter.id !== "curve"),
                    { id: "curve", value: val },
                  ]);
                  setCurrentCurve(val);
                }
              }}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  size="medium"
                  variant="standard"
                  label="Curve"
                />
              )}
            />
          </MDBox>
          <MDBox width="5rem">
            <Autocomplete
              value={currentKa}
              options={getKa()}
              onChange={(e, val) => {
                if (val === null || val === "") {
                  table.setColumnFilters((currentFilters) =>
                    currentFilters.filter((filter) => filter.id !== "ka")
                  );

                  setCurrentKa(val);
                  return;
                } else {
                  table.setColumnFilters((currentFilters) => [
                    ...currentFilters.filter((filter) => filter.id !== "ka"),
                    { id: "ka", value: val },
                  ]);
                  setCurrentKa(val);
                }
              }}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  size="medium"
                  variant="standard"
                  label="KA"
                />
              )}
            />
          </MDBox>
        </MDBox> */}
        </MDBox>
      );
    },
    muiTableHeadProps: {
      sx: {
        display: "table-header-group",
        padding: 0,
      },
    },
    muiTableHeadRowProps: {
      sx: {
        boxShadow: "none",
      },
    },
    muiTablePaperProps: {
      sx: {
        width: "100%",
        px: 2,
        elevation: 0,
        backgroundColor: "transparent",
      },
    },
    muiTableContainerProps: {
      sx: {
        mt: 2,
        elevation: 0,
        borderRadius: 0,
        boxShadow: "none",
      },
    },
    muiPaginationProps: {
      showRowsPerPage: false,
      showFirstButton: false,
      showLastButton: false,
      variant: "text",
    },
    paginationDisplayMode: "pages",
    muiTableBodyCellProps: {
      sx: {
        paddingY: 0,
        borderTop: "0.1px solid #E0E0E0",
        fontSize: "0.75rem",
        fontWeight: "400",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        fontSize: "0.8rem",
        fontWeight: "600",
        textTransform: "uppercase",
      },
    },
    mrtTheme: {
      baseBackgroundColor: "#ffffff00",
    },
  });

  return <MaterialReactTable table={table} />;
}

QueryBreakerTable.defaultProps = {
  addSinglePart: () => {},
};

QueryBreakerTable.propTypes = {
  addSinglePart: PropTypes.func,
};

export default QueryBreakerTable;
