import { useEffect, useState } from "react";
import MDSnackbar from "components/MDSnackbar";

// Success and error pop up notifications
// notification (required): string; message to be displayed
// type (required): string; "success" or "error"
// event (required): boolean; trigger notification
export default function NotifySnackbar({ notification, type, event }) {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [localEvent, setLocalEvent] = useState(event);

  useEffect(() => {
    if (
      typeof notification === "string" &&
      typeof type === "string" &&
      localEvent !== event
    ) {
      if (type === "success") {
        setShowSnackbar(true);
        setShowErrorSnackbar(false);
      } else if (type === "error") {
        setShowSnackbar(false);
        setShowErrorSnackbar(true);
      }
      setLocalEvent(event);
    }
  }, [event, notification, type, localEvent]);

  const toggleSnackbar = () => {
    setShowSnackbar(false);
  };

  const toggleErrorSnackbar = () => {
    setShowErrorSnackbar(false);
  };

  useEffect(() => {
    if (showSnackbar) {
      setShowErrorSnackbar(false);
      setTimeout(() => {
        setShowSnackbar(false);
      }, 5000);
    }
  }, [showSnackbar]);

  useEffect(() => {
    if (showErrorSnackbar) {
      setShowSnackbar(false);
      setTimeout(() => {
        setShowErrorSnackbar(false);
      }, 5000);
    }
  }, [showErrorSnackbar]);

  return (
    <>
      <MDSnackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={showSnackbar}
        close={toggleSnackbar}
        color="success"
        icon="notifications"
        title="Success"
        content={notification}
        dateTime="Just Now"
      />
      <MDSnackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={showErrorSnackbar}
        close={toggleErrorSnackbar}
        icon="cancel"
        color="error"
        title="Error"
        content={notification}
        dateTime="Just Now"
      />
    </>
  );
}
