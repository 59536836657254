import { useDrag } from "react-dnd";
import { itemTypes } from "customInputs/DragNDropChassis/dragNdropItemTypes";
import onePRCBO from "assets/images/aps/1prcbo.png";
import onePMCB from "assets/images/aps/1pmcb.png";
import MDBox from "components/MDBox";
import { Tooltip, Grid } from "@mui/material";
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import MDButton from "components/MDButton";
import { useContext } from "react";
import { DragContext } from "./DragContext";
import pxToRem from "assets/theme/functions/pxToRem";
import MDTypography from "components/MDTypography";

function SinglePhaseBreaker({ id, flip, type }) {
  const { handleOpenBreakerDialog, getBreakerFuncTextAndOther, deleteBreaker } =
    useContext(DragContext);
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: itemTypes.BREAKER,
      item: { id: id, type: "single" },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [id, flip]
  );

  const handleEditClick = () => {
    handleOpenBreakerDialog(id);
  };

  const handleDeleteClick = () => {
    deleteBreaker(id);
  };

  const toolTipPlacement = flip ? "right" : "left";
  const opToolTipPlacement = flip ? "left" : "right";

  const { funcText, other, partNum } = getBreakerFuncTextAndOther(id);

  const functionDisplayText = funcText === "" ? "" : `${funcText} ${other}`;
  const displayText = `${partNum}`;

  const innerWidth = window.innerWidth;

  const buttonsSpacing =
    innerWidth > 2200
      ? 1.5
      : innerWidth > 2000
      ? 2
      : innerWidth > 1700
      ? 2.5
      : 3.5;

  const functionDisplayTextSpacing =
    innerWidth > 2200
      ? 10.5
      : innerWidth > 2000
      ? 10
      : innerWidth > 1700
      ? 9.5
      : 8.5;

  const buttonFunctionDisplayTextGridSpacing =
    innerWidth > 2200
      ? 12
      : innerWidth > 2000
      ? 11
      : innerWidth > 1800
      ? 10
      : innerWidth > 1700
      ? 9
      : 8;

  const imageDisplayTextGridSpacing =
    innerWidth > 2200
      ? 8
      : innerWidth > 2000
      ? 9
      : innerWidth > 1800
      ? 10
      : innerWidth > 1700
      ? 11
      : 12;

  return (
    <Grid
      container
      columns={20}
      spacing={1}
      display="flex"
      justifyContent="space-between"
    >
      {!flip && (
        <Grid
          item
          xs={buttonFunctionDisplayTextGridSpacing}
          display="flex"
          justifyContent="flex-start"
        >
          <Grid
            container
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid
              item
              xs={buttonsSpacing}
              display="flex"
              justifyContent="flex-start"
            >
              <Tooltip title="Edit" placement={toolTipPlacement} arrow>
                <MDButton
                  size="small"
                  iconOnly
                  circular
                  variant="text"
                  color="info"
                  onClick={() => handleEditClick()}
                >
                  <Edit />
                </MDButton>
              </Tooltip>
              <Tooltip title="Delete" placement={opToolTipPlacement} arrow>
                <MDButton
                  size="small"
                  iconOnly
                  circular
                  variant="text"
                  color="error"
                  onClick={() => handleDeleteClick()}
                >
                  <Delete color="error" />
                </MDButton>
              </Tooltip>
            </Grid>
            {!flip && (
              <Grid
                item
                xs={functionDisplayTextSpacing}
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                sx={{ overflow: "hidden" }}
              >
                <MDTypography
                  variant="caption"
                  fontWeight="medium"
                  color="error"
                  align="left"
                  noWrap
                  sx={{ fontFamily: "monospace" }}
                >
                  {functionDisplayText}
                </MDTypography>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
      <Grid item xs={imageDisplayTextGridSpacing}>
        <Grid
          container
          columns={16}
          display="flex"
          justifyContent="space-between"
        >
          {!flip && (
            <Grid
              item
              xs={6}
              justifyContent="flex-start"
              sx={{ overflow: "hidden" }}
              display="flex"
              alignItems="center"
            >
              <MDTypography
                variant="caption"
                fontWeight="medium"
                color="text"
                noWrap
                sx={{ fontFamily: "monospace" }}
              >
                {displayText}
              </MDTypography>
            </Grid>
          )}
          <Grid
            item
            xs={8}
            justifyContent={!flip ? "flex-end" : "flex-start"}
            display="flex"
          >
            <MDBox
              component="img"
              src={type === "MCB" ? onePMCB : onePRCBO}
              pl={type === "MCB" ? 3.5 : 0.5}
              alt={id}
              minHeight={pxToRem(30)}
              maxHeight={pxToRem(30)}
              minWidth={pxToRem(160)}
              maxWidth={pxToRem(160)}
              ref={drag}
              sx={{
                opacity: isDragging ? 0.1 : 1,
                cursor: "move",
                transform: flip ? "scaleX(-1)" : null,
              }}
            />
          </Grid>
          {flip && (
            <Grid
              item
              xs={6}
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              sx={{ overflow: "hidden" }}
            >
              <MDTypography
                variant="caption"
                fontWeight="medium"
                color="text"
                align="left"
                noWrap
                sx={{ fontFamily: "monospace" }}
              >
                {displayText}
              </MDTypography>
            </Grid>
          )}
        </Grid>
      </Grid>
      {flip && (
        <Grid
          item
          xs={buttonFunctionDisplayTextGridSpacing}
          display="flex"
          justifyContent="flex-end"
        >
          <Grid container display="flex" justifyContent="flex-end">
            {flip && (
              <Grid
                item
                xs={functionDisplayTextSpacing}
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                sx={{ overflow: "hidden" }}
              >
                <MDTypography
                  variant="caption"
                  fontWeight="medium"
                  color="error"
                  align="left"
                  noWrap
                  sx={{ fontFamily: "monospace" }}
                >
                  {functionDisplayText}
                </MDTypography>
              </Grid>
            )}
            <Grid
              item
              xs={buttonsSpacing}
              display="flex"
              justifyContent="flex-end"
            >
              <Tooltip title="Delete" placement={opToolTipPlacement} arrow>
                <MDButton
                  size="small"
                  iconOnly
                  circular
                  variant="text"
                  color="error"
                  onClick={() => handleDeleteClick()}
                >
                  <Delete color="error" />
                </MDButton>
              </Tooltip>
              <Tooltip title="Edit" placement={toolTipPlacement} arrow>
                <MDButton
                  size="small"
                  iconOnly
                  circular
                  variant="text"
                  color="info"
                  onClick={() => handleEditClick()}
                >
                  <Edit />
                </MDButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default SinglePhaseBreaker;
