import { useState } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import HeaderLanding from "layouts/November/Landing/components/HeaderLanding";
import EstimateLanding from "layouts/November/EstimateLanding";
import AssemblyLanding from "layouts/November/Assembly/AssemblyLanding";
function Landing() {
  // ComplexProjectCard dropdown menu state
  const [tabValue, setTabValue] = useState(1);

  // Dropdown menu
  /* const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
    >
      <MenuItem onClick={close}>Action</MenuItem>
      <MenuItem onClick={close}>Another action</MenuItem>
      <MenuItem onClick={close}>Something else here</MenuItem>
    </Menu>
  ); */

  const handleGetTabValue = (tabValue) => {
    setTabValue(tabValue);
  };

  const viewingWidth = window.innerWidth;

  return (
    <>
      <DashboardLayout>
        <HeaderLanding handleGetTabValue={handleGetTabValue}>
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <MDBox mb={1}>
                <MDTypography variant="h5" textAlign="center">
                  Welcome to APS Industrial Quote & Assembly Web Application
                </MDTypography>
              </MDBox>
              <MDBox>
                <MDTypography variant="body2" color="text" textAlign="center">
                  Other tools are found on the left navigation menu
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </HeaderLanding>
      </DashboardLayout>
      {tabValue === 1
        ? viewingWidth >= 1200 && <EstimateLanding />
        : viewingWidth >= 1200 && <AssemblyLanding />}
    </>
  );
}

export default Landing;
