import { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import breakpoints from "assets/theme/base/breakpoints";
import localImage from "assets/images/aps/DB-Ultimate.jpg";
import AuthService from "services/auth-service";
import { AuthContext } from "context";

function HeaderLanding({ children, handleGetTabValue }) {
  const authContext = useContext(AuthContext);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [userDetails, setUserDetails] = useState({ name: "", image: "" });

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  useEffect(() => {
    if (!authContext.isAuthenticated) return;
    (async () => {
      const response = await AuthService.getProfile();
      setUserDetails({
        name: response.data.attributes.name,
        image: response.data.attributes.profile_image,
        role: response.data.attributes.role_name,
      });
    })();
  }, [authContext.isAuthenticated]);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    handleGetTabValue(tabValue);
  };

  return (
    <MDBox position="relative">
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="10rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({
            functions: { rgba, linearGradient },
            palette: { gradients },
          }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0),
              rgba(gradients.info.state, 0)
            )}, url(${localImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -16,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDAvatar
              src={userDetails.image ?? localImage}
              alt="profile-image"
              size="xl"
              shadow="sm"
            />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {userDetails.name ?? "Aps User"}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="regular">
                {userDetails.role ?? "Unable to display at this time"}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item sx={{ mx: "auto" }}>
            {children}
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ mx: "auto" }}>
            <AppBar position="static">
              <Tabs
                orientation={tabsOrientation}
                value={tabValue}
                onChange={handleSetTabValue}
              >
                <Tab
                  label="Quotes"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      request_quote
                    </Icon>
                  }
                />
                <Tab
                  label="Assemblies"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      list_alt
                    </Icon>
                  }
                />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
HeaderLanding.defaultProps = {
  children: "",
};

// Typechecking props for the Header
HeaderLanding.propTypes = {
  children: PropTypes.node,
};

export default HeaderLanding;
