import { useState } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Icon, Tooltip } from "@mui/material";
import { keyframes } from "@mui/system";

const wiggle = keyframes`
  0% { transform: rotate(0deg); }
  10% { transform: rotate(-45deg); }
  20% { transform: rotate(45deg); }
  30% { transform: rotate(-45deg); }
  40% { transform: rotate(45deg); }
  50% { transform: rotate(-45deg); }
  60% { transform: rotate(45deg); }
  70% { transform: rotate(-45deg); }
  80% { transform: rotate(45deg); }
  90% { transform: rotate(-45deg); }
  100% { transform: rotate(0deg); }
`;

function AddBreakerCell() {
  const [isClicked, setIsClicked] = useState(false);

  return (
    <MDBox display="flex" alignItems="center">
      <Tooltip title="No valid position" placement="right">
        <MDButton
          variant="text"
          size="small"
          iconOnly
          circular
          onClick={() => {
            setIsClicked(true);
            setTimeout(() => setIsClicked(false), 500); // Reset after the same duration as the transition
          }}
        >
          <Icon
            color="error"
            sx={{
              animation: isClicked ? `${wiggle} 0.5s ease-in-out` : "",
            }}
          >
            priority_high
          </Icon>
        </MDButton>
      </Tooltip>
    </MDBox>
  );
}

export default AddBreakerCell;
