/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState, useContext } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import { DialogContentText, Icon, Link, Divider, List } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavList from "examples/Sidenav/SidenavList";
import SidenavItem from "examples/Sidenav/SidenavItem";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

import CrudDialog from "customInputs/crud-dialog";

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  AuthContext,
} from "context";

import { ApsContext } from "ApsContext";

import AuthService from "services/auth-service";
import { Can } from "Can";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const authContext = useContext(AuthContext);
  const { resetContext, handleUpdateAssemblyNaming, updateResetQuoteStep } =
    useContext(ApsContext);

  const [logOutConfirm, setLogOutConfirm] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } =
    controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const items = pathname.split("/").slice(1);
  const itemParentName = items[1];
  const itemName = items[items.length - 1];

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  // function to trigger window alert when navigating away from page, refresh, or close if there are unsaved changes
  const handleNavAway = async (e) => {
    if (
      pathname.includes("/configurator/new-quote") ||
      pathname.includes("/configurator/edit-quote")
    ) {
      if (window.confirm("Any unsaved changes will be lost. Continue?")) {
        await resetContext();
        if (pathname.includes("/configurator/new-quote")) {
          updateResetQuoteStep();
        }
      } else {
        e.preventDefault();
      }
    }
    if (
      pathname.includes("/configurator/new-assembly") ||
      pathname.includes("/configurator/edit-assembly")
    ) {
      if (window.confirm("Any unsaved changes will be lost. Continue?")) {
        handleUpdateAssemblyNaming("", "", 0, true, "", "");
      } else {
        e.preventDefault();
      }
    }
  };

  useEffect(() => {
    setOpenCollapse(collapseName);
    setOpenNestedCollapse(itemParentName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      //setMiniSidenav(dispatch, window.innerWidth < 1200);
      setMiniSidenav(dispatch, true);
      setTransparentSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : transparentSidenav
      );
      setWhiteSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : whiteSidenav
      );
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location]);

  const handleLogOut = async () => {
    try {
      await AuthService.logout();
      authContext.logout();
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const handleCancelLogOut = () => {
    setLogOutConfirm(false);
  };

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    const template = collapse.map(({ name, route, key, href }) =>
      href ? (
        <Link
          key={key}
          href={href}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavItem name={name} nested />
        </Link>
      ) : (
        <NavLink
          to={route}
          key={key}
          sx={{ textDecoration: "none" }}
          onClick={handleNavAway}
        >
          <SidenavItem name={name} active={route === pathname} nested />
        </NavLink>
      )
    );

    return template;
  };
  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses) =>
    collapses.map(({ name, collapse, route, href, key, type }) => {
      let returnValue;
      if (collapse) {
        returnValue = (
          <SidenavItem
            key={key}
            color={color}
            name={name}
            active={key === itemParentName ? "isParent" : false}
            open={openNestedCollapse === key}
            onClick={({ currentTarget }) =>
              openNestedCollapse === key &&
              currentTarget.classList.contains("MuiListItem-root")
                ? setOpenNestedCollapse(false)
                : setOpenNestedCollapse(key)
            }
          >
            {renderNestedCollapse(collapse)}
          </SidenavItem>
        );
      } else {
        if (name !== "Logout") {
          returnValue = href ? (
            type ? (
              <Can I="edit" this={type}>
                <Link
                  href={href}
                  key={key}
                  target="_blank"
                  rel="noreferrer"
                  sx={{ textDecoration: "none" }}
                >
                  <SidenavItem
                    color={color}
                    name={name}
                    active={key === itemName}
                  />
                </Link>
              </Can>
            ) : (
              <Link
                href={href}
                key={key}
                target="_blank"
                rel="noreferrer"
                sx={{ textDecoration: "none" }}
              >
                <SidenavItem
                  color={color}
                  name={name}
                  active={key === itemName}
                />
              </Link>
            )
          ) : type ? (
            <Can I="edit" a={type}>
              <NavLink
                to={route}
                key={key}
                sx={{ textDecoration: "none" }}
                onClick={handleNavAway}
              >
                <SidenavItem
                  color={color}
                  name={name}
                  active={key === itemName}
                />
              </NavLink>
            </Can>
          ) : (
            <NavLink
              to={route}
              key={key}
              sx={{ textDecoration: "none" }}
              onClick={handleNavAway}
            >
              <SidenavItem
                color={color}
                name={name}
                active={key === itemName}
              />
            </NavLink>
          );
        } else {
          returnValue = (
            <MDBox>
              <MDButton
                fullWidth
                variant="gradient"
                color={color}
                type="button"
                onClick={() => setLogOutConfirm(true)}
              >
                Log Out
              </MDButton>
            </MDBox>
          );
        }
      }
      return <SidenavList key={key}>{returnValue}</SidenavList>;
    });

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({ type, name, icon, title, collapse, noCollapse, key, href, route }) => {
      let returnValue;

      if (type === "collapse") {
        if (href) {
          returnValue = (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavCollapse
                name={name}
                icon={icon}
                active={key === collapseName}
                noCollapse={noCollapse}
              />
            </Link>
          );
        } else if (noCollapse && route) {
          returnValue = (
            <NavLink to={route} key={key} onClick={handleNavAway}>
              <SidenavCollapse
                name={name}
                icon={icon}
                noCollapse={noCollapse}
                active={key === collapseName}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            </NavLink>
          );
        } else {
          returnValue = (
            <SidenavCollapse
              key={key}
              name={name}
              icon={icon}
              active={key === collapseName}
              open={openCollapse === key}
              onClick={() =>
                openCollapse === key
                  ? setOpenCollapse(false)
                  : setOpenCollapse(key)
              }
            >
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          );
        }
      } else if (type === "title") {
        returnValue = (
          <MDTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MDTypography>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      }

      return returnValue;
    }
  );

  return (
    <>
      <SidenavRoot
        {...rest}
        variant="permanent"
        ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
      >
        <MDBox pt={3} pb={1} px={4} textAlign="center">
          <MDBox
            display={{ xs: "block", xl: "none" }}
            position="absolute"
            top={0}
            right={0}
            p={1.625}
            onClick={closeSidenav}
            sx={{ cursor: "pointer" }}
          >
            <MDTypography variant="h6" color="secondary">
              <Icon sx={{ fontWeight: "bold" }}>close</Icon>
            </MDTypography>
          </MDBox>
          <MDBox
            component={NavLink}
            to="/"
            display="flex"
            alignItems="center"
            onClick={handleNavAway}
          >
            {brand && (
              <MDBox component="img" src={brand} alt="Brand" width="2rem" />
            )}
            <MDBox
              pl={1}
              width={!brandName && "100%"}
              sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
            >
              <MDTypography
                component="h6"
                variant="button"
                fontWeight="regular"
                color={textColor}
              >
                {brandName}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
        <Divider
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
        <List>{renderRoutes}</List>
      </SidenavRoot>
      <CrudDialog
        title="Logout"
        size="xs"
        control={{
          open: logOutConfirm,
          onClose: handleCancelLogOut,
        }}
        content={
          <DialogContentText>
            Are you sure you want to logout?
          </DialogContentText>
        }
        actions={{
          confirmName: "Logout",
          confirmHandler: handleLogOut,
        }}
      />
    </>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
