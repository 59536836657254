/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { InputLabel, Autocomplete } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDAvatar from "components/MDAvatar";
import MDAlert from "components/MDAlert";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import FormField from "layouts/applications/wizard/components/FormField";
import { useNavigate, useParams } from "react-router-dom";
import { AbilityContext } from "Can";
import { useAbility } from "@casl/react";
import { useAPSDB } from "hooks/useAPSDB";
import CrudService from "services/cruds-service";
import authService from "services/auth-service";
import PermissionRoleTip from "customInputs/PermissionRoleTip";

const EditUser = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { checkFileType } = useAPSDB();
  const [roles, setRoles] = useState([]);
  const [image, setImage] = useState("");
  const [notification, setNotification] = useState({
    value: false,
    color: "info",
    message: "",
  });
  const [fileState, setFileState] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [user, setUser] = useState({
    id: "",
    name: "",
    email: "",
    role: "",
  });
  const [value, setValue] = useState({});

  const [error, setError] = useState({
    name: false,
    email: false,
    role: false,
    error: false,
    textError: "",
  });

  const ability = useAbility(AbilityContext);

  useEffect(() => {
    (async () => {
      try {
        const response = await CrudService.getRoles();
        const user = await authService.getProfile();
        if (user.data.attributes.role_name === "Admin") {
          setRoles(response.data);
        } else {
          setRoles(response.data.filter((role) => role.name !== "Admin"));
        }
      } catch (err) {
        console.error(err);
        return null;
      }
    })();
  }, []);

  useEffect(() => {
    if (!id) return;
    (async () => {
      try {
        const response = await CrudService.getUser(id);
        const getData = response.data;
        const roleData = await CrudService.getRole(
          getData.relationships.roles.data[0].id
        );
        const role = roleData.data;
        setUser({
          id: getData.id,
          name: getData.attributes.name,
          email: getData.attributes.email,
          role: getData.relationships.roles.data[0].id,
        });
        setImage(getData.attributes.profile_image);
        setValue(role);
      } catch (err) {
        console.error(err);
      }
    })();
  }, [id]);

  useEffect(() => {
    if (roles && roles.length > 0) {
      const role = roles.find((role) => role.id === user.role);
      setValue(role);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles]);

  const changeHandler = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (notification.value === true) {
      setTimeout(() => {
        setNotification({ value: false, color: "info", message: "" });
      }, 5000);
    }
  }, [notification]);

  const changeImageHandler = (e) => {
    const fileInput = document.getElementById("file-input");
    if (!checkFileType(fileInput)) {
      setNotification({
        value: true,
        color: "info",
        message:
          "Invalid file type. Only .jpg, .jpeg or .png files are allowed.",
      });
      return;
    }
    const fileSize = e.target.files[0].size;
    // fileSize converted to MB
    const fileMB = Math.round(fileSize / 1024);
    // The size of the file.
    if (fileMB >= 512) {
      setNotification({
        value: true,
        color: "info",
        message: "Uploaded file is too large. Select a file less than 500KB",
      });
    } else {
      const formData = new FormData();
      formData.append("attachment", e.target.files[0]);
      setFileState(formData);
      setImageUrl(URL.createObjectURL(e.target.files[0]));
      setImage(e.target.files[0]);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const mailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (user.name.trim().length === 0) {
      setError({
        email: false,
        role: false,
        name: true,
        textError: "The name cannot be empty",
      });
      return;
    }

    if (
      user.email.trim().length === 0 ||
      !user.email.trim().match(mailFormat)
    ) {
      setError({
        role: false,
        name: false,
        email: true,
        textError: "The email is not valid",
      });
      return;
    }

    if (value.id && value.id === "") {
      setError({
        name: false,
        email: false,
        password: false,
        confirm: false,
        role: true,
        textError: "Role is required",
      });
      return;
    }

    try {
      let { url } = fileState
        ? await CrudService.imageUpload(fileState, user.id.toString())
        : image;
      const newUser = {
        data: {
          id: user.id.toString(),
          type: "users",
          attributes: {
            name: user.name,
            email: user.email,
            profile_image: fileState
              ? `${process.env.REACT_APP_IMAGES}${url}`
              : image,
          },
          relationships: {
            roles: {
              data: [
                {
                  id: value.id ? value.id.toString() : user.role.toString(),
                  type: "roles",
                },
              ],
            },
          },
        },
      };

      try {
        await CrudService.updateUser(newUser, newUser.data.id);
        navigate("/management/user-management", {
          state: { value: true, text: "The user was succesfully updated" },
        });
      } catch (err) {
        if (err.hasOwnProperty("errors")) {
          setError({ ...error, error: true, textError: err.errors[0].detail });
        }
        console.error(err);
      }
    } catch (err) {
      setError({ ...error, error: true, textError: err.message });
      return null;
    }
  };

  return (
    <DashboardLayout>
      {ability.can("edit", "users") ? (
        <MDBox mt={5} mb={9}>
          <PermissionRoleTip />
          <Grid container justifyContent="center">
            <Grid item xs={12} lg={8}>
              <MDBox mt={6} mb={8} textAlign="center">
                <MDBox mb={1}>
                  <MDTypography variant="h3" fontWeight="bold">
                    Edit User
                  </MDTypography>
                </MDBox>
                <MDTypography
                  variant="h5"
                  fontWeight="regular"
                  color="secondary"
                >
                  Change appropriate details about the user.
                </MDTypography>
              </MDBox>
              {notification.value === true && (
                <MDAlert color={notification.color} mt="20px">
                  <MDTypography variant="body2" color="white">
                    {notification.message}
                  </MDTypography>
                </MDAlert>
              )}
              <Card>
                <MDBox
                  component="form"
                  method="POST"
                  onSubmit={submitHandler}
                  encType="multipart/form-data"
                >
                  <MDBox display="flex" flexDirection="column" px={3} my={4}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          label="Name"
                          placeholder="Alec"
                          name="name"
                          value={user.name}
                          onChange={changeHandler}
                          error={error.name}
                        />
                        {error.name && (
                          <MDTypography
                            variant="caption"
                            color="error"
                            fontWeight="light"
                          >
                            {error.textError}
                          </MDTypography>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          label="Email"
                          placeholder="example@email.com"
                          inputProps={{ type: "email" }}
                          name="email"
                          value={user.email}
                          onChange={changeHandler}
                          error={error.email}
                        />
                        {error.email && (
                          <MDTypography
                            variant="caption"
                            color="error"
                            fontWeight="light"
                          >
                            {error.textError}
                          </MDTypography>
                        )}
                      </Grid>
                    </Grid>
                    <MDBox display="flex" flexDirection="column">
                      <MDBox
                        display="flex"
                        flexDirection="column"
                        marginTop="2rem"
                      >
                        <Autocomplete
                          disableClearable
                          defaultValue={null}
                          options={roles}
                          getOptionLabel={(option) => {
                            if (option) {
                              if (option.name) return option.name;
                            }
                            return "";
                          }}
                          value={value ?? null}
                          onChange={(event, newValue) => {
                            setValue(newValue);
                          }}
                          renderInput={(params) => (
                            <FormField
                              {...params}
                              label="Role"
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                        {error.role && (
                          <MDTypography
                            variant="caption"
                            color="error"
                            fontWeight="light"
                            pl={4}
                          >
                            {error.textError}
                          </MDTypography>
                        )}
                      </MDBox>
                      <MDBox
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <MDBox mt={2} display="flex" flexDirection="column">
                          <InputLabel id="demo-simple-select-label">
                            Profile Image
                          </InputLabel>
                          <MDInput
                            fullWidth
                            type="file"
                            name="attachment"
                            onChange={changeImageHandler}
                            id="file-input"
                            accept="image/*"
                            sx={{ mt: "16px" }}
                          ></MDInput>
                        </MDBox>

                        {image && (
                          <MDBox ml={4} mt={2}>
                            <MDAvatar
                              src={imageUrl ?? image}
                              alt="profile-image"
                              size="xxl"
                              shadow="sm"
                            />
                          </MDBox>
                        )}
                      </MDBox>
                    </MDBox>
                    {error.error && (
                      <MDTypography
                        variant="caption"
                        color="error"
                        fontWeight="light"
                        pt={2}
                      >
                        {error.textError}
                      </MDTypography>
                    )}
                    <MDBox
                      ml="auto"
                      mt={4}
                      mb={2}
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <MDBox mx={2}>
                        <MDButton
                          variant="gradient"
                          color="dark"
                          size="small"
                          px={2}
                          mx={2}
                          onClick={() =>
                            navigate("/management/user-management", {
                              state: { value: false, text: "" },
                            })
                          }
                        >
                          Back
                        </MDButton>
                      </MDBox>
                      <MDButton
                        variant="gradient"
                        color="dark"
                        size="small"
                        type="submit"
                      >
                        Save
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      ) : (
        <MDBox component="h1" fontWeight="bold" fontSize={24} p={2}>
          Unauthorised
        </MDBox>
      )}
      <Footer />
    </DashboardLayout>
  );
};

export default EditUser;
